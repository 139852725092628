import {Navigation} from "swiper/modules";
import {Swiper} from "swiper/react";

import './slider.scss'

const Slider = (props) => {
    const {
        clazz,
        children,
        type,
    } = props

    const paramsSwiper = {
        agency: {
            modules: [Navigation],
            navigation: {
                nextEl: `.${clazz}__button-next`,
                prevEl: `.${clazz}__button-prev`,
            },
            spaceBetween: 8,
            slidesPerView: 'auto',
            breakpoints: {
                1399.98: {
                    spaceBetween: 18,
                }
            }
        },

        default: {
            modules: [Navigation],
            navigation: {
                nextEl: `.${clazz}__button-next`,
                prevEl: `.${clazz}__button-prev`,
            },
            spaceBetween: 8,
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                767.98: {
                    slidesPerView: 2,
                },
                1000.98: {
                    slidesPerView: 3,
                },
                1199.98: {
                    slidesPerView: 4,
                },
                1399.98: {
                    slidesPerView: 4,
                    spaceBetween: 18,
                },
            }
        },
    };

    const changeParamsByType = () => {
        switch (type) {
            case 'agency': return {...paramsSwiper.agency}

            default: return {...paramsSwiper.default}
        }
    }

    const changeClassByType = () => {
        switch (type) {
            case 'agency': return ' swiper-agency'
            case 'model': return ' swiper-model'
            default: return ''
        }
    }

    return (
        <Swiper
            {...changeParamsByType()}
            className={`${clazz ? `${clazz}__slider` : ''}${changeClassByType()}`}
        >
            <button
                slot={"container-start"}
                className={`swiper-button-prev  icon icon--chevrone-left ${clazz}__button-prev`}
            >
            </button>

            {children}

            <button
                slot={"container-end"}
                className={`swiper-button-next icon icon--chevrone-right ${clazz}__button-next`}
            >
            </button>
        </Swiper>
    )
};

export default Slider;
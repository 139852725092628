import { Helmet } from "react-helmet";
import { Button, Icon, Toggle } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import useLadyService from "../../../services/LadyService";
import {Popup} from "../index";

const DeleteApPopup = (props) => {
  const {
    open,
    setOpen,
    profileSlug,
    setData,
    setCount,
    onChangeStatus,
    status,
  } = props;

  const { t } = useLadyService();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/profile/${profileSlug}`;
        const method = "DELETE";

        await makeRequest({ route, method });

        setCount((prev) => prev - 1);
        setData((prevState) => {
          const newPrevState = prevState.filter(
            (item) => item.slug !== profileSlug
          );

          return newPrevState;
        });

        showToast({
          message: `${t("ad")} ${profileSlug} удалена`,
          variant: "success",
        });
        setOpen(false);
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      method={"DELETE"}
      clazz={'gap-16'}
    >
        <h3 className="m-12-24">{t("areyousure")}</h3>
        <span className={"color-700"}>{t("irreverse")}</span>
        <div className="account-page__removebtn__active justify-center">
          <h3>{t("activate")}</h3>
          <Toggle onChange={onChangeStatus} checked={status} />
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l-forever"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {t("cancel")}
          </Button>

          <Button
            size={"l-forever"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {t("yesdelete")}
          </Button>
        </div>
    </Popup>
  );
};

export default DeleteApPopup;

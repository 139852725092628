import React from "react";
import { useTranslation } from "react-i18next";

const ModelCounters = ({ statistic, edit }) => {
  const { t } = useTranslation("translation");
  return edit ||
    !!statistic?.favorites ||
    !!statistic?.book ||
    !!statistic?.calls ? (
    <div className="model__counters">
      {(edit || !!statistic?.favorites) && (
        <div className="model__counter">
          <span className={"p2"}>{t("infavorites")}</span>
          {statistic.favorites}
        </div>
      )}

      {(edit || !!statistic?.book) && (
        <div className="model__counter">
          <span className={"p2"}>{t("prepaidorders")}</span>
          {statistic.book}
        </div>
      )}

      {(edit || !!statistic?.calls) && (
        <div className="model__counter">
          <span className={"p2"}>{t("videochats")}</span>
          {statistic.calls}
        </div>
      )}
    </div>
  ) : null;
};
export default ModelCounters;

import { createSlice } from "@reduxjs/toolkit";

const tabsSlice = createSlice({
  name: "tabs",
  initialState: {
    activeTabId: 1,
  },
  reducers: {
    setActiveTabId: (state, action) => {
      state.activeTabId = action.payload;
    },
  },
});

export const { setActiveTabId } = tabsSlice.actions;

export const selectActiveTabId = (state) => state.tabs.activeTabId;

export default tabsSlice.reducer;

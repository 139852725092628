import useLadyService from "../../services/LadyService";
import { Icon } from "../ui";

const ZeroZone = ({ setOpen, mainText, disc, longDisc, setVisualElem, visualElem, clazz }) => {
  const { t } = useLadyService();

  const handleOpen = () => {
    setOpen(true);
    if(setVisualElem) {
      setVisualElem(visualElem);
    }
  };

  return (
    <div className={`upload__zone${clazz ? ` ${clazz}` : ''}`} onClick={handleOpen}>
      <div className="upload__zone__main">
        <Icon clazz={"_green"} size={"xl"} spritePath={"plus-1"} />
        <span className="p1 color-main">{mainText}</span>
        <span className="color-green text-center">
          {disc && `+ ${disc}% ${t("toorders")}`}
          {longDisc && longDisc}
        </span>
      </div>
    </div>
  );
};

export default ZeroZone;

import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useLadyService from "@/services/LadyService";
import {
  getStories,
  getAllSorted,
  setStoriesId,
  setStories,
} from "@/stores/slices/modelsState";
import { filterPlace, isFilterSearch } from "@/stores/slices/filterSlice";
import { setStoriesStatus } from "@/stores/slices/popupSlice";
import { getUserCurrentCity } from "@/stores/slices/userSlice";
import { makeRequest } from "@/services/makeRequest";
import StoriesCircle from "../../stories/circle/StoriesCircle";

import "./storiesSlider.scss";

const StoriesSlider = ({ size = "xs" }) => {
  const { dispatch, lang, setMediaSize } = useLadyService();

  const valueNavigation = useSelector(getAllSorted);
  const isFilterValue = useSelector(isFilterSearch);
  const valuePlace = useSelector(filterPlace);
  const [isRenderElem, setIsRenderElem] = useState(true);

  useEffect(() => {
    if (valueNavigation.length > 0 || valuePlace.length || isFilterValue) {
      setIsRenderElem(false);
    } else {
      setIsRenderElem(true);
    }
  }, [valueNavigation, isFilterValue]);
  const city = useSelector(getUserCurrentCity);
  const [page, setPage] = useState(1);

  const stories = useSelector(getStories);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `stories/${city.id}`;
        const method = "GET";
        const payload = {
          page,
        };

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.stories;
          dispatch(setStories(result));
        }
      } catch (error) {}
    };

    lang && page && city.id && stories.length === 0 && fetchData();
  }, [lang, page, city.id]);

  if (isRenderElem && !!stories.length) {
    return (
      <Swiper
        className={"stories-circle"}
        breakpoints={{
          0: {
            spaceBetween: 30,
            slidesPerView: "auto",
          },
          1399.98: {
            slidesPerView: 7,
            spaceBetween: 0,
          },
        }}
      >
        {!!stories?.length &&
          stories.map((i, index) => {
            return (
              <SwiperSlide
                onClick={() => {
                  dispatch(setStoriesStatus(true))
                  dispatch(setStoriesId(index))
                }}
                key={i.id}
                className={`stories-circle__item`}
              >
                <StoriesCircle
                  img={setMediaSize(i.profile_photo, `${size}`)}
                  name={i.name}
                  age={i.age}
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
    );
  }
};

export default StoriesSlider;

import React, { useCallback } from "react";
import useLadyService from "@/services/LadyService";
import { InputInLabel } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";
import debounce from "lodash.debounce";

export const PornoStar = ({
  register,
  inputValue,
  setValue,
  getValues,
  userFormData,
  setFormData,
  setInputValue,
}) => {
  const { t, token } = useLadyService();

  const fetchData = async (str, index) => {
    try {
      setValue(`erolink-${index}`, str);
      const formValue = getValues();

      const res = [
        { video_link: formValue[`erolink-0`], original_link: formValue[`erolink-0`], status: 0},
        { video_link: formValue[`erolink-1`], original_link: formValue[`erolink-1`], status: 0},
        { video_link: formValue[`erolink-2`], original_link: formValue[`erolink-2`], status: 0},
      ].filter((item) => !!item.video_link);

      const route = `user/profile/${userFormData?.slug}`;

      const method = "PUT";
      const payload = {
        porn: res,
      };

      const result = await makeRequest({ route, method, payload });
      if (result) {
        setFormData((prevData) => {
          return {
            ...prevData,
            porn_star: res,
          };
        });
      }
    } catch (error) {
      console.log(error);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const loadPorno = useCallback(
    debounce((value, index) => {
      fetchData(value, index);
    }, 1000),
    []
  );

  const handleInputChange = (event, index) => {
    let value = event.target.value;

    setInputValue((prevStates) => {
      const newState = [...prevStates];
      if (value) {
        newState[index] = {
          status: 0,
          video_link: value,
        };
      }
      if (!value) {
        newState[index] = {
          status: undefined,
          video_link: "",
        };
      }

      return newState;
    });

    token && loadPorno(value, index);
  };
  return (
    <>
      <div className="d-flex gap-12 fd-column">
        <span>{t("getporno")}</span>
        <div className="create-page__eroLinks">
          {inputValue.map((item, index) => {

            return (
              <div className="eroLink" key={index}>
                <InputInLabel
                  type={"text"}
                  id={`create-erolink-${index}`}
                  placeholder={"https://pornhub.com/eroero99"}
                  register={{ ...register(`erolink-${index}`) }}
                  onChange={(e) => handleInputChange(e, index)}
                  value={item.original_link}
                >
                  {t("linktovid", {index: index + 1})}
                </InputInLabel>
                {item.video_link &&
                  item.video_link &&
                  item.original_link &&
                  item.original_link !== null && (
                    <div
                      className={`agency-card__img img-wrapper`}
                    >
                      <iframe
                        allowFullScreen
                        src={item.video_link}
                        title={`video-${index}`}
                      ></iframe>
                    </div>
                  )}
                {!item.original_link && item.video_link && (
                  <div
                    className={`agency-card__img img-wrapper`}
                  ></div>
                )}
                {item.video_link && !item.original_link && (
                  <span className="ero-status color-red-main text-center">
                    {t("processing")}
                  </span>
                )}
                {item.status === 2 && item.video_link && (
                  <span className="ero-status color-red-main text-center">
                    {t("declinedbymoderator")}
                  </span>
                )}
                {item.status === 1 && item.video_link && (
                  <span className="ero-status color-green text-center">
                    {t("publishedono")}
                  </span>
                )}
                {item.status === 0 && item.video_link && item.original_link && (
                  <span className="ero-status color-main text-center">
                    {t("moderated24hour")}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

import useLadyService from "@/services/LadyService";
import { Button, Icon } from "@/components/ui";
import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AttentionBox from "../components/AttentionBox/AttentionBox";
import GuideTags from "../components/tags/GuideTags";

import shortFilter from "@/assets/img/guide/default-and-customer/short-filter.webp";
import filterImg from "@/assets/img/guide/default-and-customer/filter.webp";
import mapImg from "@/assets/img/guide/default-and-customer/map.webp";
import navImg from "@/assets/img/guide/default-and-customer/nav.webp";
import noteImg from "@/assets/img/guide/default-and-customer/note.webp";
import complainImg from "@/assets/img/guide/default-and-customer/complain.webp";
import tariffsImg from "@/assets/img/guide/default-and-customer/tariffs.webp";
import { setAuthorizationStatus } from "../../../../stores/slices/popupSlice";
import GuideAside from "../components/aside/GuideAside";
import {ButtonLink} from "../../../ui";
import {HashLink} from "react-router-hash-link";
import {Helmet} from "react-helmet";

const GuideDefault = ({ scrolledClass, show, setShow }) => {
  const { dispatch } = useLadyService();

  const { t } = useTranslation("translation");

  const LogInTemplate = () => (
    <ButtonLink
      clazz={"button_outline--black guide__link"}
      onClick={() => dispatch(setAuthorizationStatus(true))}
      href={"#"}
      size={'xs'}
    >
      {t("g-auth")}{" "}{">"}{">"}
    </ButtonLink>
  );

  const IconTemplate = (translateKey, icon) => (
    <Icon
      title={t(translateKey)}
      square
      clazz={"color-green"}
      spritePath={icon}
      size={"l"}
    />
  );

  return (
    <main className="guide-page">
      <Helmet>
        <meta
            name="description"
            content={t("guidedesc")}
        />
      </Helmet>
      <section className="guide__container">
        <h1 className={"mb-24 mb-16-mob"}>{t("g-titleh1")}</h1>

        <Button
          clazz={`button--tetriary guide__button-heading${scrolledClass}`}
          size={"s"}
          onClick={() => setShow((prev) => !prev)}
        >
          <Icon size={"l"} spritePath={"instruction-cont"} />
          {t("i-contents")}
        </Button>

        <p>
          {t("g-greetings")}{" "}
          <Link to={"/"} className={"green"}>
            Lady4Love
          </Link>{" "}
          {t("g-greetingstext")}
        </p>

        <p className={"text-center"}>
          <b>
            {t("g-please")},{" "}
            <Link
              onClick={() => dispatch(setAuthorizationStatus(true))}
              to={"#"}
              className={"green"}
            >
              {t("g-auth")}
            </Link>
            , {t("g-authafter")}
          </b>
        </p>

        <div className="guide__body">
          <GuideAside
            props={{
              show,
              setShow,
              scrolledClass,
            }}
          />

          <div className="guide__content">
            <h2>{t("g-portaltext")} Lady4Love</h2>

            <h3 id={"guarantee"}>{t("g-guarantee")}</h3>

            <p>{t("g-serviceacc")}.</p>

            <p>{t("g-securedata")}.</p>

            <AttentionBox type={"advice"}>{t("g-confidenc")}.</AttentionBox>

            <h3 id={"sortAndFilter"}>{t("g-sortandfilter")}</h3>

            <div className={"clearfix"}>
              <img
                className={"float-left"}
                src={shortFilter}
                alt=""
              />

              <p>{t("g-filters")}.</p>

              <p>
                {t("g-sotrstatus")}{" "}
                <Link
                  className={"color-green"}
                  target={"_blank"}
                  to={`/prague/indi-elitelady-vchat`}
                >
                  {`${t("g-sotrstatuses")}`}.
                </Link>
              </p>
            </div>

            <h3 id={"saveFilters"}>{t("g-savefilters")}</h3>

            <div className={"guide__box--friend-agency"}>
              <img
                className={"float-right"}
                src={filterImg}
                alt=""
              />
              <p>
                {t("g-savecriterii")} <Link to={"#"}>{t("subandfilters")}</Link>
                .
              </p>

              <p>{t("g-subscribenotif")}.</p>

              <p>{t("g-privatpatry")}.</p>
            </div>

            <h3 id={"statusElite"}>{t("g-searchmodels")}</h3>

            <p>{t("g-searchmap")}</p>

            <p>
              {t("g-nearescort")} <Link to={"#"}>{t("profile")}</Link>{" "}
              {t("g-searchradius")}
            </p>
            <img className={"img-contain"} src={mapImg} alt="" />

            <h3 id={"interactions"}>{t("g-interactions")}</h3>

            <AttentionBox type={"warning"}>
              {t("g-addfav")} <Link onClick={() => dispatch(setAuthorizationStatus(true))} to={"#"}>{t("history")}</Link>{" "}
              {t("g-addfavaccess")}.
            </AttentionBox>

            <img src={navImg} className={"img-contain"} alt="" />

            <h3 id={"notes"}>{t("g-modelsnotes")}</h3>

            <div className={"guide__box--friend-agency"}>
              <p>{t("g-uniquefeature")}.</p>

              <p>{t("g-serves")}.</p>

              <img
                className={"img-contain"}
                src={noteImg}
                alt=""
              />
            </div>

            <AttentionBox type={"advice"}>
              {t("g-quicklyfind")} <Link onClick={() => dispatch(setAuthorizationStatus(true))} to={"#"}>{t("history")}</Link>{" "}
              {t("g-filtered")} «{t("i-notes")}».
            </AttentionBox>

            <h3 id={"feedback"}>{t("g-feedback")}</h3>
            <p>{t("g-commentirate")}.</p>
            <p>{t("g-reviews")}.</p>
            <p>
              {t("g-reviewsrate")} <Link onClick={() => dispatch(setAuthorizationStatus(true))} to={"#"}>{t("history")}</Link>{" "}
              {t("g-filtered")} «{t("reviews")}».
            </p>

            <AttentionBox type={"important"}>{t("g-blocking")}</AttentionBox>

            <h3 id={"complaints"}>{t("g-complaints")}</h3>
            <div className={"guide__box--friend-agency"}>
              <img
                className={"float-right"}
                src={complainImg}
                alt=""
              />
              <p>
                {t("g-violations")}{' '}{t("g-sendcomplaint")}
                .{' '}{t("g-describe")}.
              </p>
              <p>{t("g-measures")}.</p>
              <p>
                {t("g-complaininsection")} <Link onClick={() => dispatch(setAuthorizationStatus(true))} to={"#"}>{t("history")}</Link>{" "}
                {t("g-filtered")} «{t("i-complaint")}».
              </p>
            </div>

            {/*////////////////////////////////////////////////////////////////////*/}

            <h2>{t("g-uniquefeatures")}</h2>

            <h3 id={"videochat"}>
              {IconTemplate("videochat", "video")}
              {t("videochatswithmodel")}
            </h3>

            <AttentionBox type={"warning"}>
              {t("g-vchatfeature")}{" "}<HashLink to={"#tariffs"}>Platinum</HashLink>{" "}
              {t("g-vhatpermonth")}.{' '}{LogInTemplate()}
            </AttentionBox>

            <h3 id={"eroContent"}>
              {IconTemplate("erocontent", "pants")}
              {t("erocontent")}
            </h3>

            <AttentionBox type={"warning"}>
              {t("g-eromodels")}.
              <br />
              <b>{t("erocontent")}</b> {t("g-haveicon")}{" "}
              <Icon
                size={"l"}
                square
                clazz={"color-green"}
                spritePath={"pants"}
              />{" "}
              ({t("g-fastsearch")} {t("erocontent")}).
              <br />
              {t("g-erocost")} <a href={"#tariffs"}>Platinum</a>{" "}
              {t("g-erocostrest")}.
              <br />
              {t("g-erobuy")} <Link to={"#"} onClick={() => dispatch(setAuthorizationStatus(true))}>{t("history")}</Link> {t("g-filtered")}{" "}
              «{t("erocontent")}».
              {LogInTemplate()}
            </AttentionBox>

            <h3 id={"preorders"}>
              {IconTemplate("onlineorder", "pay-content")}
              {t("g-preorders")} – {t("i-uniquefunc")}!
            </h3>

            <AttentionBox type={"warning"}>{t("g-pordermodel")}.</AttentionBox>

            <AttentionBox type={"important"}>
              {t("g-porderwarning")}.{LogInTemplate()}
            </AttentionBox>

            <h3 id={"previews"}>
              {IconTemplate("modelpreviews", "casting")}
              {t("modelpreviews")}
            </h3>

            <AttentionBox type={"warning"}>
              {t("g-pordercouple")}. {t("g-makechoise")}
            </AttentionBox>

            <AttentionBox type={"advice"}>
              {t("g-reviewcan")}.{LogInTemplate()}
            </AttentionBox>

            <h3 id={"parties"}>
              {IconTemplate("g-party", "party")}
              {t("g-party")}
            </h3>

            <AttentionBox type={"warning"}>
              {t("g-partyorg")} <a href={"#tariffs"}>Platinum</a>.
              <br />
              {t("g-partysubscr")} {t("subandfilters")}. {t("g-incognitotext")}
            </AttentionBox>

            <h3 id={"modelStatuses"}>{t("g-modelstatuses")}</h3>

            <GuideTags />

            <h3 id={"tariffs"}>{t("g-usertariffs")}</h3>
            <img className={"img-contain"} src={tariffsImg} alt="" />
          </div>
        </div>
      </section>
    </main>
  );
};

export default GuideDefault;

import Helmet from "react-helmet";
import { Button, Checkbox, Icon, InputInLabel, Loader } from "@/components/ui";
import showToast from "../../toast/Toast";

import { useState, useEffect, useCallback } from "react";
import { makeRequest } from "@/services/makeRequest";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import useLadyService from "@/services/LadyService";
import { useSelector } from "react-redux";
import { getIndiProfiles } from "@/stores/slices/lkIndiSlice";
import {Popup} from "../index";

const Res = ({
  isLoading,
  options,
  value,
  handleAddChange,
  search,
  isDataEmpty,
}) => {
  const { t, setMediaSize } = useLadyService();

  switch (true) {
    case isLoading: {
      return <Loader />;
    }

    case !!options.length: {
      return options.map((i) => {
        const { photos, id, name, slug: slugGf } = i;

        return (
          <div key={id} className="agency-block">
            <img src={setMediaSize(photos[0], "xs")} alt="girl" />
            <Link className="agency-block__link-to">{name}</Link>
            <Checkbox
              // radio={true}
              border={false}
              checked={id === value}
              onChange={() => handleAddChange(id, name, slugGf )}
            />
          </div>
        );
      });
    }

    case !search: {
      return <span className="w-100 text-center">{t("startname")}</span>;
    }

    case isDataEmpty: {
      return <span className="w-100 text-center">{t("noproffound")}</span>;
    }

    default: {
      return <Loader />;
    }
  }
};

const LinkWithGirlfriend = (props) => {
  const { open, setOpen, slug: slugProfile, city_id, setProfileInfo } = props;

  const { t } = useLadyService();

  const allProfiles = useSelector(getIndiProfiles)

  const [search, setSearch] = useState("");
  // const [debouncedSearch, setDebouncedSearch] = useState("");

  // const [query, setQuery] = useState("");

  const [options, setOptions] = useState([]);

  // const [value, setValue] = useState([]);
  const [value, setValue] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  // const handleAddChange = (id) => {
  //   setValue((prevValue) => {
  //     if (prevValue.includes(id)) {
  //       return prevValue.filter((item) => item !== id);
  //     } else {
  //       return [...prevValue, id];
  //     }
  //   });
  // };

  function checkSlugs(profiles, slug1, slug2) {
    const slugs = profiles.map(product => product.slug);
    return slugs.includes(slug1) && slugs.includes(slug2);
  }

  const [selectedGirl, setSelectedGirl] = useState({
    name: "",
    slug: "",
    status: ""
  })

  const handleAddChange = (slugAgency, name, slug) => {
    const result = checkSlugs(allProfiles, slug, slugProfile);
    console.log('result', result);
    setSelectedGirl({
      name: name,
      slug: slug,
      status: result ? 1 : 0
    })
    if (slugAgency === value) {
      setValue(null);
    } else {
      setValue(slugAgency);
    }
  };

  const handleFilter = useCallback((e) => {
    setIsDataEmpty(false);
    const val = e.currentTarget.value;
    setSearch(val);
    // setDebouncedSearch(val);
  }, []);

  const onClose = () => {
    // setValue([]);
    setValue(null);
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        const route = `user/profile/${slugProfile}/add-friend`;
        const method = "POST";
        const payload = {
          profiles: [value],
        };

        setProfileInfo(selectedGirl)

        await makeRequest({ route, method, payload });
        onClose();
        showToast({
          message: t("success"),
          variant: "success",
        });
      } catch (error) {
        console.log(error);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  const fetchData = useCallback(
    debounce(async ({ searchValue }) => {
      try {
        if (!!searchValue) {
          setIsLoading(true);
          const route = `profiles`;
          const method = "GET";
          const payload = {
            city: city_id,
            limit: 1000,
            search: searchValue,
          };

          const { profiles } = await makeRequest({ route, method, payload });
          setIsDataEmpty(!profiles.length);
          setOptions(profiles);

          setIsLoading(false);
        } else {
          setOptions([]);
          setIsLoading(false);
        }
      } catch (error) {}
    }, 1000),
    []
  );

  useEffect(() => {
    if (!!search.length) {
      fetchData({ searchValue: search });
    } else {
      fetchData({ searchValue: null });
      setOptions([]);
    }
  }, [fetchData, search]);

  return (
    <Popup
      open={open}
      setOpen={onClose}
      onSubmit={onSubmit}
      clazz={'gap-24'}
    >

        <h3 className="m-12-24">{t("connectgf")}</h3>

        <span className={"color-main"}>{t("linktogirl")}</span>

        <InputInLabel
          placeholder={t("searchmodel")}
          // onChange={(e) => setQuery(e.currentTarget.value)}
          onChange={handleFilter}
          // value={query}
          value={search}
          size={"s"}
        />

        {/* {isLoading ? (
          <Loader />
        ) : (
          <div className="agency-block__content">
            {options.map((i) => {
              const { photos = [], id, name } = i;

              return (
                <div key={id} className="agency-block">
                  <img
                    src={!!photos.length ? photos[0] : null}
                    alt={name}
                    title={name}
                  />

                  <Link className="agency-block__link-to">{name}</Link>
                  <Checkbox
                    border={false}
                    onChange={() => handleAddChange(id)}
                    checked={value.includes(id)}
                  />
                </div>
              );
            })}
          </div>
        )} */}

        <div className="agency-block__content">
          <Res
            isLoading={isLoading}
            options={options}
            value={value}
            handleAddChange={handleAddChange}
            search={search}
            isDataEmpty={isDataEmpty}
          />
        </div>

        <Button
          size={"l-forever"}
          // disabled={!value.length}
          buttonType={"submit"}
          disabled={!value}
          clazz={"button--green justify-center"}
        >
          {t("confirm")}
        </Button>
    </Popup>
  );
};

export default LinkWithGirlfriend;

import {Rating, Star} from "@smastrom/react-rating";

import './ratingStars.scss'

const RatingStars = (props) => {
    const {
        value,
        clazz,
        readOnly = true,
        color,
    } = props

    const myStyles = {
        itemShapes: Star,
    }

    return (
            <Rating
                transition="none"
                spaceInside="none"
                radius="none"
                className={`rating-stars${clazz ? ` ${clazz}` : '' }${color ? ` rating-stars--${color}` : '' }`}
                readOnly={readOnly}
                value={value}
                itemStyles={myStyles}
                orientation="horizontal"
            />
    )
}

export default RatingStars
import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import useLadyService from "../../../services/LadyService";
import { Popup } from "../index";
import { Link } from "react-router-dom";

const DeclineProfile = (props) => {
  const { open, setOpen, fetch } = props;

  const { t, navigate } = useLadyService();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const handleDecline = async () => {
    const result = await fetch("decline");
    if (result) {
      setOpen(false);
      navigate(`/`)
    }
  };

  const h3 = t("delete") + " " + t("profuser").toLowerCase();

  return (
    <Popup open={open} setOpen={handleCloseDelete} clazz={"gap-16"}>
      <h3 className="m-12-24">{h3}</h3>
      <p className="color-main">{t('dontpublish')}</p>
      <div className="popup-form__buttons">
        <Button
          size={"l-forever"}
          clazz={"button--secondary justify-center"}
          onClick={() => handleCloseDelete()}
        >
          {t("cancel")}
        </Button>

        <Button
          size={"l-forever"}
          clazz={"button--error justify-center"}
          onClick={() => handleDecline()}
        >
          {t("yesdelete")}
        </Button>
      </div>
    </Popup>
  );
};

export default DeclineProfile;

import React from "react";
import {Icon, RatingStars} from "@/components/ui";
import useLadyService from "@/services/LadyService";

const GirlCardAdvantages = ({ languages, rating }) => {

  const { t} = useLadyService();
  return (
    <div className="girl-card__advantages">
      {!!rating ? <RatingStars value={rating} readOnly={true}/> : null}

      {!!languages.length ? (
        <div className="girl-card__speak">
          <span>{t("ispeak") + ":"}</span>
          {languages.map((lang, index) => {
            const key = Object.keys(lang)[0];

            return (
              <Icon
                key={index}
                title={lang[key].name}
                type={"flag"}
                spritePath={lang[key].code.toUpperCase()}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  )
}

export default GirlCardAdvantages
import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import { Banner, Breadcrumbs, ButtonLink, ShowMoreText } from "@/components/ui";
import { useSelector } from "react-redux";
import axios from "axios";
import { getSelectedCount } from "@/stores/slices/agencyFilterSlice";
import {
  setGalleryMediaIndex,
  setGalleryStatus,
  setStoriesStatus,
} from "@/stores/slices/popupSlice";
import baseUrl from "@/services/apiConfig";
import { SwiperSlide } from "swiper/react";
import { setStoriesId } from "@/stores/slices/modelsState";
import TabSlider from "../../sliders/tabSlider/TabSlider";
import StoriesComponent from "../../stories/StoriesComponent";
import Gallery from "../../popups/gallery/Gallery";

import "../agency/agencyPage.scss";
import "./partyPage.scss";
import useLadyService from "@/services/LadyService";
import { useParams } from "react-router-dom";
import { makeRequest } from "@/services/makeRequest";
import { useTranslation } from "react-i18next";
const AgencyPage = (props) => {
  const { dispatch, lang, city, navigate } = useLadyService();
  const { t } = useTranslation("translation");
  const breadcrumbsList = [
    {
      id: 1,
      title: "Главная",
      link: "/",
    },
    {
      id: 2,
      title: "События",
      link: "#",
    },
    {
      id: 3,
      title: "Kay Escorts Party 2023",
      link: "",
    },
  ];
  const { partyId } = useParams();
  const [onePartyData, setOnePartyData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `parties/${partyId}`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        if (!data) {
          navigate("/404");
        }
        if (data) {
          const result = data.data;
          setOnePartyData(result);
          console.log(result);
        }
      } catch (error) {}
    };

    partyId && lang && fetchData();
  }, [partyId, lang]);

  const [data, setData] = useState([]);
  const { partySlug } = useParams();
  useEffect(() => {
    if (lang) {
      console.log("party");
      axios
        .get(`${baseUrl}profiles/new/1&lang=${lang}`)
        .then((response) => setData(response.data.profiles));
      // axios.get(`${baseUrl}parties/0?lang=${lang}`)
      //   .then((response) => setData(response.data.profiles));
    }
  }, [lang, city.id]);

  const selectedModelsCount = useSelector(getSelectedCount);

  const {
    id = onePartyData?.id,
    name = onePartyData?.name,
    description = onePartyData?.description,
    includes = onePartyData?.includes,
    agency = onePartyData?.agency,
    date = onePartyData?.date,
    start_time = onePartyData?.start_time,
    end_time = onePartyData?.end_time,
    address = onePartyData?.address,
    coordinates = onePartyData?.coordinates,
    price = onePartyData?.price,
    prepayment = onePartyData?.prepayment,
    profiles = onePartyData?.profiles,
  } = props;

  const tabsBtns = [
    {
      title: "Фото",
      icon: "image",
      link: "photo",
    },

    {
      title: "Видео",
      icon: "film",
      link: "video",
    },
  ];

  return (
    <>
      <Header />

      <main className={"agency-party"}>
        <Breadcrumbs data={breadcrumbsList} clazz={"__container mb-20"} />

        <Banner props={{}} />

        <section className="agency__top __container">
          {/*<TabSlider tabs={tabsBtns} clazz={"agency-tabs"}>*/}
          {/*  <SwiperSlide*/}
          {/*    className={`agency-tabs__slide`}*/}
          {/*    data-hash={tabsBtns[0].link}*/}
          {/*  >*/}
          {/*    {girls_slider_db.slice(0, 6).map((data, index) => {*/}
          {/*      const result = () => {*/}
          {/*        dispatch(setStoriesStatus(true));*/}
          {/*        dispatch(setStoriesId(index));*/}
          {/*      };*/}
          {/*      return (*/}
          {/*        <img*/}
          {/*          src={data.photos[0]}*/}
          {/*          onClick={() => result()}*/}
          {/*          title={`Фотографии эскорт-модель ${data.name}`}*/}
          {/*          alt={`Фотографии эскорт-модель ${data.name}`}*/}
          {/*        />*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </SwiperSlide>*/}

          {/*  <SwiperSlide*/}
          {/*    className={`agency-tabs__slide`}*/}
          {/*    data-hash={tabsBtns[1].link}*/}
          {/*  >*/}
          {/*    {interior_db.slice(0, 6).map((data, index) => {*/}
          {/*      const result = () => {*/}
          {/*        dispatch(setGalleryMediaIndex(index));*/}
          {/*        dispatch(setGalleryStatus(true));*/}
          {/*      };*/}
          {/*      return (*/}
          {/*        <img*/}
          {/*          src={data.photos[0]}*/}
          {/*          onClick={() => result()}*/}
          {/*          title={`Фотографии эскорт-модель ${data.name}`}*/}
          {/*          alt={`Фотографии эскорт-модель ${data.name}`}*/}
          {/*        />*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </SwiperSlide>*/}
          {/*</TabSlider>*/}

          <div className="agency-party__description">
            {name && <h2 className="mb-24 mb-16-mob">{name}</h2>}

            {description ? (
              <ShowMoreText clazz="mb-24">{description}</ShowMoreText>
            ) : null}

            <h2 className="mb-24 mb-16-mob">{t("dateandplace")}</h2>
            <div className="d-flex flex-wrap gap-24 w-100 mb-32 mb-24-mob">
              {address ? (
                <a
                  href="#"
                  className="model-header__address underline"
                  title={t('viewonmap')}
                >
                  <i className="icon-flag icon-flag-m CZ"></i>
                  {address}
                  <i className="icon icon-m icon--map"></i>
                </a>
              ) : null}

              {date || (start_time && end_time) ? (
                <a
                  href="#"
                  className="model-header__address underline"
                  title="Добавить в календарь"
                >
                  {date}, {t("from")} {start_time} {t("until")} {end_time}
                  <i className="icon icon-m icon--calendar"></i>
                </a>
              ) : null}
            </div>

            <div className="d-flex gap-12 w-100 justify-center mb-32 mb-24-mob">
              <div className="start-videochat__box w-100">
                {price ? (
                  <div>
                    <p className="p2">{t("costofparticipation")}</p>
                    <span className="title_h3">
                      {price?.substring(0, price.length - 3)}€
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="start-videochat__box w-100">
                {prepayment && !(prepayment === 0) ? (
                  <div>
                    <p className="p2">{t("prepayment")}</p>
                    <span className="title_h3">
                      {prepayment.substring(0, prepayment.length - 3)}€
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            <button
              data-open-popup="takePart"
              type="button"
              className="button button-l button--green justify-center w-100 mb-32 mb-24-mob"
            >
              {t("signmeup")}
            </button>

            <h2 className="mb-24 mb-16-mob">{t("contacts")}</h2>

            {/*{phone || whatsapp || telegram ?*/}
            {/*  <div className="agency__buttons">*/}
            {/*      {phone ?*/}
            {/*        <ButtonLink size={'l'} href="tel:+422589996655" clazz="button--primary td-none w-100">*/}
            {/*            {phone}*/}
            {/*        </ButtonLink>*/}
            {/*        : null}*/}
            {/*      {whatsapp ?*/}
            {/*        <ButtonLink square={true} size={'l'} href={`https://wa.me/${whatsapp}`} clazz="button--primary">*/}
            {/*            <i className="icon icon-xl icon--whatsapp"></i>*/}
            {/*        </ButtonLink>*/}
            {/*        : null}*/}

            {/*      {telegram ?*/}
            {/*        <ButtonLink square={true} size={'l'} href={`https://t.me/${telegram}`} clazz="button--primary">*/}
            {/*            <i className="icon icon-xl icon--telegram"></i>*/}
            {/*        </ButtonLink>*/}
            {/*        : null}*/}
            {/*  </div>*/}
            {/*  : null}*/}
          </div>
        </section>

        <section className="agency-party__list __container">
          <h3>Включено в стоимость</h3>
          <ul className="model__serfices-box p1 color-main">
            <li>
              Шампанское по умолчанию:
              <span className="color-600">
                вечер вы будете наслаждаться потоком отборного шампанского,
                который создаст атмосферу веселья и роскоши.
              </span>
            </li>
            <li>
              Звук и свет:
              <span className="color-600">
                Музыкальное оформление и световые эффекты, созданные специально
                для этой вечеринки, чтобы поддерживать настроение и подчеркнуть
                веселье.
              </span>
            </li>
            <li>
              Эксклюзивная атмосфера:
              <span className="color-600">
                Пространство полностью оформлено и подсвечено, чтобы создать
                уникальную и неповторимую атмосферу вечеринки.
              </span>
            </li>
            <li>
              Дополнительные развлечения:
              <span className="color-600">
                Ожидайте неожиданное! Дополнительные развлечения и шоу будут
                украшать наш вечер, чтобы сделать его ещё более запоминающимся.
              </span>
            </li>
            <li>
              Закуски и угощения:
              <span className="color-600">
                Полный фуршет ваших любимых закусок и угощений, которые будут
                доступны в течение всего мероприятия.
              </span>
            </li>
            <li>
              Фотограф и видеограф:
              <span className="color-600">
                Профессионалы будут запечатлевать каждый момент этого вечера,
                чтобы вы могли вспомнить и поделиться этими воспоминаниями.
              </span>
            </li>
            <li>
              Профессиональные бармены:
              <span className="color-600">
                Наши опытные бармены готовы приготовить для вас любимые коктейли
                и обеспечить беспрерывное пополнение напитков.
              </span>
            </li>
            <li>
              Тематический декор:
              <span className="color-600">
                Удивительные декорации, созданные специально для вечеринки,
                чтобы добавить особый шарм и стиль.
              </span>
            </li>
          </ul>
        </section>

        <section className="agency-party__models __container">
          <h3>126 моделей примут участие</h3>
        </section>
      </main>

      <Footer />

      {/*{!!girls_slider_db.length && (*/}
      {/*  <StoriesComponent*/}
      {/*    // stories={stories}*/}
      {/*    stories={girls_slider_db.slice(0, 8)}*/}
      {/*    descriptionText={true}*/}
      {/*    favoriteButton={true}*/}
      {/*  />*/}
      {/*)}*/}

      {/*<Gallery id={'Interior'} data={interior_db}/>*/}
    </>
  );
};

export default AgencyPage;

import { GoogleMap, InfoBox, Marker } from "@react-google-maps/api";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { getGmapsApiReady } from "@/stores/slices/mapModelSlice";
import {
  setOneProfileAddress,
  setOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";
import { getOneProfileCoords } from "@/stores/slices/oneProfileSlice";
import useLadyService from "@/services/LadyService";
import { Icon } from "@/components/ui";
import { Loader } from "../../../../../ui";
import "./map.scss";

const Map = ({
  city,
  setBounds,
  coords,
  markerActive,
  setMarkerActive,
  clazz,
  setAddress = false,
  disabled,
}) => {
  const [map, setMap] = useState(null);
  const [cityBounds, setCityBounds] = useState(null); // Состояние для границ города
  const { lang, dispatch } = useLadyService();
  const apiReady = useSelector(getGmapsApiReady);
  const coordsMap = useSelector(getOneProfileCoords);

  const center =
    !!coordsMap.latitude &&
    isFinite(coordsMap.latitude) &&
    isFinite(coordsMap.longitude)
      ? new window.google.maps.LatLng(
          parseFloat(coordsMap.latitude),
          parseFloat(coordsMap.longitude)
        )
      : {};

  const [zoom, setZoom] = useState(16);
  const address = useRef("");
  const [coordinates, setCoordinates] = useState(coords);
  useEffect(() => {
    setCoordinates(coords);
  }, [coords]);
  const newCords = {
    lat:
      coordsMap?.latitude && isFinite(coordsMap?.latitude)
        ? parseFloat(coordsMap.latitude)
        : null,
    lng:
      coordsMap?.longitude && isFinite(coordsMap?.longitude)
        ? parseFloat(coordsMap.longitude)
        : null,
  };

  // Получение границ города
  useEffect(() => {
    if (map && apiReady) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: `${city}` }, (results, status) => {
        if (status === "OK" && results[0]) {
          const bounds = results[0].geometry.bounds;
          setCityBounds(bounds);
          map.fitBounds(bounds);
          setTimeout(() => map.setCenter(bounds.getCenter()), 1000);
        }
      });
    }
  }, [city, map, apiReady]);

  useEffect(() => {
    if (
      map &&
      !!coordsMap.latitude &&
      isFinite(coordsMap.latitude) &&
      isFinite(coordsMap.longitude) &&
      apiReady
    ) {
      map.panTo(center);
      setZoom(14);
    }
  }, [map, markerActive, center]);

  const handleMapClick = (event) => {
    setMarkerActive(true);
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const latLng = new window.google.maps.LatLng(lat, lng); // Создаем объект LatLng

    // Проверяем, находится ли точка внутри границ города
    if (cityBounds && cityBounds.contains(latLng)) {
      setCoordinates({ lat, lng });
      dispatch(setOneProfileCoords({ latitude: lat, longitude: lng }));

      if (
        map &&
        !!lat &&
        !!lng &&
        isFinite(coordsMap.latitude) &&
        isFinite(coordsMap.longitude) &&
        apiReady
      ) {
        map.panTo(event.latLng);
        setZoom(14);
      }

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results[0]) {
          setBounds({ lat: lat, lng: lng });
          if (setAddress) {
            setAddress(results[0]);
            return;
          }
          address.current = results[0].formatted_address;

          function findAddressComponents(arr) {
            const streetNumberComponent = arr.find((component) =>
              component.types.includes("street_number")
            );

            const routeComponent = arr.find((component) =>
              component.types.includes("route")
            );

            const premiseComponent = arr.find((component) =>
              component.types.includes("premise")
            );

            const neighborhoodComponent = arr.find((component) =>
              component.types.includes("neighborhood")
            );

            if (
              (!!streetNumberComponent || !!premiseComponent) &&
              !!routeComponent
            ) {
              return `${routeComponent.long_name}, ${
                streetNumberComponent?.long_name || premiseComponent.long_name
              }`;
            } else if (!!premiseComponent && !!neighborhoodComponent) {
              return `${neighborhoodComponent.long_name}, ${premiseComponent.long_name}`;
            }

            return "";
          }

          const result = findAddressComponents(results[0].address_components);
          dispatch(setOneProfileAddress(result));
        } else {
          address.current = "";
        }
      });
    } else {
      console.warn("Выбранная точка находится вне границ города");
      // Здесь можно добавить уведомление или сообщение для пользователя
    }
  };

  const isValidLatLng = (position) => {
    return (
      position &&
      "lat" in position &&
      "lng" in position &&
      typeof position.lat === "number" &&
      typeof position.lng === "number" &&
      isFinite(position.lat) &&
      isFinite(position.lng)
    );
  };

  return (
    <div
      className={`create-page__map${disabled ? " pointer-events-none" : ""}${
        clazz ? ` ${clazz}` : ""
      }`}
    >
      {!apiReady ? (
        <Loader height={300} decimal={"px"} />
      ) : (
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "12px",
          }}
          zoom={zoom}
          onLoad={(map) => setMap(map)}
          lang={lang}
          onClick={handleMapClick}
        >
          {(isValidLatLng(newCords) || isValidLatLng(coordinates)) && (
            <Marker
              icon={{
                path: "M0 0h24v24H0z",
                fillColor: "transparent",
                fillOpacity: 0,
                strokeWeight: 0,
                scale: 0,
              }}
              position={isValidLatLng(newCords) ? newCords : coordinates}
            >
              <InfoBox
                position={isValidLatLng(newCords) ? newCords : coordinates}
                options={{
                  enableEventPropagation: false,
                  boxStyle: {
                    translate: `-50% -100%`,
                    minWidth: `100px`,
                  },
                  closeBoxURL: "",
                }}
              >
                <div className="map-filter__tippy">
                  <Icon spritePath={"location"} size={"xl"} />
                </div>
              </InfoBox>
            </Marker>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default Map;

import useLadyService from "@/services/LadyService";
import QRCodeGenerator from "../QRcode/QRcodeGenerator";
import { useState } from "react";

export const BankBlock = ({ currency, currentSum }) => {
  const { t, userId } = useLadyService();
  const currencyBankNumber = {
    czk: "1641093002",
    eur: "1641093010",
  };
  const currencyIban = {
    czk: "CZ8827000000001641093002",
    eur: "CZ6627000000001641093010",
  };

  const tabs = [
    { id: "bank", name: t("czechbank") },
    { id: "iban", name: "IBAN" },
  ];
  const [activeTabId, setActiveTabId] = useState("bank");

  const handleTabClick = (tabId) => {
    setActiveTabId(tabId);
  };
  return (
    <div className={`d-flex justify-sb mt-16 topup-pay__details`}>
      <div className="model__params">
        <p className="p1 color-main grid-col-2">{t("sendtoreq")}</p>
        <div className="header__tabs balance-page__tabs mb-16 grid-col-2">
          {tabs.map((i, index) => {
            return (
              <div
                key={index}
                className={`header__tab min-w-160 text-center p2 ${
                  activeTabId === i.id ? "_active" : ""
                }`}
                onClick={() => handleTabClick(i.id)}
              >
                {i.name}
              </div>
            );
          })}
        </div>
        {activeTabId === "bank" && (
          <span className="model__param">
            {t("accnumber")}
            <span>{currencyBankNumber[currency]}</span>
          </span>
        )}
        {activeTabId === "bank" && (
          <span className="model__param">
            {t("bankcode")}
            <span>{"2700"}</span>
          </span>
        )}

        {activeTabId === "iban" && (
          <span className="model__param">
            IBAN
            <span>{currencyIban[currency]}</span>
          </span>
        )}
        {activeTabId === "iban" && (
          <span className="model__param">
            BIC/SWIFT
            <span>BACXCZPP</span>
          </span>
        )}
        <span className="model__param">
          {t("idofpayment") + " (varsymbol)"}
          <b className="fz-15">{userId}</b>
        </span>
        <span className="color-green grid-col-2">{t("varsymbol")}</span>
      </div>
      <div className={`height-fit d-flex gap-8 align-center`}>
        <QRCodeGenerator
          currency={currency}
          iban={currencyIban[currency]}
          currentSum={currentSum}
        />
        <div className={`d-flex fd-column min-w-160`}>
          <b className="fz-16">{t("qrpay")}</b>
          <span className="mt-8">{t("qrcode")}</span>
        </div>
      </div>
    </div>
  );
};

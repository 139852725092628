import { Icon } from "@/components/ui";
import { Swiper } from "swiper/react";
import { HashNavigation, Navigation } from "swiper/modules";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const TabSlider = (props) => {
  const { tabs, children, clazz } = props;

  const sliderRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(tabs[0].link);

  const sliderParams = {
    className: `${clazz}__slider tab-slider`,
    slidesPerView: 1,
    spaceBetween: 10,
    modules: [Navigation, HashNavigation],
    hashNavigation: {
      watchState: true,
      replaceState: true,
    },
    ref: sliderRef,
    navigation: {
      nextEl: `.${clazz}__button-next`,
      prevEl: `.${clazz}__button-prev`,
    },

    onActiveIndexChange: function () {
      let index = this.realIndex;
      let current_data = this.slides[index].dataset.hash;
      setCurrentIndex(current_data);
    },
  };

  return (
    <div className="tabs mb-40 mb-24-mob">
      <div className="tabs__nav mb-8">
        {tabs.map((btn) => {
          return !btn.disabled ?  (
            <a
              href={`#${btn.link}`}
              className={`tabs__nav-btn${
                currentIndex === btn.link ? " tabs__nav-btn--active" : ""
              }`}
            >
              <Icon size={"s"} spritePath={btn.icon} />

              {btn.title}
            </a>
          ) : null;
        })}
      </div>

      <Swiper {...sliderParams}>{children}</Swiper>
    </div>
  );
};

export default TabSlider;

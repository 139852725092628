import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isMainPage: false,
    isParamsExist: false,
    models: [],
    selections: [],
    sorted: [],
    sortedByGender: [],
    sortedByStatus: [],
    searchValue: "",
    sortBy: "date",
    page: 1,
    filterSorted: "",
    hasMorePage: true,
    notDisabledCheckbox: {},
    storiesId: 0,
    stories: [],
    isLoading: false,
    range: {
        price: {
            min: 0,
            max: 0,
        },
        age: {
            min: 0,
            max: 0,
        },
        weight: {
            min: 0,
            max: 0,
        },
        height: {
            min: 0,
            max: 0,
        },
    },
};

const modelsSlice = createSlice({
    name: "models",
    initialState,
    reducers: {
        setIsParamsExist: (state, action) => {
            state.isParamsExist = action.payload
        },
        setIsMainPage: (state, action) => {
            state.isMainPage = action.payload
        },
        rangeChangeForModel: (state, action) => {
            const {name, min, max} = action.payload;
            if (state.range[name]) {
                state.range[name].min = min;
                state.range[name].max = max;
            }
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        filterAllModels: (state) => {
            state.models = state.models.filter((item) => item);
        },
        setStories: (state, action) => {
            state.stories = action.payload;
        },
        setStoriesId: (state, action) => {
            state.storiesId = action.payload;
        },
        setNotDisabledCheckbox: (state, action) => {
            state.notDisabledCheckbox = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        setSortedModelsByFilter: (state, action) => {
            state.filterSorted = action.payload;
        },
        setPage: (state) => {
            state.page += 1;
        },
        setPageDefault: (state) => {
            state.page = 1;
        },
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setAllModels: (state, action) => {
            state.models = action.payload;
        },
        setSelections: (state, action) => {
            state.selections = action.payload
        },
        updateAllModels: (state, action) => {
            state.models = state.models.concat(action.payload);
        },
        setSorted: (state, action) => {
            const {payload, stateName} = action.payload;
            const index = state[stateName].indexOf(payload);
            if (index !== -1) {
                state[stateName].splice(index, 1);
            } else {
                state[stateName].push(payload);
            }

        },
        setArrSorted: (state, action) => {
            state.sorted = action.payload;
        },
        cleanSorted: (state) => {
            state.sorted = [];
            state.sortedByStatus = [];
            state.sortedByGender = [];
        },
        setDefaultSorted: (state, action) => {
            const {stateName, newArray} = action.payload;
            state[stateName] = newArray;
        },
        setHasMorePage: (state, action) => {
            state.hasMorePage = action.payload;
        },
    },
});

export const {
    setIsParamsExist,
    setIsMainPage,
    setArrSorted,
    rangeChangeForModel,
    setIsLoading,
    filterAllModels,
    setStories,
    setStoriesId,
    setNotDisabledCheckbox,
    setSearchValue,
    setSortedByGender,
    setSortedModelsByFilter,
    setAllModels,
    setSorted,
    setDefaultSorted,
    setSortBy,
    setPage,
    updateAllModels,
    setSelections,
    setPageDefault,
    setHasMorePage,
    cleanSorted,
} = modelsSlice.actions;

export const getAllModels = (state) => state.models.models;
export const getIsMainPage = (state) => state.models.isMainPage;
export const getIsParamsExist = (state) => state.models.isParamsExist;
export const getAllSorted = (state) => state.models.sorted;
export const getStatusSorted = (state) => state.models.sortedByStatus;
export const getGenderSorted = (state) => state.models.sortedByGender;
export const getTypeSorted = (state) => state.models.sortBy;
export const getSearchValue = (state) => state.models.searchValue;
export const getPage = (state) => state.models.page;
export const getSortedModelsByFilter = (state) => state.models.filterSorted;
export const getHasMorePage = (state) => state.models.hasMorePage;

export const getSelections = (state) => state.models.selections;
export const getNotDisabledCheckbox = (state) =>
    state.models.notDisabledCheckbox;
export const getStoriesId = (state) => state.models.storiesId;
export const getStories = (state) => state.models.stories;
export const getIsLoading = (state) => state.models.isLoading;
export const getPriceForModels = (state) => state.models.range.price;
export const getAgeForModels = (state) => state.models.range.age;
export const getWeightForModels = (state) => state.models.range.weight;
export const getHeightForModels = (state) => state.models.range.height;

export default modelsSlice.reducer;

import useTitle from "../../hooks/useTitle";
import {ButtonLink} from "../ui";
import useLadyService from "@/services/LadyService";

import Image from "@/assets/img/404.webp";

const ErrorPage = () => {
  const {t} = useLadyService()
  let text = t('page404')
  useTitle(text);

  return (
    <div className="error-page__container text-center">
      <h1>{text}</h1>

      <img
        src={Image}
        alt={text}
      />

      <ButtonLink
        href={`/`}
        size={'l-forever'}
        clazz={'button--tetriary'}
        >
        {t('backtomain')}
      </ButtonLink>
    </div>
  );
};

export default ErrorPage;

// withErrorBoundary.js
import React from 'react';
import ErrorBoundary from './ErrorBoundary';

function withErrorBoundary(Component) {
    return function WrappedComponent(props) {
        return (
            <ErrorBoundary>
                <Component {...props} />
            </ErrorBoundary>
        );
    };
}

export default withErrorBoundary;

import React from "react";

const Tabs = ({props}) => {
    const {
      clazz,
      tabsData,
      activeTab,
      setActiveTab,
    } = props

    return(
      <div className={`tabs__nav${clazz ? ` ${clazz}` : ''}`}>
          {tabsData.map((btn, index) => {
              return (
                <button
                  key={index}
                  onClick={() => setActiveTab(index)}
                  className={`tabs__nav-btn${
                    activeTab === index ? " tabs__nav-btn--active" : ""
                  }`}
                  type="button"
                >
                    {btn.title}
                </button>
              );
          })}
      </div>
    )
}

export default Tabs
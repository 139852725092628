import showToast from "../../../../toast/Toast";
import useUserProfileService from "../../../../../services/UserProfileService";
import useLadyService from "../../../../../services/LadyService";
import {makeRequest} from "../../../../../services/makeRequest";
import {InputInLabel, Toggle} from "../../../../ui";
import {getPreviewPrice, getPreviewStatus, setPreviewPrice, setPreviewStatus} from "../../../../../stores/slices/userProfileSlice";
import {useEffect, useState} from "react";
import debounce from "lodash.debounce";
import {useSelector} from "react-redux";

const TogglePreview = () => {
    const price = useSelector(getPreviewPrice)
    const status = useSelector(getPreviewStatus)
    const {t, dispatch} = useLadyService();
    const {previewStatus, setStatus} = useUserProfileService(status);
    const [priceForOneModel, setPriceFormOneModel] = useState(price);

    const savePreviewSettings = async () => {
        const route = `agency/set-preview-status`;
        const method = "PUT";
        return makeRequest({
            route, method, payload: {
                preview_status: !previewStatus,
                preview_price: parseInt(priceForOneModel),
            }
        })
    }
    const handleChangePrice = () => {
        savePreviewSettings().then(() => {
            showToast({
                message: t("success"),
                variant: "success",
            });
            dispatch(setPreviewPrice(priceForOneModel))
            dispatch(setPreviewStatus(!previewStatus))
        }).catch(() => {
            showToast({
                message: t("oops"),
                variant: "error",
            });
        })
    }
    useEffect(() => {
        debounce(() => {handleChangePrice()}, 300)
    }, [priceForOneModel]);
    const handleActivatePreview = async () => {
        savePreviewSettings().then(() => {
            showToast({
                message: t("success"),
                variant: "success",
            });
            dispatch(setPreviewStatus(!previewStatus))
            dispatch(setPreviewPrice(priceForOneModel))
        }).catch(() => {
            dispatch(setPreviewStatus(!previewStatus))
            showToast({
                message: t("oops"),
                variant: "error",
            });
        })
    };

    return (
        <div className="account-page__toggles">
            <div className="account-page__activate">
                <h3>{t("modelpreviews")}</h3>
                <Toggle
                    id={"isActivePreview"}
                    checked={!!previewStatus}
                    onChange={handleActivatePreview}
                />
                <InputInLabel
                    price={"erocoin"}
                    type={"number"}
                    id={"upload-price"}
                    value={priceForOneModel}
                    onChange={setPriceFormOneModel}
                >
                    {t("tariffs")}
                </InputInLabel>
            </div>
            <span>{t("tippyagencywatch")}</span>
        </div>
    )
}

export default TogglePreview
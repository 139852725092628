import React from "react";
import {ButtonLink, Icon} from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {useSelector} from "react-redux";
import {getUserTickets} from "@/stores/slices/userSlice";

const SupportButton = ({square = false, size = "xs", iconSize = "s"}) => {
    const {windowWidth, t} = useLadyService();

    const userTickets = useSelector(getUserTickets)
    return (
        <ButtonLink
            href={`/lk/support`}
            title={t("support")}
            size={size}
            clazz={windowWidth > 767.98 ? "button--tetriary" : "user__notification"}
            square={square}
            counter={!square && userTickets > 0 ? userTickets : null}
        >
            <Icon
                counter={square && userTickets > 0 ? userTickets : null}
                size={iconSize}
                spritePath={"operator"}
            />
            {windowWidth > 767.98 && !square ? t("support") : null}
        </ButtonLink>
    );
}

export default SupportButton
import ZeroZone from "../../../../zeroZone/ZeroZone";
import React from "react";
import {Button, Icon} from "../../../../ui";
import useLadyService from "../../../../../services/LadyService";
import EditBlock from "../editBlock/EditBlock";


const ModelInteriorZone = ({setVisualElem, setIsOpenEditPhoto}) => {

  const {t} = useLadyService();

  return (
    <div className={"model-card"}>
      <EditBlock
        title={t("interior")}
        titleType={'h3'}
        mb={0}
        clazz={'align-center mb-16 justify-sb'}
        onClick={() => {
          setVisualElem("interior")
          setIsOpenEditPhoto(true)
        }}
      />

      <ZeroZone
        setOpen={setIsOpenEditPhoto}
        longDisc={t('showyourinter')}
        setVisualElem={setVisualElem}
        visualElem={'interior'}
      />
    </div>
  )
}

export default ModelInteriorZone
import React, {useEffect, useState} from "react";
import {
    Banner,
    Breadcrumbs,
    ButtonLink,
    Icon,
    ShowMoreText,
    Video,
} from "@/components/ui";
import {Link, useParams} from "react-router-dom";
import Reviews from "../../reviews/Reviews";
import Slider from "../../sliders/Slider";
import {SwiperSlide} from "swiper/react";
import PartyCard from "../../cards/partyCard/PartyCard";
import {PartyAccess} from "../../popups";

import {makeRequest} from "@/services/makeRequest";
import OrderViewing from "../../popups/orderViewing/OrderViewing";

import "./agencyPage.scss";
import useLadyService from "@/services/LadyService";
import {useTranslation} from "react-i18next";

import TabSlider from "../../sliders/tabSlider/TabSlider";
import {setGalleryMediaIndex, setGalleryStatus, setStoriesStatus} from "../../../stores/slices/popupSlice";
import Gallery from "../../popups/gallery/Gallery";
import {setStoriesId} from "../../../stores/slices/modelsState";
import StoriesComponent from "../../stories/StoriesComponent";
import {useSelector} from "react-redux";
import {getAgency, setIsAgencyPage} from "../../../stores/slices/agencySlice";
import Navigation from "../../navigation/Navigation";
import {Helmet} from "react-helmet";
import {isUrl} from "../../../helper/stringHelper";

const AgencyPage = (props) => {
    const {lang, navigate, dispatch, setMediaSize} = useLadyService();
    const {t} = useTranslation("translation");
    const agency = useSelector(getAgency)
    const [oneAgencyData, setOneAgencyData] = useState(agency);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const {agencyId} = useParams();

    const {
        id = oneAgencyData?.id,
        slug = oneAgencyData?.slug,
        name = oneAgencyData?.name,
        country = oneAgencyData?.country,
        address = oneAgencyData?.address,
        addresses = oneAgencyData?.addresses,
        phone = oneAgencyData?.phone,
        rating = oneAgencyData?.rating,
        description = oneAgencyData?.description,
        whatsapp = oneAgencyData?.whatsapp,
        telegram = oneAgencyData?.telegram,
        website = oneAgencyData?.website,
        email = oneAgencyData?.email,
        profiles_statistic = oneAgencyData?.profiles_statistic,
        profiles = oneAgencyData?.profiles.data ?? [],
        parties = oneAgencyData?.parties,
        cover = oneAgencyData?.cover,
        logo = oneAgencyData?.logo,
        preview = oneAgencyData?.preview,
        media = {
            photo: [],
            video: [],
            interior: oneAgencyData?.interior ?? [],
        },
        video = oneAgencyData?.video,
        video_link = oneAgencyData?.video_link,
    } = props;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const payload = {
                    limit: 12,
                }
                const data = await makeRequest({route: `agencies/${agencyId}`, method: "GET", payload});
                if (!data) {
                    navigate("/404");
                }
                if (data) {
                    const result = data.data;
                    setOneAgencyData(result);
                }
            } catch (error) {
            }
        };

        !agency && agencyId && lang && fetchData();
    }, [agencyId, lang, agency]);


    useEffect(() => {
        dispatch(setIsAgencyPage(true))
        return () => {
            dispatch(setIsAgencyPage(false));
        };
    }, []);

    const breadcrumbsList = [
        {
            id: 1,
            title: t("mainpage"),
            link: "/",
        },
        {
            id: 2,
            title: `${t("agenci1")} – Чехия`,
            link: "/",
        },
        {
            id: 3,
            title: name,
            link: "",
        },
    ];

    const tabsBtns = [
        {
            title: t("ourmodels"),
            icon: "image",
            link: "photo",
        },
        {
            title: t("interior"),
            icon: "home-main",
            link: "interior",
            disabled: !media.interior || !media.interior.length
        },
    ];
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    return (
        <>
            <main className={"agency"}>
                <Helmet>
                    <title>
                        {`${t("titleAgency", {AgencyName: name, Country: country, Count: profiles_statistic?.total})}`}
                    </title>
                    <meta name="description" content={`${t("altagency", {AgencyName: name})} - ${description?.slice(0, 150)}`}/>
                </Helmet>

                <Breadcrumbs data={breadcrumbsList} clazz={"__container mb-20"}/>

                <Banner
                    props={{
                        title: name,
                        img: cover,
                        imgMobile: cover,
                        rating: rating,
                        medel_total: profiles_statistic?.total,
                        date: "",
                        logoImg: logo,
                        logoImgMobile: logo,
                    }}
                />

                <div className="agency__top __container">
                    <div className="agency__info">
                        {description && <ShowMoreText>{description}</ShowMoreText>}
                        {phone || whatsapp || telegram ? (
                            <div className="agency__buttons">
                                {phone ? (
                                    <ButtonLink
                                        size={"l"}
                                        href="tel:+422589996655"
                                        clazz="button--primary td-none w-100"
                                    >
                                        {phone}
                                    </ButtonLink>
                                ) : null}
                                {whatsapp ? (
                                    <ButtonLink
                                        square={true}
                                        size={"l"}
                                        href={`https://wa.me/${whatsapp}`}
                                        clazz="button--primary"
                                    >
                                        <i className="icon icon-xxl icon--whatsapp"></i>
                                    </ButtonLink>
                                ) : null}

                                {telegram ? (
                                    <ButtonLink
                                        square={true}
                                        size={"l"}
                                        href={`https://t.me/${telegram}`}
                                        clazz="button--primary"
                                    >
                                        <i className="icon icon-xxl icon--telegram"></i>
                                    </ButtonLink>
                                ) : null}
                            </div>
                        ) : null}

                        <div className="agency__links">
                            <div>
                                {website ? (
                                    <Link
                                        to={website}
                                        className="agency__site p3 icon icon--globe underline"
                                    >
                                        {website}
                                    </Link>
                                ) : null}

                                <Link
                                    to={`mailto:${email}`}
                                    className="agency__mail p3 icon icon--mail underline"
                                >
                                    {email}
                                </Link>
                            </div>

                            {address?.address && (
                                <Link
                                    to="#"
                                    className="agency__address p3 icon icon--map underline"
                                    title={t('viewonmap')}
                                >
                                    <Icon type={"flag"} spritePath={"CZ"}/>
                                    {address.address}
                                </Link>
                            )}

                            <Link
                                to="#"
                                className="agency__address p3 icon icon--map underline"
                                title={t('viewonmap')}
                            >
                                <Icon type={"flag"} spritePath={"CZ"}/>
                                Czechia, Praha, Žižkova tř. 220/15
                            </Link>
                        </div>
                    </div>

                </div>

                {!isJsdom ? (<>
                    <section className="agency__media __container">
                        <TabSlider tabs={tabsBtns} clazz={"agency-tabs"}>
                            <SwiperSlide
                                className={`agency-tabs__slide`}
                                data-hash={tabsBtns[0].link}
                            >
                                {profiles.slice(0, 6).map((profile, index) => {
                                    const result = () => {
                                        dispatch(setStoriesStatus(true));
                                        dispatch(setStoriesId(index));
                                    };
                                    return (
                                        <img
                                            src={setMediaSize(profile.main_photo, "m")}
                                            srcSet={`
                                              ${setMediaSize(profile.main_photo, "s2")} 220w,
                                              ${setMediaSize(profile.main_photo, "m")} 800w,
                                            `}
                                            sizes="(max-width: 600px) 200px, 750px"
                                            loading="lazy"
                                            onClick={() => result()}
                                        />
                                    );
                                })}
                            </SwiperSlide>
                            {media.interior && media.interior.length ? (
                                <SwiperSlide
                                    className={`agency-tabs__slide`}
                                    data-hash={tabsBtns[1].link}
                                >
                                    {media.interior.slice(0, 6).map((image, index) => {
                                        const result = () => {
                                            dispatch(setGalleryMediaIndex(index));
                                            dispatch(setGalleryStatus(true));
                                        };
                                        return (
                                            <img
                                                src={image}
                                                onClick={() => result()}
                                            />
                                        );
                                    })}
                                </SwiperSlide>
                            ) : null}
                        </TabSlider>

                        {(video && video.length) || isUrl(video_link) ? (
                            <div className="agency__block">
                                <h2 className="mb-32">{t('videopresentation')}</h2>

                                <Video controls={true} src={video} iframe={video_link}/>
                            </div>
                        ) : null}
                    </section>
                    {parties && parties.length ? (
                    <section className="agency-party __container">
                        <h3>{t("upcomingparties")}</h3>

                        <Slider clazz={"agency-party"} type={"agency"}>
                            {parties &&
                                parties.map((data) => (
                                    <SwiperSlide>
                                        <PartyCard props={data}/>
                                    </SwiperSlide>
                                ))}
                        </Slider>
                    </section>
                    ) : null}
                </>) : null }

                <Navigation profiles_statistic={profiles_statistic} agencyId={agencyId} checkbox={preview?.status} mainPage={false}
                            setIsDataEmpty={setIsDataEmpty} isDataEmpty={isDataEmpty} preview_price={preview?.price}/>
                <Reviews
                    props={{
                        clazz: "__container",
                        rating: rating,
                    }}
                />
            </main>

            <PartyAccess/>

            {!!profiles.length && (
                <StoriesComponent
                    favoriteButton
                    descriptionText
                    profileLink
                    stories={profiles}
                />
            )}
            {!!media.interior.length && (
                <Gallery id={'Interior'} name={name} media={media}/>
            )}

            <OrderViewing price={preview?.price} agencyId={agencyId}/>
        </>
    );
};

export default AgencyPage;

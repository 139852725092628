import { useSelector } from "react-redux";
import {
  getUserCountry,
  setCity,
  setCurrentCity,
} from "@/stores/slices/userSlice";
import { useEffect } from "react";
import containsKeyword from "../helper/containsKeyword";
import useLadyService from "@/services/LadyService";

const useDefiningCity = () => {
  const {
    dispatch,
    city,
  } = useLadyService()

  const allCities = useSelector(getUserCountry);
  const slugOfAllCities = allCities.map((city) => city.slug);

  useEffect(() => {
    if (!city && allCities.length > 0) {
      const url = window.location.pathname;
      const segments = url.split("/");
      const citySegment = segments[2];
      if (citySegment && containsKeyword(citySegment, slugOfAllCities)) {
        const cityBySlug = allCities.filter(
          (city) => city.slug === citySegment
        )[0];
        dispatch(setCity(citySegment));
        if (cityBySlug) {
          dispatch(setCurrentCity(cityBySlug));
        }
      } else {
        dispatch(setCity("prague"));
        const defaultCity = allCities.filter(
          (city) => city.slug === "prague"
        )[0];
        if (defaultCity) {
          dispatch(setCurrentCity(defaultCity));
        }
      }
    }
  }, [allCities]);
};

export default useDefiningCity;

export const userDeclarationCZ = {
  h1: "Prohlášení uživatele portálu lady4love.com a uživatele jeho služeb",
  p1: "Beru na vědomí, že níže uvedená prohlášení platí bezvýhradně pro všechny návštěvníky portálu lady4love.com a uživatele jeho služeb. Před návštěvou či před jakoukoli jinou formou konzumace obsahu portálu lady4love.com závazně a neodvolatelně prohlašuji následujcí:",
  one: {
    text: "Je mi více než 18 let, a dosáhl jsem tedy plnoletosti. Pokud se nacházím ve státě, kde je pro dosažení plnoletosti vyžadován zákonem jiný věk, prohlašuji, že jsem této hranice dosáhl. ",
  },
  two: {
    text: "Uvědomuji si, že stránky lady4love.com obsahují sexuálně explicitní materiál. Tato skutečnost mě nijak nepohoršuje, nepoškozuje ani neuráží. ",
  },
  three: {
    text: "Na stránky nepřistupuji ze zemí, které sexuálně explicitní materiály zakazují, ani nejsem občanem takových zemí. ",
  },
  four: {
    text: "Nepřipustím, aby v důsledku mého chování na těchto stránkách mohl k datům zde obsaženým přistupovat kdokoli nezletilý či jinak nezpůsobilý. ",
  },
  five: {
    text: "Můj zájem o veškeré zde obsažené nabídky a jakékoliv jiné údaje je čistě soukromý a pouze pro mou vlastní potřebu. ",
  },
  six: {
    text: "Beru na vědomí, že veškeré obrazové a textové materiály na stránkách lady4love.com jsou chráněny autorským zákonem. Bez předchozího písemného souhlasu provozovatele portálu lady4love.com a případně i dotčených inzerentů nebudu obsah portálu nebo jeho částí jakkoliv šířit nebo stahovat, a to ani pro moje osobní účely. ",
  },
  seven: {
    text: "Beru na vědomí, že provozovatel portálu lady4love.com neposkytuje žádné eskortní, erotické ani jiné služby, které jsou na těchto stránkách nabízeny, ani je neorganizuje ani nezprostředkovává (s výjimkou zprostředkovávání prodeje doplňkových služeb). Všechny zde obsažené služby jsou nabídkami konkrétních inzerentů. Jakékoliv výhrady k inzerovaným službám budu řešit bezprostředně s jejich poskytovateli.",
  },
  eight: {
    text: "Vstupem na tyto stránky beru na vědomí, že provozovatel portálu lady4love.com nepřebírá žádnou odpovědnost: ",
    list: [
      "za obsah a pravdivost informací, které jsou uveřejněny v rámci nabídky služeb inzerentů. To se týká i případů, kdy byl na chybu ve zveřejněných informacích provozovatel portálu přímo upozorněn. Provozovatel portálu lady4love.com rovněž nenese odpovědnost za kvalitu, cenu nebo aktuální dostupnost inzerovaných služeb. ",
      "za jakékoli případné škody (včetně jakékoliv finanční ztráty či újmy z poskytnutí nebo ze zveřejnění informací), a to i když tyto škody vznikly na základě použití (či nemožnosti použití) služeb nabízených inzerenty prostřednictvím portálu lady4love.com. To se týká i případů, kdy je portál lady4love.com označen jako možný původce těchto škod a jeho provozovatel byl předem upozorněn na možnost vzniku těchto škod. ",
      "za vznik a průběh vztahu mezi inzerentem a mnou coby uživatelem jeho služeb (zákazníkem), včetně případů, kdy tento vztah vyvolá občanskoprávní spory či trestní důsledky. Provozovatel lady4love.com nad nabídkou inzerentů a kvalitou jejich služeb nemá žádnou kontrolu. ",
    ],
  },
  nine: {
    text: "Při využívání služeb nabízených inzerenty na lady4love.com se zavazuji dodržovat všechny platné zákony státu, na jehož území služby využívám, zejména zákony týkající se přímo i zprostředkovaně inzerovaných služeb, šíření infekčních chorob nebo praní špinavých peněz. ",
  },
  ten: {
    text: "Vstupem na tento portál se zavazuji dodržovat všechny podmínky, které pro mne vyplývají z dokumentů <a href='/cz/rules'>Všeobecné obchodní podmínky</a> a rovněž prohlašuji, že jsem si přečetl a souhlasím <a href='cz/gdpr'>Zásady ochrany osobních údajů</a>. Beru na vědomí, že některé podmínky mne zavazují i poté, kdy návštěvu těchto stránek ukončím.",
  },
  eleven: {
    text: "Beru na vědomí, že nepravdivostí či neúplností kteréhokoli z těchto prohlášení či porušením závazků, které jsem těmito prohlášeními bezvýhradně přijal, se vystavuji riziku občanskoprávních sporů i trestního stíhání podle zákonů státu provozovatele portálu lady4love.com, tj. podle zákonů České republiky.",
  },
};

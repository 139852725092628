import React from "react";
import {setFavoriteCounter, setUserId, setUserStatus, setUserType} from "@/stores/slices/userSlice";
import useLadyService from "@/services/LadyService";
import {Button, Icon} from "@/components/ui";

const LogOutButton = ({size = "xs", square = false}) => {
  const {
    dispatch,
    t,
    navigate ,
  } = useLadyService();

  const url = window.location.pathname;

  const logOut = () => {
    if(url.includes('lk')) {
      navigate(`/`);
    }
    window.location.reload();
    dispatch(setUserType("default"));
    dispatch(setUserStatus("silver"));
    dispatch(setUserId(null));
    dispatch(setFavoriteCounter(0));
    localStorage.removeItem("user");
  };

  return (
    <Button
      onClick={() => logOut()}
      size={size}
      square={square}
      clazz="user__log-out"
    >
      {!square && t("logout")}

      <Icon size={"l"} spritePath={"logout"} />
    </Button>
  );
}

export default LogOutButton
import { Link } from "react-router-dom";

import "./breadcrumbs.scss";

const Breadcrumbs = (props) => {
  const { clazz, data = [] } = props;
  return (
    <div className={`breadcrumbs p3${clazz ? ` ${clazz}` : null}`}>
      {data.map((item) => (
        <Link key={item.id} className={"breadcrumbs__link"} to={item.link}>
          {item.title}
        </Link>
      ))}
    </div>
  );
};

export default Breadcrumbs;

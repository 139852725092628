import { makeRequest } from "@/services/makeRequest";

export const resendEmail = async () => {
    try {
      const route = `email/verify/resend`;
      const method = "POST";

      const result = await makeRequest({ route, method });
      if(result) {
        return result
      } else {
        return false
      }
    } catch (error) {
        return false
    }
  };
import { Button } from "@/components/ui";
import { useState, useRef, useEffect } from "react";
import { InputInLabel, Icon, ErrorMessage, LoadingButton } from "../../ui";

import StoryZone from "./StoryZone";

import useLadyService from "@/services/LadyService";
import axios from "axios";
import baseUrl from "@/services/apiConfig";
import showToast from "../../toast/Toast";
import { makeRequest } from "@/services/makeRequest";
import { useSelector } from "react-redux";
import {
  getAddStoriesStatus,
  setAddStoriesStatus,
} from "@/stores/slices/popupSlice";
import Popup from "../Popup";

const AddStoriesPopup = ({ slug, setStories, stories, open, setOpen }) => {
  const { t, token, dispatch } = useLadyService();

  const popupState = useSelector(getAddStoriesStatus);

  const [media, setMedia] = useState(null);

  const [descValue, setDescValue] = useState("");

  const [descValueLength, setDescValueLength] = useState(0);

  const descChange = (e) => {
    const value = e.target.value;
    const limitedValue = value.substring(0, 100);
    setDescValueLength(limitedValue.length);
    setDescValue(limitedValue);
  };

  const [loading, setLoading] = useState(false);

  const loadStories = async (formData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        baseUrl + `user/${slug}/stories`,
        formData,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      if (response.status === 200) {
        const { profiles } = await makeRequest({
          route: `user/profiles`,
          method: "GET",
          payload: { slug },
        });

        setStories(() => {
          return [...profiles[0]?.stories];
        });
        dispatch(setAddStoriesStatus(false));
        showToast({
          message: t("storiessuccess"),
          variant: "success",
        });
        if (open) {
          setOpen(false);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        setStories([]);

        const formData = new FormData();
        formData.append("file", media[0]);
        formData.append("file_name", media[0].name);
        descValue && formData.append("description", descValue);

        loadStories(formData);
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
        console.log(error);
      }
    };

    fetchData();
  };

  const videoRef = useRef(null);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const video = videoRef.current;

    video?.addEventListener("loadedmetadata", () => {
      setDuration(video.duration);
    });

    if (duration > 20) {
      setMedia(null);
      showToast({
        message: `${t("maxval")}: 20 ${t("sek")}`,
        variant: "error",
      });
    }

    return () => {
      video?.removeEventListener("loadedmetadata", () => {});
    };
  }, [media]);

  return (
    <Popup
      setOpen={() =>
        setOpen ? setOpen() : dispatch(setAddStoriesStatus(false))
      }
      open={open ? open : !!popupState}
      container={"div"}
    >
      <h3 className="m-12-24">{t("addstory")}</h3>
      <div className="popup-form__inner">
        {!!media ? (
          <div className={`example__photo ml-auto mr-auto`}>
            {media[0].type.includes("video") ? (
              <video
                width="340px"
                height="190px"
                controls
                ref={videoRef}
                src={media[0].preview}
              />
            ) : (
              <img src={media[0].preview} alt={""} />
            )}

            <span className="icon-remove" onClick={() => setMedia(null)}>
              <Icon size={"s"} spritePath={"close"} />
            </span>
          </div>
        ) : (
          <StoryZone
            slug={slug}
            data={media}
            setData={setMedia}
            accept={{
              "image/jpeg": [".jpg", ".jpeg", ".JPG", ".JPEG"],
              "image/png": [".png", ".PNG"],
              "image/webp": [".webp", ".WEBP"],
              "video/mp4": [".mp4", ".MP4"],
              "video/quicktime": [".mov", ".MOV"],
              "video/x-ms-wmv": [".wmv", ".WMV"],
              "video/x-msvideo": [".avi", ".AVI"],
              "video/x-matroska": [".mkv", ".MKV"],
            }}
            type="mixed"
          />
        )}
        <InputInLabel
          type={"textarea"}
          placeholder={`${t("until")} 100 ${t("symbols")}`}
          value={descValue}
          onChange={descChange}
        >
          {t("desc")} {descValueLength}
          /100
          {descValueLength === 100 && (
            <ErrorMessage message={`${t("maxval")} 100 ${t("symbols")}`} />
          )}
        </InputInLabel>
        <LoadingButton
          isLoading={loading}
          size={"l-forever"}
          clazz={"button--green justify-center mt-16"}
          onClick={onSubmit}
          disabled={!media}
        >
          {t("publish")}
        </LoadingButton>
      </div>
    </Popup>
  );
};

export default AddStoriesPopup;

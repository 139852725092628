import React, { useEffect, useRef, useState } from "react";
import { createPortal } from 'react-dom';
import { Link } from "react-router-dom";
import {
  plusFavoriteCounter,
  minusFavoriteCounter,
} from "@/stores/slices/userSlice";
import { useSelector } from "react-redux";
import { Button, ButtonLink, Icon } from "@/components/ui";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import {
  getStoriesStatus,
  setStoriesStatus,
} from "@/stores/slices/popupSlice";
import { getStoriesId, setStoriesId } from "@/stores/slices/modelsState";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import RemoveStory from "@/components/popups/addStories/RemoveStory";
import "./stories.scss";
import {Popup} from "../popups";

const StoriesComponent = (props) => {
  const {
    stories,
    storiesForOneModel = false,
    favoriteButton,
    descriptionText,
    profileLink,
    edit,
    setStories,
  } = props;

  const { dispatch, lang, userCity } = useLadyService();

  const [dataStories, setDataStories] = useState([]);
  const loadedSlides = useRef(new Set());
  const observerRef = useRef(null);
  const swiperRef = useRef(null);

  const [page, setPage] = useState(1);

  const storiesStatus = useSelector(getStoriesStatus);

  const currentStories = useSelector(getStoriesId);

  const [isLoad, setIsLoad] = useState(false);
  const loadMediaForVisibleSlide = (index) => {
    if (!loadedSlides.current.has(index)) {
      loadedSlides.current.add(index);
      setDataStories((prevStories) => {
        return prevStories.map((story, idx) =>
            idx === index ? { ...story, isLoaded: true } : story
        );
      });
    }
  };

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const index = parseInt(entry.target.dataset.index, 10);
              loadMediaForVisibleSlide(index); // Загружаем медиа для видимого слайда
            }
          });
        },
        { threshold: 0.1 } // Порог 10% видимости
    );

    dataStories.forEach((_, index) => {
      const slide = document.querySelector(`[data-index='${index}']`);
      if (slide) observerRef.current.observe(slide);
    });

    return () => {
      observerRef.current.disconnect();
    };
  }, [dataStories]);


  useEffect(() => {
    if (storiesStatus) {
      setDataStories(stories);
      return () => {
        dispatch(setStoriesId(null));
      };
    }
  }, [storiesStatus]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoad(true);
      try {
        const route = `stories/${userCity.id}`;
        const method = "GET";
        const payload = {
          page: page + 1,
        };

        const data = await makeRequest({ route, method, payload });

        if (data) {
          const result = data.stories;
          const dataMeta = data.meta;
          const totalStories = dataMeta.total;
          const currentPage = dataMeta.current_page;
          const perPage = dataMeta.per_page;

          if (perPage * currentPage < totalStories + perPage) {
            const newData = stories.concat(result);
            setDataStories(newData);
          }
          setIsLoad(false);
        }
      } catch (error) {}
    };

    !storiesForOneModel && currentStories > 4 && fetchData();
  }, [currentStories, storiesForOneModel]);

  const handleSlideChange = (swiper) => {
    if (Math.abs(swiper.activeIndex - swiper.previousIndex) > 2) return;
    const index = swiper.activeIndex;
    const previousIndex = swiper.previousIndex;
    if (index % 5 === 0 && index > previousIndex) {
      const fetchData = async () => {
        setPage((prev) => prev + 1);
        try {
          const route = `stories/${userCity.id}`;
          const method = "GET";
          const payload = {
            page: page + 1,
          };

          const data = await makeRequest({ route, method, payload });

          if (data) {
            const result = data.stories;
            const dataMeta = data.meta;
            const totalStories = dataMeta.total;
            const currentPage = dataMeta.current_page;
            const perPage = dataMeta.per_page;

            if (perPage * currentPage < totalStories + perPage) {
              const newData = stories.concat(result);
              setDataStories(newData);
            }
          }
        } catch (error) {}
      };

      fetchData();
    }
  };

  const paramsSwiper = {
    modules: [Pagination, Navigation, Autoplay],
    centeredSlides: true,
    autoplay: {
      delay: 6000,
      pauseOnMouseEnter: true,
    },
    slidesPerView: "auto",
  };

  const onInitFunction = (swiper) => {
    swiper.update();
    swiperRef.current.swiper.slideTo(currentStories);
  };

  const handleClose = () => {
    dispatch(setStoriesStatus(false))
  }
  return (
    <Popup
      wrapperClazz="stories-popup"
      open={!!storiesStatus}
      setOpen={handleClose}
      container={'none'}
    >
        {!!dataStories?.length && !isLoad ? (
          <Swiper
            ref={swiperRef}
            onSlideChange={storiesForOneModel ? null : handleSlideChange}
            {...paramsSwiper}
            onInit={(swiper) => onInitFunction(swiper)}
            className={"stories__slider"}
            navigation={{
              nextEl: ".stories__button-next",
              prevEl: ".stories__button-prev",
            }}
            pagination={{
              el: ".swiper-pagination.stories__progressbar",
              clickable: "true",
              type: "bullets",
              autoplayDisableOnInteraction: false,
              renderBullet: function () {
                return (
                  "<span class=swiper-pagination-bullet>" +
                  "<span class=swiper-pagination-bullet__progress-bar></span>" +
                  ' <span class=swiper-pagination-bullet__autoplay style="animation-duration: 6s"></span>' +
                  "</span>"
                );
              },
            }}
            onTransitionStart={function () {
              if (!storiesForOneModel) {
                let videos = document.querySelectorAll(
                  ".stories__slider .stories__slide video"
                );
                Array.prototype.forEach.call(videos, function (video) {
                  video.pause();
                });
              }
            }}
            onTransitionEnd={function (swiper) {
              swiper.update();
              let index = swiper.activeIndex;
              let slide = document.getElementsByClassName("stories__slide")[index];
              let slideVideo = slide.getElementsByTagName("video")[0];
              if (slideVideo) {
                slideVideo.load();
              }
            }}
          >
            <button
              slot={"container-start"}
              className="swiper-button-prev stories__button-prev"
              onClick={(e) => e.stopPropagation()}
            ></button>

            {dataStories?.length > 0 &&
              dataStories.map((story, index) => {
                const regex = /([^.]+)$/;

                const res =
                  typeof story.media === "string"
                    ? story.media
                    : story.media[0];
                const extension = res.match(regex)[1];

                return (
                  <SwiperSlide
                    key={index}
                    className={"stories__slide"}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Template
                      age={story.age}
                      id={story.id}
                      name={story.name}
                      type={extension}
                      src={story.media}
                      slug={story.slug}
                      createdAt={story.created_at}
                      last_activity={story.last_activity}
                      description={story.description}
                      favoriteButton={favoriteButton}
                      descriptionText={descriptionText}
                      profileLink={profileLink}
                      edit={edit}
                      setStories={setStories}
                    />
                  </SwiperSlide>
                );
              })}

            <button
              slot={"container-end"}
              className="swiper-button-next stories__button-next"
              onClick={(e) => e.stopPropagation()}
            ></button>

            <div
              slot={"container-end"}
              className="swiper-pagination stories__progressbar"
            ></div>
          </Swiper>
        ) : null}
    </Popup>
  )
}

const Template = (props) => {
  const {
    src,
    type = "img",
    name,
    age,
    createdAt,
    description,
    slug,
    last_activity,
    favoriteButton,
    descriptionText,
    profileLink,
    edit,
    setStories,
    id,
  } = props;

  const {dispatch, t, setMediaSize, isOnline, token} = useLadyService();

  const {TimeAgo} = useLadyService();
  const [timeAgo, setTimeAgo] = useState("");

  const [favorite, setFavorite] = useState(false);

  const toggleFavoriteCount = () => {
    if (favorite) {
      if (token) {
        const fetchData = async () => {
          try {
            const route = `user/favorites/remove/${slug}`;
            const method = "DELETE";

            const data = await makeRequest({route, method});
          } catch (error) {
          }
        };
        fetchData();
      } else {
        let favorites = JSON.parse(localStorage.getItem("favorites")) || [];

        favorites = favorites.filter((item) => item !== slug);

        localStorage.setItem("favorites", JSON.stringify(favorites));
      }
    } else {
      if (token) {
        const fetchData = async () => {
          try {
            const route = `user/favorites/add/${slug}`;
            const method = "POST";

            const data = await makeRequest({route, method});
          } catch (error) {
          }
        };

        fetchData();
      } else {
        const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
        if (!favorites.includes(slug)) {
          favorites.push(slug);
        }
        localStorage.setItem("favorites", JSON.stringify(favorites));
      }
    }

    dispatch(favorite ? minusFavoriteCounter() : plusFavoriteCounter());
    setFavorite(!favorite);
  };

  const [muted, setMuted] = useState(false);

  const [remove, setRemove] = useState(false);

  const handleRemoveStory = () => {
    setRemove(true)
    dispatch(setStoriesStatus(false))
  }

  return (
    <div className={"stories__card"}>
      {type === ("mp4" || "webm") && (
        <Button
          square={true}
          size={"s"}
          clazz={`stories__sound${muted ? " _active" : ""}`}
          onClick={() => setMuted((state) => !state)}
        >
          <Icon size={"l"} spritePath={muted ? "volume-x" : "volume"}/>
        </Button>
      )}

      {favoriteButton ? (
        <Button
          square={true}
          size={"s"}
          clazz={`girl-card__favorite`}
          onClick={() => toggleFavoriteCount()}
        >
          <Icon
            size={"l"}
            spritePath={favorite ? "favorite-fill" : "favorite"}
          />
        </Button>
      ) : edit ? (
        <Button
          square={true}
          size={"s"}
          clazz={`girl-card__remove button--secondary`}
          onClick={handleRemoveStory}
        >
          <Icon size={"xl"} spritePath={"trash"} clazz={"_red"}/>
        </Button>
      ) : null}

      {type === ("mp4" || "webm") ? (
        <video
          loop={true}
          playsInline={true}
          autoPlay={true}
          muted={muted}
          loading="lazy"
          src={src}
        />
      ) : (
        <img
          src={setMediaSize(src, "xl")}
          loading="lazy"
          alt={`${t("escortmodel")} ${name}`}
        />
      )}

      {descriptionText && description ? (
        <p className="stories__text color-white">{description}</p>
      ) : null}

      <div className="girl-card__info-wrapper">
        <div className="girl-card__age color-white">
          {age} <span>{t("years")}</span>
        </div>

        <div className="girl-card__info color-white">
          <Link
            onClick={() => dispatch(setStoriesStatus(false))}
            to={`/profile/${slug}`}
            title={name}
            className={`model__name${
              isOnline(last_activity) ? " _online" : " _offline"
            }`}
          >
            {name}
          </Link>
          <p className={"color-white"}>
            <TimeAgo
              setTimeAgo={setTimeAgo}
              timeAgo={timeAgo}
              timestamp={createdAt}
            />
          </p>
        </div>

        {profileLink ? (
          <ButtonLink
            size={"m"}
            href={`/profile/${slug}`}
            clazz={"button--green _no-underline"}
            onClick={() => dispatch(setStoriesStatus(false))}
          >
            {t("profuser")}
            <Icon spritePath={"chevrone-right"} size={"m"} />
          </ButtonLink>
        ) : null}

        {remove &&
          <RemoveStory
            open={remove}
            setOpen={setRemove}
            id={id}
            setStories={setStories}
          />
        }
      </div>
    </div>
  );
};

export default StoriesComponent;

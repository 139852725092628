import useLadyService from "@/services/LadyService";
import { Button, Checkbox, Icon, Dropdown, Toggle } from "@/components/ui";
import { useState } from "react";
import { Select } from "@/components/ui";

export const AgencyProfileActions = ({
  setIsAgencyFilterOpen,
  setAgencySelectCheckbox,
  allSlugs,
  agencySelectCheckbox,
  filterCount,
  resetFilter,
  setMakeEliteAgency,
  setOnlineStatus,
  setOrderStatus,
  setVchatStatus,
  setPreviewStatus,
  setStatus,
  setIndiStatus,
  disablePromotion,
  setCardsPerPage,
  cardsPerPage,
}) => {
  const { t, windowWidth } = useLadyService();

  const handleMakeOnline = (st) => {
    setOnlineStatus(true);
  };

  const handleSetOrderStatus = (st) => {
    setOrderStatus(st);
  };
  const handleSetVchatStatus = (st) => {
    setVchatStatus(st);
  };
  const handleSetPreviewStatus = (st) => {
    setPreviewStatus(st);
  };
  const handleSetStatus = (st) => {
    setStatus(st);
  };
  const handleSetIndiStatus = (st) => {
    setIndiStatus(st);
  };

  const handleChangeItemsPerPageAmmount = (i) => {
    setCardsPerPage(i);
  };

  const options = {
    main_title: t("actionselected"),
    options: [
      {
        id: "online",
        title: `${t("markonline")} (4 ${t("hour")})`,
        value: "online",
        toggle: false,
        onClick: (st) => handleMakeOnline(st),
      },
      {
        id: "activate",
        title: t("activate"),
        value: "activate",
        toggle: true,
        onClick: (st) => handleSetStatus(st),
      },
      {
        id: "videochats",
        title: t("videochats"),
        value: "videochat",
        toggle: true,
        onClick: (st) => handleSetVchatStatus(st),
      },
      {
        id: "preorders",
        title: t("g-preorders"),
        value: "order",
        toggle: true,
        onClick: (st) => handleSetOrderStatus(st),
      },
      {
        id: "previews",
        title: t("modelpreviews"),
        value: "previews",
        toggle: true,
        onClick: (st) => handleSetPreviewStatus(st),
      },
      {
        id: "indi",
        title: t("makeindi"),
        value: "indi",
        toggle: true,
        onClick: (st) => handleSetIndiStatus(st),
      },
    ],
    selectCardsPerPageOptions: [
      { title:  `12 ${t('profiles')}`, value: 12 },
      { title:  `36 ${t('profiles')}`, value: 36 },
      { title:  `96 ${t('profiles')}`, value: 96 },
    ],
  };

  const [toggleActive, setToggleActive] = useState([]);

  return (
    <>
      <div className="d-flex gap-8">
        <Button
          onClick={() => {
            setIsAgencyFilterOpen(true);
          }}
          size={"s"}
          clazz={"header__filter color-main"}
        >
          {t("filter")}
          <Icon size={"m"} spritePath={"filter"} />
          {!!filterCount && <span className="count">{filterCount}</span>}
        </Button>
        {!!filterCount && (
          <button
            type="button"
            className={"range__default"}
            onClick={() => {
              resetFilter();
            }}
          >
            {t("reset")}
          </button>
        )}
      </div>
      <Checkbox
        onClick={() => {
          if (allSlugs.length === agencySelectCheckbox.length) {
            setAgencySelectCheckbox([]);
          } else {
            setAgencySelectCheckbox(allSlugs);
          }
        }}
        checked={allSlugs.length === agencySelectCheckbox.length}
        size={"s"}
        title={t("selectall")}
        style={{
          "@media screen and (max-width: 500px)": { marginLeft: "0px" },
        }}
        clazz={"ml-auto"}
      ></Checkbox>
      <Dropdown
        title={t("actionselected")}
        size={"xs"}
        disabled={!agencySelectCheckbox.length ? true : false}
        clazzWrapper={`dropdown-agency__actions color-main ${
          !agencySelectCheckbox.length ? "disabled" : ""
        }`}
      >
        {options.options.map((i) => {
          if (i.toggle) {
            return (
              <div className="d-flex justify-sb w-100 align-center">
                {i.title}
                <Toggle
                  key={i.id}
                  clazz={"ml-auto"}
                  id={"isNotifyEmail"}
                  checked={toggleActive.includes(i.id)}
                  onClick={() => {
                    if (toggleActive.includes(i.id)) {
                      setToggleActive((prev) =>
                        prev.filter((item) => item !== i.id)
                      );
                      i.onClick(false);
                    } else {
                      setToggleActive((prev) => [...prev, i.id]);
                      i.onClick(true);
                    }
                  }}
                />
              </div>
            );
          } else {
            return (
              <Button onClick={i.onClick} size={"s"}>
                {i.title}
              </Button>
            );
          }
        })}
      </Dropdown>

      {windowWidth > 560 && (
        <Select
          arrowSize="xs"
          options={options.selectCardsPerPageOptions}
          size={"xs"}
          onChange={(i) => handleChangeItemsPerPageAmmount(i)}
        ></Select>
      )}
    </>
  );
};

export const privacyPolicyRu = {
  h1: 'Политика конфиденциальности',
  p1: 'Настоящая Политика конфиденциальности (далее именуемая "Политика") является неотъемлемой частью условий заключения сделки с ООО "IT4L", ОГРН 19470711, зарегистрированного по адресу: Подебрадска 1026/52, Высочаны, 19000 Прага, 9 (далее именуемое "IT4L").',
  p2: 'IT4L заботится о защите персональных данных в соответствии с действующим законодательством, в частности, с законом об обработке персональных данных № 110/2019 Св., который включает в себя соответствующие нормативные акты Европейского союза, а именно директиву 2016/680 Европейского парламента и Совета по защите физических лиц и Общий регламент 2016/679 Европейского парламента и Совета по защите персональных данных.',
  p3: 'Помимо действующего законодательства IT4L обеспечивает чрезвычайно высокую степень анонимности всем лицам, которые пользуются услугами интернет-сервера www.lady4love.com (далее именуемого "сервер"). В частности, это касается предоставления места для рекламы услуг для взрослых с возможностью онлайн-общения между клиентом и рекламодателем и посредничества при продаже дополнительных услуг.',
  p4: 'Из этого следует, что IT4L обрабатывает персональные данные рекламодателей услуг для взрослых, продавцов дополнительных услуг и покупателей дополнительных услуг.',
  p5: 'IT4L собирает персональные данные только в объеме, необходимом для обеспечения качества предоставляемых услуг и выполнения своих юридических обязательств, эти данные не предоставляются третьим лицам, за исключением уполномоченных государственных органов. IT4L раскрывает или индивидуально предоставляет только самую необходимую контактную информацию, чтобы рекламодатели, продавцы и клиенты могли начать общение друг с другом (обычно это только номер телефона или адрес электронной почты). Обращением может являться любое имя или псевдоним.',
  p6: 'Настоящая политика применяется ко всем персональным данным, обрабатываемым IT4L на вышеупомянутом сервере, на основании выполнения договорных отношений, юридических обязательств, законных интересов или предоставленного согласия. Политика описывает, каким образом IT4L использует и защищает персональные данные.',
  p7: 'IT4L может внести изменения в настоящую политику в любое время, разместив пересмотренную версию на этом сервере, и такие изменения вступают в силу с даты ее публикации.',

  one: {
    title: 'АДМИНИСТРАТОР И ЕГО КОНТАКТНЫЕ ДАННЫЕ',
    p1: 'Оператором персональных данных является IT4L.',
    p2: `Все уведомления о защите данных и нарушениях прав следует направлять по тел. +420 774231617 или по электронной почте: info@lady4love.com.`,
  },
  two: {
    title: 'ПЕРСОНАЛЬНЫЕ ДАННЫЕ, ОБРАБАТЫВАЕМЫЕ IT4L',
    p1: 'Под персональными данными подразумевается любая информация, относящаяся к опознанному или опознаваемому физическому лицу. Опознаваемое физическое лицо - это лицо, которое может быть идентифицировано прямо или косвенно, в частности, путем ссылки на идентификатор, такой как имя, идентификационный номер, данные о местоположении, сетевой идентификатор или на один или несколько факторов, специфических для физической, физиологической, генетической, психологической, экономической, культурной или социальной личности этого физического лица.',
    p2: 'В частности, компания обрабатывает следующие персональные данные: номер телефона, адрес электронной почты, контакт в сети telegram, контакт в сети WhatsApp, фотографию лица, фотографию личного документа, текущий адрес, ксерокопии медицинских анализов, веб-сайт. Некоторые из вышеперечисленных персональных данных собираются и обрабатываются только для определенных предоставляемых услуг. IT4L получает персональные данные только от лиц, которые добровольно их предоставляют; компания также может проверять данные, полученные таким образом из других источников (в частности, из Интернета).',
    p3: 'Файлы cookie и интернет-маяки помогают IT4L предоставлять свои услуги. Для получения более подробного определения, типов и целей их использования компанией IT4L, пожалуйста, ознакомьтесь с политикой ниже.',
  },
  three: {
    title: 'ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ',
    p1: 'IT4L обрабатывает персональные данные для следующих целей:',
    list: [
      'для предконтрактных переговоров; ',
      'для выполнения договорных отношений (отношений между Вами и IT4L, которые возникли, в частности, в результате ввода и публикации рекламы, использования дополнительных услуг или регистрации в качестве пользователя сервиса);',
      'для администрирования учетных записей пользователей (т.е., в частности, для выполнения Вашего запроса на предоставление услуги, создания Вашей учетной записи, администрирования Вашей учетной записи и обеспечения Вашего доступа к сервису и его обновлениям);',
      'для управления доверенными средствами (хранение виртуальной валюты Erocoin или других валют на виртуальном счете, отчет об использовании средств);',
      'для связи с Вами с целью решения вопросов, связанных с использованием сервисов или сервера;',
      'для отправки Вам информации об услугах (т.е. подтверждений, уведомлений, обновлений, вспомогательных и административных сообщений);',
      'для демонстрации рекламы в соответствии с Вашими интересами (в частности, при использовании фильтров для поиска объявлений с нужным содержанием);',
      'для выполнения юридических обязательств IT4L (вытекающих из Гражданского кодекса, закона о лицензировании торговли, закона об ограничении наличных платежей, закона о некоторых мерах по борьбе с легализацией доходов, полученных преступным путем, соответствующего налогового законодательства и т.д.).',
    ],
    p2: 'Без предоставления персональных данных невозможно провести предконтрактные переговоры, заключить контракт или выполнить его со стороны IT4L. Как правило, договоры заключаются удаленно на соответствующих страницах сервера; это не исключает возможности заключения договора иным способом (например, путем оплаты).'
  },
  four: {
    title: 'ПРЕДОСТАВЛЕНИЕ СОГЛАСИЯ',
    p1: 'IT4L обрабатывает только определенные персональные данные и для таких целей, при которых отдельное согласие на их обработку не требуется.',
    p2: 'Единственным исключением из этого положения является обработка и раскрытие отдельных медицинских данных; предоставляя эти данные IT4L, лицо, предоставившее медицинские данные, соглашается на их обработку и разглашение.',
    p3: 'Согласие, данное IT4L на обработку персональных данных, может быть отозвано в любое время по тел. +420 774231617 или по электронной почте: info@lady4love.com с указанием вашего имени, фамилии и адреса для идентификации. Отзыв согласия не влияет на законность обработки, основанной на согласии, данном до его отзыва. Отзыв согласия приведет к прекращению предоставления услуг соответствующему лицу.'
  },
  five: {
    title: 'СРОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ',
    p1: 'Персональные данные будут обрабатываться IT4L только в течение периода, строго необходимого для выполнения заказанных услуг, в частности, на время рекламы (в случае рекламодателей или поставщиков дополнительных услуг) или на период регистрации для использования сервера (в случае клиентов). Обработка персональных данных не может быть прекращена до тех пор, пока финансовые обязательства между IT4L и заинтересованным лицом не будут должным образом и в полном объеме урегулированы. Это не влияет на обязательство IT4L хранить персональные данные в течение срока, установленного законом.',
    p2: 'По истечении срока хранения персональных данных IT4L удалит персональные данные.',
  },
  six: {
    title: 'ПРЕДОСТАВЛЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ ТРЕТЬИМ ЛИЦАМ',
    p1: 'IT4L предоставляет персональные данные только своим сотрудникам и только в той мере, в какой это необходимо для выполнения согласованных обязательств IT4L (например, бухгалтерам, программистам и т.д.). Эти третьи лица связаны теми же обязательствами по защите данных, что и IT4L.',
    p2: 'IT4L не раскрывает Ваши персональные данные третьим лицам, кроме указанных выше, без вашего согласия, а также не передает их другим лицам или неаффилированным компаниям, за исключением следующих случаев:',
    list: [
      'соблюдение требований законодательства или в ответ на них;',
      'защита прав и собственности IT4L, ее представителей, пользователей и других лиц, в частности, с целью соблюдения согласованных контрактов и, в экстренных случаях, для обеспечения собственной безопасности IT4L, пользователей ее услуг или любого другого лица;',
      'в связи или в ходе любого слияния, продажи активов компании, финансирования или приобретения всего бизнеса IT4L или его части другой компанией.'
    ],
    p3: 'IT4L не будет передавать Ваши персональные данные без Вашего согласия в третью страну или международную организацию, за исключением случаев, когда это необходимо для выполнения контракта между вами и IT4L, или такое обязательство не возникает у IT4L по закону.',
    p4: 'IT4L не предоставляет никаких персональных данных, собранных через сервер или в ходе предоставления услуг третьим лицам с целью прямого маркетинга.',
  },
  seven: {
    title: 'ПРАВА СУБЪЕКТА ДАННЫХ',
    p1: 'Любое физическое лицо, чьи персональные данные обрабатываются IT4L, обладает следующими правами, которые могут быть реализованы в любое время. Речь идет о:',
    list: [
      'праве на доступ к вашим персональным данным (т.е. праве на получение информации о том, обрабатываются ли эти данные, и, если это так, то праве на доступ к ним);',
      'праве на исправление персональных данных (т.е. праве запросить исправление, если станет известно, что IT4L обрабатывает неточные или ложные данные);',
      'праве потребовать объяснений (т.е. если при подозрении, что обработка персональных данных нарушает конфиденциальность личной жизни или обработка осуществляется с нарушением закона);',
      'праве на ограничение обработки персональных данных (т.е. праве требовать временного ограничения обработки персональных данных);',
      'праве на удаление персональных данных (т.е. когда персональные данные больше не являются необходимыми для целей, для которых они были обработаны);',
      'праве выразить протест против обработки персональных данных (IT4L должно доказать существование законных оснований для обработки персональных данных, которые превышают интересы, права и свободы затронутого субъекта данных);',
      'праве на перенос данных (т.е. праве требовать, чтобы IT4L предоставило данные третьей стороне способом, который допускает дальнейшую обработку);',
      'праве отозвать согласие на обработку персональных данных в любое время;',
      'праве подать жалобу в Управление по защите персональных данных (т.е., в частности, если субъект считает, что его право на защиту персональных данных было нарушено и IT4L не устранило нарушения после запроса).'
    ]
  },
  eight: {
    title: 'МЕРЫ БЕЗОПАСНОСТИ И РАСКРЫТИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ',
    p1: 'Основным инструментом защиты персональных данных является тот факт, что IT4L обрабатывает лишь минимум персональных данных, чтобы иметь возможность предоставлять свои услуги на высоком уровне, максимально сохраняя анонимность поставщиков услуг и их клиентов. Как правило, обрабатываемых персональных данных недостаточно для однозначной идентификации субъекта данных, или такая идентификация является чрезмерно сложной.',
    p2: 'IT4L хранит персональные данные в соответствии с самыми высокими стандартами безопасности, используя широкий спектр мер безопасности, включая средства шифрования и аутентификации, для предотвращения несанкционированного доступа, изменения, раскрытия или уничтожения персональных данных и сохранения их конфиденциальности. Персональные данные доступны только ограниченному кругу лиц, которые обязаны хранить их в тайне.',
  },
  nine: {
    title: 'ФАЙЛЫ COOKIE И ИНТЕРНЕТ-МАЯКИ',
    p1: 'Чтобы предоставлять услуги, адаптированные к потребностям пользователей сервера, IT4L использует файлы cookie (файлы cookie с идентификатором сеанса и постоянные файлы cookie) для хранения и периодического отслеживания посещений сервера. IT4L использует файлы cookie для упрощения навигации по серверу. Когда вы закрываете свой браузер, срок действия файла cookie с идентификатором сеанса истекает, а постоянный файл cookie остается на Вашем жестком диске в течение более длительного периода времени. IT4L определяет, включен ли JavaScript в Вашем браузере, чтобы облегчить просмотр портала. Большинство интернет-браузеров настроены на автоматическое принятие файлов cookie.',
    p2 : 'IT4L использует файлы cookie только в той мере, в какой это необходимо для бесперебойного предоставления своих услуг, а также для их беспрепятственного поиска и возможного использования. IT4L не передает какие-либо данные, полученные с помощью файлов cookie, третьим лицам, например, для персонализированной рекламы.'
  }
}
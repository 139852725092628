import { useEffect } from "react";
import { useSelector } from "react-redux";
import useLadyService from "@/services/LadyService";
import { fetchRanges } from "@/stores/slices/filterSlice";
import { getIsMainPage } from "@/stores/slices/modelsState";

const useFetchRanges = () => {
  const { lang, dispatch, userCity } = useLadyService();
  const isMainPage = useSelector(getIsMainPage);

  useEffect(() => {
    if (lang && userCity.id && !isMainPage) {
      dispatch(fetchRanges(userCity.id, lang));
    }
  }, [userCity, lang]);

  return null;
};

export default useFetchRanges;

import React from "react";
import "./attentionBox.scss";
import AttentionBrick from "../../../../ui/attentionBrick/AttentionBrick";
import useLadyService from "../../../../../services/LadyService";

const AttentionBox = ({ children, type, tag = "p", clazz }) => {
  const { t } = useLadyService();

  if (tag === "div") {
    return (
      <div className={`attention-box${type ? ` ${type}` : ''}${clazz ? ` ${clazz}` : ''}`}>
        {type === "important" && (
          <span className={"attention-box__word"}>{t("important")}! </span>
        )}

        {(type === "advice" || type === "warning") && (
          <AttentionBrick type={type} />
        )}

        {children}
      </div>
    );
  } else {
    return (
      <p className={`attention-box${type && ` ${type}`}${clazz ? ` ${clazz}` : ''}`}>
        {type === "important" && (
          <span className={"attention-box__word"}>{t("important")}! </span>
        )}

        {(type === "advice" || type === "warning") && (
          <AttentionBrick type={type} />
        )}

        {children}
      </p>
    );
  }
};

export default AttentionBox;

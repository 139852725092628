import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    selected: [],
};

const agencyFilterSlice = createSlice({
    name: "agencyFilter",
    initialState,
    reducers: {
        selectReset: (state) => {
          state.selected = []
        },
        selectAdd: (state, action) => {
            if (!state.selected.includes(action.payload)) {
                state.selected.push(action.payload);
            }
        },
        unselectAdd: (state, action) => {
            const index = state.selected.indexOf(action.payload)
            if (index >= 0) {
                state.selected.splice(index, 1);
            }
        },
    },
});

export const {
    selectAdd,
    unselectAdd,
    selectReset,
} = agencyFilterSlice.actions;

export const getSelectedCount = (state) => state.agencyFilter.selected.length;
export const getSelected = (state) => state.agencyFilter.selected;


export default agencyFilterSlice.reducer;
import { InputInLabel, Button, LoadingButton} from "@/components/ui";
import useLadyService from "@/services/LadyService";

export const ButtonsForm = ({isLoading, invoiceLoad, fetchInvoice, isRulesCheck, rulesRef, setRulesError, errors, register, clearErrors, isFactureCheck}) => {
    const {t} = useLadyService()

    const handleClick = () => {
        setRulesError(true);
        rulesRef.current.scrollIntoView({
          behavior: "smooth",
        });
      };
    return (
    <div className="d-flex gap-12 mt-auto topup-buttons">
      <InputInLabel
        clazz={`${errors.email ? " error" : ""} w-100`}
        id={"balance-pay"}
        type={"email"}
        placeholder={"Email"}
        register={{ ...register("email") }}
        autocomplete={"email"}
        onChange={() => {
          if (errors.email) {
            clearErrors("email");
          }
        }}
      >
        {(errors.email && (
          <span className={"input-label__error"}>{errors.email.message}</span>
        )) || <span>{t("wherereceipt")}</span>}
      </InputInLabel>
      {!invoiceLoad && isFactureCheck && (
        <Button
          size={"l-forever"}
          clazz={`button--primary w-100`}
          onClick={() => fetchInvoice()}
        >
          {t("invoicedownload")}
        </Button>
      )}
      {invoiceLoad && (
        <LoadingButton
          buttonType={"button"}
          isLoading={invoiceLoad}
          size={"l-forever"}
          clazz={"button--primary w-100"}
        >
          {t("continue")}
        </LoadingButton>
      )}
      {!isLoading && (
        <Button
          disabled={!isRulesCheck}
          requiredThrow={!isRulesCheck}
          fooThrow={() => handleClick()}
          size={"l-forever"}
          buttonType={"submit"}
          wrapperClazz={`w-100`}
          clazz={`button--green w-100`}
        >
          {t("toearn")}
        </Button>
      )}
      {isLoading && (
        <LoadingButton
          buttonType={"submit"}
          isLoading={isLoading}
          size={"l-forever"}
          clazz={"button--green w-100 grid-col-3"}
        >
          {t("continue")}
        </LoadingButton>
      )}
    </div>
  );
};

import { Icon } from "@/components/ui";

const paymentsMethods = {
  card: [
    { icon: "visa", name: "Visa" },
    { icon: "master-card", name: "MasterCard" },
    { icon: "american-express", name: "AmericanExpress" },
    { icon: "google-pay", name: "Google Pay" },
    { icon: "apple-pay", name: "Apple Pay" },
    { icon: "union-pay", name: "Union Pay" },
  ],
  paypal: [
    { icon: "paypal", name: "PayPal" },
    { icon: "visa", name: "Visa" },
    { icon: "master-card", name: "MasterCard" },
    { icon: "american-express", name: "AmericanExpress" },
    { icon: "google-pay", name: "Google Pay" },
    { icon: "apple-pay", name: "Apple Pay" },
    { icon: "union-pay", name: "Union Pay" },
  ],
  crypto: [
    { icon: "bitcoin", name: "Bitcoin" },
    { icon: "tether", name: "Tether" },
    { icon: "usdc", name: "USDC" },
    { icon: "etherium", name: "Ethereum" },
    { icon: "ltc", name: "Litecoin" },
    { icon: "matic", name: "Matic" },
    { icon: "tron", name: "Tron" },
  ],
};

export const HeaderForm = ({ type, formTitle }) => {
  const isBank = type === "bank";

  return (
    <div className="balance-page__header">
      <h3>{formTitle}</h3>
      <div className="payment-methods">
        {!isBank &&
          paymentsMethods[type].map((i, index) => {
            return (
              <div
                className="payment-methods__icon"
                key={index + 1}
                title={i.name}
              >
                <Icon type={"payment"} spritePath={i.icon} />
              </div>
            );
          })}

        {isBank && <Icon size={"m"} spritePath={"bank"} />}
      </div>
    </div>
  );
};

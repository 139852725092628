import React from "react";
import ContentLoader from "react-content-loader";
import useLadyService from "@/services/LadyService";

const MyLoader = (props) => {
  const {
    theme
  } = useLadyService();

  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 335 754"
      backgroundColor={theme === "light" ? "#d5dddb" : "#adadad"}
      foregroundColor="#ffffff"
      {...props}
    >
      <rect x="25" y="754" rx="5" ry="5" width="100%" height="10" />
      <rect x="0" y="0" rx="24" ry="24" width="335" height="446" />
      <rect x="0" y="422" rx="0" ry="0" width="2" height="308" />
      <rect x="333" y="422" rx="0" ry="0" width="2" height="308" />
      <rect x="0" y="754" rx="0" ry="0" width="100%" height="2" />
      <rect x="0" y="730" rx="16" ry="16" width="335" height="2" />
      <rect x="19" y="476" rx="4" ry="4" width="53" height="38" />
      <rect x="89" y="476" rx="4" ry="4" width="40" height="38" />
      <rect x="145" y="476" rx="4" ry="4" width="40" height="38" />
      <rect x="20" y="539" rx="4" ry="4" width="67" height="12" />
    </ContentLoader>
  );
};

export default MyLoader;

import { Helmet } from "react-helmet";
import { Button, ButtonLink, Icon } from "@/components/ui";
import {
  getPartyAccessStatus,
  setPartyAccess,
} from "@/stores/slices/popupSlice";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../Popup";

const PartyAccess = () => {
  const dispatch = useDispatch();
  const popupState = useSelector(getPartyAccessStatus);
  const handleClose = () => {
    dispatch(setPartyAccess(false))
  }
  return (
    <Popup
      id={"partyAccess"}
      open={!!popupState}
      setOpen={handleClose}
      className="popup-form"
      container={'div'}
    >

        <h3 className="popup-form__title text-center m-12-24">
          Эта вечеринка только для VIP-клиентов.
        </h3>

        <ButtonLink
          to={"#"}
          size={"l-forever"}
          clazz={"button--green td-none"}
        >
          Сменить тариф
        </ButtonLink>
    </Popup>
  );
};

export default PartyAccess;

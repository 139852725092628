import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel, Select } from "@/components/ui";
import { useForm } from "react-hook-form";
import {Popup} from "../index";

const WithdrawBalancePopup = (props) => {
  const { open, setOpen } = props;

  const { t } = useTranslation("translation");

  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    // const {} = data;

    // const fetchData = async () => {
    //   try {
    //     const route = `user/change-password`;
    //     const method = "PUT";
    //     const payload = { current_password: old_password, new_password };

    //     await makeRequest({ route, method, payload });
    //     reset();
    //     setOpen(false);
    //     showToast({
    //       message: "Пароль успешно обновлен",
    //       variant: "success",
    //     });
    //   } catch (error) {
    //     const res = error.response;

    //     // eslint-disable-next-line default-case
    //     switch (res.status) {
    //       case 401: {
    //         showToast({
    //           message: "Неавторизован",
    //           variant: "error",
    //         });
    //         break;
    //       }
    //       case 422: {
    //         setErrorCurrent("Текущий пароль неверный");

    //         break;
    //       }
    //     }
    //   }
    // };

    // fetchData();
  };

  const handleCloseDelete = () => {
    reset();
    setOpen(false);
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      onSubmit={handleSubmit(onSubmit)}
      clazz={'gap-16'}
    >
          <h3 className="m-12-24">Вывод средтсв</h3>
        <div className="popup-form__inner">
          <InputInLabel
            id={"wdBalance"}
            title={"Сумма вывода"}
            type={"number"}
            register={{ ...register("balance") }}
            placeholder={"100"}
          >
            Сумма вывода
          </InputInLabel>

          {/* <Select
            options={[]}
            arrowSize={"s"}
          ></Select> */}

          <InputInLabel
            id={"wdCard"}
            title={"Номер карты"}
            type={"card"}
            register={{ ...register("numberCard") }}
          />

          <Button
            size={"l-forever"}
            buttonType={"submit"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            Вывести
          </Button>
        </div>
    </Popup>
  );
};

export default WithdrawBalancePopup;

import { useEffect, useRef, useState } from "react";
import { getForgotPasswordEmailStatus } from "../../../stores/slices/popupSlice";
import { useSelector } from "react-redux";


export const CodeInput = ({ setFormCodeError, codeError = false, setCodeError, setValue, setIsComplete }) => {
  const numberOfDigits = 4;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);
  const forgotPassEmailState = useSelector(getForgotPasswordEmailStatus);

  useEffect(() => {
    if (forgotPassEmailState) {
      setOtp(new Array(numberOfDigits).fill(""));
      setValue(new Array(numberOfDigits).fill(""));
      if (setCodeError) {
        setCodeError("");
      }
    }
  }, [forgotPassEmailState]);

  function handleChange(value, index) {
    let newArr = [...otp];

    if (newArr[index] && value && index < numberOfDigits - 1) {
      newArr[index + 1] = value;
      otpBoxReference.current[index + 1].focus();
    } else {
      newArr[index] = value;
    }

    setOtp(newArr);
    setValue(newArr);
    if (newArr.filter((item) => item).length === 4) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
    if(codeError) {
      setCodeError("")
    }
    if(setFormCodeError) {
      setFormCodeError("")
    }
    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && index > 0) {
      if (!e.target.value) {
        otpBoxReference.current[index - 1].focus();
      }
    }

    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handlePaste(e) {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, numberOfDigits);
    const newOtp = [...otp];

    for (let i = 0; i < pasteData.length; i++) {
      newOtp[i] = pasteData[i];
    }

    if (newOtp.filter((item) => item).length === 4) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }

    if(codeError) {
      setCodeError("")
    }
    if(setFormCodeError) {
      setFormCodeError("")
    }

    setOtp(newOtp);
    setValue(newOtp);

    const nextIndex =
      pasteData.length >= numberOfDigits
        ? numberOfDigits - 1
        : pasteData.length;
    otpBoxReference.current[nextIndex].focus();
  }

  return (
    <div className="mb-16 mt-16 code-elems">
      {otp.map((digit, index) => (
        <input
          key={index}
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleBackspaceAndEnter(e, index)}
          onPaste={handlePaste}
          ref={(reference) => (otpBoxReference.current[index] = reference)}
        />
      ))}
    </div>
  );
};

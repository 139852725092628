import useLadyService from "../services/LadyService";
import useUserProfileService from "../services/UserProfileService";

const useCheckAccess = (key = '') => {
  const { navigate, userTypeAgency } = useLadyService();
  const {settignsOperator} = useUserProfileService();

  // Примеры линков для ключей массива
  // const accessPath = {
  //   "agencyprofileman": "agency",
  //   "adsamanage": "profiles",
  //   "promoandads": "profiles",
  //   "reviewmanage": "reviews",
  //   "partiesmanage": "createparty",
  //   "statsaccess": "statistic",
  //   "balancemanage": "balance",
  //   "gotopreviews": "previews",
  //   "bookings": "reservations",
  //   "videochats": "videochats"
  // }

  const hasAccess = () => {
    if (!userTypeAgency) return true;
    if (userTypeAgency && !settignsOperator) return true;
    return (userTypeAgency && settignsOperator) && settignsOperator.includes(key);
  };

  const AccessTitle = () => (
    <main>
      <h2 className={'text-center'}>Доступ запрещён, обратитесть к администратору портала для получения прав.</h2>
    </main>
  )

  const redirectToMain = () => {
    if (!hasAccess()) {
      navigate('/');
    }
  };

  return {
    hasAccess: hasAccess(),
    redirectToMain,
    AccessTitle: AccessTitle()
  };
};

export default useCheckAccess;
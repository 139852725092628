import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  place: [],
  breast_size: [],
  breast_type: [],
  penis_size: [],
  ethnos: [],
  hair_color: [],
  hair_size: [],
  hair_intim: [],
  eye_color: [],
  eye_type: [],
  tag: [],
  special_lips: [],
  special_tattoo: [],
  special_piercing: [],
  special_smoke: [],
  status: [],
  gender: [],
  sex: [],
  for: [],
  services: [],
  language: [],
  min_tariffs: [],
  last_visit: [],
  radius: {
    value: [],
    latitude: 0,
    longitude: 0,
  },
  nation: [],
  resultString: "",
  modelsOnMapCount: 0,
};

const counterFilterSlice = createSlice({
  name: "counterFilter",
  initialState,
  reducers: {
    setModelsOnMapCount: (state, action) => {
      state.modelsOnMapCount = action.payload;
    },
    setRadioCount: (state, action) => {
      const value = action.payload.count;
      const stateName = action.payload.StateName;

      state[stateName] = [value];
    },
    setResultString: (state, action) => {
      state.resultString = action.payload;
    },
    setRadiusCoordsCount: (state, action) => {
      const { latitude, longitude } = action.payload;
      state.radius.latitude = latitude;
      state.radius.longitude = longitude;
    },
    resetRadiusCoordsCount: (state) => {
      state.radius.latitude = 0;
      state.radius.longitude = 0;
    },
    resetRadiusCount: (state) => {
      state.radius.value = [];
    },
    setRadiusCount: (state, action) => {
      state.radius.value = [action.payload];
    },
    setPlaceCount: (state, action) => {
      state.place = [action.payload];
    },
    toggleStateCount: (state, action) => {
      const value = String(action.payload.count);
      const stateName = String(action.payload.StateName);
      const isCheckbox = action.payload.isCheckbox;
      const isSelected = state[stateName].includes(value);

      let updatedSelectedCheckboxes = [];

      if (!isSelected) {
        if (isCheckbox) {
          state[stateName] = [];
        }
        updatedSelectedCheckboxes = [...state[stateName], value];
      } else {
        updatedSelectedCheckboxes = state[stateName].filter(
          (item) => item !== value
        );
      }

      state[stateName] = updatedSelectedCheckboxes;
    },
    resetStateCount: (state, action) => {
      const stateName = String(action.payload);
      state[stateName] = [];
    },
    resetAllCounters: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setResultString,
  toggleStateCount,
  resetStateCount,
  resetAllCounters,
  setPlaceCount,
  setRadiusCount,
  resetRadiusCount,
  setRadiusCoordsCount,
  resetRadiusCoordsCount,
  setRadioCount,
  setModelsOnMapCount,
} = counterFilterSlice.actions;

export const getModelsOnMapCount = (state) => state.modelsOnMapCount;
export const getPlaceCount = (state) => state.counterFilter.place;
export const getBreastSizeCount = (state) => state.counterFilter.breast_size;
export const getBreastTypeCount = (state) => state.counterFilter.breast_type;
export const getPenisSizeCount = (state) => state.counterFilter.penis_size;
export const getEthnosCount = (state) => state.counterFilter.ethnos;
export const getHairColorCount = (state) => state.counterFilter.hair_color;
export const getHairSizeCount = (state) => state.counterFilter.hair_size;
export const getHairIntimCount = (state) => state.counterFilter.hair_intim;
export const getEyeColorCount = (state) => state.counterFilter.eye_color;
export const getEyeTypeCount = (state) => state.counterFilter.eye_type;
export const getTagCount = (state) => state.counterFilter.tag;
export const getLipsCount = (state) => state.counterFilter.special_lips;
export const getTattooCount = (state) => state.counterFilter.special_tattoo;
export const getPiercingCount = (state) => state.counterFilter.special_piercing;
export const getSmoKeCount = (state) => state.counterFilter.special_smoke;
export const getStatusCount = (state) => state.counterFilter.status;
export const getGenderCount = (state) => state.counterFilter.gender;
export const getSexCount = (state) => state.counterFilter.sex;
export const getServicesForCount = (state) => state.counterFilter.for;
export const getServicesCount = (state) => state.counterFilter.services;
export const getLanguageCount = (state) => state.counterFilter.language;
export const getTariffsCount = (state) => state.counterFilter.min_tariffs;
export const getLastVisitCount = (state) => state.counterFilter.last_visit;
export const getRadiusCount = (state) => state.counterFilter.radius;
export const getNationCount = (state) => state.counterFilter.nation;
export const getResultFilterString = (state) =>
  state.counterFilter.resultString;

export default counterFilterSlice.reducer;

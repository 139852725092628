import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "../../toast/Toast";
import useLadyService from "../../../services/LadyService";
import axios from "axios";
import {Popup} from "../index";

const WarningOnRemovePhoto = (props) => {
  const {
    open,
    setOpen,
    profileSlug,
    setAllSteps,
    setAvailableSteps,
    link,
    setData,
    formData
  } = props;

  const { t, token } = useLadyService();

  const handleCloseDelete = () => {
    setOpen(false);
  };

  const removeFile = (fileName) => {
    setData((prevData) => {
      return {
        files:
          prevData.files
            ?.filter((file) => file.name !== fileName)
            .filter((file) => file.link !== fileName) ||
          prevData.filter((file) => file.link !== fileName),
      };
    });
  };

  const onPost = async (e) => {
    try {
      const route = `user/profile/${profileSlug}/set-status`;
      const method = "PUT";
      const payload = {
        status: false,
      };

      await makeRequest({ route, method, payload });
      if(setAllSteps) {
        setAllSteps(false);
      }
      if(setAvailableSteps) {
        setAvailableSteps((prev) => {
          return [1, 2];
        });
      }
      
      axios.delete(`${link}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      removeFile(link);
      setOpen(false);
      showToast({
        message: `${t("ad")} ${formData.name} ${t("deactivated")}`,
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    onPost();
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      method={"PUT"}
      clazz={'gap-16'}
    >
        <div className="popup-form__inner text-center">
          <h3 className="m-12-24">{t('realydelphoto')}</h3>

          <span className={"color-700"}>
            {t('photodeldeactiv')}
          </span>
        </div>
        <div className="popup-form__buttons">
          <Button
            size={"l-forever"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {t("cancel")}
          </Button>

          <Button
            size={"l-forever"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {t("yesdelete")}
          </Button>
        </div>
    </Popup>
  );
};

export default WarningOnRemovePhoto;

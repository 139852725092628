import "./agencyList.scss";
import Header from "../../header/Header";
import AgencyCard from "../../cards/agencyCard/AgencyCard";
import { ButtonLink, Icon, Select } from "@/components/ui";
import Notifications from "../../notifications/Notifications";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getUserCountry,
  getUserCurrentCity,
  getUserLang,
} from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";
import { getAgencies } from "../../../stores/slices/agencySlice";

const AgencyList = () => {
  const currentCity = useSelector(getUserCurrentCity);
  const currentCountry = useSelector(getUserCountry);
  const agencies = useSelector(getAgencies)
  const { t } = useTranslation("translation");
  const lang = useSelector(getUserLang);
  const cityId = useSelector(getUserCurrentCity).id;
  const [page, setPage] = useState(1);
  const [data, setData] = useState(agencies);
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = "agencies";
        const method = "GET";
        const payload = {
          page,
        };

        const data = await makeRequest({ route, method, payload });
        const result = data.agencies;
        const metaData = data.meta;
        if (result) {
          setData(result);
          setMetaData(metaData);
        }
      } catch (error) {}
    };

    lang && cityId && fetchData();
  }, [lang, cityId, page]);

  return (
    <main className={"agencies"}>
      <section className={"agencies__container"}>
        <div className="navigation__top">
          <h1>
            {t("allagencies")}
            <Select
              name={"countries"}
              options={
                currentCountry.length > 0 ? currentCountry : [currentCity]
              }
              clazz={"select-city--word"}
              clazzSvg={"select__flag"}
              arrowSize={"m"}
              type={"flag"}
            />
            <span className="agencies__count">
              {"Всего "}
              {metaData?.total}
            </span>
          </h1>
          <div className={"navigation__top-wrapper"}>
            <ButtonLink
              clazz={`button_outline--black _no-underline agencies__map`}
              size={"s"}
            >
              <span>{t("onmap")}</span> <Icon size={"m"} spritePath={"map"} />
            </ButtonLink>
            <Notifications />
          </div>
        </div>

        <div className="grid-cards">
          {data?.length > 0 &&
            data.map((data) => <AgencyCard props={data} key={data.id} />)}
        </div>
      </section>
    </main>
  );
};

export default AgencyList;

import { Helmet } from "react-helmet";
import { Button, Icon, Loader } from "../../ui";
import { makeRequest } from "../../../services/makeRequest";
import useLadyService from "../../../services/LadyService";
import { setStoriesStatus} from "../../../stores/slices/popupSlice";
import { useState } from "react";
import {useSelector} from "react-redux";
import {Popup} from "../index";

const RemoveStory = ({open, setOpen,  setStories, id, setOpenAll }) => {
  const { dispatch, t } = useLadyService();

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseDelete = () => {
    setOpen(false)
  };

  const handleRemoveStory = async () => {
    try {
      setIsLoading(true);
      const route = `user/stories/${id}`;

      const method = "DELETE";

      const { result } = await makeRequest({ route, method });
      if (result) {
        setStories([]);
        setIsLoading(false);
        handleCloseDelete()
        setOpenAll(false);
        dispatch(setStoriesStatus(false));
      }
    } catch (error) {}
  }

  return (
    <>
      {isLoading && (
        <div className="loader__overlay">
          <Loader height={100} />
        </div>
      )}

      <Popup
        open={open}
        setOpen={handleCloseDelete}
        clazz={'gap-16'}
        container={'div'}
      >

          <h3 className="m-12-24">
            {t("delete")} {t("modelstories")}
          </h3>

          <div className="popup-form__buttons">
            <Button
              size={"l-forever"}
              clazz={"button--secondary justify-center"}
              onClick={handleCloseDelete}
            >
              {t("cancel")}
            </Button>

            <Button
              size={"l-forever"}
              clazz={"button--error justify-center"}
              onClick={handleRemoveStory}
            >
              {t("yesdelete")}
            </Button>
          </div>
      </Popup>
    </>
  );
};

export default RemoveStory;

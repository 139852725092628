import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    address: [],
    coords: {
        latitude: 0,
        longitude: 0,
    },
    shortData: {},
    profile: {},
    sortedServices: [],
    topProfiles: [],
};

export const oneProfileSlice = createSlice({
    name: "oneProfile",
    initialState,
    reducers: {
        setOneProfileAddress: (state, action) => {
            state.address = [action.payload];
        },
        setOneProfileCoords: (state, action) => {
            const {latitude, longitude} = action.payload;
            state.coords = {
                latitude,
                longitude,
            };
        },
        setShortProfileData: (state, action) => {
            state.shortData = action.payload;
        },
        setProfileData: (state, action) => {
            state.profile = action.payload
        },
        setTopProfilesData: (state, action) => {
            state.topProfiles = action.payload
        },
        setSortedServices: (state, action) => {
            state.sortedServices = action.payload
        }
    },
});

export const {
    setOneProfileAddress,
    setOneProfileCoords,
    setShortProfileData,
    setProfileData,
    setTopProfilesData,
    setSortedServices
} = oneProfileSlice.actions;

export const getOneProfileAddress = (state) => state.oneProfile.address;
export const getOneProfileCoords = (state) => state.oneProfile.coords;
export const getShortProfileData = (state) => state.oneProfile.shortData;
export const getProfileData = (state) => state.oneProfile.profile;
export const getTopProfilesData = (state) => state.oneProfile.topProfiles;
export const getSortedServices = (state) => state.oneProfile.sortedServices;

export default oneProfileSlice.reducer;

import { Icon } from "../index";
import React from "react";

import "./attentionBrick.scss";
import useLadyService from "../../../services/LadyService";

const AttentionBrick = ({ type }) => {
  const { t } = useLadyService();
  return (
    <span
      className={`attention-brick${
        type === "advice"
          ? ""
          : " attention-brick--warning"
      }`}
    >
      <Icon
        spritePath={type === "advice" ? "warning" : "check"}
        clazz={type === "advice" ? `_green` : ""}
        size={`xl`}
      />

      {type === "advice" ? `${t("i-tips")}:` : `${t("i-uniquefunc")}!`}
    </span>
  );
};

export default AttentionBrick;

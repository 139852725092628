import {Button, Icon} from "../../index";

import './inputWithArrows.scss'
import React from "react";

const InputWithArrows = ({id, onClickUp, onClickDown, onChange, value}) => {
  return(
      <div className="input-label input-arrow">
        <Button
          clazz={"button--secondary"}
          square={true}
          size={"2xs"}
          onClick={onClickUp}
        >
          <Icon size={"xs"} spritePath={"chevrone-up"} />
        </Button>

        <label
          htmlFor={id}
          className={"input-price input-label--number currency--erocoin"}>
          <input
            id={id}
            value={value}
            type="number"
            max={99999}
            onChange={onChange}
            className={`input-label--number`}
          />
          {value}
        </label>

        <Button
          clazz={"button--secondary"}
          square={true}
          size={"2xs"}
          onClick={onClickDown}
        >
          <Icon size={"xs"} spritePath={"chevrone-down"} />
        </Button>
      </div>
  )
}

export default InputWithArrows
import { Button, RatingStars } from "@/components/ui";
import { Controller, useForm } from "react-hook-form";
import { Rating, Star } from "@smastrom/react-rating";
import { useTimer } from "react-timer-hook";

import useLadyService from "../../../../services/LadyService";
import { makeRequest } from "../../../../services/makeRequest";
import { setVideochatAmount } from "../../../../stores/slices/userSlice";
import showToast from "../../../toast/Toast";
import { useState } from "react";

const statusObj = {
  1: "workstatus",
  4: "approvestatus",
  6: "declinestatus",
  7: "canceledbyclient",
};

const Variant = ({ status, reviews, id }) => {
  const { t } = useLadyService();

  function getLastReviews(reviews) {
    let lastCustomerReview = null;
    let lastIndiReview = null;

    reviews.forEach((review) => {
      const userType = review.from.user_type;

      if (userType === "customer") {
        if (
          !lastCustomerReview ||
          review.created_at > lastCustomerReview.created_at
        ) {
          lastCustomerReview = review;
        }
      } else if (userType === "indi") {
        if (!lastIndiReview || review.created_at > lastIndiReview.created_at) {
          lastIndiReview = review;
        }
      }
    });

    return { lastCustomerReview, lastIndiReview };
  }

  const { lastIndiReview } = getLastReviews(reviews);

  const [isIndi, setIsIndi] = useState(!!lastIndiReview);
  const [indiRating, setIndiRating] = useState(lastIndiReview?.score || 0);

  const { control, getValues } = useForm({
    mode: "onChange",
  });

  const starsStyles = {
    itemShapes: Star,
  };

  const RatingComponent = (props) => {
    const { name, onChange: onSubmit } = props;
    return (
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (clazz) => clazz > 0,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Rating
            className={"rating-stars rating-stars--gold"}
            value={value}
            isRequired
            spaceInside="none"
            onChange={(value) => {
              onChange(value);
              onSubmit({
                score: value,
                body: getValues("body"),
              });
            }}
            onBlur={onBlur}
            itemStyles={starsStyles}
          />
        )}
      />
    );
  };

  const onSubmit = (data) => {
    const fetchData = async () => {
      try {
        const route = `reviews`;
        const method = "PUT";
        const payload = {
          score: data?.score || 0,
          body: null,
          reviewable_type: "videoCall",
          room_uuid: id,
        };

        const { message, review_id } = await makeRequest({ route, method, payload });

        if (message === "review_created" && review_id) {
          setIsIndi(true);
          setIndiRating(data?.score);
        } else {
          setIndiRating(0);
          showToast({
            message: t("oops"),
            variant: "error",
          });
        }
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

    if (statusObj[status] === "declinestatus") {
        return (
            <span className="p1 color-red-700 text-center">
          {t("declinestatus")}
        </span>
        );
    } else if (statusObj[status] === "declinestatus") {
        return (
            <span className="p1 color-red-700 text-center">
          {t("declinestatus")}
        </span>
        );
    } else if (statusObj[status] === "canceledbyclient") {
        return (
            <span className="p1 color-red-700 text-center">
          {t("canceledbyclient")}
        </span>
        );
    } else if (!isIndi && statusObj[status] === "approvestatus") {
        return (
            <>
          <span className="p1 color-green text-center">
            {t("approvestatus")}
          </span>
                <div className={`res__status done`}>
                    <span>{t("yourratingvideo")}</span>
                    <RatingComponent name={"score"} onChange={onSubmit}/>
                </div>
            </>
        );
    } else {
        return (
            <>
          <span className="p1 color-green text-center">
            {t("approvestatus")}
          </span>
                <div className={`res__status done`}>
                    <span>{t("yourclientreview")}</span>
                    <RatingStars color={'gold'} value={indiRating}/>
                </div>
            </>
        );
    }
};

const VariantTimer = ({ handleStatus, id, setData, date }) => {
  const { dispatch, t } = useLadyService();

  const expiryTimestamp = new Date(date * 1000);

  const newExpiryTimestamp = expiryTimestamp.setSeconds(
    expiryTimestamp.getSeconds() + 120
  );

  const fetchData = async () => {
    const route = `calls/${id}/cancel`;
    const method = "PUT";
    dispatch(
      setVideochatAmount((prev) => {
        if (prev - 1 < 0) {
          return 0;
        } else {
          return prev - 1;
        }
      })
    );
    await makeRequest({ route, method });
    setData((prevData) => {
      return prevData.map((item) => {
        if (item.uuid === id) {
          return { ...item, status: 6 };
        }
        return item;
      });
    });
  };

  const { seconds, minutes } = useTimer({
    autoStart: true,
    expiryTimestamp: newExpiryTimestamp,
    onExpire: fetchData,
  });

  const secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
  const minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return (
    <>
      <span>
        {t("timetoaccept")}{" "}
        <span className="p2 color-main">
          {minuteTime}:{secondTime}
        </span>
      </span>
      <div className={`res__status__actions`}>
        <Button
          size={"m"}
          clazz={"button--green justify-center"}
          onClick={() => handleStatus("confirm", id)}
        >
          {t("confirm")}
        </Button>

        <Button
          size={"m"}
          clazz={"button--secondary justify-center"}
          onClick={() => handleStatus("cancel", id)}
        >
          {t("cancel")}
        </Button>
      </div>
    </>
  );
};

const VideoStatus = (props) => {
  const { status } = props;
  let classVariant;
  switch (statusObj[status]) {
    case "approvestatus": {
      classVariant = " approve";
      break;
    }

    case "declinestatus":
    case "canceledbyclient" : {
      classVariant = " decline";
      break;
    }

    default: {
      classVariant = " work";
    }
  }

  return (
    <div className={`res__status${classVariant}`}>
      {status === 1 ? <VariantTimer {...props} /> : <Variant {...props} />}
    </div>
  );
};

export default VideoStatus;

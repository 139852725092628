import { Helmet } from "react-helmet";

import "./burger.scss";
import { Button } from "../index";
import useLadyService from "../../../services/LadyService";
import {useSelector} from "react-redux";
import {getBurgerMenu, setBurgerMenu} from "../../../stores/slices/headerSlice";

const Burger = () => {
 const { dispatch } = useLadyService();

 const burgerMenuState = useSelector(getBurgerMenu);

  return (
    <>
      {!!burgerMenuState ? (
        <Helmet>
          <html className={"lock"}></html>
        </Helmet>
      ) : null}

      <Button
        square={true}
        size={"s"}
        onClick={() => dispatch(setBurgerMenu(!burgerMenuState))}
        clazz={`icon-menu${!!burgerMenuState ? " _active" : ""}`}
      >
        <span></span>
      </Button>
    </>
  );
};

export default Burger;

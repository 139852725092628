import "./adCard.scss";

import {Icon, Button, Toggle, ButtonLink, ProgressBar} from "@/components/ui";
import {UpAd} from "./components";

import {
    getUserOrderStatus,
    getUserVideoStatus,
    setReadyToVideochat,
    setReadyToOrder,
} from "@/stores/slices/userSlice";

import {useSelector} from "react-redux";

import {makeRequest} from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";

import {useEffect, useState} from "react";
import {
    AddStoriesPopup,
    ConfirmDeletePopup,
    DeleteApPopup,
    LinkWithAgencyPopup,
    LinkWithGirlfriend,
} from "@/components/popups";
import {useDispatch} from "react-redux";

import {Link} from "react-router-dom";
import useLadyService from "@/services/LadyService";
import GirlCard from "../../../../../cards/girlCard/GirlCard";
import StoriesCircle from "../../../../../stories/circle/StoriesCircle";
import StoriesComponentEdit from "@/components/stories/StoriesComponentEdit";
import {setRequestVerStatus} from "@/stores/slices/popupSlice";
import {
    getIndiHealthStatus,
    getIndiVerStatus,
} from "@/stores/slices/lkIndiSlice";
import {setFastVerStatus} from "@/stores/slices/popupSlice";
import {getIndiFetchVer} from "@/stores/slices/lkIndiSlice";
import {getIndiBoosted, setVerStatus} from "../../../../../../stores/slices/lkIndiSlice";
import ToggleButton from "@/components/ui/toggleButton/ToggleButton";
import Verification from "../../../../../popups/verification/Verification";
import HealthyVerification from "../../../../../popups/healthyVerification/HealthyVerification";

const AdCard = ({
                    setAgencySelectCheckbox,
                    agencySelectCheckbox,
                    data,
                    view,
                    setData,
                    setCount,
                    dataInfo,
                    verRef,
                    setCurrentSlug,
                    setCurrentType,
                    setCurrentCallback,
                    healthy_boost,
                    healthyAt,
                }) => {
    const {
        slug,
        ready_videochat,
        tag_booking,
        ready_preview,
        is_individual,
        is_enabled: statusAd,
        agency,
        gf,
        completion,
        address,
        current_position,
        is_elite,
        elite_to,
        is_top,
        city_id,
        city,
        name,
        id,
        position_price,
        main_photo,
        promotion_to_at,
        stories: storiesRes,
        verified_at,
        healthy_at,
        verify,
        verify_media,
        healthy_media,
        healthy,
    } = data;

    const {
        lang,
        windowWidth,
        setMediaSize,
        t,
        getDayMonthYear,
        scrollToTop,
        navigate,
        userType,
    } = useLadyService();

    const isTypeAgency = userType === "agency";

    const [call, setCall] = useState(ready_videochat);
    const [indi, setIndi] = useState(is_individual);
    const [booking, setBooking] = useState(tag_booking);
    const [preview, setPreview] = useState(ready_preview);
    const [status, setStatus] = useState(statusAd);
    const [verifyStatus, setVerifyStatus] = useState(verify?.status || 0)
    const [verifyMedia, setVerifyMedia] = useState(verify_media)
    const [healthyMedia, setHealthyMedia] = useState(healthy_media)
    const [boostStatus, setBoostStatus] = useState(verify?.boost || 0)

    let verStatus = useSelector(getIndiVerStatus);
    let healthyStatus = useSelector(getIndiHealthStatus);
    if (isTypeAgency) {
        verStatus = verified_at > 0
        healthyStatus = healthy_media?.verified_at > 0
    }
    const [isVerActive, setIsVerActive] = useState(verStatus)
    const [isHealthActive, setIsHealthActive] = useState(healthyStatus)
    const getHealthStatus = (moderated) => {
        if (moderated === 0) {
            return "wait";
        }
        if (moderated === 1) {
            return "true";
        }
        if (moderated === 2) {
            return "rejected";
        }
        return "false"
    }
    const [agencyData, setAgencyData] = useState(agency);
    const [healthStatus, setHealthStatus] = useState(isTypeAgency && healthy_media.media?.data ? getHealthStatus(healthy_media.media?.data.moderated) : typeof dataInfo.healthy_photo === "object" &&
    Object.keys(dataInfo.healthy_photo).length > 0 ? getHealthStatus(dataInfo.healthy_photo?.data.moderated) : "false")

    const [elite, setElite] = useState(is_elite);
    const [healthyBoosted, setHealthyBoosted] = useState(healthy?.boost || 0);
    const [stories, setStories] = useState(storiesRes);

    const [isOpenAgency, setIsOpenAgency] = useState(false);
    const [isOpenGirlfriend, setIsOpenGirlfriend] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    const [isOpenRemoveGf, setIsOpenRemoveGf] = useState(false);
    const [isOpenRemoveAgency, setIsOpenRemoveAgency] = useState(false);

    const [gfData, setGfData] = useState(gf);


    const toggleButtonOptions = [
        {
            title: t('modeltests').split(' ')[0] + " " + t('searchagency').split(' ')[t('searchagency').split(' ').length - 1],
            value: false,
            id: "model"
        },
        {title: t('indi'), value: true, id: "indi"},
    ]

    const isActive = status;

    const isVideo = useSelector(getUserVideoStatus);
    const isRes = useSelector(getUserOrderStatus);

    const dispatch = useDispatch();

    let standart;
    let viewCard;

    switch (true) {
        case windowWidth > 1199: {
            standart = view === "standart" ? "" : " small";
            viewCard = view === "standart";

            break;
        }

        default: {
            viewCard = false;
            standart = " small";
        }
    }

    const handleAddAgency = () => {
        setIsOpenAgency(true);
    };

    const handleAddGirlfriend = () => {
        setIsOpenGirlfriend(true);
    };

    const handleOpenDelete = () => {
        setIsOpenDelete(true);
    };

    const handleOpenDeleteAgency = () => {
        setIsOpenRemoveAgency(true);
    };

    const handleOpenDeleteGf = () => {
        setIsOpenRemoveGf(true);
    };

    const handleRemoveAgency = async () => {
        try {
            const route = `user/profile/${slug}/remove-agency`;

            const method = "DELETE";

            await makeRequest({route, method});

            showToast({
                message: t("success"),
                variant: "success",
            });
            setAgencyData([]);
            setIsOpenRemoveAgency(false);
        } catch (error) {
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };

    const handleRemoveGirlfriend = async () => {
        try {
            const route = `user/profile/${slug}/remove-friend`;

            const method = "DELETE";

            const payload = {friend: gfData.slug};

            await makeRequest({route, method, payload});

            showToast({
                message: t("success"),
                variant: "success",
            });
            setGfData([]);
            setIsOpenRemoveGf(false);
        } catch (error) {
            console.log(error);
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };

    const onChangeCall = async () => {
        try {
            const route = `user/profile/${slug}/set-call-status`;
            const method = "PUT";
            const payload = {
                status: !call,
            };
            setCall((prev) => !prev);
            await makeRequest({route, method, payload});

            showToast({
                message: t("success"),
                variant: "success",
            });
        } catch (error) {
            setCall((prev) => !prev);
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };

    const changeIndiStatus = async (status) => {
        try {
            const route = `user/profile/${slug}/set-indi-status`;
            const method = "PUT";
            const payload = {
                status,
            };
            setIndi(status);
            await makeRequest({route, method, payload});

            showToast({
                message: t("success"),
                variant: "success",
            });
        } catch (error) {
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };

    const onChangeBook = async () => {
        try {
            const route = `user/profile/${slug}/set-booking-status`;
            const method = "PUT";
            const payload = {
                status: !booking,
            };
            setBooking((prev) => !prev);
            await makeRequest({route, method, payload});

            showToast({
                message: t("success"),
                variant: "success",
            });
        } catch (error) {
            setBooking((prev) => !prev);
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };
    const onChangePreview = async () => {
        try {
            const route = `user/profile/${slug}/set-preview-status`;
            const method = "PUT";
            const payload = {
                status: !booking,
            };
            setPreview((prev) => !prev);
            await makeRequest({route, method, payload});

            showToast({
                message: t("success"),
                variant: "success",
            });
        } catch (error) {
            setPreview((prev) => !prev);
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };

    const onChangeStatus = async () => {
        if (main_photo) {
            try {
                const route = `user/profile/${slug}/set-status`;
                const method = "PUT";
                const payload = {
                    status: !status,
                };
                setStatus((prev) => !prev);
                setIsOpenDelete(false);
                await makeRequest({route, method, payload});

                showToast({
                    message: `${t("ad")} ${name} ${
                        !status ? t("activated") : t("deactivated")
                    }`,
                    variant: "success",
                });
            } catch (error) {
                setStatus((prev) => !prev);
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
            }
        } else {
            showToast({
                message: t("foractivate"),
                variant: "error",
            });
        }
    };

    const onChangeVideochat = async () => {
        try {
            dispatch(setReadyToVideochat(Number(!isVideo)));

            const route = `user/set-call-status`;
            const method = "PUT";
            const payload = {
                status: Number(!isVideo),
            };

            await makeRequest({route, method, payload});

            showToast({
                message: t("success"),
                variant: "success",
            });
        } catch (error) {
            dispatch(setReadyToVideochat(Number(!isVideo)));
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };

    const onChangeOrder = async () => {
        try {
            dispatch(setReadyToOrder(Number(!isRes)));
            const route = `user/set-booking-status`;
            const method = "PUT";
            const payload = {
                status: Number(!isRes),
            };

            await makeRequest({route, method, payload});

            showToast({
                message: t("success"),
                variant: "success",
            });
        } catch (error) {
            dispatch(setReadyToVideochat(Number(!isRes)));
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };

    const handleDuplicate = async (e) => {
        try {
            const route = `user/profile/${slug}/clone`;
            const method = "POST";

            const {status, profile} = await makeRequest({route, method});
            if (status) {
                navigate(`/${lang}/lk/indi/profile/${profile.slug}/edit`);
                scrollToTop();
                showToast({
                    message: t("success"),
                    variant: "success",
                });
            }
        } catch (error) {
            showToast({
                message: t("oops"),
                variant: "error",
            });
        }
    };

    const [isOpenAddStory, setIsOpenAddStory] = useState(false);
    const [isOpenStory, setIsOpenStory] = useState(false);
    const [isOpenVerifyForAgency, setIsOpenVerifyForAgency] = useState(false);
    const [isOpenHealthyForAgency, setIsOpenHealthyForAgency] = useState(false);

    const handleOpenAddStory = () => {
        setIsOpenAddStory(true);
    };

    const handleOpenStory = () => {
        setIsOpenStory(true);
    };
    const activeRequestVer = useSelector(getIndiFetchVer);
    const activeBoosted = useSelector(getIndiBoosted);

    const VerificationBlock = () => {

        const errorStatus = verifyStatus === 3
        const verifyRequest = () => {
            setCurrentSlug(slug);

            if (isVerActive || isTypeAgency) {
                if (verifyStatus === 1 || activeRequestVer.includes(slug)) {
                    dispatch(setFastVerStatus(true));
                    setCurrentType("verification");
                    setCurrentSlug(slug);
                }
                if (
                    (!verifyStatus || errorStatus) &&
                    !activeRequestVer.includes(slug)
                ) {
                    if (isTypeAgency) {
                        setIsOpenVerifyForAgency(true)
                    } else {
                        dispatch(setRequestVerStatus(true));
                    }
                }
            } else if (errorStatus) {
                dispatch(setRequestVerStatus(true));
            } else {
                if (isTypeAgency) {
                    setIsOpenVerifyForAgency(true)
                } else {
                    verRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }
        }
        return (
            <div
                className={`mt-auto d-flex gap-8 align-center ${
                    !isActive ? "disabled-global" : ""
                } ${errorStatus ? "text-nowrap" : ""}`}
            >
                <Icon
                    spritePath={"verify-fill"}
                    size={"l"}
                    clazz={`${
                        verifyStatus === 2
                            ? "_green"
                            : verifyStatus === 1 ||
                            activeRequestVer.includes(slug) ||
                            activeBoosted.includes(slug)
                                ? ""
                                : "_red"
                    }`}
                />
                <div className="d-flex fd-column">
                    {isTypeAgency && verifyStatus ?
                        (<Link onClick={() => {setIsOpenVerifyForAgency(true)}}><span className="p2 color-700">{t("verification")}</span></Link>) :
                        (<span className="p2 color-700">{t("verification")}</span>)}

                    {!verifyStatus && !activeRequestVer.includes(slug) && (
                        <span className="color-red-700">{t("notact")}</span>
                    )}

                    {((verifyStatus === 1 && boostStatus === 0) ||
                            activeRequestVer.includes(slug)) &&
                        !activeBoosted.includes(slug) && (
                            <span>{t("moderated24hour") + ` ~ 24 ` + t("hourfew")}</span>
                        )}

                    {((verifyStatus === 1 && boostStatus === 1) ||
                        activeBoosted.includes(slug)) && (
                        <span>{t("fastedmoder") + ` ~ 1 ` + t("hour")}</span>
                    )}

                    {verifyStatus === 2 && (
                        <span>{t("activefrom") + ": " + getDayMonthYear(verified_at)}</span>
                    )}

                    {errorStatus && !activeRequestVer.includes(slug) && (
                        <>
                            <span className="color-red-700">{t("declinedbymoderator")}</span>
                            <span>{verify?.reason}</span>
                        </>
                    )}
                </div>

                {(verifyStatus !== 2 && boostStatus !== 1 && !activeBoosted.includes(slug)) || errorStatus ? (
                    <Button
                        disabled={!isActive}
                        onClick={() => {
                            verifyRequest()
                        }}
                        size={"s"}
                        clazz={`button_outline--green-accent ml-auto ${
                            !isActive ? "_disabled" : ""
                        }`}
                    >
                        {isVerActive &&
                            (!verifyStatus || errorStatus) &&
                            !activeRequestVer.includes(slug) &&
                            t("request ")}

                        {isVerActive &&
                            (verifyStatus === 1 || activeRequestVer.includes(slug)) &&
                            t("fasttrack")}

                        {!isVerActive && !verifyStatus && t("idphotoupload")}
                        {!isVerActive && verifyStatus === 3 && t("idphotoupload")}
                        {!isVerActive && verifyStatus === 1 && t("fastmoderated")}
                    </Button>
                ) : null}
            </div>
        );
    };

    const HealthButton = () => {
        const healthyRequest = () => {
            if (!isHealthActive) {
                if (isTypeAgency) {
                    setIsOpenHealthyForAgency(true)
                } else {
                    verRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }
            if (healthStatus !== "rejected") {
                if (isHealthActive) {
                    dispatch(setFastVerStatus(true));
                    setCurrentType("healthy");
                    setCurrentSlug(slug);
                    setCurrentCallback(() => {
                        setHealthyBoosted(true)
                    })
                }
                if (dataInfo.healthy_photo) {
                    if (healthStatus === "wait" && !!verified_at) {
                        dispatch(setFastVerStatus(true));
                        setCurrentType("healthy");
                        setCurrentSlug(slug);
                        setCurrentCallback(() => {
                            setHealthyBoosted(true)
                        })
                    }
                }
            } else {
                if (isTypeAgency) {
                    setIsOpenHealthyForAgency(true)
                } else {
                    verRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }
        }

        return (
            <div
                className={`d-flex gap-8 align-center ${
                    !isActive ? "disabled-global" : ""
                } `}
            >
                <Icon
                    spritePath={"health-fill"}
                    size={"l"}
                    clazz={`${
                        healthStatus === "true" && verified_at
                            ? "_green"
                            : (healthStatus === "wait" || isHealthActive) &&
                            healthStatus !== "rejected"
                                ? ""
                                : "_red"
                    }`}
                />
                <div className="d-flex fd-column text-nowrap">
                    {isTypeAgency && verifyStatus ?
                        (<Link onClick={() => {setIsOpenHealthyForAgency(true)}}><span className="p2 color-700">{t("healthy")}</span></Link>) :
                        (<span className="p2 color-700">{t("healthy")}</span>)}
                    {((healthStatus === "false" || !verified_at) && !isHealthActive) && (
                        <span className="color-red-700">{t("notact")}</span>
                    )}
                    {(healthStatus === "wait" || isHealthActive) &&
                        !healthyBoosted &&
                        healthStatus !== "true" &&
                        healthStatus !== "rejected" && (
                            <span className="">{t("moderated24hour") + ` ~ 24 ` + t("hourfew")}</span>
                        )}
                    {healthStatus === "wait" && healthyBoosted ? (
                        <span className="">{t("fastedmoder") + ` ~ 1 ` + t("hour")}</span>
                    ) : null}

                    {healthStatus === "rejected" && (
                        <>
                            <span className="color-red-700">{t("declinedbymoderator")}</span>
                            {/* <span>{dataInfo.healthy_reason}</span> */}
                        </>
                    )}
                    {(healthStatus === "true" && verified_at) ? (
                        <span>{t("activefrom") + ": " + getDayMonthYear(healthyAt)}</span>
                    ) : null}
                    {healthStatus === "true" && !verified_at && (
                        <span>{t("notvalidfor")}</span>
                    )}

                </div>
                {healthStatus === "rejected" || (healthStatus !== "true" && !healthyBoosted) ?  (
                    <Button
                        onClick={() => {
                            healthyRequest()
                        }}
                        size={"s"}
                        clazz={`button_outline--green-accent ml-auto ${
                            !isActive ? "_disabled" : ""
                        }`}
                    >
                        {!isHealthActive && !isVerActive && t("confrimid")}
                        {!isHealthActive && isVerActive && t("requestanalysis")}
                        {isHealthActive && (!healthyBoosted || healthyBoosted) && healthStatus !== "rejected" &&
                            t("fasttrack")}
                        {healthStatus === "rejected" && t("requestanalysis")}
                    </Button>
                ) : null }
            </div>
        );
    };

    const [profileInfo, setProfileInfo] = useState("");

    return (
        <>
            <div className={`adcard-main${standart}`}>
                <GirlCard
                    agencySelectCheckbox={agencySelectCheckbox}
                    setAgencySelectCheckbox={setAgencySelectCheckbox}
                    targetBlank
                    props={data}
                    big={!!viewCard}
                    status={isActive}
                    edit
                />

                <div className={`adcard-main__root${standart}`}>
                    <div className="adcard-main__header">
                        <div className={"panel"}>
                            <ProgressBar percentage={completion}/>
                            <ButtonLink
                                target={"_blank"}
                                href={`/${lang}/profile/${slug}`}
                                clazz={"button--secondary"}
                                square={true}
                                size={"xs"}
                                title={t("seead")}
                            >
                                <Icon size={"xs"} spritePath={"eye"}/>
                            </ButtonLink>
                            <ButtonLink
                                target={"_blank"}
                                href={`/${lang}/lk/create/${slug}`}
                                clazz={"button--secondary"}
                                square={true}
                                size={"xs"}
                                title={t("edit") + " " + t("bysteps")}
                            >
                                <Icon size={"xs"} spritePath={"edit-2"}/>
                            </ButtonLink>
                            <Button
                                clazz={"button--secondary"}
                                square={true}
                                size={"xs"}
                                title={t("duplicate")}
                                onClick={handleDuplicate}
                            >
                                <Icon size={"xs"} spritePath={"copy"}/>
                            </Button>
                        </div>

                        <div
                            className={`toggle-switch defaulttitle words`}
                        >
              <span className={`p3 color-main`}>
                {t("activate")}
              </span>
                            <Toggle
                                onChange={onChangeStatus}
                                checked={status}
                            />
                        </div>
                    </div>

                    <div className={`adcard-main__toogle`}>
                        <div className="">
                            <Toggle
                                clazz={isTypeAgency ? "fd-column" : ""}
                                titleDefault={t("videochat")}
                                onChange={onChangeCall}
                                checked={call}
                                disabled={!isActive || !isVideo}
                            />

                            {!isVideo && (
                                <div className="d-flex fd-column gap-4 mt-8">
                                    <span className={"color-700 fz-13"}>{t("globalblock")}</span>
                                    <Button
                                        onClick={onChangeVideochat}
                                        size={"s"}
                                        clazz={`button_outline--black`}
                                    >
                                        {t("on")}
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="vertical-line"></div>

                        <div className="">
                            <Toggle
                                clazz={isTypeAgency ? "fd-column" : ""}
                                titleDefault={t("onlineorder")}
                                onChange={onChangeBook}
                                checked={booking}
                                disabled={!isActive || !isRes}
                            />

                            {!isRes && (
                                <div className="d-flex fd-column gap-4 mt-8">
                                    <span className={"color-700 fz-13"}>{t("globalblock")}</span>
                                    <Button
                                        onClick={onChangeOrder}
                                        clazz={`button_outline--black`}
                                        size={"s"}
                                    >
                                        {t("on")}
                                    </Button>
                                </div>
                            )}
                        </div>
                        {isTypeAgency && (
                            <>
                                <div className="vertical-line"></div>

                                <div className="">

                                    <Toggle
                                        clazz={"fd-column"}
                                        titleDefault={t("demos")}
                                        onChange={onChangePreview}
                                        checked={preview}
                                        disabled={!isActive || !isRes}
                                    />

                                    {!isRes && (
                                        <div className="d-flex fd-column gap-4 mt-8">
                                          <span className={"color-700 fz-13"}>
                                            {t("globalblock")}
                                          </span>
                                            <Button
                                                onClick={onChangePreview}
                                                clazz={`button_outline--black`}
                                                size={"s"}
                                            >
                                                {t("on")}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <UpAd
                        status={isActive}
                        address={address}
                        current_position={current_position}
                        is_elite={elite}
                        elite_to={elite_to}
                        is_top={is_top}
                        city={city}
                        slug={slug}
                        name={name}
                        city_id={city_id}
                        setElite={setElite}
                        id={id}
                        main_photo={main_photo}
                        position_price={position_price}
                        promotion_to_at={promotion_to_at}
                        setData={setData}
                    />
                    <div className="d-flex gap-12">
                        <StoriesCircle
                            onClick={handleOpenAddStory}
                            clazz={
                                !isActive
                                    ? "model-header__stories disabled"
                                    : "model-header__stories"
                            }
                            img={null}
                            edit
                            data={stories}
                        />
                        {!!stories?.length ? (
                            <StoriesCircle
                                onClick={handleOpenStory}
                                img={setMediaSize(stories[0]?.media, "xs")}
                                clazz={"model-header__stories"}
                            />
                        ) : null}
                    </div>

                    <VerificationBlock/>

                    <HealthButton/>
                    {isTypeAgency && (
                        <ToggleButton options={toggleButtonOptions} handleChange={changeIndiStatus} defaultValue={indi}/>
                    )}

                    <div className={`adcard-main__link ${!status ? "not_active" : ""} `}>
            <span className="d-flex fd-column align-start color-700">
              {!!agencyData.length ? t("managedbyagency") : t("connectagency")}{" "}
                {!!agencyData.length && (
                    <Link
                        to={`/${lang}/agency/${agencyData[0].id}`}
                        className="green text-right"
                    >
                        {agencyData[0].name}
                    </Link>
                )}
            </span>
                        <Button
                            size={"s"}
                            clazz={"button_outline--green-accent"}
                            disabled={!status}
                            onClick={
                                !!agencyData.length ? handleOpenDeleteAgency : handleAddAgency
                            }
                        >
                            {!!agencyData.length ? t("disconnect") : t("addad")}
                        </Button>
                    </div>

                    <>
                        <hr/>
                        <div
                            className={`adcard-main__link ${!isActive ? "not_active" : ""}`}
                        >
              <span className="color-700">
                {!!gfData || profileInfo ? t("connectedgf") : t("connectgf")}{" "}
                  {!!gfData && (
                      <Link
                          target={"_blank"}
                          to={`/profile/${gfData.slug}`}
                          className="green text-right"
                      >
                          {gfData.name}
                      </Link>
                  )}
                  {!!profileInfo && (
                      <Link
                          target={"_blank"}
                          to={`/profile/${profileInfo.slug}`}
                          className="green text-right"
                      >
                          {profileInfo.name}
                      </Link>
                  )}
                  {gfData && (
                      <>
                          <br></br>
                          <span className="color-500">
                      {gfData.status === 1
                          ? t("publishedono")
                          : t("moderated24hour")}
                    </span>
                      </>
                  )}
                  {profileInfo && (
                      <>
                          <br></br>
                          <span className="color-500">
                      {profileInfo.status === 1
                          ? t("publishedono")
                          : t("moderated24hour")}
                    </span>
                      </>
                  )}
              </span>
                            <Button
                                size={"s"}
                                clazz={"button_outline--green-accent"}
                                disabled={!isActive}
                                onClick={
                                    !!gfData || profileInfo
                                        ? handleOpenDeleteGf
                                        : handleAddGirlfriend
                                }
                            >
                                {!!gfData || profileInfo ? t("disconnect") : t("addad")}
                            </Button>
                        </div>
                    </>
                    <Button
                        type="button"
                        clazz={"button--error width-fit"}
                        size={"s"}
                        onClick={handleOpenDelete}
                    >
                        {t("deletead")}
                    </Button>
                </div>
            </div>

            {isOpenAddStory && (
                <AddStoriesPopup
                    open={isOpenAddStory}
                    setOpen={setIsOpenAddStory}
                    slug={slug}
                    setStories={setStories}
                    stories={stories}
                />
            )}

            {isOpenAgency && (
                <LinkWithAgencyPopup
                    open={isOpenAgency}
                    setOpen={setIsOpenAgency}
                    slug={slug}
                />
            )}

            {isOpenGirlfriend && (
                <LinkWithGirlfriend
                    open={isOpenGirlfriend}
                    setOpen={setIsOpenGirlfriend}
                    slug={slug}
                    city_id={city_id}
                    setProfileInfo={setProfileInfo}
                />
            )}

            {isOpenDelete && (
                <DeleteApPopup
                    open={isOpenDelete}
                    setOpen={setIsOpenDelete}
                    profileSlug={slug}
                    setData={setData}
                    setCount={setCount}
                    status={status}
                    onChangeStatus={onChangeStatus}
                />
            )}

            {isOpenRemoveGf && (
                <ConfirmDeletePopup
                    onSubmit={handleRemoveGirlfriend}
                    open={isOpenRemoveGf}
                    setOpen={setIsOpenRemoveGf}
                />
            )}

            {isOpenRemoveAgency && (
                <ConfirmDeletePopup
                    onSubmit={handleRemoveAgency}
                    open={isOpenRemoveAgency}
                    setOpen={setIsOpenRemoveAgency}
                />
            )}

            {isOpenStory && (
                <StoriesComponentEdit
                    open={isOpenStory}
                    setOpen={setIsOpenStory}
                    setStories={setStories}
                    status={isActive}
                    data={stories}
                />
            )}
            {isOpenVerifyForAgency && (
                <Verification
                    open={isOpenVerifyForAgency}
                    setOpen={setIsOpenVerifyForAgency}
                    setVerifyStatus={setVerifyStatus}
                    verifyStatus={verifyStatus}
                    verifyMedia={verifyMedia}
                    setVerifyMedia={setVerifyMedia}
                    isBoosted={boostStatus}
                    setIsBoosted={setBoostStatus}
                    setCurrentType={setCurrentType}
                    setCurrentSlug={setCurrentSlug}
                    setCurrentCallback={setCurrentCallback}
                    verified_at={verified_at}
                    verify={verify}
                    slug={slug}
                />)}
            {isOpenHealthyForAgency && (
                <HealthyVerification
                    open={isOpenHealthyForAgency}
                    setOpen={setIsOpenHealthyForAgency}
                    setCurrentType={setCurrentType}
                    setCurrentSlug={setCurrentSlug}
                    setCurrentCallback={setCurrentCallback}
                    healthy_at={healthy_at}
                    verified_at={verified_at}
                    healthy={healthy}
                    verify={verify}
                    verifyMedia={verifyMedia}
                    setVerifyMedia={setVerifyMedia}
                    healthyMedia={healthyMedia}
                    setHealthyMedia={setHealthyMedia}
                    verifyStatus={isHealthActive}
                    setSuperVerify={setIsVerActive}
                    setHealthyStatus={setHealthStatus}
                    healthyBoosted={healthyBoosted}
                    setHealthyBoosted={setHealthyBoosted}
                    slug={slug}
                />)}
        </>
    );
};

export default AdCard;

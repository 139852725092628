import { Icon } from "../index";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  filterLanguage,
  filterPlace,
  filterStatus,
  toggleSelected,
  toggleState,
  filterNation,
  filterLastVisit,
  filterRadius,
  filterMinTariffs,
  filterGender,
  filterServices,
  filterBreast,
  filterFor,
  setRadiusValue,
  filterAddress,
} from "@/stores/slices/filterSlice";
import {
  getSexCount,
  setRadiusCount,
  toggleStateCount,
} from "@/stores/slices/counterFilterSlice";
import { getNotDisabledCheckbox } from "@/stores/slices/modelsState";
import { getAllSorted, setSorted } from "@/stores/slices/modelsState";
import useLadyService from "@/services/LadyService";

import "./checkbox.scss";
import { setTitle } from "@/stores/slices/titleSlice";
import {
  filterBreastSize,
  filterBreastType,
  filterPenisSize,
  resetState,
} from "@/stores/slices/filterSlice";
import { resetStateCount } from "@/stores/slices/counterFilterSlice";
import { filterSex } from "../../../stores/slices/filterSlice";

const CheckboxFilter = ({ ...props }) => {
  const {
    id,
    name,
    title,
    checked,
    disabled = true,
    value,
    buttonCheckbox = false,
    clazz,
    flag,
    count,
    radio = false,
    forDisabled = "",
    important
  } = props;

  const { city, dispatch } = useLadyService();

  let checkRef = useRef(null);
  const { keysWithValueTrue, services, languages, nationalities } = useSelector(
    getNotDisabledCheckbox
  );
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    if (keysWithValueTrue && !!keysWithValueTrue.length) {
      if (name !== "services" && name !== "language" && name !== "nation") {
        if (name === "place") {
          const checkbox = keysWithValueTrue.find(
            (item) => item === id || item === forDisabled
          );
          if (checkbox) {
            setIsDisabled(false);
          }
        } else if (name === "gender") {
          const checkbox = keysWithValueTrue.find(
            (item) => item === id || item === forDisabled
          );
          if (checkbox) {
            setIsDisabled(false);
          }
        } else {
          const checkbox = keysWithValueTrue.find((item) => item === id);
          if (checkbox) {
            setIsDisabled(false);
          }
        }
      } else if (name === "services") {
        const checkbox = services?.find((item) => String(item) === value);
        if (checkbox) {
          setIsDisabled(false);
        }
      } else if (name === "language") {
        const checkbox = languages?.find(
          (item) => item.toUpperCase() === value
        );
        if (checkbox) {
          setIsDisabled(false);
        }
      } else if (name === "nation") {
        const checkbox = nationalities?.find(
          (item) => item.toUpperCase() === value
        );
        if (checkbox) {
          setIsDisabled(false);
        }
      }
    } else {
      setIsDisabled(true);
    }
    if (name === "last_visit") {
      setIsDisabled(false);
    }
    if (name === "min_tariffs") {
      setIsDisabled(false);
    }
  }, [keysWithValueTrue, city]);

  const filterPlaceSlice = useSelector(filterPlace);
  const filterBreastSlice = useSelector(filterBreast);
  // const filterBreastSizeSlice = useSelector(filterBreastSize);
  // const filterBreastTypeSlice = useSelector(filterBreastType);
  // const filterPenisSizeSlice = useSelector(filterPenisSize);
  const sortedValue = useSelector(getAllSorted);
  const filterStatusSlice = useSelector(filterStatus);
  const filterGenderSlice = useSelector(filterGender);
  const filterSexSlice = useSelector(filterSex);
  const filterServicesSlice = useSelector(filterServices);
  const filterForSlice = useSelector(filterFor);
  const filterLanguageSlice = useSelector(filterLanguage);
  const filterNationSlice = useSelector(filterNation);
  const filterLastVisitSlice = useSelector(filterLastVisit);
  const filterRadiusSlice = useSelector(filterRadius);
  const filterAddressSlice = useSelector(filterAddress);
  const filterMinTariffsSlice = useSelector(filterMinTariffs);

  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    if (name === "radius") {
      setIsDisabled(filterAddressSlice.length > 0 ? false : true);
    }
  }, [filterAddressSlice]);
  
  useEffect(() => {
    if (name === "place") {
      if (value === "incall") {
        const idExistsInSelectedCheckboxes =
        filterStatusSlice.includes("incall");
        setIsChecked(idExistsInSelectedCheckboxes);
      }
      if (value === "outcall") {
        const idExistsInSelectedCheckboxes =
          filterPlaceSlice.includes("outcall");
        setIsChecked(idExistsInSelectedCheckboxes);
      }
      if (value === "salon") {
        const idExistsInSelectedCheckboxes = filterPlaceSlice.includes("salon");
        setIsChecked(idExistsInSelectedCheckboxes);
      }
      if (value === "escort") {
        const idExistsInSelectedCheckboxes = filterPlaceSlice.includes("escort");
        setIsChecked(idExistsInSelectedCheckboxes);
      }
    }
    if (
      name === "breast_size" ||
      name === "breast_type" ||
      name === "penis_size" ||
      name === "hair_color" ||
      name === "hair_size" ||
      name === "hair_intim" ||
      name === "eye_color" ||
      name === "eye_type" ||
      name === "special_lips" ||
      name === "special_tattoo" ||
      name === "special_piercing" ||
      name === "special_smoke"
    ) {
      const idExistsInSelectedCheckboxes = filterBreastSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "language") {
      const idExistsInSelectedCheckboxes = filterLanguageSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "status" || name === "tag") {
      const idExistsInSelectedCheckboxes = filterStatusSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "ethnos" ||name === "nation") {
      const idExistsInSelectedCheckboxes = filterNationSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "last_visit") {
      const idExistsInSelectedCheckboxes = filterLastVisitSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "radius") {
      const idExistsInSelectedCheckboxes =
        filterRadiusSlice.value.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "min_tariffs") {
      const idExistsInSelectedCheckboxes =
        filterMinTariffsSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "gender") {
      const idExistsInSelectedCheckboxes = filterGenderSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "sex") {
      const idExistsInSelectedCheckboxes = filterSexSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "services") {
      const idExistsInSelectedCheckboxes = filterServicesSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
    if (name === "for") {
      const idExistsInSelectedCheckboxes = filterForSlice.includes(value);
      setIsChecked(idExistsInSelectedCheckboxes);
    }
  }, [
    sortedValue,
    filterForSlice,
    filterPlaceSlice,
    filterStatusSlice,
    filterLanguageSlice,
    filterNationSlice,
    filterLastVisitSlice,
    filterRadiusSlice,
    filterMinTariffsSlice,
    filterGenderSlice,
    filterServicesSlice,
    // filterBreastTypeSlice,
    // filterBreastSizeSlice,
    // filterPenisSizeSlice,
    filterBreastSlice
  ]);

  function handleClick() {
    // checkRef.current.click();
    setIsChecked(!checked);
  }

  const handleChange = () => {
    // setIsChecked(!checked);
  };

  const handleRedux = (value, name, count) => {

    if (count && name !== "radius") {
      if(value === 'incall') {
        dispatch(toggleStateCount({ StateName: 'status', count }));
      } else {
        dispatch(toggleStateCount({ StateName: name, count }));
      }
    }
    if (name === "last_visit") {
      dispatch(resetState(name));
      dispatch(resetStateCount(name));
      dispatch(toggleStateCount({ StateName: name, count }));
    }
    if (name === "min_tariffs") {
      dispatch(resetState(name));
      dispatch(resetStateCount(name));
      dispatch(toggleStateCount({ StateName: name, count }));
    }
    if (name === "tag" || value === "incall") {
      dispatch(
        setSorted({
          stateName: "sorted",
          payload: value,
        })
      );
    }

    if (name === "radius") {
      dispatch(setRadiusValue(value));
      dispatch(setRadiusCount(value));
      dispatch(toggleSelected({ value, name: "radius" }));
    } else {
      if (name === "sex") {
        dispatch(toggleSelected({ value, name: "gender" }));
      } else if (
        name === "breast_type" ||
        name === "hair_size" ||
        name === "hair_intim" ||
        name === "eye_type" ||
        name === "special_lips" ||
        name === "special_tattoo" ||
        name === "special_piercing" ||
        name === "special_smoke"
      ) {
        dispatch(toggleSelected({ value, name: "breast" }));
      } else if (name === "tag" || name === "last_visit") {
        dispatch(toggleSelected({ value, name: "status" }));
      } else {
        dispatch(toggleSelected({ value, name }));
      }
      if (name === "min_tariffs") {
        dispatch(toggleState({ value, StateName: "services" }));
        dispatch(toggleSelected({ value, name: "services" }));
      }

      if (
        name === "hair_size" ||
        name === "breast_type" ||
        name === "hair_intim" ||
        name === "eye_type" ||
        name === "special_lips" ||
        name === "special_tattoo" ||
        name === "special_piercing" ||
        name === "special_smoke"
      ) {
        dispatch(toggleState({ value, StateName: "breast" }));
      } else if (name === "tag") {
        dispatch(toggleState({ value, StateName: "status" }));
        dispatch(toggleStateCount({ StateName: "status", count }));
        dispatch(setTitle(value));
      } else {
          if(value === "incall") {
            dispatch(toggleState({ value, StateName: "status" }));
          } else {
            dispatch(toggleState({ value, StateName: name }));
          }
      }
    }

    setIsChecked((prev) => !prev);
  };

  const handleReduxCheckbox = (value, name, count) => {
    
    if (count) {
      if(value === "incall") {
        dispatch(toggleStateCount({ StateName: "status", count }));
      } else {
        dispatch(toggleStateCount({ StateName: name, count }));
      }
    }
    if (name === "gender") {
      if (value === "female") {
        dispatch(toggleState({ value, StateName: name }));
        dispatch(toggleSelected({ value, name: "gender" }));
      } else {
        dispatch(toggleSelected({ value, name: "status" }));
        dispatch(toggleSelected({ value, name: "gender" }));
        dispatch(toggleState({ value, StateName: name }));

        let transformValue = "";
        switch (value) {
          case "trans":
            transformValue = "trance";
            break;
          case "male":
            transformValue = "guys";
            break;
          default:
            transformValue = value;
        }
        dispatch(
          setSorted({
            stateName: "sorted",
            payload: transformValue,
          })
        );
      }
    }
    if (name === "status" || value === "incall") {
      dispatch(
        setSorted({
          stateName: "sorted",
          payload: value,
        })
      );
    }

    if (name === "services") {
      dispatch(toggleState({ value, StateName: "services" }));
      dispatch(toggleSelected({ value, name: "services" }));
    } else if (name === "for") {
      dispatch(toggleState({ value, StateName: "for" }));
      dispatch(toggleSelected({ value, name: "services" }));
    } else if (
      name === "breast_size" ||
      name === "penis_size" ||
      name === "breast_type" ||
      name === "hair_color" ||
      name === "eye_color"
    ) {
      dispatch(toggleState({ value, StateName: "breast" }));
      dispatch(toggleSelected({ value, name: "breast" }));
    } else if(name === "ethnos") {
      dispatch(toggleState({ value, StateName: "nation" }));
      dispatch(toggleSelected({ value, name: "nation" }));
    } else {
      if (name !== "gender") {
        if(value === "incall") {
          dispatch(toggleState({ value, StateName: 'status' }));
        } else {
          console.log({ value, StateName: name })
          dispatch(toggleState({ value, StateName: name }));
          dispatch(toggleSelected({ value, name }));
        }
      }
    }

    setIsChecked((prev) => !prev);
  };

  if (buttonCheckbox === true) {
    return (
      <label
        className={`checkbox-button ${
          isDisabled ? " checkbox-button_disabled" : ""
        }${isChecked ? " checkbox-button_checked" : ""}${
          important ? ` _important` : ''}${
          clazz ? ` ${clazz}` : ""
        }`}
        htmlFor={id}
        tabIndex={1}
        onKeyUp={(e) => {
          if (e.key === "Enter") setIsChecked((prev) => !prev);
        }}
      >
        <input
          onClick={() => (isDisabled ? null : handleRedux(value, name, count))}
          tabIndex={0}
          id={id}
          type={radio === true ? "radio" : "checkbox"}
          onChange={handleChange}
          disabled={isDisabled ? true : null}
          name={name ? name : ""}
          checked={isChecked ? true : null}
          value={value}
          data-count={count ? count : ""}
        />
        {title}
      </label>
    );
  }

  return (
    <div
      className={`checkbox${isChecked ? " checkbox_checked" : ""}${
        isDisabled ? " checkbox_disabled" : ""
      }${
        important ? ` _important` : ''}${
        clazz ? ` ${clazz}` : ""}`}
      tabIndex={isDisabled ? null : 1}
      // onClick={() => handleClick()}
      onKeyUp={(e) => {
        if (e.key === "Enter") setIsChecked((prev) => !prev);
      }}
    >
      <input
        tabIndex={0}
        disabled={isDisabled ? true : null}
        id={id}
        type="checkbox"
        onChange={handleChange}
        name={name ? name : ""}
        checked={isChecked ? true : null}
        value={value ? value : ""}
        data-count={count ? count : ""}
      />
      <label
        htmlFor={id}
        // onClick={() => (isDisabled ? null : handleReduxCheckbox(value, name))}
        onClick={
          isDisabled
            ? null
            : () => {
                handleReduxCheckbox(value, name, count);
                handleClick();
              }
        }
        ref={checkRef}
      >
        {flag ? (
          <Icon type={"flag"} clazz={"checkbox-flag"} spritePath={`${flag}`} />
        ) : null}
        {title}
      </label>
    </div>
  );
};

// CheckboxFilter.propTypes = {
//   id: PropTypes.any,
//   name: PropTypes.string,
//   value: PropTypes.string,
//   title: PropTypes.string,
//   disabled: PropTypes.bool,
//   checked: PropTypes.bool,
// };

export default CheckboxFilter;

import React from "react";
import {Icon} from "@/components/ui";
import useLadyService from "@/services/LadyService";

import "./showMoreContent.scss";

const ShowMoreContent = ({onClick}) => {
    const { t } = useLadyService();

    return (
        <button
            onClick={onClick}
            className={"showmore-content"}
            type={'button'}
        >
            <Icon size={"m"} spritePath={"eye-off"}/>
            <span>{t("show")}</span>
        </button>
    )
}

export default ShowMoreContent
export const mergeTranslations = (original, updates) => {
  const merged = { ...original };

  updates.forEach((update) => {
    Object.keys(update).forEach((lang) => {
      if (merged[lang]) {
        merged[lang].description = update[lang];
      }
    });
  });

  return merged;
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mediaToChange: "",
  verStatus: false,
  superVerStatus: false,
  healthStatus: false,
  profiles: [],
  fetchVer: [],
  boosted: []
};

export const lkIndiSlice = createSlice({
  name: "lkindi",
  initialState,
  reducers: {
    setIndiProfiles: (state, action) => {
      state.profiles = action.payload
    },
    setMediaToChange: (state, action) => {
      state.mediaToChange = action.payload
    },
    setFetchVer: (state, action) => {
      state.fetchVer = [...state.fetchVer, action.payload]
    },
    setBoosted: (state, action) => {
      state.boosted = [...state.boosted, action.payload]
    },
    setVerStatus: (state, action) => {
      state.verStatus = action.payload;
    },
    setSuperVerStatus: (state, action) => {
      state.superVerStatus = action.payload;
    },
    setHealthStatus: (state, action) => {
      state.healthStatus = action.payload;
    },
  },
});

export const {setMediaToChange, setBoosted, setFetchVer, setVerStatus, setHealthStatus, setIndiProfiles, setSuperVerStatus } = lkIndiSlice.actions;

export const getIndiProfiles = (state) => state.lkindi.profiles;

export const getIndiMediaToChange = (state) => state.lkindi.mediaToChange;

export const getIndiFetchVer = (state) => state.lkindi.fetchVer;

export const getIndiBoosted = (state) => state.lkindi.boosted;

export const getIndiVerStatus = (state) => state.lkindi.verStatus;

export const getIndiSuperVerStatus = (state) => state.lkindi.superVerStatus;

export const getIndiHealthStatus = (state) => state.lkindi.healthStatus;

export default lkIndiSlice.reducer;

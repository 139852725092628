import useLadyService from "@/services/LadyService";
import useCheckAccess from "../../../hooks/useCheckAccess";
import {useEffect, useState} from "react";
import {DateInput, Dropdown, Icon, Loader} from "../../ui";
import {addSpacesEveryThreeDigits} from "../../../helper/addSpacesEveryThreeDigits";
import SearchBlock from "../balancePage/components/SearchBlock";
import {useSelector} from "react-redux";
import {getUserHold} from "../../../stores/slices/userSlice";
import {useLocation} from "react-router-dom";
import {makeRequest} from "../../../services/makeRequest";


import './statistic-page.scss'
import StatisticTable from "./components/StatisticTable/StatisticTable";
import showToast from "../../toast/Toast";

const StatisticPage = () => {
  const {t, token} = useLadyService();
  
  const {hasAccess, AccessTitle} = useCheckAccess('statsaccess')

  const [activeTabId, setActiveTabId] = useState(1);
  const tabs = [
    {id: 1, name: t("viewtable")},
    // {id: 2, name: t("viewchart")},
  ];
  const handleTabClick = (tabId) => {
    setActiveTabId(tabId);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [selectName, setSelectName] = useState({
    name: "",
    slug: "",
  });

  function formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = String(date.getFullYear());

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (year < 10) {
      year = "0" + year;
    }

    return `${day}.${month}.${year}`;
  }

  const currentDate = new Date();

  const formattedCurrentDate = formatDate(currentDate);
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(currentDate.getMonth() - 1);
  const formattedPreviousMonthDate = formatDate(previousMonthDate);
  const [optionsBalanceIndex, setOptionsBalanceIndex] = useState([]);

  const [statistic, setStatistic] = useState( false);
  const [selectData, setSelectData] = useState([]);
  const [selectSearch, setSelectSearch] = useState("");
  const [date, setDate] = useState(
    formattedPreviousMonthDate + " - " + formattedCurrentDate
  );


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/statistic`;
        const method = "GET";

        const resultType = optionsBalanceIndex.join(",");
        const dateResult = date.split(" - ");
        const firstDate = dateResult[0];
        const secondDate = dateResult[1];

        const payload = {
          ...(!!selectName.slug && {profile: selectName.slug}),
          ...(!!optionsBalanceIndex.length && {type: resultType}),
          date_from: firstDate,
          date_to: secondDate,
        }

        const {result} = await makeRequest({route, method, payload});
        setStatistic(result.statistic);
      } catch (error) {
        showToast({message: t("oops"), variant: "error"})
      } finally {
        setIsLoading(false);
      }
    };

    token && fetchData();
  }, [token, selectName.slug, optionsBalanceIndex, date]);

  return !hasAccess ? AccessTitle : isLoading ? <Loader height={100}/> : (
    <main>
      <section className={"statistic-page__container"}>
        <div className="statistic-page__header">
          <div className="statistic-page__info-block">
            <h1>{t("mystat")}</h1>

            <div className="statistic-page__search">
              <Dropdown
                title={!!selectName.name ? selectName.name : t("allads")}
                name={!!selectName.name ? selectName.name : t("allads")}
                size={"s"}
                hideOnCHange={true}
                count={!!selectName.name}
              >
                <SearchBlock
                  currentName={selectName}
                  setName={setSelectName}
                  selectSearch={selectSearch}
                  setSelectSearch={setSelectSearch}
                  selectData={selectData}
                  setSelectData={setSelectData}
                />
              </Dropdown>

              <div className={'d-flex gap-8 align-center'}>
                <span className={'d-none-tablet-small color-main'}>{t('forperiod')}</span>
                <Dropdown
                  title={date}
                  name={"date"}
                  size={"s"}
                  iconClass={`calendar`}
                  clazz={`calendar`}
                >
                  <DateInput
                    isMulti
                    onChange={setDate}
                    notAfterCurDate={true}
                    notBeforeCurDate={false}
                  />
                </Dropdown>
              </div>
            </div>

          </div>
        </div>

        <h3>{t('i-detailedstat')}</h3>

        <div className="header__tabs balance-page__tabs mt-16 mb-16">
          {tabs.map((i, index) => {
            return (
              <button
                key={index}
                className={`header__tab p2 ${
                  activeTabId === i.id ? "_active" : ""
                }`}
                onClick={() => handleTabClick(i.id)}
              >
                {i.name}
              </button>
            );
          })}
        </div>

        <StatisticTable statistic={statistic}/>

      </section>
    </main>
  );
};

export default StatisticPage;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  complainReviewsId: false,
};

export const reviewsSlice = createSlice({
  name: "reviewsSlice",
  initialState,
  reducers: {
    setComplainReviewsId: (state, action) => {
      state.complainReviewsId = action.payload
    },
    clearComplainReviewsId: (state) => {
        state.complainReviewsId = false
    }
  },
});

export const {setComplainReviewsId, clearComplainReviewsId } = reviewsSlice.actions;

export const getComplainReviewsId = (state) => state.reviewsSlice.complainReviewsId;

export default reviewsSlice.reducer;

import useLadyService from "@/services/LadyService";
import { Checkbox, Icon, InputInLabel, Tippy } from "../../ui";
import { object } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { makeRequest } from "../../../services/makeRequest";
import SubsItem from "./SubsItem/SubsItem";
import useTitle from "@/hooks/useTitle";
import { useSelector, useDispatch } from "react-redux";
import {
  getPartiesNotifyStatus,
  setNotifyPartiesStatus,
} from "@/stores/slices/userProfileSlice";

import "./subscriptions.scss";

export const Subscriptions = () => {
  const { t } = useLadyService();

  const formSchema = object().shape({});

  const {
    register,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const [filterData, setFilterData] = useState([]);
  const notifyPArtyStatus = useSelector(getPartiesNotifyStatus);
  const [inviteParty, setInviteParty] = useState(notifyPArtyStatus);
  const dispatch = useDispatch();

  const handleInvitePartySubscribeFetch = async () => {
    try {
      const newStatus = !inviteParty;
      const route = `user/notify/parties`;
      const method = "POST";
      const payload = {
        status: newStatus,
      };
      const result = await makeRequest({ route, method, payload });
      if (result.success) {
        setInviteParty(newStatus);

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    dispatch(setNotifyPartiesStatus(inviteParty));
  }, [inviteParty]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `user/filter`;
        const method = "GET";

        const data = await makeRequest({ route, method });
        setFilterData(data.data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  // get notify data onload

  useTitle(t("subandfilters"));

  return (
    <main>
      <section className="registration__container">
        <div className="subs__title">
          <h1 className="text-nowrap">{t("subandfilters")}</h1>
          <span className={"color-700"}>{t("filtersets")}</span>
        </div>
        <div className="subs__search">
          <InputInLabel
            clazz={`${errors.text ? " error" : ""}`}
            id={`subs-search`}
            type={"text"}
            placeholder={t("wenotifyaboutnew")}
            register={{ ...register("text") }}
            onChange={(e) => {
              if (errors.text) {
                clearErrors("text");
              }
            }}
          >
            {(errors.text && (
              <span className={"input-label__error"}>
                {errors.text.message}
              </span>
            )) || <span>{t("notificationmessage")}</span>}
          </InputInLabel>

          <InputInLabel
            id={"party-check"}
            clazz={"fd-row"}
            type={"checkbox"}
            checked={inviteParty}
            onChange={() => {
              handleInvitePartySubscribeFetch(inviteParty);
            }}
          >
            {t("invitestoparties")}

            <Tippy title={t("g-partyorg")}>
              <Icon size={"s"} spritePath={"info"} />
            </Tippy>
          </InputInLabel>
        </div>
        <div className="subs__grid">
          {filterData.map((item) => (
            <SubsItem setFilterData={setFilterData} props={item} />
          ))}
        </div>
      </section>
    </main>
  );
};

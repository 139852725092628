import axios from "axios";
import useLadyService from "./LadyService";
import { useMemo } from "react";
import { convertData } from "@/helper/convertData";
import { removeSubstrings } from "@/helper/removeSubstrings";
import { setOneProfileAddress, setOneProfileCoords } from "@/stores/slices/oneProfileSlice";

const useHandleLocationClick = (
  setFormCity,
  setActiveCountry,
  setBounds,
  setCurrentActive,
  setSelectedCity,
  activeCountry,
  cities,
  setIsAddressActive,
  setMapMarker
) => {
  const citiesOptions = useMemo(() => convertData(cities), [cities]);
  const { lang, dispatch } = useLadyService();

  const handleLocationClick = () => {
    setIsAddressActive(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        const { latitude, longitude } = position.coords;

        setMapMarker({ lat: latitude, lng: longitude });
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
          );
          const {
            city: sityRes,
            road,
            house_number,
            town,
          } = response.data.address;

          const city = sityRes || town;

          let uncorrectName = false;

          citiesOptions[0][activeCountry].cities.map((item) => {
            if (
              item.name.toLowerCase() !== city.toLowerCase() &&
              !uncorrectName
            ) {
              uncorrectName = true;
              setSelectedCity(citiesOptions[0][activeCountry].cities.filter(city => city.slug === 'prague')[0].name);
              setCurrentActive(1);
              return null;
            }
          });
          setBounds({ lat: latitude, lng: longitude });

          const substringsToRemove = [
            "Столица",
            "Capital",
            "The Capital",
            "Hlavní město",
          ];

          if (citiesOptions?.length) {
            if (
              citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.some((obj) => {
                  return (
                    obj?.name.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove)
                        .trim()
                        .toLowerCase() ||
                    obj.slug.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove)
                        .trim()
                        .toLowerCase()
                  );
                })
            ) {
              const currentCity = citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.filter((i) => {
                  return (
                    i?.name.toLowerCase() ===
                      removeSubstrings(
                        city,
                        substringsToRemove
                      ).toLowerCase() ||
                    i.slug.toLowerCase() ===
                      removeSubstrings(city, substringsToRemove).toLowerCase()
                  );
                })[0];

              if (currentCity.id) {
                setSelectedCity(currentCity?.name);
                setCurrentActive(currentCity.id);
                setActiveCountry("cz");
                setFormCity({
                  ...currentCity,
                  icon: "CZ",
                });
              }
            }
          }

          dispatch(
            setOneProfileAddress(
              `${road} ${house_number ? ", " + house_number : ""}`
            )
          );
          dispatch(setOneProfileCoords({ latitude, longitude }));
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  return handleLocationClick;
};

export default useHandleLocationClick;

import { Button, Toggle } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";
import useLadyService from "@/services/LadyService";
import {
  setFavoriteCounter,
  setUserId,
  setUserStatus,
  setUserType,
} from "@/stores/slices/userSlice";
import {Popup} from "../index";
import {useSelector} from "react-redux";
import {getDeleteProfileStatus, setDeleteProfileStatus} from "@/stores/slices/popupSlice";
import useUserProfileService from "@/services/UserProfileService";

const DeleteProfilePopup = (props) => {
  const { isActiveProfile, handleActivate } = props;

  const {
    t,
    dispatch,
    navigate
  } = useLadyService();

  const {profileStatus} = useUserProfileService()

  const open = useSelector(getDeleteProfileStatus)

  const handleCloseDelete = () => {
    dispatch(setDeleteProfileStatus(false))
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user`;
        const method = "DELETE";

        const result = await makeRequest({ route, method });
        if(result) {
          dispatch(setUserType("default"));
          dispatch(setUserStatus("silver"));
          dispatch(setUserId(null));
          dispatch(setFavoriteCounter(0));
          localStorage.removeItem("user");
          navigate(`/`);
        }
        
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      method={"DELETE"}
      clazz={'gap-16'}
    >
        <h3 className="m-12-24">{t("aredeleteprofile")}</h3>

        <span className={"color-700"}>{t("notrecovery")}</span>

        <div className="account-page__activate justify-center">
          <h3>{isActiveProfile}</h3>

          <Toggle
            id={"isActiveProfile"}
            checked={!!profileStatus}
            onClick={handleActivate}
          />
        </div>

        <div className="popup-form__buttons">
          <Button
            size={"l-forever"}
            clazz={"button--green justify-center"}
            onClick={handleCloseDelete}
          >
            {t("cancel")}
          </Button>

          <Button
            size={"l-forever"}
            buttonType={"submit"}
            clazz={"button--error justify-center"}
            onClick={onSubmit}
          >
            {t("yesdelete")}
          </Button>
        </div>
    </Popup>
  );
};

export default DeleteProfilePopup;

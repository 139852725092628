import { setLang } from "@/stores/slices/userSlice";
import { useEffect } from "react";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import useLadyService from "@/services/LadyService";

const useDefiningLang = () => {
  const { dispatch, navigate } = useLadyService();

  const allowedLangs = ["en", "ru", "cz", ""];

  const { lang: langSegment } = useParams();

  useEffect(() => {
    if (!langSegment) {
      const userLanguage = navigator.language || navigator.userLanguage;
      let updateLang = localStorage.getItem("i18nextLng") || "";
      if (updateLang) {
        dispatch(setLang(updateLang));
        // console.log('changeLang');
        i18next.changeLanguage(updateLang);
      } else {
        switch (userLanguage.slice(0, 2)) {
          case "en":
            updateLang = "en";
            break;
          case "ru":
            updateLang = "ru";
            break;
          case "cs":
            updateLang = "cz";
            break;
          default:
            updateLang = "en";
        }
        dispatch(setLang(updateLang));
        // console.log('changeLang');
        i18next.changeLanguage(updateLang);
      }
    }
    if (langSegment && allowedLangs.includes(langSegment)) {
      dispatch(setLang(langSegment));
      // console.log('changeLang');
      i18next.changeLanguage(langSegment);
    }
    // if (
    //   langSegment &&
    //   !allowedLangs.includes(langSegment)
    // ) {
      // navigate("/404");
      // return;
    // }
  }, []);

  useEffect(() => {
    if (langSegment && allowedLangs.includes(langSegment)) {
      i18next.changeLanguage(langSegment);
      // dispatch(setLang(langSegment));
      // console.log('changeLang');
    }
  }, [langSegment]);
};

export default useDefiningLang;

export const viewList = [
  { position: 1, view: 370000 },
  { position: 2, view: 303000 },
  { position: 3, view: 274500 },
  { position: 4, view: 256000 },
  { position: 5, view: 220500 },
  { position: 6, view: 210500 },
  { position: 7, view: 200500 },
  { position: 8, view: 187500 },
  { position: 9, view: 169500 },
  { position: 10, view: 163500 },
  { position: 11, view: 157500 },
  { position: 12, view: 151500 },
  { position: 13, view: 128000 },
  { position: 14, view: 124000 },
  { position: 15, view: 120000 },
  { position: 16, view: 116000 },
  { position: 17, view: 112000 },
  { position: 18, view: 108000 },
  { position: 19, view: 104000 },
  { position: 20, view: 100000 },
  { position: 21, view: 96000 },
  { position: 22, view: 92000 },
  { position: 23, view: 88000 },
  { position: 24, view: 84100 },
  { position: 25, view: 73200 },
  { position: 26, view: 70500 },
  { position: 27, view: 67800 },
  { position: 28, view: 65100 },
  { position: 29, view: 62500 },
  { position: 30, view: 59900 },
  { position: 31, view: 57360 },
  { position: 32, view: 54820 },
  { position: 33, view: 52300 },
  { position: 34, view: 49780 },
  { position: 35, view: 47260 },
  { position: 36, view: 44740 },
  { position: 37, view: 39200 },
  { position: 38, view: 35990 },
  { position: 39, view: 34780 },
  { position: 40, view: 33570 },
  { position: 41, view: 32360 },
  { position: 42, view: 31150 },
  { position: 43, view: 29940 },
  { position: 44, view: 28730 },
  { position: 45, view: 27520 },
  { position: 46, view: 26310 },
  { position: 47, view: 25100 },
  { position: 48, view: 23890 },
  { position: 49, view: 20280 },
  { position: 50, view: 18970 },
  { position: 51, view: 17860 },
  { position: 52, view: 16850 },
  { position: 53, view: 15940 },
  { position: 54, view: 15130 },
  { position: 55, view: 14320 },
  { position: 56, view: 13510 },
  { position: 57, view: 12709 },
  { position: 58, view: 11909 },
  { position: 59, view: 11109 },
  { position: 60, view: 10309 },
  { position: 61, view: 8459 },
  { position: 62, view: 7559 },
  { position: 63, view: 6909 },
  { position: 64, view: 6559 },
  { position: 65, view: 6209 },
  { position: 66, view: 5859 },
  { position: 67, view: 5609 },
  { position: 68, view: 5359 },
  { position: 69, view: 5109 },
  { position: 70, view: 4859 },
  { position: 71, view: 4659 },
  { position: 72, view: 4489 },
  { position: 73, view: 3949 },
  { position: 74, view: 3819 },
  { position: 75, view: 3689 },
  { position: 76, view: 3564 },
  { position: 77, view: 3439 },
  { position: 78, view: 3324 },
  { position: 79, view: 3209 },
  { position: 80, view: 3097 },
  { position: 81, view: 2985 },
  { position: 82, view: 2874 },
  { position: 83, view: 2763 },
  { position: 84, view: 2652 },
  { position: 85, view: 2341 },
  { position: 86, view: 2280 },
  { position: 87, view: 2219 },
  { position: 88, view: 2159 },
  { position: 89, view: 2099 },
  { position: 90, view: 2039 },
  { position: 91, view: 1979 },
  { position: 92, view: 1919 },
  { position: 93, view: 1858 },
  { position: 94, view: 1798 },
  { position: 95, view: 1738 },
  { position: 96, view: 1678 },
  { position: 97, view: 1508 },
  { position: 98, view: 1483 },
  { position: 99, view: 1458 },
  { position: 100, view: 1432 },
  { position: 101, view: 1406 },
  { position: 102, view: 1381 },
  { position: 103, view: 1356 },
  { position: 104, view: 1331 },
  { position: 105, view: 1306 },
  { position: 106, view: 1281 },
  { position: 107, view: 1256 },
  { position: 108, view: 1226 },
  { position: 109, view: 1144 },
  { position: 110, view: 1122 },
  { position: 111, view: 1100 },
  { position: 112, view: 1078 },
  { position: 113, view: 1056 },
  { position: 114, view: 1034 },
  { position: 115, view: 1012 },
  { position: 116, view: 990 },
  { position: 117, view: 968 },
  { position: 118, view: 946 },
  { position: 119, view: 924 },
  { position: 120, view: 902 },
  { position: 121, view: 836 },
  { position: 122, view: 814 },
  { position: 123, view: 792 },
  { position: 124, view: 770 },
  { position: 125, view: 748 },
  { position: 126, view: 726 },
  { position: 127, view: 704 },
  { position: 128, view: 682 },
  { position: 129, view: 660 },
  { position: 130, view: 638 },
  { position: 131, view: 616 },
  { position: 132, view: 594 },
  { position: 133, view: 540 },
  { position: 134, view: 528 },
  { position: 135, view: 516 },
  { position: 136, view: 503 },
  { position: 137, view: 491 },
  { position: 138, view: 479 },
  { position: 139, view: 467 },
  { position: 140, view: 455 },
  { position: 141, view: 441 },
  { position: 142, view: 430 },
  { position: 143, view: 419 },
  { position: 144, view: 408 },
  { position: 145, view: 377 },
  { position: 146, view: 371 },
  { position: 147, view: 365 },
  { position: 148, view: 359 },
  { position: 149, view: 353 },
  { position: 150, view: 347 },
  { position: 151, view: 341 },
  { position: 152, view: 335 },
  { position: 153, view: 328 },
  { position: 154, view: 322 },
  { position: 155, view: 316 },
  { position: 156, view: 310 },
  { position: 157, view: 304 },
  { position: 158, view: 298 },
  { position: 159, view: 287 },
  { position: 160, view: 281 },
  { position: 161, view: 275 },
  { position: 162, view: 269 },
  { position: 163, view: 263 },
  { position: 164, view: 257 },
  { position: 165, view: 250 },
  { position: 166, view: 244 },
  { position: 167, view: 238 },
  { position: 168, view: 232 },
  { position: 169, view: 226 },
  { position: 170, view: 220 },
  { position: 171, view: 209 },
  { position: 172, view: 206 },
  { position: 173, view: 202 },
  { position: 174, view: 199 },
  { position: 175, view: 198 },
  { position: 176, view: 196 },
  { position: 177, view: 194 },
  { position: 178, view: 192 },
  { position: 179, view: 190 },
  { position: 180, view: 188 },
  { position: 181, view: 186 },
  { position: 182, view: 184 },
  { position: 183, view: 180 },
  { position: 184, view: 178 },
  { position: 185, view: 175 },
  { position: 186, view: 173 },
  { position: 187, view: 171 },
  { position: 188, view: 169 },
  { position: 189, view: 167 },
  { position: 190, view: 165 },
  { position: 191, view: 163 },
  { position: 192, view: 161 },
  { position: 193, view: 159 },
  { position: 194, view: 157 },
  { position: 195, view: 153 },
  { position: 196, view: 152 },
  { position: 197, view: 151 },
  { position: 198, view: 150 },
  { position: 199, view: 149 },
  { position: 200, view: 148 },
  { position: 201, view: 146 },
  { position: 202, view: 145 },
  { position: 203, view: 144 },
  { position: 204, view: 143 },
  { position: 205, view: 142 },
  { position: 206, view: 141 },
  { position: 207, view: 140 },
  { position: 208, view: 139 },
  { position: 209, view: 138 },
  { position: 210, view: 137 },
  { position: 211, view: 136 },
  { position: 212, view: 135 },
  { position: 213, view: 135 },
  { position: 214, view: 135 },
  { position: 215, view: 135 },
  { position: 216, view: 135 },
  { position: 217, view: 135 },
  { position: 218, view: 135 },
  { position: 219, view: 135 },
  { position: 220, view: 135 },
  { position: 221, view: 134 },
  { position: 222, view: 134 },
  { position: 223, view: 133 },
  { position: 224, view: 133 },
  { position: 225, view: 133 },
  { position: 226, view: 133 },
  { position: 227, view: 133 },
  { position: 228, view: 133 },
  { position: 229, view: 133 },
  { position: 230, view: 133 },
  { position: 231, view: 133 },
  { position: 232, view: 133 },
  { position: 233, view: 132.6753247 },
  { position: 234, view: 132.5957086 },
  { position: 235, view: 131.5160926 },
  { position: 236, view: 131.4364766 },
  { position: 237, view: 131.3568605 },
  { position: 238, view: 131.2772445 },
  { position: 239, view: 131.1976285 },
  { position: 240, view: 131.1180124 },
  { position: 241, view: 130.0383964 },
  { position: 242, view: 129.9587804 },
  { position: 243, view: 129.8791643 },
  { position: 244, view: 129.7995483 },
  { position: 245, view: 129.7199322 },
  { position: 246, view: 129.6403162 },
  { position: 247, view: 129.5607002 },
  { position: 248, view: 129.4810841 },
  { position: 249, view: 129.4014681 },
  { position: 250, view: 129.3218521 },
  { position: 251, view: 129.242236 },
  { position: 252, view: 129.16262 },
  { position: 253, view: 129.083004 },
  { position: 254, view: 129.0033879 },
  { position: 255, view: 128.9237719 },
  { position: 256, view: 128.8441558 },
  { position: 257, view: 128.7645398 },
  { position: 258, view: 128.6849238 },
  { position: 259, view: 128.6053077 },
  { position: 260, view: 128.5256917 },
  { position: 261, view: 127.4460757 },
  { position: 262, view: 127.3664596 },
  { position: 263, view: 127.2868436 },
  { position: 264, view: 127.2072276 },
  { position: 265, view: 127.1276115 },
  { position: 266, view: 127.0479955 },
  { position: 267, view: 126.9683794 },
  { position: 268, view: 126.8887634 },
  { position: 269, view: 126.8091474 },
  { position: 270, view: 126.7295313 },
  { position: 271, view: 126.6499153 },
  { position: 272, view: 126.5702993 },
  { position: 273, view: 126.4906832 },
  { position: 274, view: 126.4110672 },
  { position: 275, view: 126.3314512 },
  { position: 276, view: 126.2518351 },
  { position: 277, view: 126.1722191 },
  { position: 278, view: 126.092603 },
  { position: 279, view: 126.012987 },
  { position: 280, view: 125.933371 },
  { position: 281, view: 124.8537549 },
  { position: 282, view: 114.7741389 },
  { position: 283, view: 114.6945229 },
  { position: 284, view: 114.6149068 },
  { position: 285, view: 114.5352908 },
  { position: 286, view: 114.4556748 },
  { position: 287, view: 114.3760587 },
  { position: 288, view: 114.2964427 },
  { position: 289, view: 114.2168267 },
  { position: 290, view: 114.1372106 },
  { position: 291, view: 114.0575946 },
  { position: 292, view: 113.9779785 },
  { position: 293, view: 113.8983625 },
  { position: 294, view: 113.8187465 },
  { position: 295, view: 113.7391304 },
  { position: 296, view: 113.6595144 },
  { position: 297, view: 113.5798984 },
  { position: 298, view: 113.5002823 },
  { position: 299, view: 113.4206663 },
  { position: 300, view: 113.3410503 },
  { position: 301, view: 112.2614342 },
  { position: 302, view: 112.1818182 },
  { position: 303, view: 112.1022021 },
  { position: 304, view: 112.0225861 },
  { position: 305, view: 111.9429701 },
  { position: 306, view: 111.863354 },
  { position: 307, view: 111.783738 },
  { position: 308, view: 111.704122 },
  { position: 309, view: 111.6245059 },
  { position: 310, view: 111.5448899 },
  { position: 311, view: 111.4652739 },
  { position: 312, view: 111.3856578 },
  { position: 313, view: 111.3060418 },
  { position: 314, view: 111.2264257 },
  { position: 315, view: 111.1468097 },
  { position: 316, view: 111.0671937 },
  { position: 317, view: 110.9875776 },
  { position: 318, view: 110.9079616 },
  { position: 319, view: 110.8283456 },
  { position: 320, view: 110.7487295 },
  { position: 321, view: 110.6691135 },
  { position: 322, view: 110.5894975 },
  { position: 323, view: 110.5098814 },
  { position: 324, view: 110.4302654 },
  { position: 325, view: 110.3506494 },
  { position: 326, view: 110.2710333 },
  { position: 327, view: 110.1914173 },
  { position: 328, view: 110.1118012 },
  { position: 329, view: 110.0321852 },
  { position: 330, view: 109.9525692 },
  { position: 331, view: 109.8729531 },
  { position: 332, view: 109.7933371 },
  { position: 333, view: 109.7137211 },
  { position: 334, view: 109.634105 },
  { position: 335, view: 109.554489 },
  { position: 336, view: 109.474873 },
  { position: 337, view: 109.3952569 },
  { position: 338, view: 109.3156409 },
  { position: 339, view: 109.2360248 },
  { position: 340, view: 109.1564088 },
  { position: 341, view: 109.0767928 },
  { position: 342, view: 108.9971767 },
  { position: 343, view: 108.9175607 },
  { position: 344, view: 108.8379447 },
  { position: 345, view: 108.7583286 },
  { position: 346, view: 108.6787126 },
  { position: 347, view: 108.5990966 },
  { position: 348, view: 108.5194805 },
  { position: 349, view: 108.4398645 },
  { position: 350, view: 108.3602484 },
  { position: 351, view: 108.2806324 },
  { position: 352, view: 108.2010164 },
  { position: 353, view: 108.1214003 },
  { position: 354, view: 108.0417843 },
  { position: 355, view: 107.9621683 },
  { position: 356, view: 107.8825522 },
  { position: 357, view: 107.8029362 },
  { position: 358, view: 107.7233202 },
  { position: 359, view: 107.6437041 },
  { position: 360, view: 107.5640881 },
  { position: 361, view: 107.484472 },
  { position: 362, view: 107.404856 },
  { position: 363, view: 107.32524 },
  { position: 364, view: 107.2456239 },
  { position: 365, view: 107.1660079 },
  { position: 366, view: 107.0863919 },
  { position: 367, view: 107.0067758 },
  { position: 368, view: 106.9271598 },
  { position: 369, view: 106.8475438 },
  { position: 370, view: 106.7679277 },
  { position: 371, view: 106.6883117 },
  { position: 372, view: 106.6086957 },
  { position: 373, view: 106.5290796 },
  { position: 374, view: 106.4494636 },
  { position: 375, view: 106.3698475 },
  { position: 376, view: 106.2902315 },
  { position: 377, view: 106.2106155 },
  { position: 378, view: 106.1309994 },
  { position: 379, view: 106.0513834 },
  { position: 380, view: 105.9717674 },
  { position: 381, view: 105.8921513 },
  { position: 382, view: 105.8125353 },
  { position: 383, view: 105.7329193 },
  { position: 384, view: 105.6533032 },
  { position: 385, view: 105.5736872 },
  { position: 386, view: 105.4940711 },
  { position: 387, view: 105.4144551 },
  { position: 388, view: 105.3348391 },
  { position: 389, view: 105.255223 },
  { position: 390, view: 105.175607 },
  { position: 391, view: 105.095991 },
  { position: 392, view: 105.0163749 },
  { position: 393, view: 104.9367589 },
  { position: 394, view: 104.8571429 },
  { position: 395, view: 104.7775268 },
  { position: 396, view: 104.6979108 },
  { position: 397, view: 104.6182947 },
  { position: 398, view: 104.5386787 },
  { position: 399, view: 104.4590627 },
  { position: 400, view: 104.3794466 },
  { position: 401, view: 103.2998306 },
  { position: 402, view: 103.2202146 },
  { position: 403, view: 103.1405985 },
  { position: 404, view: 103.0609825 },
  { position: 405, view: 102.9813665 },
  { position: 406, view: 102.9017504 },
  { position: 407, view: 102.8221344 },
  { position: 408, view: 102.7425184 },
  { position: 409, view: 102.6629023 },
  { position: 410, view: 102.5832863 },
  { position: 411, view: 102.5036702 },
  { position: 412, view: 102.4240542 },
  { position: 413, view: 102.3444382 },
  { position: 414, view: 102.2648221 },
  { position: 415, view: 102.1852061 },
  { position: 416, view: 102.1055901 },
  { position: 417, view: 102.025974 },
  { position: 418, view: 101.946358 },
  { position: 419, view: 101.866742 },
  { position: 420, view: 101.7871259 },
  { position: 421, view: 101.7075099 },
  { position: 422, view: 101.6278938 },
  { position: 423, view: 101.5482778 },
  { position: 424, view: 101.4686618 },
  { position: 425, view: 101.3890457 },
  { position: 426, view: 101.3094297 },
  { position: 427, view: 101.2298137 },
  { position: 428, view: 101.1501976 },
  { position: 429, view: 101.0705816 },
  { position: 430, view: 100.9909656 },
  { position: 431, view: 100.9113495 },
  { position: 432, view: 100.8317335 },
  { position: 433, view: 100.7521174 },
  { position: 434, view: 100.6725014 },
  { position: 435, view: 100.5928854 },
  { position: 436, view: 100.5132693 },
  { position: 437, view: 100.4336533 },
  { position: 438, view: 100.3540373 },
  { position: 439, view: 100.2744212 },
  { position: 440, view: 100.1948052 },
  { position: 441, view: 100.1151892 },
  { position: 442, view: 100.0355731 },
  { position: 443, view: 99.95595709 },
  { position: 444, view: 99.87634105 },
  { position: 445, view: 99.79672501 },
  { position: 446, view: 99.71710898 },
  { position: 447, view: 99.63749294 },
  { position: 448, view: 99.55787691 },
  { position: 449, view: 99.47826087 },
  { position: 450, view: 99.39864483 },
  { position: 451, view: 99.3190288 },
  { position: 452, view: 99.23941276 },
  { position: 453, view: 99.15979673 },
  { position: 454, view: 99.08018069 },
  { position: 455, view: 99.00056465 },
  { position: 456, view: 98.92094862 },
  { position: 457, view: 98.84133258 },
  { position: 458, view: 98.76171654 },
  { position: 459, view: 98.68210051 },
  { position: 460, view: 98.60248447 },
  { position: 461, view: 98.52286844 },
  { position: 462, view: 98.4432524 },
  { position: 463, view: 98.36363636 },
  { position: 464, view: 98.28402033 },
  { position: 465, view: 98.20440429 },
  { position: 466, view: 98.12478826 },
  { position: 467, view: 98.04517222 },
  { position: 468, view: 97.96555618 },
  { position: 469, view: 97.88594015 },
  { position: 470, view: 97.80632411 },
  { position: 471, view: 97.72670807 },
  { position: 472, view: 97.64709204 },
  { position: 473, view: 97.567476 },
  { position: 474, view: 97.48785997 },
  { position: 475, view: 97.40824393 },
  { position: 476, view: 97.32862789 },
  { position: 477, view: 97.24901186 },
  { position: 478, view: 97.16939582 },
  { position: 479, view: 97.08977979 },
  { position: 480, view: 97.01016375 },
  { position: 481, view: 96.93054771 },
  { position: 482, view: 96.85093168 },
  { position: 483, view: 96.77131564 },
  { position: 484, view: 96.6916996 },
  { position: 485, view: 96.61208357 },
  { position: 486, view: 96.53246753 },
  { position: 487, view: 96.4528515 },
  { position: 488, view: 96.37323546 },
  { position: 489, view: 96.29361942 },
  { position: 490, view: 96.21400339 },
  { position: 491, view: 96.13438735 },
  { position: 492, view: 96.05477132 },
  { position: 493, view: 95.97515528 },
  { position: 494, view: 95.89553924 },
  { position: 495, view: 95.81592321 },
  { position: 496, view: 95.73630717 },
  { position: 497, view: 95.65669113 },
  { position: 498, view: 95.5770751 },
  { position: 499, view: 95.49745906 },
  { position: 500, view: 95.41784303 },
  // { position: 501, view: 94.33822699 },
  // { position: 502, view: 94.25861095 },
  // { position: 503, view: 94.17899492 },
  // { position: 504, view: 94.09937888 },
  // { position: 505, view: 94.01976285 },
  // { position: 506, view: 93.94014681 },
  // { position: 507, view: 93.86053077 },
  // { position: 508, view: 93.78091474 },
  // { position: 509, view: 93.7012987 },
  // { position: 510, view: 93.62168267 },
  // { position: 511, view: 93.54206663 },
  // { position: 512, view: 93.46245059 },
  // { position: 513, view: 93.38283456 },
  // { position: 514, view: 93.30321852 },
  // { position: 515, view: 93.22360248 },
  // { position: 516, view: 93.14398645 },
  // { position: 517, view: 93.06437041 },
  // { position: 518, view: 92.98475438 },
  // { position: 519, view: 92.90513834 },
  // { position: 520, view: 92.8255223 },
  // { position: 521, view: 92.74590627 },
  // { position: 522, view: 92.66629023 },
  // { position: 523, view: 92.5866742 },
  // { position: 524, view: 92.50705816 },
  // { position: 525, view: 92.42744212 },
  // { position: 526, view: 92.34782609 },
  // { position: 527, view: 92.26821005 },
  // { position: 528, view: 92.18859401 },
  // { position: 529, view: 92.10897798 },
  // { position: 530, view: 92.02936194 },
  // { position: 531, view: 91.94974591 },
  // { position: 532, view: 91.87012987 },
  // { position: 533, view: 91.79051383 },
  // { position: 534, view: 91.7108978 },
  // { position: 535, view: 91.63128176 },
  // { position: 536, view: 91.55166573 },
  // { position: 537, view: 91.47204969 },
  // { position: 538, view: 91.39243365 },
  // { position: 539, view: 91.31281762 },
  // { position: 540, view: 91.23320158 },
  // { position: 541, view: 91.15358554 },
  // { position: 542, view: 91.07396951 },
  // { position: 543, view: 90.99435347 },
  // { position: 544, view: 90.91473744 },
  // { position: 545, view: 90.8351214 },
  // { position: 546, view: 90.75550536 },
  // { position: 547, view: 90.67588933 },
  // { position: 548, view: 90.59627329 },
  // { position: 549, view: 90.51665726 },
  // { position: 550, view: 90.43704122 },
  // { position: 551, view: 90.35742518 },
  // { position: 552, view: 90.27780915 },
  // { position: 553, view: 90.19819311 },
  // { position: 554, view: 90.11857708 },
  // { position: 555, view: 90.03896104 },
  // { position: 556, view: 89.959345 },
  // { position: 557, view: 89.87972897 },
  // { position: 558, view: 89.80011293 },
  // { position: 559, view: 89.72049689 },
  // { position: 560, view: 89.64088086 },
  // { position: 561, view: 89.56126482 },
  // { position: 562, view: 89.48164879 },
  // { position: 563, view: 89.40203275 },
  // { position: 564, view: 89.32241671 },
  // { position: 565, view: 89.24280068 },
  // { position: 566, view: 89.16318464 },
  // { position: 567, view: 89.08356861 },
  // { position: 568, view: 89.00395257 },
  // { position: 569, view: 88.92433653 },
  // { position: 570, view: 88.8447205 },
  // { position: 571, view: 88.76510446 },
  // { position: 572, view: 88.68548842 },
  // { position: 573, view: 88.60587239 },
  // { position: 574, view: 88.52625635 },
  // { position: 575, view: 88.44664032 },
  // { position: 576, view: 88.36702428 },
  // { position: 577, view: 88.28740824 },
  // { position: 578, view: 88.20779221 },
  // { position: 579, view: 88.12817617 },
  // { position: 580, view: 88.04856014 },
  // { position: 581, view: 87.9689441 },
  // { position: 582, view: 87.88932806 },
  // { position: 583, view: 87.80971203 },
  // { position: 584, view: 87.73009599 },
  // { position: 585, view: 87.65047995 },
  // { position: 586, view: 87.57086392 },
  // { position: 587, view: 87.49124788 },
  // { position: 588, view: 87.41163185 },
  // { position: 589, view: 87.33201581 },
  // { position: 590, view: 87.25239977 },
  // { position: 591, view: 87.17278374 },
  // { position: 592, view: 87.0931677 },
  // { position: 593, view: 87.01355167 },
  // { position: 594, view: 86.93393563 },
  // { position: 595, view: 86.85431959 },
  // { position: 596, view: 86.77470356 },
  // { position: 597, view: 86.69508752 },
  // { position: 598, view: 86.61547149 },
  // { position: 599, view: 86.53585545 },
  // { position: 600, view: 86.45623941 },
  // { position: 601, view: 86.37662338 },
  // { position: 602, view: 86.29700734 },
  // { position: 603, view: 86.2173913 },
  // { position: 604, view: 86.13777527 },
  // { position: 605, view: 86.05815923 },
  // { position: 606, view: 85.9785432 },
  // { position: 607, view: 85.89892716 },
  // { position: 608, view: 85.81931112 },
  // { position: 609, view: 85.73969509 },
  // { position: 610, view: 85.66007905 },
  // { position: 611, view: 85.58046302 },
  // { position: 612, view: 85.50084698 },
  // { position: 613, view: 85.42123094 },
  // { position: 614, view: 85.34161491 },
  // { position: 615, view: 85.26199887 },
  // { position: 616, view: 85.18238283 },
  // { position: 617, view: 85.1027668 },
  // { position: 618, view: 85.02315076 },
  // { position: 619, view: 84.94353473 },
  // { position: 620, view: 84.86391869 },
  // { position: 621, view: 84.78430265 },
  // { position: 622, view: 84.70468662 },
  // { position: 623, view: 84.62507058 },
  // { position: 624, view: 84.54545455 },
  // { position: 625, view: 84.46583851 },
  // { position: 626, view: 84.38622247 },
  // { position: 627, view: 84.30660644 },
  // { position: 628, view: 84.2269904 },
  // { position: 629, view: 84.14737436 },
  // { position: 630, view: 84.06775833 },
  // { position: 631, view: 83.98814229 },
  // { position: 632, view: 83.90852626 },
  // { position: 633, view: 83.82891022 },
  // { position: 634, view: 83.74929418 },
  // { position: 635, view: 83.66967815 },
  // { position: 636, view: 83.59006211 },
  // { position: 637, view: 83.51044608 },
  // { position: 638, view: 83.43083004 },
  // { position: 639, view: 83.351214 },
  // { position: 640, view: 83.27159797 },
  // { position: 641, view: 83.19198193 },
  // { position: 642, view: 83.11236589 },
  // { position: 643, view: 83.03274986 },
  // { position: 644, view: 82.95313382 },
  // { position: 645, view: 82.87351779 },
  // { position: 646, view: 82.79390175 },
  // { position: 647, view: 82.71428571 },
  // { position: 648, view: 82.63466968 },
  // { position: 649, view: 82.55505364 },
  // { position: 650, view: 82.47543761 },
  // { position: 651, view: 82.39582157 },
  // { position: 652, view: 82.31620553 },
  // { position: 653, view: 82.2365895 },
  // { position: 654, view: 82.15697346 },
  // { position: 655, view: 82.07735743 },
  // { position: 656, view: 81.99774139 },
  // { position: 657, view: 81.91812535 },
  // { position: 658, view: 81.83850932 },
  // { position: 659, view: 81.75889328 },
  // { position: 660, view: 81.67927724 },
  // { position: 661, view: 81.59966121 },
  // { position: 662, view: 81.52004517 },
  // { position: 663, view: 81.44042914 },
  // { position: 664, view: 81.3608131 },
  // { position: 665, view: 81.28119706 },
  // { position: 666, view: 81.20158103 },
  // { position: 667, view: 81.12196499 },
  // { position: 668, view: 81.04234896 },
  // { position: 669, view: 80.96273292 },
  // { position: 670, view: 80.88311688 },
  // { position: 671, view: 80.80350085 },
  // { position: 672, view: 80.72388481 },
  // { position: 673, view: 80.64426877 },
  // { position: 674, view: 80.56465274 },
  // { position: 675, view: 80.4850367 },
  // { position: 676, view: 80.40542067 },
  // { position: 677, view: 80.32580463 },
  // { position: 678, view: 80.24618859 },
  // { position: 679, view: 80.16657256 },
  // { position: 680, view: 80.08695652 },
  // { position: 681, view: 80.00734049 },
  // { position: 682, view: 79.92772445 },
  // { position: 683, view: 79.84810841 },
  // { position: 684, view: 79.76849238 },
  // { position: 685, view: 79.68887634 },
  // { position: 686, view: 79.6092603 },
  // { position: 687, view: 79.52964427 },
  // { position: 688, view: 79.45002823 },
  // { position: 689, view: 79.3704122 },
  // { position: 690, view: 79.29079616 },
  // { position: 691, view: 79.21118012 },
  // { position: 692, view: 79.13156409 },
  // { position: 693, view: 79.05194805 },
  // { position: 694, view: 78.97233202 },
  // { position: 695, view: 78.89271598 },
  // { position: 696, view: 78.81309994 },
  // { position: 697, view: 78.73348391 },
  // { position: 698, view: 78.65386787 },
  // { position: 699, view: 78.57425184 },
  // { position: 700, view: 76.4946358 },
  // { position: 701, view: 76.41501976 },
  // { position: 702, view: 76.33540373 },
  // { position: 703, view: 76.25578769 },
  // { position: 704, view: 76.17617165 },
  // { position: 705, view: 76.09655562 },
  // { position: 706, view: 76.01693958 },
  // { position: 707, view: 75.93732355 },
  // { position: 708, view: 75.85770751 },
  // { position: 709, view: 75.77809147 },
  // { position: 710, view: 75.69847544 },
  // { position: 711, view: 75.6188594 },
  // { position: 712, view: 75.53924337 },
  // { position: 713, view: 75.45962733 },
  // { position: 714, view: 75.38001129 },
  // { position: 715, view: 75.30039526 },
  // { position: 716, view: 75.22077922 },
  // { position: 717, view: 75.14116318 },
  // { position: 718, view: 75.06154715 },
  // { position: 719, view: 74.98193111 },
  // { position: 720, view: 74.90231508 },
  // { position: 721, view: 74.82269904 },
  // { position: 722, view: 74.743083 },
  // { position: 723, view: 74.66346697 },
  // { position: 724, view: 74.58385093 },
  // { position: 725, view: 74.5042349 },
  // { position: 726, view: 74.42461886 },
  // { position: 727, view: 74.34500282 },
  // { position: 728, view: 74.26538679 },
  // { position: 729, view: 74.18577075 },
  // { position: 730, view: 74.10615471 },
  // { position: 731, view: 74.02653868 },
  // { position: 732, view: 73.94692264 },
  // { position: 733, view: 73.86730661 },
  // { position: 734, view: 73.78769057 },
  // { position: 735, view: 73.70807453 },
  // { position: 736, view: 73.6284585 },
  // { position: 737, view: 73.54884246 },
  // { position: 738, view: 73.46922643 },
  // { position: 739, view: 73.38961039 },
  // { position: 740, view: 73.30999435 },
  // { position: 741, view: 73.23037832 },
  // { position: 742, view: 73.15076228 },
  // { position: 743, view: 73.07114625 },
  // { position: 744, view: 72.99153021 },
  // { position: 745, view: 72.91191417 },
  // { position: 746, view: 72.83229814 },
  // { position: 747, view: 72.7526821 },
  // { position: 748, view: 72.67306606 },
  // { position: 749, view: 72.59345003 },
  // { position: 750, view: 72.51383399 },
  // { position: 751, view: 72.43421796 },
  // { position: 752, view: 72.35460192 },
  // { position: 753, view: 72.27498588 },
  // { position: 754, view: 72.19536985 },
  // { position: 755, view: 72.11575381 },
  // { position: 756, view: 72.03613778 },
  // { position: 757, view: 71.95652174 },
  // { position: 758, view: 71.8769057 },
  // { position: 759, view: 71.79728967 },
  // { position: 760, view: 71.71767363 },
  // { position: 761, view: 71.63805759 },
  // { position: 762, view: 71.55844156 },
  // { position: 763, view: 71.47882552 },
  // { position: 764, view: 71.39920949 },
  // { position: 765, view: 71.31959345 },
  // { position: 766, view: 71.23997741 },
  // { position: 767, view: 71.16036138 },
  // { position: 768, view: 71.08074534 },
  // { position: 769, view: 71.00112931 },
  // { position: 770, view: 70.92151327 },
  // { position: 771, view: 70.84189723 },
  // { position: 772, view: 70.7622812 },
  // { position: 773, view: 70.68266516 },
  // { position: 774, view: 70.60304912 },
  // { position: 775, view: 70.52343309 },
  // { position: 776, view: 70.44381705 },
  // { position: 777, view: 70.36420102 },
  // { position: 778, view: 70.28458498 },
  // { position: 779, view: 70.20496894 },
  // { position: 780, view: 70.12535291 },
  // { position: 781, view: 70.04573687 },
  // { position: 782, view: 69.96612084 },
  // { position: 783, view: 69.8865048 },
  // { position: 784, view: 69.80688876 },
  // { position: 785, view: 69.72727273 },
  // { position: 786, view: 69.64765669 },
  // { position: 787, view: 69.56804065 },
  // { position: 788, view: 69.48842462 },
  // { position: 789, view: 69.40880858 },
  // { position: 790, view: 69.32919255 },
  // { position: 791, view: 69.24957651 },
  // { position: 792, view: 69.16996047 },
  // { position: 793, view: 69.09034444 },
  // { position: 794, view: 69.0107284 },
  // { position: 795, view: 68.93111237 },
  // { position: 796, view: 68.85149633 },
  // { position: 797, view: 68.77188029 },
  // { position: 798, view: 68.69226426 },
  // { position: 799, view: 68.61264822 },
  // { position: 800, view: 68.53303219 },
  // { position: 801, view: 68.45341615 },
  // { position: 802, view: 68.37380011 },
  // { position: 803, view: 68.29418408 },
  // { position: 804, view: 68.21456804 },
  // { position: 805, view: 68.134952 },
  // { position: 806, view: 68.05533597 },
  // { position: 807, view: 67.97571993 },
  // { position: 808, view: 67.8961039 },
  // { position: 809, view: 67.81648786 },
  // { position: 810, view: 67.73687182 },
  // { position: 811, view: 67.65725579 },
  // { position: 812, view: 67.57763975 },
  // { position: 813, view: 67.49802372 },
  // { position: 814, view: 67.41840768 },
  // { position: 815, view: 67.33879164 },
  // { position: 816, view: 67.25917561 },
  // { position: 817, view: 67.17955957 },
  // { position: 818, view: 67.09994353 },
  // { position: 819, view: 67.0203275 },
  // { position: 820, view: 66.94071146 },
  // { position: 821, view: 66.86109543 },
  // { position: 822, view: 66.78147939 },
  // { position: 823, view: 66.70186335 },
  // { position: 824, view: 66.62224732 },
  // { position: 825, view: 66.54263128 },
  // { position: 826, view: 66.46301525 },
  // { position: 827, view: 66.38339921 },
  // { position: 828, view: 66.30378317 },
  // { position: 829, view: 66.22416714 },
  // { position: 830, view: 66.1445511 },
  // { position: 831, view: 66.06493506 },
  // { position: 832, view: 65.98531903 },
  // { position: 833, view: 65.90570299 },
  // { position: 834, view: 65.82608696 },
  // { position: 835, view: 65.74647092 },
  // { position: 836, view: 65.66685488 },
  // { position: 837, view: 65.58723885 },
  // { position: 838, view: 65.50762281 },
  // { position: 839, view: 65.42800678 },
  // { position: 840, view: 65.34839074 },
  // { position: 841, view: 65.2687747 },
  // { position: 842, view: 65.18915867 },
  // { position: 843, view: 65.10954263 },
  // { position: 844, view: 65.0299266 },
  // { position: 845, view: 64.95031056 },
  // { position: 846, view: 64.87069452 },
  // { position: 847, view: 64.79107849 },
  // { position: 848, view: 64.71146245 },
  // { position: 849, view: 64.63184641 },
  // { position: 850, view: 64.55223038 },
  // { position: 851, view: 64.47261434 },
  // { position: 852, view: 64.39299831 },
  // { position: 853, view: 64.31338227 },
  // { position: 854, view: 64.23376623 },
  // { position: 855, view: 64.1541502 },
  // { position: 856, view: 64.07453416 },
  // { position: 857, view: 63.99491813 },
  // { position: 858, view: 63.91530209 },
  // { position: 859, view: 63.83568605 },
  // { position: 860, view: 63.75607002 },
  // { position: 861, view: 63.67645398 },
  // { position: 862, view: 63.59683794 },
  // { position: 863, view: 63.51722191 },
  // { position: 864, view: 63.43760587 },
  // { position: 865, view: 63.35798984 },
  // { position: 866, view: 63.2783738 },
  // { position: 867, view: 63.19875776 },
  // { position: 868, view: 63.11914173 },
  // { position: 869, view: 63.03952569 },
  // { position: 870, view: 62.95990966 },
  // { position: 871, view: 62.88029362 },
  // { position: 872, view: 62.80067758 },
  // { position: 873, view: 62.72106155 },
  // { position: 874, view: 62.64144551 },
  // { position: 875, view: 62.56182947 },
  // { position: 876, view: 62.48221344 },
  // { position: 877, view: 62.4025974 },
  // { position: 878, view: 62.32298137 },
  // { position: 879, view: 62.24336533 },
  // { position: 880, view: 62.16374929 },
  // { position: 881, view: 62.08413326 },
  // { position: 882, view: 62.00451722 },
  // { position: 883, view: 61.92490119 },
  // { position: 884, view: 61.84528515 },
  // { position: 885, view: 61.76566911 },
  // { position: 886, view: 61.68605308 },
  // { position: 887, view: 61.60643704 },
  // { position: 888, view: 61.52682101 },
  // { position: 889, view: 61.44720497 },
  // { position: 890, view: 61.36758893 },
  // { position: 891, view: 61.2879729 },
  // { position: 892, view: 61.20835686 },
  // { position: 893, view: 61.12874082 },
  // { position: 894, view: 61.04912479 },
  // { position: 895, view: 60.96950875 },
  // { position: 896, view: 60.88989272 },
  // { position: 897, view: 60.81027668 },
  // { position: 898, view: 60.73066064 },
  // { position: 899, view: 60.65104461 },
  // { position: 900, view: 58.57142857 },
  // { position: 901, view: 58.49181254 },
  // { position: 902, view: 58.4121965 },
  // { position: 903, view: 58.33258046 },
  // { position: 904, view: 58.25296443 },
  // { position: 905, view: 58.17334839 },
  // { position: 906, view: 58.09373235 },
  // { position: 907, view: 58.01411632 },
  // { position: 908, view: 57.93450028 },
  // { position: 909, view: 57.85488425 },
  // { position: 910, view: 57.77526821 },
  // { position: 911, view: 57.69565217 },
  // { position: 912, view: 57.61603614 },
  // { position: 913, view: 57.5364201 },
  // { position: 914, view: 57.45680407 },
  // { position: 915, view: 57.37718803 },
  // { position: 916, view: 57.29757199 },
  // { position: 917, view: 57.21795596 },
  // { position: 918, view: 57.13833992 },
  // { position: 919, view: 57.05872388 },
  // { position: 920, view: 56.97910785 },
  // { position: 921, view: 56.89949181 },
  // { position: 922, view: 56.81987578 },
  // { position: 923, view: 56.74025974 },
  // { position: 924, view: 56.6606437 },
  // { position: 925, view: 56.58102767 },
  // { position: 926, view: 56.50141163 },
  // { position: 927, view: 56.4217956 },
  // { position: 928, view: 56.34217956 },
  // { position: 929, view: 56.26256352 },
  // { position: 930, view: 56.18294749 },
  // { position: 931, view: 56.10333145 },
  // { position: 932, view: 56.02371542 },
  // { position: 933, view: 55.94409938 },
  // { position: 934, view: 55.86448334 },
  // { position: 935, view: 55.78486731 },
  // { position: 936, view: 55.70525127 },
  // { position: 937, view: 55.62563523 },
  // { position: 938, view: 55.5460192 },
  // { position: 939, view: 55.46640316 },
  // { position: 940, view: 55.38678713 },
  // { position: 941, view: 55.30717109 },
  // { position: 942, view: 55.22755505 },
  // { position: 943, view: 55.14793902 },
  // { position: 944, view: 55.06832298 },
  // { position: 945, view: 54.98870695 },
  // { position: 946, view: 54.90909091 },
  // { position: 947, view: 54.82947487 },
  // { position: 948, view: 54.74985884 },
  // { position: 949, view: 54.6702428 },
  // { position: 950, view: 54.59062676 },
  // { position: 951, view: 54.51101073 },
  // { position: 952, view: 54.43139469 },
  // { position: 953, view: 54.35177866 },
  // { position: 954, view: 54.27216262 },
  // { position: 955, view: 54.19254658 },
  // { position: 956, view: 54.11293055 },
  // { position: 957, view: 54.03331451 },
  // { position: 958, view: 53.95369848 },
  // { position: 959, view: 53.87408244 },
  // { position: 960, view: 53.7944664 },
  // { position: 961, view: 53.71485037 },
  // { position: 962, view: 53.63523433 },
  // { position: 963, view: 53.55561829 },
  // { position: 964, view: 53.47600226 },
  // { position: 965, view: 53.39638622 },
  // { position: 966, view: 53.31677019 },
  // { position: 967, view: 53.23715415 },
  // { position: 968, view: 53.15753811 },
  // { position: 969, view: 53.07792208 },
  // { position: 970, view: 52.99830604 },
  // { position: 971, view: 52.91869001 },
  // { position: 972, view: 52.83907397 },
  // { position: 973, view: 52.75945793 },
  // { position: 974, view: 52.6798419 },
  // { position: 975, view: 52.60022586 },
  // { position: 976, view: 52.52060982 },
  // { position: 977, view: 52.44099379 },
  // { position: 978, view: 52.36137775 },
  // { position: 979, view: 52.28176172 },
  // { position: 980, view: 52.20214568 },
  // { position: 981, view: 52.12252964 },
  // { position: 982, view: 52.04291361 },
  // { position: 983, view: 51.96329757 },
  // { position: 984, view: 51.88368154 },
  // { position: 985, view: 51.8040655 },
  // { position: 986, view: 51.72444946 },
  // { position: 987, view: 51.64483343 },
  // { position: 988, view: 51.56521739 },
  // { position: 989, view: 51.48560136 },
  // { position: 990, view: 51.40598532 },
  // { position: 991, view: 51.32636928 },
  // { position: 992, view: 51.24675325 },
  // { position: 993, view: 51.16713721 },
  // { position: 994, view: 51.08752117 },
  // { position: 995, view: 51.00790514 },
  // { position: 996, view: 50.9282891 },
  // { position: 997, view: 50.84867307 },
  // { position: 998, view: 50.76905703 },
  // { position: 999, view: 50.68944099 },
  // { position: 1000, view: 38.60982496 },
];

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Button, Icon, InputInLabel } from "@/components/ui";
import { makeRequest } from "@/services/makeRequest";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { ref, string, object } from "yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getNewPassStatus, setNewPassStatus } from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import axios from "axios";
import baseUrl from "@/services/apiConfig";
import { getNewToken } from "@/stores/slices/userSlice";
import { setAuthorizationStatus } from "@/stores/slices/popupSlice";
import { setForgotPasswordEmailStatus } from "../../../stores/slices/popupSlice";
import { Popup } from "../index";
import { LoadingButton } from "../../ui";
import { useState } from "react";

const NewPassPopup = () => {
  const { t, dispatch } = useLadyService();

  const newToken = useSelector(getNewToken);

  const statusPopup = useSelector(getNewPassStatus);

  const formSchema = object().shape({
    password: string().min(8, t("min8")).required(),
    password_confirmation: string()
      .min(8, t("min8"))
      .required()
      .oneOf([ref("password")], t("passdonot")),
  });

  const handleCloseDelete = () => {
    dispatch(setNewPassStatus(false));
  };

  const handleBack = () => {
    dispatch(setNewPassStatus(false));
  };

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await axios.put(
        baseUrl + `auth/password/set`,
        {
          password: data.password,
          password_confirmation: data.password_confirmation,
        },
        {
          headers: {
            Authorization: newToken ? `Bearer ${newToken}` : "",
            "Content-Type": "application/json",
          },
        }
      );
      if (result) {
        dispatch(setNewPassStatus(false));
        dispatch(setAuthorizationStatus(true));
        dispatch(setForgotPasswordEmailStatus(false));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Popup
      open={!!statusPopup}
      setOpen={handleCloseDelete}
      method={"PUT"}
      onSubmit={handleSubmit(onSubmit)}
      clazz="gap-16"
    >
      <h2>{t("newpas")}</h2>

      <div className="popup-form__inner">
        <InputInLabel
          clazz={`${errors.password ? " error" : ""}`}
          id={"passRegistration"}
          title={t("pass")}
          type={"password"}
          placeholder={"**********"}
          register={{ ...register("password") }}
          autocomplete={"new-password"}
        />

        <InputInLabel
          clazz={`${errors.password_confirmation ? " error" : ""}`}
          id={"passConfirmRegistration"}
          title={t("repeatpassword")}
          type={"password"}
          placeholder={"**********"}
          register={{ ...register("password_confirmation") }}
          autocomplete={"new-password"}
        />
      </div>
      {errors.password_confirmation && (
        <span className={"popup-form__error"}>
          {errors.password_confirmation.message}
        </span>
      )}
      <div className="popup-form__buttons">
        <LoadingButton
          buttonType={"submit"}
          size={"l-forever"}
          square={false}
          isLoading={isLoading}
          clazz="button--green justify-center"
        >
          {t("apply")}
        </LoadingButton>

        <Button
          size={"l-forever"}
          clazz={"button--error justify-center"}
          onClick={() => handleBack()}
        >
          {t("cancel")}
        </Button>
      </div>
    </Popup>
  );
};

export default NewPassPopup;

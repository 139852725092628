import {useEffect, useState} from "react";

const useEffectWindowSize = () => {
    const [size, setSize] = useState(0);
    useEffect(() => {
        const updateSize = () => {
            setSize(window.innerWidth);
        };
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

export default useEffectWindowSize
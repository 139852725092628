import Helmet from "react-helmet";
import { Button, Checkbox, Icon, InputInLabel, Loader } from "@/components/ui";

import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import { Link } from "react-router-dom";

import showToast from "../../toast/Toast";

import "./linkWithAgencyPopup.scss";
import useLadyService from "../../../services/LadyService";
import {Popup} from "../index";

const Res = ({ isLoading, agencyOptions, value, handleAddChange, search }) => {
  const {t, setMediaSize} = useLadyService()
  switch (true) {
    case isLoading: {
      return <Loader />;
    }

    case !!agencyOptions.length: {
      return agencyOptions.map((i) => {
        const { image, id, name, slug: slugAgency } = i;

        return (
          <div key={id} className="agency-block">
            <img src={setMediaSize(image, "xs")} alt="agency" />
            <Link className="agency-block__link-to">{name}</Link>
            <Checkbox
              // radio={true}
              border={false}
              checked={slugAgency === value}
              onChange={() => handleAddChange(slugAgency)}
            />
          </div>
        );
      });
    }

    case !!search && !!agencyOptions.length: {
      return (
        <span className="w-100 text-center">
          {t('startagency')}
        </span>
      );
    }

    case !!search && !agencyOptions.length: {
      return (
        <span className="w-100 text-center">
          {t('noproffound')}
        </span>
      );
    }

    default: {
      return (
        <span className="w-100 text-center">
          {t('startagency')}
        </span>
      );
    }
  }
};

const LinkWithAgencyPopup = (props) => {
  const { open, setOpen, slug: slugProfile } = props;
  const { token, lang, t } = useLadyService();

  const [agencyOptions, setAgencyOptions] = useState([]);

  const [value, setValue] = useState(null);
  const [isLoading, seiIsLoading] = useState(false);

  const [search, setSearch] = useState("");

  const onClose = () => {
    setValue(null);
    setOpen(false);
  };

  const handleAddChange = (slugAgency) => {
    if (slugAgency === value) {
      setValue(null);
    } else {
      setValue(slugAgency);
    }
  };

  const handleFilter = (e) => {
    const val = e.currentTarget.value;
    setSearch(val);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fetchData = async () => {
      try {
        const route = `user/profile/${slugProfile}/add-agency`;
        const method = "POST";
        const payload = {
          agency: value,
        };

        await makeRequest({ route, method, payload });
        onClose();
        showToast({
          message: t("success"),
          variant: "success",
        });
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  const handleRemoveAgency = async () => {
    try {
      const route = `user/profile/${slugProfile}/remove-agency`;

      const method = "DELETE";

      await makeRequest({ route, method });

      showToast({
        message: t("success"),
        variant: "success",
      });
      onClose()
    } catch (error) {
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        seiIsLoading(true);
        const route = `agencies`;
        const method = "GET";
        const payload = {
          search,
        };

        const { agencies } = await makeRequest({ route, method, payload });
        setAgencyOptions(agencies);
        seiIsLoading(false);
      } catch (error) {}
    };

    if (!search) {
      setAgencyOptions([]);
    }

    !!search && token && fetchData();
  }, [token, lang, search]);

  return (
    <Popup
      open={open}
      setOpen={onClose}
      onSubmit={onSubmit}
      clazz={'gap-24'}
    >

        <h3 className="m-12-24">{t("connectagency")}</h3>
        <span className={"color-main"}>{t("linkagencyinfo")}</span>

        <InputInLabel
          placeholder={t('searchagency')}
          onChange={handleFilter}
          value={search}
          size={"s"}
        />

        <div className="agency-block__content">
          <Res
            isLoading={isLoading}
            agencyOptions={agencyOptions}
            value={value}
            handleAddChange={handleAddChange}
            search={search}
          />
        </div>

        <Button
          size={"l-forever"}
          disabled={value}
          onClick={() => handleRemoveAgency()}
          clazz={"button--error justify-center"}
        >
          {t("disconnect")}
        </Button>

        <Button
          size={"l-forever"}
          disabled={!value}
          buttonType={"submit"}
          clazz={"button--green justify-center"}
        >
          {t("confirm")}
        </Button>
    </Popup>
  );
};

export default LinkWithAgencyPopup;

import './tag.scss'

const Tag = ({clazz, children, title}) => {
  return(
    <span
      title={title}
      className={`tag${clazz ? ` ${clazz}` : ''}`}
    >
      {children}
    </span>
  )
}

export default Tag
import { createSlice } from "@reduxjs/toolkit";

const translateAi = createSlice({
  name: "translateAi",
  initialState: {
    description_translate_to: [],
    description_ero_translate_to: [],
    preferences_translate_to: [],
  },
  reducers: {
    setTranslateDesc: (state, action) => {
      state.description_translate_to.push(action.payload);
    },
    setTranslateDescEro: (state, action) => {
      state.description_ero_translate_to.push(action.payload);
    },
    setTranslatePrefer: (state, action) => {
      state.preferences_translate_to.push(action.payload);
    },
    clearDescByName: (state, action) => {
      const stateName = action
      state[stateName] = []
    }
  },
});

export const { setTranslateDesc, setTranslateDescEro, setTranslatePrefer,clearDescByName } =
  translateAi.actions;

export const getDescTranslateTo = (state) =>
  state.translateAi.description_translate_to;
export const getDescEroTranslateTo = (state) =>
  state.translateAi.description_ero_translate_to;
export const getPreferTranslateTo = (state) =>
  state.translateAi.preferences_translate_to;

export default translateAi.reducer;

export function convertData(data) {
    const result = [];
    Object.keys(data).forEach((key) => {
      const res = {};
      res[key] = {
        name: data[key]?.name,
        code: key,
        cities: data[key].cities,
      };
      result.push(res);
    });
    return result;
  }
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ladyForCall: null,
  videoCallCamera: null,
  videoCallRoom: null,
  videoCallFreeTime: null,
  videoCallTime: null,
  videoCallStatus: null,
  videoCallPrice: 2,
};

const videoCallSlice = createSlice({
  name: "videoCall",
  initialState,
  reducers: {
    setVideoCallCamera: (state, action) => {
      state.videoCallCamera = action.payload;
    },
    setLadyForCall: (state, action) => {
      state.ladyForCall = action.payload;
    },
    setVideoCallRoom: (state, action) => {
      console.log("payload", action.payload);
      state.videoCallRoom = action.payload.room_id;
      state.videoCallFreeTime = parseInt(action.payload.free_time);
      state.videoCallTime = parseInt(action.payload.time);
      state.videoCallPrice = parseInt(action.payload.price ?? 2);
    },
    setVideoCallStatus: (state, action) => {
      state.videoCallStatus = action.payload;
    },
    cancelVideoCall: (state) => {
      state.ladyForCall = null;
      state.videoCallRoom = null;
      state.videoCallStatus = null;
      state.videoCallFreeTime = null;
      state.videoCallTime = null;
    },
  },
});

export const {
  setVideoCallCamera,
  setVideoCallStatus,
  setLadyForCall,
  setVideoCallRoom,
  cancelVideoCall,
} = videoCallSlice.actions;


export const getCreateVideoRoomModalStatus = (state) =>
  state.videoCall.ladyForCall !== undefined &&
  state.videoCall.ladyForCall !== null &&
  !state?.videoCall?.ladyForCall?.uuid;
export const getVideoCallModalStatus = (state) =>
  state.videoCall.videoCallRoom !== undefined &&
  state.videoCall.videoCallRoom !== null;
export const getVideoCallStatus = (state) => state.videoCall.videoCallStatus;
export const getVideoCallRoom = (state) => state.videoCall.videoCallRoom;
export const getVideoCallPrice = (state) => state.videoCall.videoCallPrice;
export const getLadyForCall = (state) => state.videoCall.ladyForCall;
export const getVideoCallCamera = (state) => state.videoCall.videoCallCamera;
export const getVideoCallMaxTime = (state) => state.videoCall.videoCallTime + state.videoCall.videoCallFreeTime;
export const getVideoCallFreeTime = (state) => state.videoCall.videoCallFreeTime;

export default videoCallSlice.reducer;

import {Link} from "react-router-dom";
import React from "react";
import {LogoSvg} from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {cleanSorted, setPageDefault, setSearchValue, setSortedModelsByFilter} from "@/stores/slices/modelsState";
import {resetAll, setSearchFilter} from "@/stores/slices/filterSlice";
import {clearTitle} from "@/stores/slices/titleSlice";
import {resetAllCounters} from "@/stores/slices/counterFilterSlice";

import logoDarkScrolled from "@/assets/img/logo-scroll--dark.svg";
import logoLightScrolled from "@/assets/img/logo-scroll--light.svg";
import {useSelector} from "react-redux";
import {getScrolledClass} from "@/stores/slices/headerSlice";
import { setProfileFilterCount } from "../../../stores/slices/mapModelSlice";

const Logo = () => {
  const {
    theme,
    windowWidth,
    dispatch,
    scrollToTop,
    t,
    userCity,
  } = useLadyService();

  const postAltLogo = t("altlogo", {City:  userCity?.title});
  const scrolledClass = useSelector(getScrolledClass);

  const handleLogo = () => {
    dispatch(cleanSorted());
    dispatch(resetAll());
    dispatch(clearTitle());
    dispatch(setSearchFilter(false));
    dispatch(setSearchValue(""));
    dispatch(setPageDefault());
    dispatch(setSortedModelsByFilter(""));
    dispatch(resetAllCounters());
    dispatch(setProfileFilterCount(null));

    if (scrolledClass) {
      scrollToTop()
    }
  };

  const getThemeForLogo = () => {
    if (theme === "dark") {
      return logoDarkScrolled;
    } else {
      return logoLightScrolled;
    }
  };

  return(
    <Link
      to={`/`}
      title={t("titlelogo")}
      className="logo"
      onClick={() => handleLogo()}
    >
      {scrolledClass === " _scroll" && windowWidth > 767.98 ? (
        <img src={getThemeForLogo()} alt={postAltLogo} />
      ) : (
        <LogoSvg alt={postAltLogo} />
      )}
    </Link>
  );
}

export default Logo
import { Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { HashLink } from "react-router-hash-link";

import "./rules.scss";

const ContainerBlock = (props) => {
  const { windowWidth } = useLadyService();

  const isSmallTablet = windowWidth < 1000.98;

  const size = isSmallTablet ? "5xl" : "6xl";

  const { text, spritePath, href } = props;
  return (
    <HashLink to={href} className={"rules__block"}>
      <Icon size={size} spritePath={spritePath} />
      <span>{text}</span>
    </HashLink>
  );
};

const Rules = () => {
  const { t, userType } = useLadyService();

  return (
    <div className={"rules"}>
      <h2>{t("guide")}</h2>

      {userType === 'customer' ? (
        <ContainerBlock
          text={t("findout")}
          spritePath={"instruction"}
          href={`/guide`}
        />
        ) : (
        <div className={"rules__main"}>
          <ContainerBlock
            text={t("findout")}
            spritePath={"instruction"}
            href={`/guide`}
          />
          <ContainerBlock
            text={t("opportunities")}
            spritePath={"euro"}
            href={`/guide#eroCoins`}
          />
        </div>
        )}

      <div className={"rules__secure"}>
        <Icon size={"l"} spritePath={"secure"} />

        <span>{t("secured")}</span>
      </div>
    </div>
  );
};

export default Rules;

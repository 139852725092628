import { ButtonLink, Button, Icon } from "@/components/ui";
import React, {useState} from "react";
import ZeroZone from "@/components/zeroZone/ZeroZone";
import useLadyService from "@/services/LadyService";
import EditBlock from "../editBlock/EditBlock";

const ModelPornstarCard = ({porn_star, setIsOpenEditPorno, setVisualElem, edit}) => {
  const { t } = useLadyService();

  return (
    <div className={"model-card d-flex fd-column gap-12"}>

      {!!edit ?
        <EditBlock
          title={t("pornstar")}
          btnTitle={t("edit")}
          titleType={'h3'}
          mb={'0'}
          onClick={() => {
            setVisualElem("video")
            setIsOpenEditPorno(true)
          }}
          clazz={'align-center justify-sb'}
        />
        : (
          <h3 className={'mb-0'}>{t("pornstar")}</h3>
        )}

      {!!porn_star.length ? (
        porn_star.map((i) => (
            <div className={"agency-card"}>

              <div className="agency-card__img">

                {i.video_link && (
                  <iframe
                    allowFullScreen
                    src={i.video_link}
                  ></iframe>
                )}
              </div>

              {i.website_link ? (
                <ButtonLink
                  href={i.website_link}
                  size={"m"}
                  clazz={`button--tetriary w-100 hover-line`}
                >
                  {i.website_link}
                </ButtonLink>
              ) : null}

              {i.status === 0 && <span className="p1 ero-status color-main text-center">{t('moderated24hour')}</span>}
            </div>
          ))) : edit ? (
        <ZeroZone
          setOpen={setIsOpenEditPorno}
          setVisualElem={setVisualElem}
          visualElem={'video'}
          longDisc={t('getporno')}
        />
      ) : null}
    </div>
  );
};
export default ModelPornstarCard;

import useLadyService from "@/services/LadyService";
import { useEffect, useState } from "react";
import { Icon, Button, Loader, Checkbox } from "../../ui";
import "./supportPage.scss";
import { NewSupportPopup } from "../../popups";
import { makeRequest } from "@/services/makeRequest";
import TicketContainer from "./components/TicketContainer";
import useTitle from "@/hooks/useTitle";
import { useSelector } from "react-redux";
import { getComplainReviewsId } from "@/stores/slices/reviewsSlice";
import showToast from "../../toast/Toast";
import { clearComplainReviewsId } from "../../../stores/slices/reviewsSlice";
import Pagination from "../../pagination/Pagination";

const SupportPage = () => {
  const { t, scrollToTop, dispatch, token, lang, navigate } = useLadyService();

  const [openNew, setOpenNew] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  const isComplainReviewsId = useSelector(getComplainReviewsId);

  const [defaultPopupTitle, setDefaultPopupTitle] = useState("");
  const [pagesList, setPagesList] = useState([]);
  const [page, setPage] = useState(1);

  const handlePage = (e, value) => {
    e.preventDefault();
    navigate(`/${lang}/lk/support#${value}`);
    setPage(value);
  };

  const handleFileChange = (items, setItems) => {
    const files = Array.from(items);
    const allowedExtensions = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/zip",
      "application/x-zip-compressed",
      "application/x-rar-compressed",
      "application/vnd.rar",
      "video/mp4",
    ];

    const validFiles = [];

    files.forEach((file) => {
      if (allowedExtensions.includes(file.type)) {
        validFiles.push(file);
      } else {
        const acceptsFilesMessage = `${t(
          "accepttypes"
        )}: ${`PDF, DOC, DOCX, Zip, Rar, PNG, WEBP, JPG`}`;
        showToast({
          message: acceptsFilesMessage,
          variant: "error",
        });
      }
    });

    setItems(validFiles);
  };

  useEffect(() => {
    if (isComplainReviewsId) {
      setOpenNew(true);
      setDefaultPopupTitle(t("unrealreweiv"));
    }
  }, []);

  useEffect(() => {
    return () => {
      if (isComplainReviewsId) {
        dispatch(clearComplainReviewsId());
      }
    };
  }, []);

  const TicketRes = () => {
    switch (true) {
      case isLoading: {
        return <Loader />;
      }

      case !!data.length: {
        return data?.map((i, index) => {
          return (
            <TicketContainer
              handleFileChange={handleFileChange}
              allData={data}
              setData={setData}
              key={index}
              data={i}
            />
          );
        });
      }

      default: {
        return <h3 className="text-center mt-12">{t("emptyrecord")}</h3>;
      }
    }
  };

  const handleOpen = () => {
    setOpenNew(true);
  };

  const [checkboxType, setCheckboxType] = useState("tickets");

  useTitle(
    t("support") + " " + t("and") + " " + t("notifications").toLowerCase()
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setData([]);
      if(page > 1) {
        scrollToTop();
      }
      try {
        const route = "tickets"
        const method = "GET";
        const payload = {
          ...(checkboxType === "archive" && {archive: "true"}),
          per_page: 12,
          page: page
        }

        const data = await makeRequest({ route, method,payload });

        setData(data.tickets);
        const { per_page, total } = data.meta;
        const result = Math.ceil(total / per_page);
        const arrayRes = Array.from({ length: result }, (_, i) => i + 1);
        setPagesList(arrayRes);

        setIsLoading(false);
      } catch (error) {}
    };

    token && fetchData();
  }, [checkboxType, page]);

  return (
    <main>
      <section className={"balance-page__container gap-12"}>
        <h1>
          {t("support")} {t("and")} {t("notifications").toLowerCase()}
        </h1>

        <div className={"support-page__header"}>
          <Button clazz={"button--green"} size="m" onClick={handleOpen}>
            <Icon spritePath={"plus-1"} size={"s"} />
            <span>{t("newreq")}</span>
          </Button>
          <div className={"support-page__filter"}>
            <Checkbox
              id={"1"}
              title={t("opentickets")}
              value={"tickets"}
              name={"tickets"}
              checked={checkboxType.includes("tickets")}
              onChange={() => setCheckboxType("tickets")}
            />
            {/* <Checkbox
              id={"2"}
              title={t("notifications")}
              value={"notifications"}
              checked={checkboxType.includes("notifications")}
              name={"notifications"}
              onChange={() => setCheckboxType("notifications")}
            /> */}
            <Checkbox
              id={"3"}
              title={t("arch")}
              value={"archive"}
              name={"archive"}
              checked={checkboxType.includes("archive")}
              onChange={() => setCheckboxType("archive")}
            />
          </div>
        </div>
        <TicketRes />
        {pagesList.length > 1 && (
          <Pagination
            pages={pagesList}
            activePage={page}
            onPageChange={handlePage}
          />
        )}
      </section>

      {openNew &&
        <NewSupportPopup
          setData={setData}
          handleFileChange={handleFileChange}
          defaultPopupTitle={defaultPopupTitle}
          open={openNew}
          setOpen={setOpenNew}
        />
      }
    </main>
  );
};

export default SupportPage;

import {useEffect, useState} from "react";
import "@/components/pages/accounts/model/profiles/pageComponets/verAds/verAds.scss";
import Popup from "../Popup";
import useLadyService from "../../../services/LadyService";
import axios from "axios";
import baseUrl from "../../../services/apiConfig";
import showToast from "../../toast/Toast";
import {VerHealthAgency} from "../../pages/accounts/model/profiles/pageComponets";
import "./HealthyVerification.scss"

const HealthyVerification = ({
                                 open,
                                 setOpen,
                                 popup = true,
                                 healthy,
                                 verified_at,
                                 healthy_at,
                                 healthyMedia,
                                 verifyMedia,
                                 setVerifyMedia,
                                 verifyStatus,
                                 setSuperVerify,
                                 setHealthyStatus,
                                 setCurrentType,
                                 setCurrentSlug,
                                 setCurrentCallback,
                                 slug,
                                 setHealthyBoosted,
                                 healthyBoosted
                             }) => {

    const {is_super_verified = false} = verifyMedia || {};
    const {token, t} = useLadyService()
    const [superVerifyFile, setSuperVerifyFile] = useState(false)
    const [healthyFile, setHealthyFile] = useState(false)
    const [superVerifyLink, setSuperVerifyFileLink] = useState(null)
    const [healthyLink, setHealthyLink] = useState(null)
    const [healthyFileName, setHealthyFileName] = useState(null)
    const [mediaUploaded, setMediaUploaded] = useState(false)
    const uploadMedia = async () => {
        await uploadSuperVerify();
        const file = healthyFile
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file_name", file.name);
        formData.append("type", 'healthy');
        formData.append('profile', slug);

        return axios.post(baseUrl + `agency/media`, formData, {
            headers: {
                Authorization: token ? `Bearer ${token}` : "",
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            setHealthyLink(response.data.link)
            setHealthyStatus('wait')
            setMediaUploaded(true)
            showToast({message: t('success'), variant: 'success'})
        }).catch(() => {
            showToast({message: t('oops'), variant: 'error'})
        })
    };

    const handleFileSelected = (file, type) => {
        if (type === 'super_verify') {
            setSuperVerifyFile(file)
            const reader = new FileReader();
            reader.onload = function (e) {
                setSuperVerifyFileLink(e.target.result);
            };
            reader.readAsDataURL(file);
        } else if (type === 'healthy') {
            setHealthyFile(file)
            setHealthyFileName(file.name);
            const reader = new FileReader();
            reader.onload = function (e) {
                setHealthyLink(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    }

    const uploadSuperVerify = () => {
        const formData = new FormData();
        formData.append("file", superVerifyFile);
        formData.append("file_name", superVerifyFile.name);
        formData.append("type", 'super_verify');
        formData.append('profile', slug);

        return axios.post(baseUrl + `agency/media`, formData, {
            headers: {
                Authorization: token ? `Bearer ${token}` : "",
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            setSuperVerifyFile(false)
            setSuperVerify(true)
            setSuperVerifyFileLink(response.data.link)
            showToast({message: t('success'), variant: 'success'})
        }).catch(() => {
            showToast({message: t('oops'), variant: 'error'})
        })
    }

    const [dataInfo, setDataInfo] = useState({
        is_super_verified,
        healthy_photo: healthyMedia?.media ? healthyMedia.media : {data: {link: healthyLink, moderated: 0}},
        super_verified_at: is_super_verified ? verified_at : null,
        verified_at,
        verify_photo: {
            data: {link: superVerifyLink, moderated: 0},
            is_super_verified: true,
        },
        healthy_reason: "",
    })

    useEffect(() => {
        setDataInfo({
            is_super_verified: true,
            healthy_photo: healthyFileName ? {
                data: {
                    link: healthyLink,
                    moderated: 0,
                    name: healthyFileName
                }
            } : healthyMedia?.media ? healthyMedia.media : {data: {link: healthyLink, moderated: 0}},
            super_verified_at: is_super_verified ? verified_at : null,
            verified_at,
            verify_photo: {
                data: {link: superVerifyLink, moderated: 0},
                is_super_verified: true,
            },
            healthy_reason: "",
        })
    }, [superVerifyLink, healthyLink, healthyFileName])


    const updateTypeAndSlug = (type) => {
        setCurrentType(type)
        setCurrentSlug(slug)
        setCurrentCallback(() => {
            setHealthyBoosted(true)
        })
    }


    return popup ? (
        <Popup
            open={open}
            setOpen={setOpen}
            clazz={'gap-16 healthy-modal'}
        >
            <VerHealthAgency
                setSuperVerifyFile={setSuperVerifyFile}
                setSuperVerifyFileLink={setSuperVerifyFileLink}
                isHealthBoosted={healthyBoosted}
                setCurrentType={updateTypeAndSlug}
                handleFileSelected={handleFileSelected}
                handleUpload={uploadMedia}
                healthy_at={healthy_at}
                mediaUploaded={mediaUploaded}
                data={dataInfo}
            />
        </Popup>
    ) : (<VerHealthAgency
        setSuperVerifyFile={setSuperVerifyFile}
        setSuperVerifyFileLink={setSuperVerifyFileLink}
        isHealthBoosted={healthyBoosted}
        setCurrentType={updateTypeAndSlug}
        handleFileSelected={handleFileSelected}
        handleUpload={uploadMedia}
        healthy_at={healthy_at}
        mediaUploaded={mediaUploaded}
        data={dataInfo}
    />)
};

export default HealthyVerification;

import React from "react";
import {ButtonLink, Icon} from "@/components/ui";
import useLadyService from "@/services/LadyService";

const InstructionLink = ({clazz, size = 'xs'}) => {
  const {windowWidth, t} = useLadyService();

  return (
    <ButtonLink
      href={`/guide`}
      title={t("guide")}
      size={size}
      clazz={`add-form${
        clazz ? ` ${clazz}` : ""
      } button_outline--green _no-underline`}
    >
      <Icon size={"m"} spritePath={"instruction-cont"}/>
      {windowWidth > 1199.98 ? t("guide") : null}
    </ButtonLink>
  );
}
export default InstructionLink
import React, { useState, useEffect, useRef } from "react";
import { DayPicker } from "react-day-picker";
import { useSelector } from "react-redux";
import { getUserLang } from "@/stores/slices/userSlice";
import { ru, enUS, cs } from "date-fns/locale";
import { isAfter, isBefore, isEqual, subDays } from "date-fns";

import "react-day-picker/dist/style.css";
import './inputInLabel.scss'
const css = `
  .my-selected:not([disabled]) { 
    background-color: #1ca182;
    color: white;
  }
`;

const DateInput = (props) => {
  const {
    isMulti = false,
    clazz,
    onChange,
    children,
    placeholder,
    notBeforeCurDate = true,
    notAfterCurDate = false,
    required,
    register
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(isMulti ? { from: null, to: null } : null);

  const lang = useSelector(getUserLang);

  let locale;

  switch (lang) {
    case "ru": {
      locale = ru;
      break;
    }

    case "cz": {
      locale = cs;
      break;
    }

    default: {
      locale = enUS;
      break;
    }
  }

  const childrenRef = useRef(null);

  const currentDate = new Date();

  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  const formattedDate = `${day}.${month}.${year}`;

  const formatRange = (range) => {
    if (!range.from || !range.to) return "";
    const formatDate = (date) => {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = String(date.getFullYear());

      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + month : month;

      return `${day}.${month}.${year}`;
    };
    return `${formatDate(range.from)} - ${formatDate(range.to)}`;
  };

  const handleDayClick = (date) => {
    if (notBeforeCurDate && isBefore(date, subDays(new Date(), 1))) {
      return;
    }
    if (notAfterCurDate && isAfter(date, subDays(new Date(), 0))) {
      return;
    }
    if (isMulti) {
      const { from, to } = selectedDate;
      if (!from) {
        setSelectedDate({ from: date, to: null });
      } else if (!to && date > from) {
        setSelectedDate({ from, to: date });
        const result = { from, to: date };
        onChange(formatRange(result));
      } else {
        setSelectedDate({ from: date, to: null });
      }
    } else {
      setSelectedDate(date);
      onChange(formatRange(selectedDate));
      setIsOpen(false);
    }
  };

  const handleBodyClick = (event) => {
    event.stopPropagation();
  };

  const handleClickOutside = (event) => {
    if (childrenRef.current && !childrenRef.current.contains(event.target)) {
      setIsOpen(false);
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`input-label${clazz ? ` ${clazz}` : ""}${required ? ` required` : ""}`}
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <label>
        {children}
        <div className={`dropdowndate${isOpen ? " dropdowndate_active" : ""}`}>
          <div
            ref={childrenRef}
            className={`dropdowndate__body`}
            onClick={handleBodyClick}
          >
            <style>{css}</style>
            <DayPicker
              mode={isMulti ? "range" : "single"}
              captionLayout="dropdown-buttons"
              fromYear={2024}
              toYear={2090}
              selected={selectedDate}
              onDayClick={handleDayClick}
              locale={locale}
              modifiersClassNames={{
                selected: "my-selected",
              }}
              modifiers={{
                disabled: (date) => {
                  return (
                    (notBeforeCurDate && isBefore(date, subDays(new Date(), 1))) ||
                    (notAfterCurDate && isAfter(date, subDays(new Date(), 0)))
                  );
                },
              }}
            />
          </div>
        </div>
      </label>
    </div>
  );
};

export default DateInput;

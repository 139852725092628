import { Link } from "react-router-dom";

import img from "../../../assets/img/agency/banner-cover-mob.webp";

import "./partyCard.scss";
import { Icon } from "@/components/ui";
import { useDispatch } from "react-redux";
import { setPartyAccess } from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
const PartyCard = ({ props }) => {
  const { lang } = useLadyService();

  const {
    privat_party,
    description,
    models_count,
    image = img,
    address,
    price,
    date,

    agency_id,
    coordinates,
    end_time,
    id,
    includes,
    name,
    prepayment,
    start_time,
  } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation("translation");

  const View = (props) => (
    <>
      {image && (
        <div title={name} className="agency-card__img">
          <img src={image} alt={name} />
        </div>
      )}

      <div className="agency-card__content">
        <div className="agency-card__heading">
          <div className={"party-card__info"}>
            <span className="party-card__time">
              {!privat_party ? start_time : "******"}

              {!privat_party ? (
                <span className="agency-card__date">{date}</span>
              ) : (
                <span className="agency-card__date">******</span>
              )}
            </span>
            <span className="party-card__address">
              {!privat_party ? address : "******"}

              {/*<span className="party-card__country">*/}
              {/*     <Icon type={'flag'} size={'s'} spritePath={'CZ'}/>*/}
              {/*    /!*{city}*!/*/}
              {/* </span>*/}
            </span>
          </div>

          {name && <span className="title_h3">{name}</span>}
        </div>

        <div className="agency-card__counts">
          <div className="agency-card__count">
            <Icon size={"l"} spritePath={"body2"} />
            <div className="agency-card__questionnaires">
              {/*{models_count} */}
              <span>{t("modeles")}</span>3
            </div>
          </div>
          {price && (
            <span className="p1">{`${t("participation")} - ${price?.substring(
              0,
              price.length - 3
            )}€`}</span>
          )}
        </div>

        {description && <p>{description}</p>}
      </div>
    </>
  );

  switch (privat_party) {
    case true:
      return (
        <div
          className={"agency-card party-card party-card--private"}
          onClick={() => dispatch(setPartyAccess(true))}
        >
          {View()}
        </div>
      );

    default:
      return (
        <Link to={`/party/${id}`} className={"agency-card party-card"}>
          {View()}
        </Link>
      );
  }
};

export default PartyCard;

import useLadyService from "@/services/LadyService";
import { Checkbox, InputInLabel, Loader } from "../../ui";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { makeRequest } from "@/services/makeRequest";

export const SearchModels = ({setDropDownTitle, setSelectedModel, setDropDownOpen, selectedModel}) => {
  const { t } = useLadyService();

  const Res = ({
    isLoading,
    options,
    value,
    handleAddChange,
    search,
    isDataEmpty,
  }) => {
    const { t, setMediaSize } = useLadyService();


    switch (true) {
      case isLoading: {
        return <Loader />;
      }

      case !!options.length: {
        return options.map((i) => {
          const { main_photo, id, name, slug } = i;
            
          return (
            <div key={id} className="agency-block">
              <img src={setMediaSize(main_photo, "xs")} alt="girl" />
              <Link to={`/profile/${slug}`} className="agency-block__link-to">{name}</Link>
              <Checkbox
                border={false}
                checked={!!selectedModel?.slug === slug}
                onChange={() => handleAddChange(main_photo, slug, name)}
              />
            </div>
          );
        });
      }

      case !search: {
        return <span className="w-100 text-center">{t("startname")}</span>;
      }

      case isDataEmpty: {
        return <span className="w-100 text-center">{t("noproffound")}</span>;
      }

      default: {
        return <Loader />;
      }
    }
  };

  const fetchData = useCallback(
    debounce(async ({ searchValue }) => {
      try {
        if (!!searchValue) {
          setIsLoading(true);
          const route = `user/profiles`;
          const method = "GET";
          const payload = {
            limit: 1000,
            search: searchValue,
          };

          const { profiles } = await makeRequest({ route, method, payload });
          setIsDataEmpty(!profiles.length);
          setOptions(profiles);

          setIsLoading(false);
        } else {
          setOptions([]);
          setIsLoading(false);
        }
      } catch (error) {}
    }, 1000),
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [search, setSearch] = useState("");

  const handleAddChange = (img, slug, name) => {
    setDropDownTitle(name)
    setSelectedModel({
        img,
        slug,
        name
    });
    setDropDownOpen(true);
  };

  const handleFilter = useCallback((e) => {
    setIsDataEmpty(false);
    const val = e.currentTarget.value;
    setSearch(val);
  }, []);

  useEffect(() => {
    if (!!search.length) {
      fetchData({ searchValue: search });
    } else {
      fetchData({ searchValue: null });
      setOptions([]);
    }
  }, [fetchData, search]);

  return (
    <div className="agency-block__content">
      <InputInLabel
        placeholder={t("searchmodel")}
        onChange={handleFilter}
        value={search}
        size={"s"}
      />
      <Res
        isLoading={isLoading}
        options={options}
        value={value}
        handleAddChange={handleAddChange}
        search={search}
        isDataEmpty={isDataEmpty}
      />
    </div>
  );
};

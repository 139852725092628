import React, {Fragment, useEffect, useState} from "react";
import {Button, ErrorMessage, Icon, Loader, SelectCountry, Checkbox, Tippy} from "@/components/ui";
import useLadyService from "@/services/LadyService";
import GeoInput from "@/components/ui/input/GeoInput";
import {convertData} from "@/helper/convertData";
import {Map} from "../../createProfile/pageComponent";
import useHandleLocationClick from "@/services/HandleLocationClick";
import showToast from "../../../../toast/Toast";
import {makeRequest} from "@/services/makeRequest";
import {useSelector} from "react-redux";
import {getAddressStatus} from "@/stores/slices/userProfileSlice";

const AddressRow = ({
                               onChange,
                               cities = [],
                               isActive,
                               formCity,
                               setFormCity,
                               selectedCity,
                               setSelectedCity,
                               currentActive,
                               setCurrentActive,
                               setBounds,
                               bounds,
                               activeCountry,
                               setActiveCountry,
                               setIsActive,
                               setMapMarker
                           }) => {
    const {t, userTypeAgency} = useLadyService();

    const userAddress = useSelector(getAddressStatus)
    const currentCity = cities[activeCountry]?.cities?.find((city) => city?.name === selectedCity)
    const [currentAddress, setCurrentAddress] = useState(userAddress?.length > 0 ? {...userAddress} : [{
        address: "",
        city: currentCity?.name || '',
        city_id: currentCity?.id
    }])
    const [primaryAddress, setPrimaryAddress] = useState({})
    const [primaryIndex, setPrimaryIndex] = useState(0)
    useEffect(() => {
        if (currentAddress.length <= 1) {
            setPrimaryAddress({...currentAddress[0]})
        } else if (currentAddress.length) {
            const index = currentAddress.findIndex((item) => item.primary)
            setPrimaryAddress({...currentAddress[index]})
            setPrimaryIndex(index)
        }
    }, [currentAddress])
    useEffect(() => {
        if (userAddress.length) {
            setCurrentAddress([...userAddress])
        }
    }, [userAddress])

    useEffect(() => {
        if (!Array.isArray(currentAddress)) {
            console.error('currentAddress is not an array', currentAddress);
            return;
        }

        const updatedAddress = currentAddress.map((item, index) => ({
            ...item,
            primary: index === primaryIndex,
        }));

        setCurrentAddress(updatedAddress);
        setPrimaryAddress(updatedAddress[primaryIndex]);
        setIsActive(true)
    }, [primaryIndex]);


    const citiesOptions = convertData(cities);
    const [cityError, setCityError] = useState("");
    const [geoError, setGeoError] = useState(false);

    const handleChangeActive = (e) => {
        e.preventDefault();
        setIsActive(true);
    };
    const [isAddressActive, setIsAddressActive] = useState(false);
    const throwError = (e) => {
        if (
          !e.target.classList.contains("icon--location") &&
          !e.target.classList.contains("button--tetriary")
        ) {
            if (!selectedCity) {
                setGeoError(true);

                setTimeout(() => {
                    setGeoError(false);
                }, 2000);
            }
        }
    };

    const handleSubmit = () => {
        const fetchData = async () => {
            try {
                const route = `user`;
                const method = "PUT";
                let payload = {
                    address: currentAddress[0].address,
                    city_id: currentAddress[0].city_id,
                    longitude: currentAddress[0].longitude,
                    latitude: currentAddress[0].latitude
                };
                if (userTypeAgency) {
                    payload = {addresses: {...currentAddress}}
                }

                const result = await makeRequest({route, method, payload});
                if (result) {
                    setIsActive(false);
                    showToast({
                        message: t("success"),
                        variant: "success",
                    });
                }
            } catch (error) {
                const res = error.response;

                // eslint-disable-next-line default-case
                switch (res.status) {
                    case 401: {
                        showToast({
                            message: t("noauth"),
                            variant: "error",
                        });
                        break;
                    }
                    case 422: {
                        showToast({
                            message: t("oops"),
                            variant: "error",
                        });
                        break;
                    }
                }
            }
        };

        fetchData();
    };

    const handleLocationClick = useHandleLocationClick(
      setFormCity,
      setActiveCountry,
      setBounds,
      setCurrentActive,
      setSelectedCity,
      activeCountry,
      cities,
      setIsAddressActive,
      setMapMarker
    );

    const handleChange = (e, id) => {
        const {name} = e;
        onChange(id, name ? "city" : "address", name || e);
    };

    const changeCity = (index, city) => {
        let userAddress = [...currentAddress];
        userAddress[index] = {
            ...userAddress[index],
            city: city.name,
            city_id: city.id,
            address: "",
            latitude: "",
            longitude: "",
        };
        setCurrentAddress(userAddress);
    }
    const setAddress = (newAddress) => {
        let userAddress = [...currentAddress];
        userAddress[primaryIndex] = {
            ...userAddress[primaryIndex],
            address: newAddress.formatted_address,
            latitude: newAddress.geometry.location.lat(),
            longitude: newAddress.geometry.location.lng(),
        };
        setCurrentAddress(userAddress);
        setIsActive(true);
    };
    const removeAddress = (index) => {
        let userAddress = [...currentAddress.slice(0, index), ...currentAddress.slice(index + 1)];
        setCurrentAddress(userAddress);
        setIsActive(true);

    }
    const addAddress = () => {
        const updatedAddresses = [...currentAddress, {
            address: "",
            city: currentCity?.name,
            city_id: currentCity?.id
        }];
        setCurrentAddress(updatedAddresses);
    }
    return (
      <>
          {userTypeAgency && (
            <div className="d-flex justify-sb align-center">
                <h3 className={'m-0'}>{t("youraddress")}</h3>
                <Button
                  size={"s"}
                  clazz={"button_outline--green-accent justify-center float-right"}
                  onClick={addAddress}
                >
                    <Icon spritePath={"plus"} size={"s"}/>

                    {t("addad") + " " + t("fulladdress").split(" ")[1]}
                </Button>
            </div>
          )}

          <div className={`info-address`}>
              {currentAddress.length && currentAddress.map((item, index) => {
                  return (
                        <div key={index} className="info-address__row">
                            {userTypeAgency && (
                                  <Checkbox
                                    checked={primaryIndex === index}
                                    id={index}
                                    title={t("main")}
                                    onClick={() => {
                                        setPrimaryIndex(index);
                                    }}
                                  />
                            )}

                            <SelectCountry
                              data={citiesOptions}
                              name={"create-city"}
                              clazz={`${cityError ? "_error" : ""} size-l-forever justify-start select`}
                              clazzSvg={`select__flag ${formCity?.icon || ""}`}
                              arrowSize={"m"}
                              type={"flag"}
                              placeholder={t("yourcity")}
                              defaultTitle={item.city}
                              subTitle={t("yourcity")}
                              withoutIcon={!item.city}
                              setValue={setSelectedCity}
                              currentActiveIndex={currentActive}
                              setActiveCountry={setActiveCountry}
                              activeCountry={activeCountry}
                              setActive={setCurrentActive}
                              error={cityError}
                              errorMessage={<ErrorMessage message={cityError}/>}
                              onChange={(e) => {
                                  changeCity(index, e)
                              }}
                              setError={setCityError}
                              setIsAddressActive={setIsAddressActive}
                            />

                            <div className={`search filter__map-input ${
                              item.city ? "" : "geo-error"
                            }`}
                                 onClick={(e) => {
                                     if (isActive) {
                                         throwError(e);
                                     }
                                 }}
                            >
                                <span className={"filter__map-title"}>{t("youraddress")}</span>

                                <label
                                  htmlFor="geosuggest__input-create"
                                  className="search__label header__search"
                                >
                                    <GeoInput
                                      value={item.address}
                                      setValue={handleChange}
                                      name={"location-create"}
                                      id="geosuggest__input-create"
                                      clazz={`header__search-input ${geoError ? "_error" : ""}`}
                                      placeholder={t("fulladdress")}
                                      bounds={bounds}
                                      isAgency={true}
                                    />

                                    {geoError && <ErrorMessage message={t("cityfirst")}/>}
                                </label>
                            </div>

                            <Button
                              title={t("chekmyplace")}
                              onClick={handleLocationClick}
                              name={"location"}
                              square={true}
                              size={"l"}
                              clazz={isAddressActive ? "button--tetriary bg-color-500" : "button--tetriary"}
                            >
                                <Icon size={"xl"} spritePath={"location"}/>
                            </Button>

                            {userTypeAgency &&
                            primaryIndex === index ? (
                                <Tippy title={t("adressvisible")}>
                                    <Icon square size={"l"} spritePath={"info"} clazz={"mt-4"}/>
                                </Tippy>
                              ) : (
                                <button
                                  type={'button'}
                                  className={`tarifs-row-trash icon icon-xl icon--trash _red`}
                                  onClick={() => removeAddress(index)}
                                />
                            )}
                        </div>
                  )})}

              <Button
                size={"s"}
                onClick={!isActive ? () => handleChangeActive : () => handleSubmit()}
                disabled={!isActive}
                clazz={"button_outline--green-accent w-160 ml-auto"}
              >
                  {t("save")}
              </Button>

              {(primaryAddress && cities[activeCountry]?.name) ? (
                <Map
                  setMarkerActive={setIsAddressActive}
                  markerActive={isAddressActive}
                  region={cities[activeCountry].name}
                  city={primaryAddress.city}
                  setBounds={setBounds}
                  coords={{lat: primaryAddress.latitude, lng: primaryAddress.longitude}}
                  setAddress={setAddress}
                />
              ) : <Loader height={300} decimal={'px'}/>}
          </div>
      </>
    )
};

export default AddressRow
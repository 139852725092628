import useLadyService from "../../../services/LadyService";
import { useEffect, useState } from "react";
import { userDeclarationEN } from "./locales/en";
import { userDeclarationRU } from "./locales/ru";
import { userDeclarationCZ } from "./locales/cz";
import { Helmet } from "react-helmet";

const ProvisionsPage = () => {
  const { lang, t } = useLadyService();

  const [translate, setTranslate] = useState(userDeclarationEN);

  useEffect(() => {
    switch (lang) {
      case "ru":
        return setTranslate(userDeclarationRU);

      case "cz":
        return setTranslate(userDeclarationCZ);

      default:
        return setTranslate(userDeclarationEN);
    }
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>{t("provisions")}</title>
        <meta name="description" content={t("provisionsdesc")} />
      </Helmet>
      <main>
        <section className="other-page__container">
          <h1 dangerouslySetInnerHTML={{__html:`${translate.h1}`}}/>

          <p>1. {translate.one.text}</p>
          <p>2. {translate.two.text} </p>
          <p>3. {translate.three.text}</p>
          <p>4. {translate.four.text}</p>
          <p>5. {translate.five.text}</p>
          <p dangerouslySetInnerHTML={{__html:`6. ${translate.six.text}`}}/>
          <p dangerouslySetInnerHTML={{__html:`7. ${translate.seven.text}`}}/>
          {translate.seven.list && (
            <ul>
              {translate.seven.list?.map((i) => (
                <li dangerouslySetInnerHTML={{__html:`${i}`}}/>
              ))}
            </ul>
          )}
          <p dangerouslySetInnerHTML={{__html:`8. ${translate.eight.text}`}}/>
          {translate.eight.list && (
            <ul>
              {translate.eight.list?.map((i) => (
                 <li dangerouslySetInnerHTML={{__html:`${i}`}}/>
              ))}
            </ul>
          )}
          <p dangerouslySetInnerHTML={{__html:`9. ${translate.nine.text}`}}/>
          <p dangerouslySetInnerHTML={{__html:`10. ${translate.ten.text}`}}/>
          {translate.eleven && <p> 11. {translate.eleven.text}</p>}
        </section>
      </main>
    </>
  );
};

export default ProvisionsPage;

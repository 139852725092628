import {Button} from "@/components/ui";
import {makeRequest} from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";
import useLadyService from "@/services/LadyService";
import {Popup} from "../index";
import useUserProfileService from "@/services/UserProfileService";
import {getActivateProfileStatus, setActivateProfileStatus} from "@/stores/slices/popupSlice";
import {setProfileStatus} from "@/stores/slices/userProfileSlice";
import {useSelector} from "react-redux";

const ActivateProfilePopup = () => {
  const {t, dispatch} = useLadyService();

  const {profileStatus} = useUserProfileService()

  const open = useSelector(getActivateProfileStatus)

  const handleCloseDelete = () => {
    dispatch(setActivateProfileStatus(false))
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const fetchData = async () => {
      try {
        const route = `user/set-profiles-status`;
        const method = "PUT";
        const payload = {
          status: !profileStatus,
        };

        await makeRequest({route, method, payload});

        dispatch(setProfileStatus((prev) => !prev))

        handleCloseDelete()

        showToast({
          message: t("success"),
          variant: "success",
        });
      } catch (error) {
        dispatch(setProfileStatus((prev) => !prev))
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={handleCloseDelete}
      clazz={"gap-16"}
      id={"activateProfile"}
      container={"div"}
    >
      <h3 className="m-12-24">{t("realywant")}</h3>

      <div className="popup-form__inner text-center">
        <span className={"color-700"}>{t("alladsdeactivate")}</span>
      </div>

      <div className="popup-form__buttons">
        <Button
          size={"l"}
          clazz={"button--green justify-center"}
          onClick={handleCloseDelete}
        >
          {t("cancel")}
        </Button>

        <Button
          size={"l-forever"}
          buttonType={"submit"}
          clazz={"button--error justify-center"}
          onClick={onSubmit}
        >
          {t("yes")}, {t("deactivate")}
        </Button>
      </div>
    </Popup>
  );
};

export default ActivateProfilePopup;

const icons_db = [
  { name: "body" },
  { name: "body2" },
  { name: "body-fill" },
  { name: "burger" },
  { name: "calendar" },
  { name: "call" },
  { name: "call-outline" },
  { name: "check" },
  { name: "chevrone-down" },
  { name: "chevrone-left" },
  { name: "chevrone-right" },
  { name: "chevrone-up" },
  { name: "health-fill" },
  { name: "comment" },
  { name: "dark" },
  { name: "edit" },
  { name: "incognito" },
  { name: "edit-2" },
  { name: "light" },
  { name: "video-play" },
  { name: "video-fill" },
  { name: "verify-fill" },
  { name: "verify" },
  { name: "upload" },
  { name: "escort" },
  { name: "eye" },
  { name: "eye-off" },
  { name: "favorite" },
  { name: "favorite-fill" },
  { name: "film" },
  { name: "home-main" },
  { name: "image" },
  { name: "location" },
  { name: "person" },
  { name: "erocoin" },
  { name: "erocoin-bold" },
  { name: "erocoin-bold-1" },
  { name: "erocoin-four" },
  { name: "erocoin-leg" },
  { name: "currency-erocoin" },
  { name: "filter" },
  { name: "filter2" },
  { name: "girlfriend" },
  { name: "fix" },
  { name: "ing-yang-1" },
  { name: "ing-yang-2" },
  { name: "logout" },
  { name: "map" },
  { name: "minus" },
  { name: "more" },
  { name: "navigation" },
  { name: "notification" },
  { name: "notify" },
  { name: "pants" },
  { name: "payment" },
  { name: "photo" },
  { name: "plus" },
  { name: "plus-1" },
  { name: "profile-add" },
  { name: "profile-card" },
  { name: "promo" },
  { name: "search" },
  { name: "sort" },
  { name: "star-fill" },
  { name: "stat" },
  { name: "statistic" },
  { name: "status" },
  { name: "telegram" },
  { name: "travel" },
  { name: "web-cam" },
  { name: "call-check" },
  { name: "catalog" },
  { name: "diploma-verified" },
  { name: "filter-1" },
  { name: "gender-couple" },
  { name: "gender-female" },
  { name: "gender-male" },
  { name: "gender-trans" },
  { name: "globe" },
  { name: "map-pin" },
  { name: "play" },
  { name: "whatsapp" },
  { name: "party" },
  { name: "volume" },
  { name: "volume-x" },
  { name: "payed" },
  { name: "warning" },
  { name: "wallet-money" },
  { name: "user-id" },
  { name: "user-circle" },
  { name: "undo-left" },
  { name: "triangle" },
  { name: "trash" },
  { name: "time-plan" },
  { name: "star" },
  { name: "small-view" },
  { name: "send" },
  { name: "secure" },
  { name: "save" },
  { name: "18-popup" },
  { name: "mail" },
  { name: "longer-view" },
  { name: "instruction" },
  { name: "info" },
  { name: "import" },
  { name: "geo" },
  { name: "euro" },
  { name: "error" },
  { name: "copy" },
  { name: "close" },
  { name: "change" },
  { name: "cash-up" },
  { name: "cash-down" },
  { name: "camera" },
  { name: "arrow-up-right" },
  { name: "user" },
  { name: "operator" },
  { name: "instruction-cont" },
  { name: "pay-content" },
  { name: "paypal" },
  { name: "casting" },
  { name: "arrow-left" },
  { name: "arrow-right" },
  { name: "bank" },
  { name: "card" },
  { name: "novideo" },
  { name: "video" },
  { name: "bitcoin" },
  { name: "time" },
]

export default icons_db
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@/components/ui";

import "./storiesCircle.scss";
import {AddStoriesPopup} from "../../popups";

const StoriesCircle = ({
                         img,
                         name,
                         age,
                         onClick,
                         edit,
                         clazz,
                         alt,
}) => {

  const [isHover, setIsHover] = useState(false);

  const { t } = useTranslation("translation");

  const title = edit ? t("addstory") : t("modelstories");

  return (
    <>
      <button
        onMouseLeave={() => setIsHover(false)}
        onMouseEnter={() => setIsHover(true)}
        className={`stories-circle__button${clazz ? ` ${clazz}` : ""}${isHover ? " _hover" : ""}`}
        title={title}
        onClick={onClick}
        type={'button'}
      >
        {edit && (
          <Icon
            size={"m"}
            spritePath={"plus-1"}
            clazz={"stories-circle__icon"}
          />
        )}
        <span
          className={`stories-circle__image${
            !img && edit ? " _empty" : ""
          }`}
        >
          {img ? (
            <img src={img} alt={alt} />
          ) : edit ? (
            <>
              <Icon size={"m"} spritePath={"plus-1"} />
              {t("addstory")}
            </>
          ) : null}
        </span>
      </button>

      {!!name && (
        <Link
          onMouseLeave={() => setIsHover(false)}
          onMouseEnter={() => setIsHover(true)}
          className="stories-circle__name hover-line"
        >
          {name}
        </Link>
      )}

      {!!age && (
        <span
          onClick={(e) => e.stopPropagation()}
          onMouseLeave={() => setIsHover(false)}
          onMouseEnter={() => setIsHover(true)}
          className="stories-circle__age"
        >
          {age} {t("years")}
        </span>
      )}
    </>
  );
};

export default StoriesCircle;

import { useSelector } from "react-redux";
import React from "react";
import { ButtonLink, Icon } from "@/components/ui";
import { getFavoriteCounterStatus } from "@/stores/slices/userSlice";
import useLadyService from "@/services/LadyService";

const FavoriteButton = ({ size }) => {
  const favoriteCount = useSelector(getFavoriteCounterStatus);

  const { t } = useLadyService();

  return (
    <ButtonLink
      title={t("favorites")}
      href={`/history`}
      size={size}
      square={true}
      clazz="button--tetriary user__favorite"
    >
      <Icon
        counter={favoriteCount > 0 ? favoriteCount : null}
        size={"l"}
        spritePath={`favorite`}
      />
    </ButtonLink>
  );
};

export default FavoriteButton;

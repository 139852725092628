export const rulesCz = {
  h1: "Všeobecné obchodní podmínky",

  one: {
    title: "VYMEZENÍ POJMŮ",
    p1: "Provozovatelem internetového serveru www.lady4love.com (dále také jako „portál“) a poskytovatelem na něm prezentovaných služeb se pro potřeby těchto všeobecných smluvních a obchodních podmínek (dále také jako „VOP“) rozumí obchodní společnost IT4L, s.r.o., IČ 19470711, sídlem Poděbradská 1026/52, Vysočany, 19000 Praha 9. Základními službami serveru jsou poskytování prostoru pro inzerci erotických služeb s možností on-line komunikace zákazníka s inzerentem a zprostředkování prodeje doplňkových služeb.",
    p2: "Provozovatel není tvůrcem vizuálního a mediálního obsahu zveřejněného na portálu (ve smyslu definice podle 18 USC § 2257). Portál slouží jako platforma pro sdílení obsahu, včetně obsahu pro dospělé. Uživatelé mají možnost nahrávat, sdílet a zobrazovat různé typy obsahu, který je určen výhradně pro dospělé osoby. Pro zajištění, že všichni uživatelé splňují požadavky věku, jsou zavedeny následující postupy:",
    list: [
      "Přístup na portál je povolen pouze uživatelům starším 18 let. Věk je potvrzován čestným prohlášením, přičemž provozovatel portálu si vyhrazuje právo kdykoli požádat o ověření věku prostřednictvím osobního dokladu.",
      "Veškerý erotický obsah prochází před zveřejněním schvalovacím procesem moderace.",
      "Provozovatel vede evidenci věku všech modelů, kteří se na portálu inzerují, a to jen v rámci možnosti vizuálně definovat věk podle zveřejněných foto a videa.",
      "Navíc všem modelkám se doporučuje verifikovat svou identitu pomocí selfie s pasem či jiným identifikačním dokladem.",
    ],

    p3: "Zpracovatelem reklamy se pro potřeby těchto VOP rozumí právnická nebo fyzická osoba, která pro sebe nebo pro jinou právnickou nebo fyzickou osobu zpracovala reklamu, kterou provozovatele serveru zveřejňuje podle objednávky zadavatele.",
    p4: "Zákazníkem se pro potřeby těchto VOP rozumí fyzická osoba starší 18 let, která využije pro svoji osobní potřebu informace zveřejněné v rámci inzerce nebo zakoupí alespoň jednu z nabízených doplňkových služeb. Pro zakoupení nabízených doplňkových služeb je nutná předchozí registrace spojená s otevřením virtuálního účtu zákazníka a zaplacení zálohy na tento virtuální účet.",
    p5: "Reklamou a inzercí se pro potřeby těchto VOP rozumí oznámení, předvedení či jiná prezentace mající za cíl podporu výdělečné činnosti zadavatele, zveřejněná prostřednictvím provozovatele serveru na internetovém serveru www.lady4love.com. Textové i obrazové informace sdělované v každém jednotlivém inzerátu musejí být v souladu s příslušnými ustanoveními českého právního řádu.",
    p6: "Obrazovými materiály se pro účely prezentace rozumějí zejména fotografie a videa osob nabízejících erotické či jiné blízké služby. Provozovatel serveru si vyhrazuje právo rozhodnout o kvalitě fotografií a videí, rovněž si vyhrazuje právo odmítnout jejich zveřejnění; všechny obrazové materiály jsou ověřovány moderátorem.",
    p7: "Každý jeden inzerát může nabízet služby pouze jedné fyzické osoby; materiály týkající se jiné osoby jsou nepřípustné.",
    p8: "Prodejem doplňkových služeb se rozumí zejména skupina služeb spojených s komunikací mezi zadavatelem a zákazníkem (on-line videohovory, rezervace setkání apod.) a poskytování placeného přístupu k videosnímkům s erotickým obsahem. Prodej služeb je přípustný pouze jako doplňková činnost zadavatele reklamy, na prodejce služeb (dále též jen prodejce) se tudíž vztahují veškeré povinnosti zadavatele reklamy.",
  },
  two: {
    title: "SMLUVNÍ PODMÍNKY",
    p1: "Veškeré smluvní o obchodní vztahy mezi zadavatelem a provozovatelem serveru se řídí příslušnými ustanoveními českého právního řádu, zejména potom zák. č. 89/12012 Sb., občanský zákoník v platném znění, zák. č. 90/2012 Sb., o obchodních korporacích, v platném znění, zák. č. 40/1995 Sb., o regulaci reklamy v platném znění, a zák. č. 480/2004 Sb., o některých službách informační společnosti.",
    p2: "Provozovatel serveru poskytuje zadavateli zveřejnění reklamy formou datového prostoru na serveru www.lady4love.com v rozsahu a formátu, jak je vymezen písemnou nebo ústní objednávkou, smlouvou, nebo jiným projevem vůle smluvních stran. Není-li sjednáno jinak, je reklamou míněno uveřejnění textových a obrazových materiálů sloužících k propagaci nabízených služeb ve formě prezentace jednoho podniku nebo jedné osoby, s uvedením jednoho kontaktního telefonního čísla nebo e-mailu. Inzerce trvá až do doby, kdy ji buď její zadavatel prokazatelným způsobem zruší, nebo kdy na virtuálním účtu zadavatele nebude dostatečné množství prostředků na úhradu dalšího týdenního období.",
    p3: "Zpracoval-li reklamu sám zadavatel, odpovídá plně za její obsah. Pokud byla reklama zpracována pro potřeby jiné právnické nebo fyzické osoby, odpovídají za její soulad se zákonem zadavatel a zpracovatel společně a nerozdílně. Obdobné platí i pro zhotovitele a prodejce služeb. Provozovatel serveru nenese žádnou odpovědnost za obsah takovéto reklamy či charakter prodávaných služeb.",
    p4: "Zadavatel bere na vědomí, že provozovatel serveru neodpovídá za obsah reklamy ani za pravdivost údajů obsažených v reklamě a prohlašuje, že tyto údaje jsou pravdivé a obsah reklamy je v souladu se zněním příslušných předpisů českého právního řádu, zejména pak se zněním zák. č. 40/1995 Sb., o regulaci reklamy v platném znění. Toto ujednání se přiměřeně použije i na prodejce a jím prodávané služby. Zadavatel a prodejce jsou srozuměni s tím, že nesou plnou odpovědnost za škody, které obsahem reklamy či doplňkových služeb případně způsobí provozovateli serveru či zákazníkovi.",
    p5: "Zadavatel výslovně prohlašuje, že disponuje všemi potřebnými právy k užívání ochranných známek a/nebo autorských práv k veškerým obrazovým a/nebo textovým materiálům použitým v objednané reklamě. Toto ujednání se přiměřeně použije i na prodejce a prodávané služby.",
    p6: "Zadavatel prohlašuje, že je mu známo, že ve smyslu ust. § 2 odst. 3 zák. č. 40/1995 Sb. nesmí být reklama v rozporu s dobrými mravy, zejména nesmí obsahovat jakoukoliv diskriminaci z důvodů rasy, pohlaví nebo národnosti nebo napadat náboženské nebo národnostní cítění, ohrožovat obecně nepřijatelným způsobem mravnost, snižovat lidskou důstojnost, obsahovat prvky pornografie, násilí nebo prvky využívající motivu strachu, a že reklama nesmí napadat politické přesvědčení. Toto ujednání se přiměřeně použije i na prodejce a prodávané služby. V případě porušení uvedené povinnosti bude provozovatel serveru postupovat podle zákona, zejména reklamu odstraní a porušení zákona oznámí příslušným orgánům. Náklady s tím spojené jdou k tíži zadavatele.",
    p7: "Zadavatel výslovně prohlašuje, že všechny osoby případně na reklamě nebo v jejím rámci vyobrazené či vystupující jsou starší osmnácti let. Toto ujednání se přiměřeně použije i na prodejce a jím prodávané služby.",
    p8: "Zadavatel je povinen na žádost provozovatele serveru prokázat svoji totožnost a zmocněnec zadavatele i své oprávnění jednat za zadavatele. Provozovatel serveru je povinen na výzvu orgánů finančního dozoru pro účely správního řízení a orgánů činných v trestním řízení pro účely trestního řízení sdělit údaje o zadavateli, zpracovateli nebo prodejci.",
    p9: "Zadavatel se zavazuje, že nahradí provozovateli serveru případnou škodu vzniklou uveřejněním reklamy neodpovídající všeobecným požadavkům. Toto ujednání se přiměřeně použije i na prodejce a jím prodávané služby.",
    p10: "Provozovatel serveru má právo zveřejnění reklamy a prodej služeb odmítnout nebo s okamžitou platností ukončit, a to i bez uvedení důvodu.  ",
    p11: "Provozovatel serveru má právo použít textové i obrazové materiály zadavatele či prodejce na svých dalších marketingových kanálech, pokud tím hrubě nepoškodí oprávněné zájmy zadavatele či prodejce.",
    p12: "Zákazník má právo využít placených služeb zprostředkovaných provozovatelem serveru, zejména možnost on-line videohovoru s vybraným poskytovatelem služby, možnost rezervace osobní schůzky s vybraným poskytovatelem služby či možnost uspořádání setkání s více poskytovateli služeb za účelem výběru jednoho pro čerpání jeho služeb, získání pozvánky na soukromé párty pořádané zadavatelem či prohlížení obsahu erotických videí. Zákazník těchto placených služeb má právo průběh poskytované služby zhodnotit formou recenze a poskytovatel služby je povinen takovou recenzi zveřejnit.",
    p13: "Provozovatel serveru nabízí zadavatelům, prodejcům i zákazníkům další služby, zejména takové, které zvyšují pohodlí při využívání serveru a bezpečnost při poskytování a využívání inzerovaných služeb. Jde například o zveřejňování dat posledních zdravotních prohlídek nebo o ochranu zadavatelů zákazem přístupu k jejich materiálům z vybraných zemí; provozovatel serveru bude další služby postupně vyvíjet a nabízet tak, jak se bude vyjevovat jejich potřeba, budou-li technicky či ekonomicky realizovatelné.",
    p14: "Provozovatel serveru zaručuje mimořádně vysoký stupeň anonymity zadavatelů, prodejců i zákazníků. Provozovatel serveru získává osobní údaje jen v nezbytném rozsahu pro zajištění kvality poskytovaných služeb, tyto údaje ale žádným způsobem nezveřejňuje (s výjimkou údajů nezbytných pro vzájemnou komunikaci zadavatelů a zákazníků, např. zadaného telefonního čísla či e-mailové adresy) a uchovává na zabezpečeném úložišti. Veškeré získané údaje zlikviduje ihned, jakmile zanikne potřeba jejich uchování. K těmto údajům umožní přístup jen oprávněným státním orgánům a osobám, kterých se údaje týkají (subjektům údajů).",
  },
  three: {
    title: "PLATBY",
    p1: "Za účelem eliminace problémů s různými měnami, rozdíly kurů v čase atd. provozovatel serveru vytvořil virtuální měnu Erocoin. Veškeré platby se provádějí v této virtuální měně, a to odepsáním z virtuálního účtu plátce a připsáním na virtuální účet příjemce. Veškeré platby provozovateli serveru musejí být uhrazeny nejpozději před poskytnutím objednané služby; pokud plátce nebude mít na svém virtuálním účtu dostatek Erocoinů, inzerce nebude zveřejněna a požadovaná služba nebude provedena.",
    p2: "Platby došlé na virtuální účet zadavatele, prodejce či zákazníka jsou vedeny jako zálohové na budoucí úhrady provozovateli serveru a zaúčtovány budou až v rámci těchto úhrad. Každý zadavatel, prodejce či zákazník má u svého virtuálního účtu k dispozici i přehled obratů na něm za poslední tři roky.",
    p3: "Tržby za doplňkové služby, připsané na virtuální účet zadavatele (prodejce), se použijí jako zvýšení záloh pro budoucí úhrady.",
    p4: "Platby na vlastní virtuální účet a výplaty z něj, které se neuskuteční v Erocoinech, se provádějí v EUR, USD nebo v měně státu, kde či kam se platba provádí (dále též jen reálné měny), a to bankovním převodem, platební kartou či jiným zákonným způsobem. Směna Erocoinů na reálné měny a obráceně se provede dle zveřejněného kursu automaticky k okamžiku, kdy platba reálné měny dojde na virtuální účet či když z něj bude odcházet. Výplata v měně státu, kam se platba provádí, může být omezena zákonem či podmínkami zúčastněné platební instituce; v takovém případě se platba provede v měně, kterou je příjemce platby schopen přijmout. Platby reálnými měnami na cizí virtuální účet jsou vyloučeny.",
    p5: "V případě, že provozovatel serveru odmítne zveřejnit již zaplacenou inzerci, vrátí platbu v plné výši na virtuální účet zadavatele. V případě, že zadavatel v průběhu inzertního období zruší již zaplacenou inzerci, se platba nevrací.",
    p6: "V případě, že zadavatel či zákazník nevyužije zaplacenou zálohu, provozovatel serveru nespotřebovaný zbytek zálohy převede jejímu plátci, sníženou o náklady platby, a to na základě prokazatelné žádosti zadavatele či zákazníka. O výplatu z virtuálního účtu lze žádat pouze jednou týdně.",
    p7: "Server nesmí být zneužit k jakékoliv trestné činnosti. Z preventivních důvodů proto jeho provozovatel provádí kontroly, zda některé platby nepocházejí z trestné činnosti či nejsou používány k páchání trestné činnosti, například formou kuplířství. V případě podezření, že některá z plateb případně souvisí s trestnou činností, provozovatel serveru bude postupovat podle všeobecně platných právních předpisů proti praní špinavých peněz.",
    p8: 'Replenishment purchases of Erocoins (subscription to auto-replenishment of the balance) using a payment card can be made automatically, for the same amount once a month. An order for subscription can be placed when replenishing the balance, and the subscription can be disabled at any time and for free in the "My Balance" section.',
  },
  four: {
    title: "OCHRANA PRÁV TŘETÍCH OSOB",
    p1: "Všem osobám, jakkoliv využívajícím služeb serveru, náležejí veškerá práva na ochranu jejich osobnosti a na ochranu jejich vlastnictví. Těmito osobami se zejména, nikoliv výlučně, rozumějí fyzické i právnické osoby inzerující své služby na serveru, osoby zobrazené na fotografiích nebo videozáznamech umístěných z jakéhokoliv důvodu na serveru, autoři jakýchkoliv textů zveřejněných na serveru i zákazníci serveru. Stejná ochrana náleží i osobám, které služeb serveru nevyužívají, ale jejichž osobnostní, autorská či jiná práva byla zneužita některým z uživatelů služeb serveru.",
    p2: "Provozovatel serveru činí veškeré kroky, které lze od něj rozumně požadovat, zejména sám aktivně provádí pravidelné i namátkové kontroly, zda nedochází k porušování práv třetích osob, dále přijímá oznámení o podezření na porušování práv třetích osob, a v případě zjištěného porušení práv třetích osob bez zbytečného odkladu přijímá účinná opatření, zejména aby zamezil (a) zneužívání osobních údajů, (b) sexuálnímu zneužívání inzerentů a osob zobrazených na fotografiích nebo videozáznamech s erotickým obsahem, přičemž u osob mladších než 18 let se sexuálním zneužitím rozumí i „pouhá“ možnost zobrazení takových fotografií či videozáznamů, (c) porušování práv k duševnímu vlastnictví, zejm. autorských práv k fotografiím, videozáznamům či textům, práv k ochranným známkám atd.",
    p3: "Provozovatel serveru při odhalování porušování práv třetích osob spolupracuje s příslušnými státními orgány (policie, orgány ochrany dětí a mladistvých) i nestátními organizacemi.",
    p4: "Každý, kdo má jakékoliv podezření, že ve smyslu bodu 4.1. došlo k porušení práv na ochranu osobnosti nebo na ochranu vlastnictví, může toto podezření oznámit písemně poštou na adresu IT4L s.r.o., Poděbradská 1026/52, 19000 Praha 9, Czech Republic, elektronicky na e-mail info@lady4love.com, případně telefonicky na číslo +420774231617, a to v českém nebo anglickém jazyku. Oznámení musí obsahovat: (a) identifikaci práva, které je údajně porušeno, například identifikaci chráněného autorského díla, identifikaci chráněného osobního údaje apod., (b) dostatečnou identifikaci materiálu, který má být odstraněn nebo k němuž má být zamezen přístup, včetně informace, která provozovateli serveru umožní materiál nalézt, (c) informaci o možnostech komunikace se stěžovatelem, zejména jeho jméno, adresu, telefonní číslo a e-mail, (d) prohlášení, že stěžovatel v dobré víře věří, že zneužití osobnostních či vlastnických práv způsobem, na který si stěžuje, není povoleno držitelem práv, jeho zástupcem nebo zákonem, (e) prohlášení, že informace v oznámení jsou tak přesné, jak od stěžovatele lze oprávněně požadovat a (f) fyzický nebo elektronický podpis stěžovatele. Je-li podstatou oznámení porušení autorského či obdobného majetkového práva, oznámení musí obsahovat také prohlášení, že stěžovatel je oprávněn jednat jménem držitele práva, které je údajně porušeno, pokud sám není jeho oprávněným vlastníkem.",
    p5: "Provozovatel portálu  obdobně postupuje i v případech, kdy stěžovatel oznámí nejen podezření na konkrétní porušení zákona, ale i v případech, kdy stěžovatel označí některý obsah jako nevhodný (obtěžující, urážlivý, pohoršující apod.) a písemně odůvodní, v čem nevhodnost spatřuje.",
    p6: "Provozovatel portálu  uchovává veškeré údaje o zadavatelích reklamy a o jejím obsahu, včetně údajů o prodejcích doplňkových služeb a o jejich obsahu, po dobu stanovenou zákonem, nejméně však po dobu, po kterou se lze domáhat příslušných práv u soudu. ",
    p7: "Ačkoli provozovatel portálu uplatňuje maximální úsilí k zajištění souladu s platnými právními předpisy, není možné garantovat 100% přesnost kontrol. Pokud je obsah označen jako nezákonný, urážlivý, obtěžující nebo škodlivý, bude bezodkladně odstraněn z portálu. Uživatelé, kteří narazí na obsah, který je v rozporu s pravidly portálu, jsou vyzváni, aby jej označili jako nevhodný prostřednictvím tlačítka „Podat stížnost“ na stránce příslušné modelky nebo kontaktovali podporu uživatelů.",
  },
  five: {
    title: "USTANOVENÍ ZÁVĚREČNÁ",
    p1: "Veškerou komunikaci s provozovatelem serveru lze provádět zejména prostřednictvím jeho datové schránky skvp3cu nebo prostřednictvím komunikačních kanálů vytvořených za tímto účelem přímo na stránce www.lady4love.com. Kontakty pro přijímání a řešení stížností a reklamací, pro přijímání podezření na nelegální jednání provozovatele serveru, zadavatelů, prodejců či zákazníků a pro přijímání a řešení dalších sdělení jsou: telefon: +420 774231617, e-mail: info@lady4love.com.",
    p2: "Zadavatel, prodejce či zákazník tímto prohlašuje, že se s těmito VOP seznámil a jejich obsahu zcela a úplně porozuměl a zavazuje se, že se jimi bude řídit po celou dobu jakéhokoliv obchodního vztahu s provozovatelem serveru.",
  },
};

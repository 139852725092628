import {Icon, ShowMoreText, Button} from "@/components/ui";
import React, {Fragment} from "react";
import bodyShapeLight from "../../../../../assets/img/body_shape-light.svg";
import bodyShape from "../../../../../assets/img/body_shape.svg";
import useLadyService from "@/services/LadyService";
import ModelIcons from "../icons/ModelIcons";
import EditBlock from "../editBlock/EditBlock";

const ModelInfoContent = ({props, setOpen, edit}) => {
    const {
        healthy_at,
        verified_at,
        is_individual,
        is_new,
        tag_bdsm,
        porn_star,
        description,
        height,
        weight,
        penis_size,
        gender,
        breast,
        have_glasses,
        have_lanses,
        lips,
        eyes,
        smoking,
        nationality,
        skin_color,
        tatoo,
        piercing,
        hair,
        hair_length,
        intim_hairstyle,
        perfume,
        languages,
        hips,
        waist,
        shoulders,
    } = props;

    const {theme, t, lang} = useLadyService();
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    return (
        <>
            {!!edit && (
                <EditBlock
                    title={t("params").split(" ")[0]}
                    onClick={() => setOpen(true)}
                    clazz={'align-center'}
                />
            )}

            <ModelIcons
                props={{
                    healthy_at,
                    verified_at,
                    is_individual,
                    is_new,
                    tag_bdsm,
                    porn_star,
                }}
            />
            {!isJsdom ? (<>{!!description && <ShowMoreText>{description}</ShowMoreText>}</>) : (<>{description}</>)}

            <div className="model__params">
                {!!height ? (
                    <span className={"model__param"}>
            {t("height")}{" "}
                        <span>
              {height} {t("cm")}
            </span>
          </span>
                ) : null}
                {!!weight ? (
                    <span className={"model__param"}>
            {t("weight")}{" "}
                        <span>
              {weight} {t("kg")}
            </span>
          </span>
                ) : null}
                {!!penis_size && (gender === "male" || gender === "trance") ? (
                    <span className={"model__param"}>
            {t("penissize")}{" "}
                        <span>
              {penis_size} {t("cm")}
            </span>
          </span>
                ) : null}
                {!!breast && (
                    <span className={"model__param"}>
            {t("breastsize")} <span>{breast}</span>
          </span>
                )}
                {!!have_glasses && (
                    <span className={"model__param"}>
            {t("glasses")} <span>{t("yes")}</span>
          </span>
                )}
                {!!have_lanses && (
                    <span className={"model__param"}>
            {t("lenses")} <span>{t("yes")}</span>
          </span>
                )}
                {!!lips ? (
                    <span className={"model__param"}>
            {t("lips")} <span>{t(lips)}</span>
          </span>
                ) : null}
                {!!eyes && (
                    <span className={"model__param"}>
            {t("eyes")} <span>{t(eyes)}</span>
          </span>
                )}
                {!!smoking && (
                    <span className={"model__param"}>
            {t("smoking")}{" "}
                        <span>{smoking === "regularly" ? t("rarely") : t(smoking)}</span>
          </span>
                )}
                {!!nationality && (
                    <span className={"model__param"}>
            {t("nationality")} <span>{t(nationality)}</span>
          </span>
                )}
                {!!skin_color && (
                    <span className={"model__param"}>
            {t("ethnicity")} <span>{t(skin_color)}</span>
          </span>
                )}
                {!!tatoo && (
                    <span className={"model__param"}>
            {t("tattoos")} <span>{t(tatoo)}</span>
          </span>
                )}
                {!!piercing && (
                    <span className={"model__param"}>
            {t("piercing")} <span>{t(piercing)}</span>
          </span>
                )}
                {(!!hair || !!hair_length) && (
                    <span className={"model__param"}>
            {t("hair")}{" "}
                        <span>
              {hair === "blonde" ? `${t("blond")},` : hair && t(hair)}{" "}
                            {hair_length && t(hair_length)}
            </span>
          </span>
                )}
                {!!intim_hairstyle && (
                    <span className={"model__param"}>
            {t("pubichair")} <span>{t(intim_hairstyle)}</span>
          </span>
                )}
                {!!perfume && (
                    <span className={"model__param"}>
            {t("myperfume")} <span>{perfume}</span>
          </span>
                )}
            </div>

            <div className={"model__info-bottom"}>
                <div className={"model__param"}>
                    {t("ispeak") + ":"}
                    <div className="girl-card__speak">
                        {!!languages.length &&
                            languages.map((lang, index) => {
                                const key = Object.keys(lang)[0];

                                return (
                                    <div className={"model__lang"} key={index}>
                                        <Icon
                                            type={"flag"}
                                            spritePath={lang[key].code.toUpperCase()}
                                        />
                                        {lang[key].name}
                                    </div>
                                );
                            })}
                    </div>
                </div>

                {!!hips && !!waist && !!shoulders ? (
                    <div className="body-shape">
                        <span className="body-shape--shoulders">{shoulders}</span>
                        <span className="body-shape--waist">{waist}</span>
                        <span className="body-shape--hips">{hips}</span>
                        <img
                            className={"img-contain"}
                            src={theme === "light" ? bodyShapeLight : bodyShape}
                            alt=""
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default ModelInfoContent;

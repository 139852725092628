import {useTranslation} from "react-i18next";

const Time = (props) => {
    const {seconds} = props
    const { t } = useTranslation('translation');
    const time = () => {
        let h = Math.trunc(seconds / 3600) + "";
        let m = Math.trunc((seconds % 3600) / 60) + "";
        if (h > 0) {
            return `${h.padStart(2, '0')} ${t('h')} ${m.padStart(2, '0')} ${t('m')}`;
        }

        return `${m.padStart(2, '0')} ${t('m')}`;
    }

    return (<>{time()}</>)
}

export default Time
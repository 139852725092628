import React from "react";
import ContentLoader from "react-content-loader";
import useLadyService from "@/services/LadyService";

const CustomSkeleton = ({ height }) => {
  const { theme } = useLadyService();

  return (
    <ContentLoader
      speed={2}
      height={`${height}px`}
      backgroundColor={theme === "light" ? "#d5dddb" : "#adadad"}
    >
      <rect rx="12" ry="12" width="100%" height={`${height}`} />
    </ContentLoader>
  );
};

export default CustomSkeleton;

export const privacyPolicyEn = {
  h1: 'Privacy policy',
  p1: 'This Privacy Policy (hereinafter referred to as "Policy") is an integral part of the commercial terms at IT4L LLC, OGRN 19470711, registered at Podebradska 1026/52, Vysocany, 19000 Prague, 9 (hereinafter referred to as "IT4L").',
  p2: 'IT4L values protection of personal data in accordance with current legislation, in particular the Law No. 110/2019 on personal data processing, which includes relevant regulations of the European Union, namely Directive 2016/680 of the European Parliament and the Board for Protection of Individuals and General Regulation 2016/679 of the European Parliament and Personal Data Protection Board.',
  p3: 'In addition to current legislation, IT4L provides an extremely high degree of anonymity to all persons using services of the Internet server www.lady4love.com (hereinafter referred to as the "server"). In particular, this includes provision of space for advertising adult services with possibility of online communication between a client and advertiser and mediation in sale of additional services.',
  p4: 'This implies that IT4L processes personal data of adult service advertisers, as well as sellers and buyers of additional services.',
  p5: 'IT4L collects personal data only to the extent necessary to ensure quality of services provided and to fulfill its legal obligations, this data is not provided to third parties, except for authorized government agencies. IT4L discloses or individually provides only vital contact information so that advertisers, sellers and customers can start communicating with each other (usually only a phone number or email address). Name or alias may be used to address to server users.',
  p6: 'This policy applies to all personal data processed by IT4L on the aforementioned server, based on the fulfillment of contractual arrangements, legal obligations, legitimate interests or provided consent. The policy describes how IT4L uses and protects personal data.',
  p7: 'IT4L may amend this policy at any time by posting a revised version on this server, and such changes will take effect from the publication date.',

  one: {
    title: 'ADMINISTRATOR AND CONTACT DETAILS',
    p1: 'The operator of personal data is IT4L.',
    p2: `All notifications of data protection and violations of rights should be sent by phone +420 774231617 or via e-mail: info@lady4love.com.`,
  },
  two: {
    title: 'PERSONAL DATA PROCESSED BY IT4L',
    p1: 'Personal data refers to any information related to an identified or identifiable individual. An identifiable individual is a person who can be identified directly or indirectly, in particular by reference to an identifier such as a name, identification number, location data, network identifier, or to one or more factors specific to physical, physiological, genetic, psychological, economic, cultural, or social personality of that individual.',
    p2: 'In particular, the company processes following personal data: phone number, email address, telegram, WhatsApp, face photo, personal ID photo, current address, photocopies of medical tests, website. Some of the above personal data is collected and processed only for certain services. IT4L receives personal data only from individuals who voluntarily provide it; the company may also verify data obtained this way from other sources (in particular, from the Internet).',
    p3: 'Cookies and Internet beacons help IT4L to provide its services. For a more detailed definition, types and purposes of their use by IT4L, please refer to the policy below.',
  },
  three: {
    title: 'PURPOSES OF PERSONAL DATA PROCESSING',
    p1: 'IT4L processes personal data for the following purposes:',
    list: [
      "for pre-contract negotiations;",
      "to fulfill contractual arrangements (arrangements between you and IT4L, which arose mainly as a result of adding and publishing advertisements, using additional services or registering as a service user);",
      "to administer user accounts (i.e., in particular, to fulfill your service request, create your account, administer your account, and ensure your access to the service and its updates);",
      "to manage trusted funds (storing Erocoin virtual currency or other currencies in a virtual account, reporting use of funds);",
      "to contact you to resolve issues related to use of services or the server;",
      "to send you information about the services (i.e. confirmations, notifications, updates, support and administrative messages);",
      "to display ads according to your interests (in particular, usage of filters to search for ads with suitable content);",
      "to fulfill IT4L's legal obligations (arising from the Civil Code, the law on trade licensing, the law on restriction of cash payments, the law on certain measures to prevent legalization of revenue from criminal activity, relevant tax legislation, etc.).",
    ],
    p2: 'IT4L is not able to conduct pre-contractual negotiations, conclude a contract or fulfill it without provision of personal data. As a rule, contracts are concluded remotely on the relevant server pages; this does not exclude possibility of concluding a contract in another way (for example, by payment).'
  },
  four: {
    title: 'CONSENT',
    p1: 'IT4L processes only certain personal data and for purposes that do not require separate consent to their processing.',
    p2: 'The only exception to this provision is processing and disclosure of certain medical data; by providing this data to IT4L, the person providing medical data agrees to its processing and disclosure.',
    p3: 'Consent for personal data processing given to IT4L may be withdrawn at any time by phone +420 774231617 or via e-mail: info@lady4love.com; use your first name, last name and address for identification. Withdrawal of consent does not affect validity of processing based on consent given prior to its withdrawal. Withdrawal of consent will result in termination of service provision to a certain individual.'
  },
  five: {
    title: 'PERIOD OF PERSONAL DATA PROCESSING',
    p1: 'Personal data will be processed by IT4L only for the period strictly necessary to perform ordered services, in particular, for duration of advertising (in case of advertisers or additional service providers) or for registration period on the server (in case of customers). Processing of personal data cannot be terminated until financial obligations between IT4L and interested party are properly and fully settled. This does not affect IT4L\'s obligation to store personal data for the period prescribed by law.',
    p2: 'Upon expiration of the personal data storage period, IT4L will delete personal data.',
  },
  six: {
    title: 'PROVISION OF PERSONAL DATA TO THIRD PARTIES',
    p1: "IT4L provides personal data only to its employees and only to the extent necessary to fulfill IT4L's obligations (for example, accountants, programmers, etc.). These third parties are bound by the same data protection obligations as IT4L.",
    p2: 'IT4L does not disclose your personal data to third parties other than those mentioned above without your consent, nor does it transfer it to other persons or unaffiliated companies, except for the following cases:',
    list: [
      'compliance with or in response to legal requirements;',
      "protection of IT4L's rights and property, its representatives, users and others, in order to comply with agreed contracts and, in emergency cases, to ensure personal safety of IT4L, users of its services or any other person;",
      'due to or during any merger, sale of company assets, financing or acquisition of the entire IT4L business or its part by another company.'
    ],
    p3: 'Without your consent, IT4L will not transfer your personal data to a third country or an international organization, except for cases requiring fulfillment of the contract between you and IT4L, or such an obligation does not arise from IT4L by law.',
    p4: 'IT4L does not provide any personal data, collected through the server or during provision of services, to third parties for the purpose of direct marketing.',
  },
  seven: {
    title: 'RIGHTS OF THE DATA SUBJECT',
    p1: 'Any individual whose personal data is processed by IT4L has the following rights, which can be exercised at any time. This refers to:',
    list: [
      "the right to access your personal data (i.e. the right to receive information about whether this data is being processed and, if so, the right to access it);",
      "the right to correct personal data (i.e. the right to request correction if it becomes known that IT4L processes inaccurate or false data);",
      "the right to demand explanation (i.e. in case of suspicion that processing of personal data violates confidentiality of personal life or processing violates the law);",
      "the right to restrict processing of personal data (i.e. the right to demand a temporary restriction on personal data processing);",
      "the right to delete personal data (i.e. when personal data is no longer necessary for the purposes requiring its processing);",
      "the right to protest against personal data processing (IT4L must prove the existence of legitimate grounds for processing personal data that exceed interests, rights and freedoms of an affected data subject);",
      "the right to transfer data (i.e. the right to require data provision by IT4L to a third party in a manner that allows further processing);",
      "the right to withdraw consent to personal data processing at any time;",
      "the right to file a complaint with the Department of personal data security (i.e., in particular, if the subject believes that his/her right to personal data protection has been violated and IT4L has not eliminated the violation after the request)."
    ]
  },
  eight: {
    title: 'SECURITY MEASURES AND DISCLOSURE OF PERSONAL DATA',
    p1: 'The main tool for personal data protection is the fact that IT4L processes only a minimum amount of personal data in order to be able to provide its services at a high level, while maintaining anonymity of service providers and their customers as much as possible. As a rule, processed personal data is not sufficient to uniquely identify data subjects or such identification is excessively complex.',
    p2: 'IT4L stores personal data in accordance with the highest security standards, using a wide range of security measures, including encryption and authentication tools, to prevent unauthorized access, modification, disclosure or destruction of personal data and maintain its confidentiality. Personal data is available only to a limited number of persons who are required to keep it confidential.',
  },
  nine: {
    title: 'COOKIES AND INTERNET BEACONS',
    p1: 'In order to provide services adjusted to needs of server users, IT4L uses cookies (session ID cookies and persistent cookies) to store and periodically track server visits. IT4L uses cookies to simplify server navigation. When you close your browser, the session ID cookie expires and the persistent cookie remains on your hard drive for a longer period of time. IT4L determines whether JavaScript is enabled in your browser for facilitated view of the portal. Most Internet browsers are configured to automatically accept cookies.',
    p2 : 'IT4L uses cookies only to the extent necessary for smooth provision of services, as well as for smooth search and possible use of these services. IT4L does not transfer any data obtained through cookies to third parties, for example, for personalized advertising.'
  }
}
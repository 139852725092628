import Geosuggest from "@ubilabs/react-geosuggest";

import { useDispatch, useSelector } from "react-redux";

import "./input.scss";
import {
  setAddressValue,
  setRadiusCoords,
  toggleSelected,
} from "@/stores/slices/filterSlice";
import { setRadiusCoordsCount } from "@/stores/slices/counterFilterSlice";
import {
  setOneProfileAddress,
  setOneProfileCoords,
  getOneProfileCoords,
} from "@/stores/slices/oneProfileSlice";
import { getGmapsApiReady } from "../../../stores/slices/mapModelSlice";

const GeoInput = ({
  name,
  value,
  clazz,
  placeholder,
  register,
  setValue,
  isAgency = false,
  disabled
}) => {
  const dispatch = useDispatch();

  const apiReady = useSelector(getGmapsApiReady);

  const onSuggestSelect = (suggest) => {
    if (suggest) {
      if (name === "location" || name === "address") {
        dispatch(setAddressValue(suggest.description));
        dispatch(
          toggleSelected({
            value: suggest.description,
            name: "radius",
          })
        );

        dispatch(
          setRadiusCoords({
            latitude: suggest.location.lat,
            longitude: suggest.location.lng,
          })
        );
        dispatch(
          setRadiusCoordsCount({
            latitude: suggest.location.lat,
            longitude: suggest.location.lng,
          })
        );

        !!setValue && setValue(name, suggest.description);
      }
      if (name === "location-create") {
        dispatch(setOneProfileAddress(suggest.description));
        dispatch(
          setOneProfileCoords({
            latitude: suggest.location.lat,
            longitude: suggest.location.lng,
          })
        );
        !!setValue && setValue(name, suggest.description);
      }
    }
  };

  const onInputChange = (userInput) => {
    if (isAgency) {
      !!setValue && setValue(userInput);
    } else {
      if (!userInput) {
        !!setValue && setValue(name, null);
      }
    }
  };

  return (
    typeof window !== "undefined" &&
    !!apiReady && (
      <Geosuggest
        inputProps={!!register && { ...register(name) }}
        onChange={onInputChange}
        disabled={disabled}
        initialValue={value}
        inputClassName={`input ${clazz ? clazz : ""}`}
        placeholder={placeholder}
        onSuggestSelect={onSuggestSelect}
        // location={new window.google.maps.LatLng(coordsMap.lat, coordsMap.lng)}
        // radius={20}
      />
    )
  );
};

export default GeoInput;

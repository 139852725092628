import {fetcher} from "./apiFetch";
import showToast from "../components/toast/Toast";

export const makeRequest = async ({route, method = "GET", payload = {}, t = false}) => {
    return await fetcher([route, method, payload]).catch((error) => {
        const res = error.response;
        if (t) {
            switch (res?.status) {
                case 401: {
                    showToast({
                        message: t("noauth"),
                        variant: "error",
                    });
                    break;
                }
                case 403: {
                    showToast({
                        message: t("noAuthAction"),
                        variant: "error",
                    });
                    break;
                }
                case 422: {
                    showToast({
                        message: t("oops"),
                        variant: "error",
                    });
                    break;
                }
            }
        }
    });
};

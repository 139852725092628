import useLadyService from "@/services/LadyService";
import useTitle from "@/hooks/useTitle";
import AccountCustomer from "./customer/AccountCustomer";
import {Loader} from "../../ui";
import AccountAgency from "./agency/AccountAgency";
import AccountModel from "./model/AccountModel";

import './accountPage.scss'
import useCheckAccess from "../../../hooks/useCheckAccess";
const AccountPage = () => {
  const { t, userType} = useLadyService();
  const {hasAccess, AccessTitle} = useCheckAccess('agencyprofileman')


  const checkUserType = () => {
    switch (userType){
      case 'customer': return <AccountCustomer/>

      case 'indi': return <AccountModel/>

      case 'agency': return <AccountAgency/>

      default: return <Loader height={100} decimal={'%'}/>
    }
  }

  useTitle(t("profuser") + " - Lady4Love");

  return !hasAccess ? AccessTitle : (
    <main className={"container w-100"}>
      <h1>{t("profuser")}</h1>

      {checkUserType()}
    </main>
  );
};

export default AccountPage;

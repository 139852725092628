export const privacyPolicyCz = {
  h1: 'Zásady ochrany osobních údajů',
  p1: 'Tyto zásady ochrany osobních údajů (dále jen "Zásady") jsou nedílnou součástí obchodních podmínek společnosti IT4L, s.r.o., IČ 19470711, sídlem Poděbradská 1026/52, Vysočany, 19000 Praha 9 (dále jen „společnost IT4L“).',
  p2: 'Společnost IT4L dbá na ochranu osobních údajů v souladu s platnou a účinnou legislativou, kterou je zejména Zákon o zpracování osobních údajů č. 110/2019 Sb., který zapracovává příslušné předpisy Evropské unie, a to Směrnice Evropského parlamentu a Rady o ochraně fyzických osob 2016/680 a Obecné nařízení Evropského parlamentu a Rady o ochraně osobních údajů 2016/679.',
  p3: 'Společnost IT4L poskytuje nad rámec platné legislativy mimořádně vysoký stupeň anonymity všem osobám, které využívají služeb internetového serveru www.lady4love.com (dále též jen server). Jde zejména o poskytování prostoru pro inzerci erotických služeb s možností on-line komunikace zákazníka s inzerentem a o zprostředkování prodeje doplňkových služeb.',
  p4: 'Z uvedeného vyplývá, že společnost IT4L zpracovává osobní údaje inzerentů erotických služeb, prodejců doplňkových služeb a zákazníků doplňkových služeb.',
  p5: 'Společnost IT4L získává osobní údaje jen v nezbytném rozsahu pro zajištění kvality poskytovaných služeb a pro plnění svých zákonných poviností, tyto údaje zásadně neposkytuje třetím osobám s výjimkou oprávněných státních orgánů. Společnost IT4L zveřejňuje nebo individuálně poskytuje pouze nejnutnější kontaktní údaje tak, aby inzerenti, prodejci a zákazníci mohli zahájit vzájemnou komunikaci (zpravidla pouze telefonní číslo nebo e-mailovou adresu a oslovení). Oslovením může být jakékoliv jméno či přezdívka.',
  p6: 'Tyto zásady se vztahují na všechny osobní údaje zpracovávané společností IT4L na základě plnění smluvního vztahu, právní povinnosti, oprávněného zájmu nebo uděleného souhlasu, a to prostřednictvím výše uvedeného serveru. Zásady popisují způsoby využívání a ochrany osobních údajů ze strany společnosti IT4L.',
  p7: 'Společnost IT4L může měnit a doplňovat tyto Zásady kdykoli umístěním revidované verze na uvedeném serveru a taková změna a doplnění nabude účinnost k datu takového umístění.',

  one: {
    title: 'SPRÁVCE A JEHO KONTAKTNÍ ÚDAJE',
    p1: 'Správcem osobních údajů je společnost IT4L.',
    p2: `Veškerou komunikaci týkající se ochrany osobních údajů či upozornění na porušení práv zasílejte prostřednictvím tel. +420 774231617,  nebo e-mail: info@lady4love.com.`,
  },
  two: {
    title: 'OSOBNÍ ÚDAJE, KTERÉ SPOLEČNOST IT4L ZPRACOVÁVÁ',
    p1: 'Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické osobě. Identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby.',
    p2: 'Společnost zpracovává zejména tyto osobní údaje: Telefonní číslo, e-mailovou adresu, kontakt v síti telegram, kontakt v síti WhatsApp, fotografii obličeje, fotografii osobního dokladu, momentální adresu, fotokopii zdravotních analýz, webové stránky. Některé z uvedených osobních údajů získává a zpracovává pouze u některých poskytovaných služeb. Osobní údaje společnost IT4L získává pouze od osob, které je dobrovolně poskytnou, takto získané údaje může ověřovat i z jiných zdrojů (zejména ze sítě internetu).',
    p3: 'Při poskytování služeb pomáhají společnosti IT4L tzv. cookies a internetové beacons. Jejich podrobnější vymezení, druhy a účely jejich užívání společností IT4L naleznete v Zásadách níže.',
  },
  three: {
    title: 'ÚČELY ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ',
    p1: 'Společnost IT4L zpracovává osobní údaje k následujícím účelům:',
    list: [
      'k předsmluvnímu jednání;',
      'k plnění smluvního vztahu (vztah mezi Vámi a společností IT4L, který vznikl zejména zadáním a zveřejněním inzerátu, využitím doplňkovým služeb či registrací uživatele služeb);',
      'ke správě uživatelských účtů (tj. zejména vyhovění Vaší žádosti o službu, založení Vašeho účtu, správa Vašeho účtu a zajištění Vašeho přístupu ke službě a k jejím aktualizacím);',
      'ke správě svěřených finančních prostředků (uložení virtuální měny Erocoin, popř. i jiné měny na virtuálním účtu, záznamy o využití finančních prostředků);',
      'ke kontaktování za účelem řešení problémů souvisejících s využíváním služeb nebo serveru;',
      'k zasílání informací o službách (tj. potvrzení, oznámení, aktualizace a podpůrná a administrativní sdělení);',
      'k zobrazování reklam na základě Vašeho zájmu (zejména využíváním filtrů pro vyhledávání reklam s požadovaných obsahem);',
      'k plnění zákonných povinností společnosti IT4L (vyplývajících z občanského zákoníku, živnostenského zákona, zákona o omezení plateb v hotovosti, zákona o některých opatřeních proti legalizaci výnosů z trestné činnosti, příslušných daňových zákonů apod.).',
    ],
    p2: 'Bez poskytnutí osobních údajů není možné uskutečnit předsmluvní jednání, smlouvu uzavřít či jí ze strany společnosti IT4L plnit. Smlouvy se zpravidla uzavírají vzdáleným způsobem na příslušných stránkách serveru; tím není vyloučena možnost uzavřít smlouvu jinak (například zaplacením).'
  },
  four: {
    title: 'UDĚLENÍ SOUHLASU',
    p1: 'Společnost IT4L zpracovává pouze takové osobní údaje a k takovým účelům, kde samostatný souhlas s jejich zpracováním není potřebný.',
    p2: 'Jedinou výjimkou z uvedeného prohlášení je zpracovávání a zveřejňování vybraných údajů o zdravotním stavu; poskytnutím těchto údajů společnosti IT4L se má za to, že osoba, která údaje o svém zdravotním stavu poskytla, s jejich zpracováním a zveřejněním souhlasí.',
    p3: 'Souhlas udělený společnosti IT4L se zpracováním osobních údajů lze kdykoliv odvolat, a to prostřednictvím tel. +420 774231617,  nebo e-mail: info@lady4love.com, s uvedením Vašeho jména, příjmení a adresy z důvodu Vaší identifikace. Odvoláním souhlasu není dotčena zákonnost zpracování vycházející ze souhlasu, který byl dán před jeho odvoláním. Odvoláním souhlasu zanikne poskytování služeb dotčené osobě.'
  },
  five: {
    title: 'DOBA ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ',
    p1: 'Osobní údaje budou společností IT4L zpracovávány jen po dobu nezbytně nutnou k plnění objednaných služeb, zejm. po dobu trvající inzerce (v případě inzerentů či poskytovatelů doplňkových služeb) či po dobu registrace k využívání serveru (v případě zákazníků). Zpracování osobních údajů nelze ukončit dříve, dokud nebudou řádně a úplně vyrovnány finanční závazky mezi společností IT4L a příslušnou osobou. Tím není dotčena povinnost společnosti IT4L, uchovávat osobní údaje po dobu stanovenou zákonem.',
    p2: 'Po uplynutí doby uchovávání osobních údajů společnost IT4L osobní údaje vymaže.',
  },
  six: {
    title: 'POSKYTOVÁNÍ OSOBNÍCH ÚDAJŮ TŘETÍM OSOBÁM',
    p1: 'Společnost IT4L poskytuje osobní údaje pouze svým spolupracovníkům a pouze v rozsahu, který je nezbytný pro plnění sjednaných povinností společnosti IT4L (například účetním, programátorům apod.). Tyto třetí osoby jsou vázány stejnými povinnostmi ochrany osobních údajů, jako společnost IT4L.',
    p2: 'Společnost IT4L bez Vašeho souhlasu neposkytuje Vaše osobní údaje jiným třetím osobám než výše uvedeným, ani je nesdílí s jinými osobami nebo nespřízněnými společnostmi, s výjimkou těchto případů:',
    list: [
      'dodržování právních předpisů nebo v reakci na požadavky právních předpisů;',
      'ochrana práv a majetku společnosti IT4L, jejích zástupců, uživatelů a jiných osob, zejména za účelem vymáhání dodržování sjednaných smluv, a v naléhavých případech i na ochranu vlastní bezpečnosti společnosti IT4L, uživatelů jejích služeb nebo jakékoli jiné osoby;',
      'v souvislosti s jakoukoli fúzí, prodejem majetku společnosti, financováním nebo nabytím všech nebo části podniku společnosti IT4L ze strany jiné společnosti nebo v průběhu takového procesu.'
    ],
    p3: 'Společnost IT4L nepředá Vaše osobní údaje bez Vašeho souhlasu do třetí země nebo mezinárodní organizaci, pokud to není nezbytné pro plnění smlouvy mezi Vámi a společností IT4L nebo taková povinnost nevyplývá pro společnost IT4L ze zákona.',
    p4: 'Společnost IT4L neposkytuje žádné osobní údaje shromážděné prostřednictvím serveru nebo v průběhu poskytované služby třetím osobám pro účely přímého marketingu.',
  },
  seven: {
    title: 'PRÁVA SUBJEKTU ÚDAJŮ',
    p1: 'Každá fyzická osoba, jejíž osobní údaje společnost IT4L zpracovává, má níže uvedená práva, které může kdykoliv uplatnit. Jde o:',
    list: [
      'právo na přístup ke svým osobním údajům (tj. právo získat informace, zda jsou údaje zpracovávány, a pokud ano, tak má právo k nim získat přístup);',
      'právo na opravu osobních údajů (tj. žádat opravu, pokud zjistí, že společnost IT4L zpracovává nepřesné nebo nepravdivé údaje);',
      'právo požadovat vysvětlení (tj. pokud má podezření, že zpracování osobních údajů narušuje ochranu jejího osobního a soukromého života nebo dochází ke zpracování v rozporu s právními předpisy);',
      'právo na omezení zpracování osobních údajů (tj. právo požadovat dočasné omezení zpracovávání osobních údajů);',
      'právo na výmaz osobních údajů (tj. v případě, pokud osobní údaje již nejsou potřebné pro účely, pro které byly zpracovávány);',
      'právo vznést námitku proti zpracování osobních údajů (společnost IT4L je povinna prokázat, že existuje závažný oprávněný důvod pro zpracování osobních údajů, který převažuje nad zájmy dotčené osoboy nebo jejími právy a svobodami);',
      'právo na přenositelnost údajů (tj. právo požadovat, aby její údaje společnosti IT4L poskytla třetí osobě způsobem umožňujícím další zpracování);',
      'právo kdykoliv odvolat souhlas se zpracováním osobních údajů;',
      'právo podat stížnost u Úřadu pro ochranu osobních údajů (tj. zejména v případě, že se domnívá, že bylo porušeno její právo na ochranu osobních údajů a společnost IT4L své pochybení po výzvě neodstranila).'
    ]
  },
  eight: {
    title: 'BEZPEČNOSTNÍ OPATŘENÍ A SDĚLENÍ OSOBNÍCH ÚDAJŮ',
    p1: 'Základním nástrojem ochrany osobních údajů je skutečnost, že společnost IT4L zpracovává jen minimum osobních údajů tak, aby mohla kvalitně poskytovat své služby, a přitom v nejvyšší možné míře zachovala anonymitu poskytovatelů služeb a jejich zákazníků. Zpracovávané osobní údaje zpravidla nepostačují k jednoznačné identifikaci dotčené osoby nebo je taková identifikace neúměrně složitá.',
    p2: 'Společnost IT4L uchovává osobní údaje v souladu s nejvyššími bezpečnostními standardy, prostřednictvím široké škály bezpečnostních opatření, včetně šifrovacích a ověřovacích nástrojů, aby zabránila neoprávněnému přístupu, pozměnění, zveřejnění nebo zničení osobních údajů a zachovávala jejich utajení. Osobní údaje jsou přístupné pouze omezenému počtu osob, které jsou povinny uchovávat tyto údaje v tajnosti.',
  },
  nine: {
    title: 'COOKIES A INTERNETOVÉ BEACONS',
    p1: 'Aby společnost IT4L mohla poskytovat služby přizpůsobené potřebám uživatelů serveru, používá cookies (session ID cookies a trvalé cookies) k uchovávání a příležitostnému sledování návštěv serveru. Společnost IT4L užívá cookies, aby usnadnila navigaci po serveru. Po zavření Vašeho prohlížeče platnost session ID cookie uplyne, trvalé cookie zůstává na Vašem pevném disku po delší dobu. Společnost IT4L zjišťuje, zda je ve Vašem prohlížeči povolen JavaScript k usnadnění prohlížení Portálu. Většina internetových prohlížečů je nastavena tak, že automaticky akceptuje cookies.',
    p2 : 'Společnost IT4L používá cookies jen v nezbytném rozsahu pro hladký provoz svých služeb a pro jejich hladké nalezení a případné využití. Společnost IT4L nepředává žádná zjištění získaná pomocí cookies třetím osobám, například k personifikované reklamě.'
  }
}
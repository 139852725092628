import { Icon, Button, InputInLabel } from "@/components/ui";

import "./addHeader.scss";

import useLadyService from "@/services/LadyService";
import { setAddOrImportProfile } from "@/stores/slices/popupSlice";
import { ExternalLink } from "@/components/ui";
import { Fragment, useState } from "react";
import ImportAdComponent from "@/helper/ImportAdComponent";
import { ButtonLink, LoadingButton, Dropdown } from "../../../../../../ui";
// import {Dropdown} from "react-day-picker";

const options = [
  { name: "banging.cz" },
  { name: "secretgirlprague.com" },
  { name: "eurogirlsescort.com" },
  { name: "escorts.cz" },
];

const AddHeader = () => {
  const { t, dispatch } = useLadyService();

  const [importAdValue, setImportAdValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { importAd } = ImportAdComponent();

  const [importFieldError, setImportFieldError] = useState(false);

  const handleImport = async () => {
    const result = await importAd(importAdValue, setLoading);

    if (result === "error-empty") {
      setImportFieldError(true);
    }
  };

  return (
    <div className="add__root">
      <Button
        // href={`/lk/indi/create`}
        clazz={"button--green _no-underline"}
        size="l"
        onClick={() => dispatch(setAddOrImportProfile(true))}
      >
        <Icon spritePath={"plus"} size={"l"} />
        <span className="p1 add__add">{t("add")}</span>
        <span className="p1 add__addad">{t("addad")}</span>
      </Button>
      {/* <div className="add__right">
        <span className={"p2 color-main add__text"}>
          {t("importfor")}, <p>{t("parametrs")}</p>
        </span>

        <Dropdown
          title={t("avaliblesites")}
          name={"site"}
          iconClass={'info'}
          iconSize={'m'}
          clazz={"add__select p3"}
          openOnHover
          size={"l"}
        >
          {options.map((i, index) => {
            return (
            <Fragment key={index}>
              <ExternalLink
                clazz={'button button-xs _no-underline justify-start'}
                exact
                path={`/${i.name}`}
                to={`https://${i.name}`}
              />
            </Fragment>
            );
          })}
        </Dropdown>

        <InputInLabel
          type={"text"}
          id={"import-ad"}
          placeholder={t("linkinenglish")}
          //   register={{ ...register("tg") }}
          //   clazz={"w-100"}
          value={importAdValue}
          onChange={(e) => {
            if (importFieldError) {
              setImportFieldError(false);
            }
            setImportAdValue(e.target.value);
          }}
          clazz={`add__link ${importFieldError ? "error" : ""}`}
        >
          {importFieldError ? t("required") : t("url")}
        </InputInLabel>

        <LoadingButton
          isLoading={loading}
          clazz={"add__import__modal button--primary"}
          size={"l"}
          onClick={() => {
            handleImport();
          }}
        >
          <Icon spritePath={"import"} size={"l"} />
          <span className="p1">{t("import")}</span>
        </LoadingButton>

        <LoadingButton
          isLoading={loading}
          clazz={"add__import button--primary"}
          size={"l"}
          onClick={() => {
            handleImport();
          }}
        >
          <Icon spritePath={"import"} size={"l"} />
          <span className="p1">{t("import")}</span>
        </LoadingButton>
      </div> */}
    </div>
  );
};

export default AddHeader;

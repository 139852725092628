import "./iconFlag.scss";
import "./iconPaymentMethod.scss";
import "./icon.scss";

import sprite from '@/assets/img/icons/payment-sprite.svg'

const Icon = (props) => {
  const {spritePath, clazz, size, type, title, square, counter} = props;

  switch (type) {
    case "payment":
      return (
        <svg
          id={spritePath}
          className={`icon-payment${
          size ? ` icon-payment-${size}` : ""
        } ${clazz ? ` ${clazz}` : ""}`}
        >
          <use xlinkHref={`${sprite}#${spritePath}`}></use>
        </svg>
      );

    case "flag":
      return (
        <i
          title={title}
          className={`icon-flag${
            size ? ` icon-flag-${size}` : ""
          } ${spritePath}${clazz ? ` ${clazz}` : ""}`}
        ></i>
      );

    default:
      let classNames = `icon${square ? '-square' : ''}${size ? ` icon-${square ? 'square-' : ''}${size}` : ""}`
      return (
        <i
          data-counter={counter}
          title={title}
          className={`${classNames} icon--${spritePath}${
            clazz ? ` ${clazz}` : ""
          }`}
        ></i>
      );
  }
};

export default Icon;

import { useState } from "react";
import { Button, ButtonLink, Icon } from "@/components/ui";
import { useSelector } from "react-redux";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {
  getMapStatus,
  setAuthorizationStatus,
  setMapMode,
  setStoriesMap,
  setVideoChatMode,
} from "@/stores/slices/popupSlice";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import { setLadyForCall } from "../../../../stores/slices/videoCallSlice";
import {makeRequest} from "../../../../services/makeRequest";

const GirlCardButtons = ({ props, edit }) => {
  const { slug, phone, telegram, whatsapp, ready_videochat } = props;

  const videoRes = {
    profile: {
      name: props.name,
      slug: props.slug,
      photo: !!props?.photos?.length ? [props?.photos[0]] : null,
    },
  };

  const { userType, dispatch } = useLadyService();

  const { t } = useTranslation("translation");

  const mapPopupStatus = useSelector(getMapStatus);

  const [clicked, setClicked] = useState(false);

  const phoneRes = !!formatPhoneNumberIntl(`${phone}`)
    ? formatPhoneNumberIntl(`${phone}`)
    : formatPhoneNumberIntl(`+${phone}`);

  return (
    <div className="girl-card__buttons">
      {edit ? (
        <>
          <Button size={"m"} clazz={"button--primary"}>
            {phoneRes}
          </Button>

          {!!whatsapp ? (
            <Button
              href={whatsapp}
              clazz={"button--primary"}
              square={true}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"whatsapp"} />
            </Button>
          ) : null}

          {!!telegram ? (
            <Button clazz={"button--primary"} square={true} size={"m"}>
              <Icon size={"xl"} spritePath={"telegram"} />
            </Button>
          ) : null}
        </>
      ) : (
        <>
          {!!ready_videochat &&
          !(userType === "indi" || userType === "agency") ? (
            <Button
              clazz={"button--green girl-card__videochat"}
              size={"m"}
              onClick={() => {
                if (userType !== "default") {
                  console.log("1");
                  dispatch(setLadyForCall(videoRes));
                } else {
                  dispatch(setVideoChatMode(true));
                  dispatch(setAuthorizationStatus(true));
                }
                if (mapPopupStatus) {
                  dispatch(setMapMode(true));
                  dispatch(setStoriesMap(false));
                }
              }}
            >
              <Icon size={"l"} spritePath={"video"} />
              {t("videochat")}
            </Button>
          ) : null}

          {!!ready_videochat &&
          !(userType === "indi" || userType === "agency") ? (
            <ButtonLink
              href={`tel:${phone}`}
              square
              clazz={"button--primary girl-card__phone"}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"call"} />
            </ButtonLink>
          ) : (
            <ButtonLink
              href={clicked ? `tel:${phone}` : "#"}
              size={"m"}
              clazz={`button--primary${clicked ? " girl-card__phone" : ""}`}
              onClick={!clicked ? () => {
                setClicked(true);
                makeRequest({route: `event/contact/profile/${slug}`, method: "POST"});
                window.ym(98088419,'reachGoal','getphone')
              } : null}
            >
              {clicked && phoneRes}
              {!clicked && t("shownumber")}
            </ButtonLink>
          )}

          {!!whatsapp ? (
            <ButtonLink
              href={`https://wa.me/${whatsapp}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${slug}`}
              clazz={"button--primary"}
              square={true}
              target={"_blank"}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"whatsapp"} />
            </ButtonLink>
          ) : null}

          {!!telegram ? (
            <ButtonLink
              href={`https://t.me/${telegram}?text=Hi!%20I%20am%20interested%20in%20your%20profile%20https://lady4love.com/ru/profile/${slug}`}
              clazz={"button--primary"}
              square={true}
              target={"_blank"}
              size={"m"}
            >
              <Icon size={"xl"} spritePath={"telegram"} />
            </ButtonLink>
          ) : null}
        </>
      )}
    </div>
  );
};

export default GirlCardButtons;

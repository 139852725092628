import React, { useEffect, useState } from "react";
import axios from "axios";
import useLadyService from "@/services/LadyService";
import { Loader } from "@/components/ui";

const EroMedia = ({ src, title, alt, clazz, onClick }) => {
  const [mediaSrc, setMediaSrc] = useState(null);
  const { token } = useLadyService();

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get(src, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        });

        const mediaBlob = response.data;
        const mediaUrl = URL.createObjectURL(mediaBlob);

        setMediaSrc(mediaUrl);
      } catch (error) {}
    };

    if (token) {
      fetchMedia();
    } else {
      setMediaSrc(src);
    }
  }, [src, token]);

  if (!mediaSrc) {
    return <Loader height={50} />;
  } else {
    return (
      <img
        src={mediaSrc}
        alt={alt}
        title={title}
        className={clazz}
        onClick={onClick}
      />
    );
  }
};

export default EroMedia;
import {createSlice} from "@reduxjs/toolkit";

const agencySlice = createSlice({
    name: 'agency',
    initialState: {
        agencies: [],
        agency: null,
    },
    isAgencyPage: false,
    reducers: {
        setAgencies: (state, action) => {
            state.agencies = action.payload;
        },
        setAgency: (state, action) => {
            state.agency = action.payload;
        },
        setIsAgencyPage: (state, action) => {
            state.isAgencyPage = action.payload;
        },
    }
})

export const {
    setAgencies,
    setAgency,
    setIsAgencyPage
} = agencySlice.actions

export const getAgencies = (state) => state.agencies.agencies;
export const getAgency = (state) => state.agencies.agency;
export const getIsAgencyPage = (state) => state.agencies.isAgencyPage;

export default agencySlice.reducer;
import './statisticTable.scss'
import useLadyService from "../../../../../services/LadyService";
import moment from "moment";

const StatisticTable = ({statistic}) => {
    const {t} = useLadyService();
    const header = [
        t('date'),
        t('profileshows'),
        t('viewprofile'),
        t('viewcontact'),
        t('erocontentaccess'),
        t('minofvideo'),
        t('prepaidorders'),
        t("incomes"),
        t("expences"),
    ]
    return (
        <>
            <div className={'statistic-table'}>
                <div className="statistic-table__wrapper">

                    <div className="statistic-table__header">
                        {header.map(i => {
                            return (
                                <div className={'statistic-table__cell'}>
                                    {i}
                                </div>
                            )
                        })}
                    </div>
                    {Object.entries(statistic).map(([date, actions]) => (

                            <div className="statistic-table__row">
                                <div className={'statistic-table__cell'}>
                                    {date === 'forperiod' ? t('forperiod') : moment.unix(date).utc().format("DD.MM.YYYY")}
                                </div>
                                <div className={'statistic-table__cell'}>
                                    {actions?.miniCardShow ?? 0}
                                </div>
                                <div className={'statistic-table__cell'}>
                                    {actions?.visit ?? 0}
                                </div>
                                <div className={'statistic-table__cell'}>
                                    {actions?.contact ?? 0}
                                </div>
                                <div className={'statistic-table__cell'}>
                                    {actions?.ero ?? 0}
                                </div>
                                <div className={'statistic-table__cell'}>
                                    {actions?.call ?? 0}
                                </div>
                                <div className={'statistic-table__cell'}>
                                    {actions?.booking ?? 0}
                                </div>
                                <div className={'statistic-table__cell'}>
                                    {actions?.income ?? 0}
                                </div>
                                <div className={'statistic-table__cell'}>
                                    {actions?.outcome ?? 0}
                                </div>
                            </div>
                    ))}
                </div>
            </div>

            <div className="statistic-table__options">
                <span className={'statistic-table__option--elite'}>{t('Status')} Elite</span>
                <span className={'statistic-table__option--promo'}>{t('activepromo')}</span>
            </div>
        </>
    )
}

export default StatisticTable
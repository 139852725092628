import { ButtonLink } from "@/components/ui";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


import { Icon } from "@/components/ui/index.js";
import Popup from "../Popup";

const DiscountToHealthPopup = ({open, setOpen}) => {
  const { t } = useTranslation();

  const liOptions = [
    <li>
      <Link
        to={"https://cz.unilabs.online/auth/register"}
        className="color-green"
        target={"_blank"}
      >
        {t("registeron")}
      </Link>{" "}
      {t("onthe").toLowerCase()} Unilabs.online
    </li>,
    <li>
      {t("onpage")}{" "}
      <Link
        to="https://cz.unilabs.online/sexualne-prenosne-nemoci-expert"
        className="color-green d-inline"
        target={"_blank"}
      >
        {t("test")}
      </Link>{" "}
      {t("selectvar")} <span className="color-main">EXPERT</span> (
      {t("getstatus")} <span className="color-main">{t("healthy")}</span>)
    </li>,
    <li>{t("selectplace")}</li>,
    <li>
      {t("basket")} <span className="color-main">STDE20-24</span>{" "}
      {t("beforeorder")}
    </li>,
    <li>{t("followrecomend")}</li>,
    <li>
      {t("waitfortest")} <span className="color-green">Unilabs.online</span>
    </li>,
    <li>
      {t("uploadtoprofil")} <span className="color-green">Lady4Love.com</span>
    </li>,
  ];

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      container={'div'}
    >
        <h3 className="m-12-24">{t("qrcodediscount")}</h3>
      <div className="popup-form__inner">
        
        <h3 className="fz-32 bold">STDE20-24</h3>

        <ol>{liOptions.map((i) => i)}</ol>
        <span className="d-flex fd-column align-center">
        {t("donegethelth")}
      </span>
        <span title={t("photosverified")} className={"model__icon width-fit ml-auto mr-auto"}>
          <Icon spritePath={"health-fill"} size={"l"}/>
          {t("healthy")}
      </span>

        <ButtonLink
          href={"https://cz.unilabs.online/sexualne-prenosne-nemoci-expert"}
          size={"l"}
          target={"_blank"}
          clazz={"button--green justify-center _no-underline"}
        >
          {t('goto')} Unilabs
        </ButtonLink>
      </div>
    </Popup>
  );
};

export default DiscountToHealthPopup;

import "./loader.scss";

const Loader = ({ height, decimal = 'vh' }) => {
  const style = !!height ? { height: `${height}${decimal}` } : {};
  return (
    <div className="loader-wrapper" style={style}>
      <div className="lds-heart">
        <div></div>
      </div>
    </div>
  );
};

export default Loader;

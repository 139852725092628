import { InputInLabel } from "@/components/ui";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {Popup} from "../index";
import useLadyService from "@/services/LadyService";
import { makeRequest } from "@/services/makeRequest";
import showToast from "@/components/toast/Toast";
import { useState } from "react";
import { LoadingButton } from "@/components/ui";

const CheckPhonePopup = (props) => {
  const { open, setOpen, phoneClient, options, setTags } = props;

  const {t} = useLadyService()
  const [value, setValue] = useState("");
  const [valueError, setValueError] = useState(false);
  const [loading, setLoading] = useState(false);

  const phoneRes = !!formatPhoneNumberIntl(`${phoneClient}`)
    ? formatPhoneNumberIntl(`${phoneClient}`)
    : formatPhoneNumberIntl(`+${phoneClient}`);

  const fetchData = async () => {
    setLoading(true);
    try {
      const route = `tags`;
      const method = "POST";
      const payload = {
        phone: phoneClient.replace(/\s/g, "").replace("+", ""),
        tag: value
      }

      const result = await makeRequest({ route, method, payload });
      if(result) {
        setTags(prev => [...prev, value]);
        setValue("")
        showToast({
          message: t("success"),
          variant: "success",
        });
      }
      setLoading(false)

    } catch (error) {
      setLoading(false)
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };
  const handleAddTag = () => {
    if(!value) {
      setValueError(true);
      return
    }

    fetchData();
  };

  return (
    <Popup
      open={open}
      setOpen={setOpen}
      method={"POST"}
      clazz={'gap-16'}
    >
        <h3 className="m-12-24">
          {t("phoneTegs")} <p>{phoneRes}</p>
        </h3>
        <div className="popup-form__inner">
          
          <div className="popup-form__scroll__list">
            {options?.length ? options.map((item, index) => {
              return (
                <span className="p1 color-main" key={index}>
                  #{item}
                </span>
              );
            }) : <span className="className="p1 color-main>{t('emptyrecord')}</span>}
          </div>
          <hr />
          <InputInLabel
            id={"tegPhoneClient"}
            type={"input"}
            placeholder={""}
            value={value}
            onChange={(e) => {
              if(valueError) {
                setValueError(false)
              }
              setValue(e.target.value)
            }}
            clazz={`${valueError ? "error" : ""}`}
          >
            {valueError ? t('required') : t("addTeg")}
          </InputInLabel>

          <LoadingButton
            isLoading={loading}
            size={"l-forever"}
            clazz={"button--green justify-center"}
            onClick={() => {
              handleAddTag()
            }}
          >
            {t("save")}
          </LoadingButton>
        </div>
    </Popup>
  );
};

export default CheckPhonePopup;

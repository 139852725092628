import React from "react";
import {Avatar, Icon, RatingStars} from "../index";

import useLadyService from "../../../services/LadyService";
import './userBadge.scss'
const UserBadge = ({props, rating = true}) => {
  const {
    score,
    name,
    slug,
  } = props

  const {windowWidth} = useLadyService()

  return (
    <div className="user-badge">
      <Avatar
        onlyIcon
        profileStatus={slug}
        size={windowWidth > 767.98 ? "m" : 's'}
      />

      {name && <span>{name}</span>}

      {(score && rating) && <RatingStars color={'gold'} value={score} readOnly={true}/>}
    </div>
  )
}

export default UserBadge
import { Link } from "react-router-dom";
import { Icon } from "../index";
import useLadyService from "../../../services/LadyService";
import "./avatar.scss";

const Avatar = ({
  size,
  avatarLink,
  onlyIcon = false,
  profileStatus,
  viewTariff,
}) => {
  const { userType, userTypeCustomer, userTariffSlug } = useLadyService();
  let checkSize = size ? ` avatar-size-${size}` : "";
  let onlyIconCheck = onlyIcon ? ` avatar--only-icon` : "";
  let tariff =
    userTypeCustomer && viewTariff ? ` avatar--${userTariffSlug}` : "";
  let className = `avatar${checkSize} avatar--${userType}${tariff}${onlyIconCheck}`;
  const { t } = useLadyService();

  let template = () => (
    <>
      <Icon
        clazz={"avatar__icon"}
        spritePath={profileStatus === "diamond" ? "diamond" : "user"}
        size={size}
      />
      <span className={"avatar__text p2"}></span>
    </>
  );

  if (onlyIcon) {
    return (
      <Icon
        spritePath={profileStatus === "diamond" ? "diamond" : "user"}
        size={size}
        clazz={className}
      />
    );
  }

  if (!avatarLink) {
    return <div className={className}>{template()}</div>;
  }

  return (
    <Link
      title={t("goto") + " " + t("toprofile")}
      to={`${avatarLink}`}
      className={className}
    >
      {template()}
    </Link>
  );
};

export default Avatar;

import { useParams } from "react-router-dom";
import { useEffect } from "react";

import useLadyService from "@/services/LadyService";
import { Loader } from "@/components/ui";
export const AdminLogin = () => {
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      localStorage.setItem("user", token);
      window.location.href = `/lk`
    }
  }, []);

  return <Loader height={"100"}></Loader>;
};

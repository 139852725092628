import { InputInLabel } from "@/components/ui";
import useLadyService from "@/services/LadyService";

export const FactureBlock = ({
  phoneValue,
  register,
  errors,
  clearErrors,
  typePayCheckbox,
}) => {
  const { t } = useLadyService();
  return (
    <div className="short-filter__group mb-16">
      {
        <InputInLabel
          clazz={`${errors.name ? " error" : ""}`}
          id={`balance-name`}
          type={"text"}
          placeholder={
            typePayCheckbox === "firm" ? t("entercompanyname") : t("inputname")
          }
          register={{ ...register("name") }}
          onChange={(e) => {
            if (errors.name) {
              clearErrors("name");
            }
          }}
        >
          {(errors.name && (
            <span className={"input-label__error"}>{errors.name.message}</span>
          )) || (
            <span>
              {typePayCheckbox === "firm"
                ? t("name") + " " + t("company")
                : t("names")}
            </span>
          )}
        </InputInLabel>
      }
      {
        <InputInLabel
          clazz={`${errors.lastName ? " error" : ""}`}
          id={`balance-lastName`}
          type={"text"}
          placeholder={t(
            typePayCheckbox === "firm" ? t("entercompanyid") : "inputsurname"
          )}
          register={{ ...register("lastName") }}
          onChange={(e) => {
            if (errors.lastName) {
              clearErrors("lastName");
            }
          }}
        >
          {(errors.lastName && (
            <span className={"input-label__error"}>
              {errors.lastName.message}
            </span>
          )) || (
            <span>
              {t(typePayCheckbox === "firm" ? "ID " + t("company") : "family")}
            </span>
          )}
        </InputInLabel>
      }
      <InputInLabel
        clazz={`${errors.phone ? " error" : ""}`}
        id={"balance-phone"}
        type={"tel"}
        placeholder={""}
        register={{ ...register("phone") }}
        maskValue={phoneValue}
      >
        {(errors.phone && (
          <span className={"input-label__error"}>{errors.phone.message}</span>
        )) || <span>{t("phonenumber")}</span>}
      </InputInLabel>
      <InputInLabel
        clazz={`${errors.country ? " error" : ""}`}
        id={"balance-country"}
        type={"text"}
        placeholder={""}
        register={{ ...register("country") }}
        onChange={() => {
          if (errors.country) {
            clearErrors("country");
          }
        }}
      >
        {(errors.country && (
          <span className={"input-label__error"}>{errors.country.message}</span>
        )) || <span>{t("country")}</span>}
      </InputInLabel>
      <InputInLabel
        clazz={`${errors.city ? " error" : ""}`}
        id={"balance-city"}
        type={"text"}
        placeholder={""}
        register={{ ...register("city") }}
        onChange={() => {
          if (errors.city) {
            clearErrors("city");
          }
        }}
      >
        {(errors.city && (
          <span className={"input-label__error"}>{errors.city.message}</span>
        )) || <span>{t("yourcity")}</span>}
      </InputInLabel>
      <InputInLabel
        clazz={`${errors.address ? " error" : ""}`}
        id={"balance-address"}
        type={"text"}
        placeholder={t("fulladdress")}
        register={{ ...register("address") }}
        onChange={() => {
          if (errors.address) {
            clearErrors("address");
          }
        }}
      >
        {(errors.address && (
          <span className={"input-label__error"}>{errors.address.message}</span>
        )) || <span>{t("youraddress")}</span>}
      </InputInLabel>
    </div>
  );
};

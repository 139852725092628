import { useDispatch, useSelector } from "react-redux";
import {
  getActionsCountStatus,
  getAddressStatus, getBlockedCountriesList,
  getCitiesListStatus,
  getCityIdStatus,
  getCityStatus,
  getEmailStatus,
  getEmailVerifiedAtStatus,
  getFiltersCountStatus,
  getLatitudeStatus,
  getLongitudeStatus,
  getNameStatus,
  getNotifyEmailStatus,
  getNotifyTelegramStatus,
  getNotifyWhatsappStatus,
  getPhoneStatus,
  getPhoneVerifiedAtStatus,
  getProfilesCountStatus,
  getProfileStatus,
  getSalonStatus,
  getPreviewStatus,
  getTelegramStatus,
  getWhatsappStatus, getPreviewPrice, getSettingsOperatorStatus
} from "../stores/slices/userProfileSlice";

const useUserProfileService = () => {
  const profileStatus = useSelector(getProfileStatus)
  const profileCountStatus = useSelector(getProfilesCountStatus)
  const filtersCountStatus = useSelector(getFiltersCountStatus)
  const phoneStatus = useSelector(getPhoneStatus)
  const blockedCountriesList = useSelector(getBlockedCountriesList)
  const nameStatus = useSelector(getNameStatus)
  const emailStatus = useSelector(getEmailStatus)
  const phoneVerifiedAtStatus = useSelector(getPhoneVerifiedAtStatus)
  const telegramStatus = useSelector(getTelegramStatus)
  const whatsappStatus = useSelector(getWhatsappStatus)
  const notifyEmainStatus = useSelector(getNotifyEmailStatus)
  const notifyTelegramStatus = useSelector(getNotifyTelegramStatus)
  const notifyWhatappStatus = useSelector(getNotifyWhatsappStatus)
  const emailVerifiedAtStatus = useSelector(getEmailVerifiedAtStatus)
  const salonStatus = useSelector(getSalonStatus)
  const previewStatus = useSelector(getPreviewStatus)
  const previewPrice = useSelector(getPreviewPrice)
  const citiesList = useSelector(getCitiesListStatus)
  const longitudeStatus = useSelector(getLongitudeStatus)
  const latitudeStatus = useSelector(getLatitudeStatus)
  const cityStatus = useSelector(getCityStatus)
  const cityIdStatus = useSelector(getCityIdStatus)
  const addressStatus = useSelector(getAddressStatus)
  const actionsStatus = useSelector(getActionsCountStatus)
  const settignsOperator = useSelector(getSettingsOperatorStatus)

  return {
    profileStatus,
    filtersCountStatus,
    phoneStatus,
    nameStatus,
    emailStatus,
    emailVerifiedAtStatus,
    phoneVerifiedAtStatus,
    telegramStatus,
    whatsappStatus,
    notifyEmainStatus,
    notifyTelegramStatus,
    notifyWhatappStatus,
    salonStatus,
    citiesList,
    longitudeStatus,
    latitudeStatus,
    cityIdStatus,
    cityStatus,
    addressStatus,
    actionsStatus,
    profileCountStatus,
    blockedCountriesList,
    previewStatus,
    previewPrice,
    settignsOperator
  };
};

export default useUserProfileService;

import React, {useEffect, useState} from "react";
import {InputInLabel, Button} from "@/components/ui";
import useLadyService from "@/services/LadyService";
import {useForm} from "react-hook-form";
import {ChangePasswordPopup} from "@/components/popups";
import {Link} from "react-router-dom";
import useUserProfileService from "@/services/UserProfileService";
import {setChangePasswordStatus} from "@/stores/slices/popupSlice";

export const PersonalData = () => {
  const {windowWidth, t, dispatch} = useLadyService();

  const {phoneStatus} = useUserProfileService()

  const [valueLoad, setValueLoad] = useState(false);

  const {setValue} = useForm();

  useEffect(() => {
    setValueLoad(false);
    setValue("phone", String(phoneStatus));
    setValueLoad(true);
  }, [setValue, phoneStatus]);

  const isMobile = windowWidth < 479.98;
  const {register} = useForm();

  const handleOpenPassword = () => {
    dispatch(setChangePasswordStatus(true))
  };

  return (
    <>
      <div className="account-page__identity">
        <h2>{t("personaldata")}</h2>

        <form className="account-page__identity__form">
          <InputInLabel
            type={"tel"}
            id={"phone"}
            placeholder={t("phonenumber")}
            disabled
            register={{...register("phone")}}
            maskValue={phoneStatus}
            valueLoad={valueLoad}
          >
            {t("phonenumber")}
          </InputInLabel>

          {!isMobile && (
            <span>
            {t("forphonechange")} <Link to="/lk/support">{t("toadmin")}</Link>
          </span>
          )}

          <Button
            size={"s"}
            clazz={"button_outline--green-accent w-160"}
            onClick={handleOpenPassword}
          >
            {t("changepass")}
          </Button>
        </form>

        {isMobile && (
          <span>
            {t("forphonechange")} <Link to="/lk/support">{t("toadmin")}</Link>
          </span>
        )}
      </div>

      <ChangePasswordPopup/>
    </>
  );
};

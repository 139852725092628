import {Checkbox, InputInLabel, Loader} from "../../../ui";
import {Link} from "react-router-dom";
import {useCallback, useState} from "react";
import debounce from "lodash.debounce";
import {makeRequest} from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";

const SearchBlock = ({
                       selectName,
                       isLoading,
                       currentName,
                       setName,
                       selectSearch,
                       setSelectSearch,
                       selectData,
                       setSelectData,
                     }) => {
  const {t, setMediaSize} = useLadyService()

  const Res = ({ options, handleAddChange, selectSearch, isDataEmpty }) => {
    switch (true) {
      case load: {
        return <Loader />;
      }

      case !!options.length: {
        return options.map((i) => {
          const { main_photo, id, name, slug } = i;

          return (
            <div key={id} className="agency-block">
              <img src={setMediaSize(main_photo, "xs")} alt="girl" />
              <Link
                target="_blank"
                to={`/profile/${slug}`}
                className="agency-block__link-to"
              >
                {name}
              </Link>
              <Checkbox
                border={false}
                checked={slug === selectName?.slug}
                onChange={() => handleAddChange(slug, name)}
              />
            </div>
          );
        });
      }

      case !selectSearch: {
        return <span className="w-100 text-center">{t("startname")}</span>;
      }

      case isDataEmpty: {
        return <span className="w-100 text-center">{t("noproffound")}</span>;
      }

      default: {
        return <Loader />;
      }
    }
  };

  const [value, setValue] = useState(null);
  const [options, setOptions] = useState(selectData);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [load, setLoad] = useState(false);
  const [search, setSearch] = useState(selectSearch);

  const handleAddChange = (slugAgency, name) => {
    setSelectSearch(search);
    setSelectData(options);
    if (name === currentName.name) {
      setName({
        name: "",
        slug: "",
      });
    } else {
      setName({
        name,
        slug: slugAgency,
      });
    }
    if (slugAgency === value) {
      setValue(null);
    } else {
      setValue(slugAgency);
    }
  };

  const handleFilter = useCallback((e) => {
    if (isDataEmpty) {
      setIsDataEmpty(false);
    }
    const val = e.currentTarget.value;
    setSearch(val);
    fetchData(val);
  }, []);

  const fetchData = useCallback(
    debounce(async (value) => {
      try {
        setLoad(true);
        if (!!value) {
          const route = `user/profiles`;
          const method = "GET";
          const payload = {
            search: value,
          };

          const { profiles } = await makeRequest({ route, method, payload });
          setIsDataEmpty(!profiles.length);
          setOptions(profiles);
          setLoad(false);
        } else {
          setLoad(false);
          setOptions([]);
        }
      } catch (error) {}
    }, 1000),
    []
  );

  return (
    <form>
      <InputInLabel
        placeholder={t("searchmodel")}
        onChange={handleFilter}
        clazz={"fd-row"}
        size={"s"}
        value={search}
      />
      <div className="agency-block__content mt-16">
        <Res
          isLoading={isLoading}
          options={options}
          handleAddChange={handleAddChange}
          search={search}
          isDataEmpty={isDataEmpty}
        />
      </div>
    </form>
  );
};

export default SearchBlock
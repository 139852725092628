import logo from "@/assets/img/logo.svg";
import logoLight from "@/assets/img/logo-light.svg";
import useLadyService from "@/services/LadyService";

const LogoSvg = (props) => {
  const { alt } = props;
  const {
    theme
  } = useLadyService();

  const getTheme = () => {
    switch (theme) {
      case "light":
        return logoLight;
      default:
        return logo;
    }
  };
  return <img className={"logo-img"} src={getTheme()} alt={alt} />;
};
export default LogoSvg;

import { Helmet } from "react-helmet";
import { Button, Icon } from "@/components/ui";
import { useSelector } from "react-redux";
import { getAfterAuth, setAfterAuth } from "../../../stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
import {ButtonLink} from "../../ui";
import {Popup} from "../index";

const AfterAuth = () => {
  const { dispatch, lang, userType } = useLadyService();

  const { t } = useTranslation("translation");

  const afterAuth = useSelector(getAfterAuth);

  let link = "";
  switch (userType) {
    case "indi":
    case "agency":
    case "admin":
    case "moderator":
      link = `/lk/profiles`;
      break;

    case "customer":
      link = `/lk/customer`;
      break;
    default:
      link = `/`;
  }

  const onClose = () => {
    window.location.reload();
    dispatch(setAfterAuth(false));
  };

  return (
    <>
      <Popup
        open={!!afterAuth}
        setOpen={onClose}
        id={"afterAuth"}
        wrapperClazz="popup-form"
        method={"POST"}
        clazz={'gap-16'}
      >

          <h3 className="m-12-24">{t("successauth")}</h3>

          <div className="d-flex gap-16 mt-16 justify-sb">
            <Button
              size={"l"}
              clazz={"button--secondary w-100"}
              onClick={() => onClose()}
            >
              {t("stayonweb")}
            </Button>
            <ButtonLink
              size={"l"}

              href={link}
              clazz={"button--green w-100"}
              onClick={() => dispatch(setAfterAuth(false))}
            >
              {t("toprofile")}
            </ButtonLink>
          </div>
      </Popup>
    </>
  );
};

export default AfterAuth;

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import showToast from "../../toast/Toast";
import { Icon } from "../../ui";

import useLadyService from "../../../services/LadyService";

const StoryZone = ({ data, setData, accept, type, slug }) => {
  const { t } = useLadyService();

  const getValidFormatsMessage = () => {
    return `${t("allowed")}: JPG, JPEG, PNG, WEBP, MP4, MOV, WMV, AVI, MKV`;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = [];

      const filesToProcess = acceptedFiles;
      filesToProcess.forEach(async (file) => {
        if (
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\.\w+$/.test(
            file.name
          )
        ) {
          return;
        }
        if (
          !(
            type === "mixed" &&
            (file.type.startsWith("image/") || file.type.startsWith("video/"))
          )
        ) {
          showToast({
            message: `${getValidFormatsMessage()}`,
            variant: "error",
          });
          return;
        }

        if (file.size > 20 * 1024 * 1024) {
          showToast({
            message: `${t("maxfilesize")}: 20 Mb`,
            variant: "error",
          });
          return;
        }

        const filePreview = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        newFiles.push(filePreview);
      });

      if (newFiles.length > 0) {
        setData(() => {
          const newImages = newFiles.filter((file) =>
            file.type.startsWith("image/")
          );
          const newVideos = newFiles.filter((file) =>
            file.type.startsWith("video/")
          );
          return [...newImages, ...newVideos];
        });
      }
    },
    [setData, type, data, showToast]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
  });

  return (
    <div {...getRootProps({ className: "dropzone upload__zone" })}>
      <input {...getInputProps()} />
      <Icon clazz={"_green mb-12"} size={"xl"} spritePath={"plus-1"} />

      <span className="p1 color-main text-center">{t("choicecontents")}</span>
      <span className="mb-12">({t("storyhelp")})</span>

      <span className="p1 color-green text-center">+25% {t("toorders")}</span>
    </div>
  );
};

export default StoryZone;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  big_filter: false,
  short_filter: false,
  burger: false,
  scrolled_class: '',
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setMainFilter: (state, action) => {
      state.big_filter = action.payload;
    },

    setShortFilter: (state, action) => {
      state.short_filter = action.payload;
    },

    setBurgerMenu: (state, action) => {
      state.burger = action.payload;
    },

    setScrolledClass: (state, action) => {
      state.scrolled_class = action.payload;
    },
  },
});

export const {
  setMainFilter,
  setShortFilter,
  setBurgerMenu,
  setScrolledClass,

} = headerSlice.actions;

export const getMainFilter = (state) => state.header.big_filter;

export const getShortFilter = (state) => state.header.short_filter;

export const getBurgerMenu = (state) => state.header.burger;

export const getScrolledClass = (state) => state.header.scrolled_class;

export default headerSlice.reducer;

import { makeRequest } from "@/services/makeRequest";
import showToast from "../components/toast/Toast";
import useLadyService from "../services/LadyService";

const CheckPhoneTags = () => {
    const { t } = useLadyService();
  
    const checkTags = async (phone) => {
      if(!phone.replace('+', "")) {
        return 'error-empty'
      }
      
      try {
        const route = `tags`;
        const method = "GET";
        const payload = {
          phone: phone.replace(/\s/g, "").replace("+", ""),
        };
  
        const result = await makeRequest({ route, method, payload });
        if (result) {
          return result.tags
        } else {
          showToast({
            message: t("oops"),
            variant: "error",
          });
        }
      } catch (error) {
        console.log(error);
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };
  
    return {
      checkTags
    };
  };
  
  export default CheckPhoneTags;
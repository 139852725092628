import {
  BurgerMenu,
  DropdownLanguage,
  HeaderTabs,
  InstructionLink,
  Logo, MenuNavBar,
  SelectCity,
  SupportButton,
  UserWrapperDesktop
} from "../components";
import Filters from "../components/Filters";
import ShortFilter from "@/components/shortFilter/ShortFilter";
import React from "react";
import useLadyService from "@/services/LadyService";
import {Burger, SwitchTheme} from "@/components/ui";
import {useSelector} from "react-redux";
import {getScrolledClass} from "@/stores/slices/headerSlice";


const UserOrPhotograph = () => {
  const {windowWidth,} = useLadyService();

  const scrolledClass = useSelector(getScrolledClass);

  if (windowWidth > 1199.98)
    return (
      <>
        <div className="header__top">
          <div className="header__tabs">
            <HeaderTabs/>
          </div>

          <InstructionLink/>

          <SupportButton square={false} size={"xs"} iconSize={"m"} />

          <DropdownLanguage />

          <SwitchTheme type={"switch"} />
        </div>

        <div className="header__bottom">
          <Logo scrolledClass={scrolledClass}/>

          <SelectCity />

          <Filters/>

          <UserWrapperDesktop/>
        </div>
      </>
    );

  if (windowWidth > 767.98)
    return (
      <div className="header__bottom">
        <Logo scrolledClass={scrolledClass}/>

        <SelectCity />

        <Filters/>

        <div className="user__wrapper">
          <SupportButton square={false} size={"s"} iconSize={"m"} />

          <InstructionLink size={'s'}/>

          <DropdownLanguage size={"s"} />

          <Burger />
          <SwitchTheme type={"switch"} />
          <BurgerMenu />
        </div>

        <ShortFilter />
      </div>
    );

  return (
    <>
      <div className="header__top">
        <div className="user__wrapper">
          <Burger />
          <DropdownLanguage />
        </div>

        <Logo scrolledClass={scrolledClass}/>

        <div className="user__wrapper">
          <SupportButton square={true} size={"s"} />

          <SwitchTheme type={"switch"} />
        </div>

        <BurgerMenu />

        <MenuNavBar />

        <ShortFilter />
      </div>
    </>
  );
};

export default UserOrPhotograph
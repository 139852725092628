export const userDeclarationRU = {
  h1: "Заявление пользователя портала <a href='http://lady4love.com/'>lady4love.com.</a>",
  p1: "Я принимаю к сведению, что нижеследующие положения безоговорочно применяются ко всем посетителям портала <a href='http://lady4love.com/'>lady4love.com.</a> и пользователям услуг портала. Перед посещением или любым иным использованием контента портала <a href='http://lady4love.com/'>lady4love.com.</a> я заявляю следующее:",
  one: {
    text: "Мне более 18 лет, и я достиг(ла) совершеннолетия. Если я нахожусь в стране, где законом установлен иной возраст совершеннолетия, я подтверждаю, что достиг(ла) этого возраста.",
  },
  two: {
    text: "Мне более 18 лет, и я достиг(ла) совершеннолетия. Если я нахожусь в стране, где законом установлен иной возраст совершеннолетия, я подтверждаю, что достиг(ла) этого возраста.",
  },
  three: {
    text: "Я не посещаю сайт из стран, где запрещены материалы сексуального характера, и не являюсь гражданином таких стран.",
  },
  four: {
    text: "Я не позволю, чтобы несовершеннолетние или иные неуполномоченные лица получили доступ к данным, представленным на сайте, по причине моего поведения на этом сайте.",
  },
  five: {
    text: "Мой интерес ко всем содержащимся здесь предложениям и любой другой информации является исключительно частным и предназначен только для моего собственного использования.",
  },
  six: {
    text: "Я понимаю, что все графические и текстовые материалы на сайте <a href='http://lady4love.com/'>lady4love.com.</a> защищены законом об авторских правах. Без предварительного письменного согласия оператора портала <a href='http://lady4love.com/'>lady4love.com.</a> и, возможно, заинтересованных рекламодателей я не буду каким-либо образом распространять или скачивать содержимое портала или его частей, даже для личного использования.",
  },
  seven: {
    text: "Я понимаю, что оператор портала <a href='http://lady4love.com/'>lady4love.com.</a> не предоставляет, не организует и не посредничает в каких-либо эскортных, эротических или других услугах, предлагаемых на этом сайте (за исключением посредничества в продаже дополнительных услуг рекламы). Все содержащиеся здесь услуги являются предложениями конкретных рекламодателей. Любые претензии к услугам я буду решать напрямую с их поставщиками.",
  },
  eight: {
    text: "Заходя на эти страницы, я подтверждаю, что оператор портала <a href='http://lady4love.com/'>lady4love.com.</a> не несет ответственности:",
    list: [
      "за содержание и правдивость информации, публикуемой в рамках предложения услуг рекламодателей. Это касается и случаев, когда оператор портала был напрямую проинформирован об ошибке в опубликованной информации.",
      "за качество, цену и текущую доступность рекламируемых услуг.",
      "за любые возможные убытки (в том числе любые финансовые потери или ущерб от предоставления или публикации информации), даже если эти убытки возникли по причине использования (или невозможности использования) услуг, предлагаемых рекламодателями через портал <a href='http://lady4love.com/'>lady4love.com.</a> Это также относится к случаям, когда портал <a href='http://lady4love.com/'>lady4love.com.</a> определен как возможная причина таких убытков и его оператор заранее предупрежден о возможности таких убытков.",
      "за возникновение и развитие отношений между рекламодателем и мной как пользователем его услуг (заказчиком), включая случаи, когда эти отношения порождают гражданско-правовые споры или уголовные последствия. Оператор <a href='http://lady4love.com/'>lady4love.com.</a> не контролирует предложение рекламодателей и качество их услуг.",
    ],
  },
  nine: {
    text: "При использовании услуг, предлагаемых рекламодателями на сайте <a href='http://lady4love.com/'>lady4love.com.</a>, я обязуюсь соблюдать все применимые законы страны, на территории которой я использую услуги, особенно законы, касающиеся прямо и косвенно рекламируемых услуг, распространения инфекционных заболеваний или отмывания денег.",
  },
  ten: {
    text: "Заходя на портал, я обязуюсь соблюдать все условия, вытекающие для меня из <a href='/ru/rules'>Общие положения и условия</a>, а также заявляю, что ознакомлен и согласен с <a href='/ru/gdpr'>Политика конфиденциальности.</a> Я признаю, что некоторые условия обязывают меня даже после того, как я перестану посещать этот портал.",
  },
  eleven: {
    text: "Я признаю, что ложность или неполнота любого из этих заявлений или нарушение обязательств, которые я безоговорочно принял этими заявлениями, подвергает меня риску гражданских споров и уголовного преследования по законам страны оператора lady4love. com, то есть в соответствии с законодательством Чешской Республики.",
  },
};

import { Icon, LogoSvg } from "@/components/ui";
import useLadyService from "@/services/LadyService";

import "./aboutService.scss";

const AboutService = ({clazz}) => {
  const {t} = useLadyService();

  const arrayLady = [ t("aboutp3"), t("aboutp1"), t("aboutp2")];

  const iconsMap = [
    { spritePath: "web-cam", name: t("add-1") },
    { spritePath: "body-fill", name: t("add-2") },
    { spritePath: "health-fill", name: t("add-3") },
    { spritePath: "incognito", name: t("add-4") },
    { spritePath: "notification", name: t("add-5") },
    { spritePath: "star-fill", name: t("add-6") },
  ];

  return (
    <div className={`about__container${clazz ? ` ${clazz}` : ''}`}>
      <div className="about__description">
        <span className={"about__undertitle"}>
          {t("aboutservice")} Lady<span className={'color-green'}>4</span>Love
        </span>
        <span className="title_h1">
          The Best Choice<span>4</span>You
        </span>
        {arrayLady.map((i, index) => {
          return (
            <p key={index}>
              Lady<span>4</span>Love — {i}
            </p>
          );
        })}
      </div>

      <div className="about__benefits p1">
        <span className="title">{t("addpref")}</span>
        {iconsMap.map((item, index) => {
          const { spritePath, name } = item;
          return (
            <span className="about__item" key={index}>
              <Icon clazz={'color-green'} square size={'xxxl'} spritePath={spritePath} />
              {name}
            </span>
          );
        })}
      </div>

      <div className="about__slogan">
        <LogoSvg alt={t("sensiblechoice")} />
        <span>{t("sensiblechoice")}</span>
      </div>
    </div>
  );
};

export default AboutService;

import topLadyLight from "../../../../../assets/img/status/top-light.svg";
import topLady from "../../../../../assets/img/status/top.svg";
import eliteLady from "../../../../../assets/img/status/elite.svg";
import { Icon } from "@/components/ui";
import useLadyService from "@/services/LadyService";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { Tag } from "../../../../ui";

const GuideTags = () => {
  const { theme, userType } = useLadyService();

  const { t } = useTranslation("translation");

  let data = {
    indi_and_agency: [
      {
        title: "TopLady",
        text: t("i-topladydescr"),
        hash_link: "#questionnairePosition",
        img: theme === "light" ? topLadyLight : topLady,
      },
      {
        title: "Elite Lady",
        text: t("i-eliteladydescr"),
        hash_link: "#statusElite",
        img: eliteLady,
      },
      {
        title: t("verification"),
        text: t("i-verifydescr"),
        hash_link: "#verify",
        icon: "verify-fill",
        iconClass: "color-green",
      },
      {
        title: t("healthy"),
        text: t("i-healtydescr"),
        hash_link: "#healthControl",
        icon: "health-fill",
        iconClass: "color-green",
      },
    ],

    default: [
      {
        title: "TopLady",
        text: t("i-topladydescr"),
        img: theme === "light" ? topLadyLight : topLady,
      },
      {
        title: "Elite Lady",
        text: t("i-eliteladydescr"),
        img: eliteLady,
      },
      {
        title: t("verification"),
        text:
          userType === "customer"
            ? `${t("i-verifydescr")}. ${t("i-verifydescr")}`
            : t("i-verifydescr"),
        icon: "verify-fill",
        iconClass: "color-green",
      },
      {
        title: t("healthy"),
        text: t("i-healtydescr"),
        icon: "health-fill",
        iconClass: "color-green",
      },
    ],
  };

  let list = [
    {
      title: t("erocontent"),
      text: t("i-erodescr"),
      hash_link: "#eroContent",
      icon: "pants",
      iconClass: "color-green",
    },
    {
      title: t("onlineorder"),
      text: t("i-orderdescr"),
      icon: "pay-content",
      iconClass: "color-green",
    },
    {
      title: t("videochat"),
      text: t("i-videochatdescr"),
      icon: "video",
      iconClass: "color-green",
    },
    {
      title: t("modelpreviews"),
      text: t("i-previewdescr"),
      icon: "casting",
      iconClass: "color-green",
    },
    {
      title: "INDI",
      text: t("i-indidescr"),
      tag: true,
    },
    {
      title: "STAR",
      text: t("i-stardescr"),
      tag: true,
    },
    {
      title: "NEW",
      text: t("i-newdescr"),
      tag: true,
    },
    {
      title: "BDSM",
      text: t("i-bdsmdescr"),
      tag: true,
    },
    {
      title: t("video"),
      text: t("i-videodescr"),
      icon: "video-play",
    },
    {
      title: t("girlfriend"),
      text: t("i-gfdescr"),
      icon: "girlfriend",
    },
    {
      title: t("travel"),
      text: t("i-traveldescr"),
      icon: "travel",
    },
    {
      title: t("accompaniment"),
      text: t("i-escortdescr"),
      icon: "escort",
    },
  ];

  const [content, setContent] = useState(data.indi_and_agency);

  useEffect(() => {
    switch (userType) {
      case "indi":
      case "agency":
        return setContent(data.indi_and_agency);

      default:
        return setContent(data.default);
    }
  }, [userType]);

  return (
    <div className="guide__items">
      {content.map((i, index) => Template(i, index))}

      {list.map((i, index) => Template(i, index))}
    </div>
  );
};

const Template = (i, index) => {
  const { t } = useTranslation("translation");
  let exeption =
    i.title === t("erocontent") ? (
      <p className={"p3 color-600"}>
        *за исключением клиентов с профилем Platinum
      </p>
    ) : null;

  return (
    <div key={index} className="guide__item">
      <div className="guide__item-top">
        {i.img && <img src={i.img} alt={i.title} />}

        {i.icon && (
          <Icon
            square
            spritePath={i.icon}
            size={"l"}
            title={i.title}
            clazz={i.iconClass}
          />
        )}

        {i.tag ? <Tag>{i.title}</Tag> : null}

        <span className={"title_h3"}>{i.title}</span>
      </div>

      <p>
        <b>{i.title}</b> – {i.text}.
        {i.hash_link && (
          <>
            {" "}
            <HashLink to={i.hash_link}>{t("moreinfo")}...</HashLink>
          </>
        )}
      </p>

      {exeption}
    </div>
  );
};

export default GuideTags;

export const userDeclarationEN = {
  h1: "User Declaration for the lady4love.com portal and its services",
  p1: 'This Privacy Policy (hereinafter referred to as "Policy") is an integral part of the commercial terms at IT4L LLC, OGRN 19470711, registered at Podebradska 1026/52, Vysocany, 19000 Prague, 9 (hereinafter referred to as "IT4L").',

  one: {
    text: "I am over 18 years of age and, therefore, of legal age. If I am located in a jurisdiction where a different age of majority is required by law, I declare that I have reached that age.",
  },
  two: {
    text: "I understand that lady4love.com contains sexually explicit material, and this fact does not offend, harm, or disturb me in any way.",
  },
  three: {
    text: "I do not access the site from any country where sexually explicit material is prohibited, nor am I a citizen of such a country.",
  },
  four: {
    text: "I will not allow any underage or otherwise incompetent individuals to access the content on these pages as a result of my behavior.",
  },
  five: {
    text: "My interest in all offers and any other information on this site is purely private and for my own personal use only.",
  },
  six: {
    text: "I understand that all image and text materials on <a href='http://lady4love.com/'>lady4love.com</a> are protected by copyright law. Without the prior written consent of the operator of lady4love.com and, where applicable, the involved advertisers, I will not distribute, download, or otherwise use any content from the portal, even for personal purposes.",
  },
  seven: {
    text: "I understand that the operator of <a href='http://lady4love.com/'>lady4love.com</a> does not provide, organize, or mediate any escort, erotic, or other services offered on this site (except for facilitating the sale of additional services). All services on this site are provided by specific advertisers, and I will address any concerns about these services directly with their providers.",
    list: [
      "for the content or accuracy of the information published as part of the advertisers' service offers. This includes cases where the portal operator has been directly informed of errors in the published information. The operator of <a href='http://lady4love.com/'>lady4love.com</a> is also not responsible for the quality, price, or current availability of the advertised services.",
      "for any damages (including financial losses or harm arising from the provision or publication of information), even if these damages result from the use (or inability to use) the services offered by advertisers through the <a href='http://lady4love.com/'>lady4love.com</a> portal. This applies even in cases where <a href='http://lady4love.com/'>lady4love.com</a> is identified as a potential cause of such damages and the operator has been warned in advance of the possibility of such damages.",
      "for the origin or development of the relationship between the advertiser and me as a user of its services (customer), including cases where this relationship leads to civil disputes or criminal consequences. The operator of <a href='http://lady4love.com/'>lady4love.com</a> has no control over the offers made by advertisers or the quality of their services.",
    ],
  },
  eight: {
    text: "By visiting the website, I acknowledge that the operator of the <a href='http://lady4love.com/'>lady4love.com</a> portal assumes no responsibility:",
  },
  nine: {
    text: "When using the services offered by advertisers on lady4love.com, I undertake to comply with all applicable laws of the country in whose territory I use the services, especially laws regarding directly and indirectly advertised services, the spread of infectious diseases or money laundering.",
  },
  ten: {
    text: "By visiting the website, I undertake to comply with all the conditions that arise for me from the <a href='/en/rules'>General Terms and Conditions</a> and I also declare that I have read and agree with the <a href='/en/gdpr'>Privacy Policy</a>. I acknowledge that some conditions bind me even after I stop visiting this website.",
  },
};

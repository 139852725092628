import {useDispatch, useSelector} from "react-redux";
import {getViewingStatus, setViewingStatus} from "@/stores/slices/popupSlice";
import {Button, Icon, InputInLabel} from "@/components/ui";
import React, {useEffect, useState} from "react";
import {number, object, string} from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useTranslation} from "react-i18next";

import "./orderViewing.scss";
import {getSelectedCount, getSelected} from "@/stores/slices/agencyFilterSlice";
import {Popup} from "../index";
import moment from "moment/moment";
import showToast from "../../toast/Toast";
import {useMakeRequest} from "../../../hooks/useMakeRequest";
import useLadyService from "../../../services/LadyService";
import GeoInput from "../../ui/input/GeoInput";
import {Loader} from "../../ui";

const OrderViewing = ({price, agencyId}) => {
    const [phoneValue, setPhoneValue] = useState("");
    const {dispatch, userType, minutesToDhms, t, lang} = useLadyService();
    const viewingValue = useSelector(getViewingStatus);
    const profiles = useSelector(getSelected);
    const [addressUser, setAddressUser] = useState(null);
    const selectedCount = useSelector(getSelectedCount);
    const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);
    const [waValue, setWaValue] = useState("");
    const [valueLoad, setValueLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const fullReset = () => {
        reset();
        setNewDate(null);
        setWaValue("");
        setPhoneValue("");
        dispatch(setViewingStatus(false));
    };
    useEffect(() => {
        const fetchData = async () => {
            setValueLoad(false);
            setIsLoading(true);
            try {
                const route = `user/info`;
                const method = "GET";

                const data = await makeRequest({route, method});

                const result = data?.data;
                setPhoneValue(result?.phone);
                setWaValue(result?.whatsapp);
                setAddressUser(result?.city + ", " + result?.address);
                setValue("address", result?.address);

                setIsLoading(false);
                setValueLoad(true);
            } catch (error) {
            }
        };

        userType !== "default" && fetchData();
    }, [userType]);
    const formSchema = object().shape({
        phone: string()
            .min(14, `${t("phonenumerrequired")}`)
            .test("phone-is-empty", t("phonenumerrequired"), function () {
                return !!phoneValue;
            }),
        address: string().required(t("required")),
        book_at: string().min(5, t("required")).required(t("required")),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
        clearErrors,
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
    });

    useEffect(() => {
        setValueLoad(false);
        setValue("phone", String(phoneValue));
        setNewDate(moment(new Date()).format("YYYY-MM-DD"));
        setValue("date", moment(new Date()).format("DD.MM.YYYY"));
        setValueLoad(true);
    }, [phoneValue, setValue]);


    const [newDate, setNewDate] = useState(
        moment(new Date()).format("DD.MM.YYYY")
    );

    const makeRequest = useMakeRequest()
    const onSubmit = (data, e) => {

        e.preventDefault();
        const {book_at} = data;

        const combineDateTime = (date, time) => {
            const dateComponents = date.split("-");
            const timeComponents = time.split(":");

            const year = parseInt(dateComponents[0]);
            const month = parseInt(dateComponents[1]) - 1;
            const day = parseInt(dateComponents[2]);

            const hours = parseInt(timeComponents[0]);
            const minutes = parseInt(timeComponents[1]);

            return new Date(year, month, day, hours, minutes);
        };

        const combinedDateTime = combineDateTime(newDate, book_at);

        const fetchData = async () => {
            setIsLoadingGlobal(true);
            try {
                const route = `previews/${agencyId}`;

                const unixTimestamp = moment(combinedDateTime).unix();

                const checkTime = (unixTime) => {
                    const targetTime = new Date(unixTime * 1000);
                    const currentTime = new Date();

                    let targetTimePlusOneHour = new Date(targetTime);

                    let currentTimePlusOneHour = new Date(currentTime);
                    currentTimePlusOneHour.setHours(currentTime.getHours() + 1);

                    return currentTimePlusOneHour < targetTimePlusOneHour;
                };

                const unixTimestampResult = checkTime(unixTimestamp);

                if (!unixTimestampResult) {
                    showToast({
                        message: t("timemorehour"),
                        variant: "error",
                    });
                    setIsLoadingGlobal(false);
                    return;
                }



                const method = "POST";
                const payload = {
                    book_at: unixTimestamp,
                    address: data.address,
                    phone: String(phoneValue).replace(/[" "+]/g, ""),
                    ...(!!waValue.replace(/["+"+]/g, "") && {
                        whatsapp: String(waValue).replace(/[" "+]/g, ""),
                    }),
                    price,
                    profiles
                };

                const result = await makeRequest({route, method, payload});
                if (result.message === "booked") {
                    showToast({
                        message: t("ordersuccess"),
                        variant: "order",
                    });
                    setIsLoadingGlobal(false);
                    fullReset();
                }
            } catch (error) {
                setIsLoadingGlobal(false);
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
            }
        };

        !!combinedDateTime && fetchData();
    };

    const onClose = () => {
        reset();
        dispatch(setViewingStatus(false));
    };

    const onChangeDate = (value) => {
        clearErrors("date");

        setNewDate(
            !value
                ? moment(new Date()).format("DD.MM.YYYY")
                : moment(value).format("YYYY-MM-DD")
        );
        clearErrors("date");
    };


    return (
        <Popup
            open={!!viewingValue}
            setOpen={fullReset}
            id={"viewedModel"}
            wrapperClazz="viewed-model"
            clazz={'gap-20'}
            onSubmit={handleSubmit(onSubmit)}
        >
            <h3 className="popup-form__title m-12-24">{t("numberofmodels")}</h3>

            <div className="popup-form__inner fd-row gap-8">
                <InputInLabel
                    clazz={`${errors.date ? " error" : ""}`}
                    type={"date"}
                    id={"orderDate"}
                    register={{...register("date")}}
                    required
                    onChange={onChangeDate}
                    value={newDate}
                >
                    {errors.date ? (
                        <span className={"input-label__error"}>{t("date")}</span>
                    ) : (
                        t("date")
                    )}
                </InputInLabel>

                <InputInLabel
                    clazz={`${errors.book_at ? " error" : ""}`}
                    register={{...register("book_at")}}
                    registerName={"book_at"}
                    setValue={setValue}
                    id={"orderTime"}
                    placeholder={"18:00"}
                    type={"time"}
                    required
                >
                    {errors.book_at ? (
                        <span className={"input-label__error"}>{t("time")}</span>
                    ) : (
                        t("time")
                    )}
                </InputInLabel>
            </div>

            <div className="search filter__map-input">
          <span className={"filter__map-title"}>
            {errors.address ? (
                <span
                    className={`input-label__error ${
                        errors.address ? "error" : ""
                    }`}
                >
                {errors.address.message}
              </span>
            ) : (
                t("youraddress")
            )}
          </span>
                <label
                    htmlFor="geosuggest__input"
                    className={`search__label header__search ${
                        errors.address ? "error" : ""
                    } required`}
                >
                    <GeoInput
                        value={addressUser ? addressUser[0]?.address : ''}
                        name={"address"}
                        id="geosuggest__input-create"
                        clazz={`header__search-input`}
                        placeholder={t("fulladdress")}
                        register={register}
                        setValue={setValue}
                    />
                </label>
            </div>

            {isLoading ? (
                <Loader />
            ) : (
                <div className="d-flex fd-column gap-10">
                    <InputInLabel
                        clazz={`${errors.phone ? " error" : ""}`}
                        id={"orderPhone"}
                        type={"tel"}
                        placeholder={t("yourphone")}
                        register={{ ...register("phone") }}
                        autocomplete={"tel"}
                        maskValue={phoneValue}
                        setMaskValue={setPhoneValue}
                        valueLoad={valueLoad}
                        required
                    >
                        {(errors.phone && (
                            <span className={"input-label__error"}>
                {errors.phone.message}
              </span>
                        )) || <span>{t("yourphone")}</span>}
                    </InputInLabel>

                    <div className={"d-flex justify-sb gap-8"}>
                        <InputInLabel
                            id={"orderWaPhone"}
                            clazz={"w-100"}
                            type={"tel"}
                            placeholder={"Whatsapp"}
                            register={{ ...register("whatsapp") }}
                            autocomplete={"tel"}
                            maskValue={waValue}
                            setMaskValue={setWaValue}
                            valueLoad={valueLoad}
                        >
                            <span>Whatsapp</span>
                        </InputInLabel>
                    </div>
                </div>
            )}
            <div className="popup-form__inner fd-row gap-8 color-700">
          <span className="agency-filter__count w-50">
            {t("numberofmodels")}
              <span className="title title_h2">{selectedCount}</span>
          </span>
                <span
                    className={
                        "d-flex fd-column gap-4 align-center justify-center p2 w-50"
                    }
                >
            {t("priceforselected")}
                    <span className="title title_h2">{price * selectedCount} € </span>
          </span>
            </div>

            <p className={"p1 fz-13"}>
                Вся сумма перечисляется агентству, когда оно подтвердит бронь. В
                случае отмены заказа со стороны агентства, эта сумма вернётся на Ваш
                баланс.
                <br/>
                *Это оплата услуги Просмотр моделей. Стоимость оказания эскорт-услуг
                обговаривается с выбранной моделью.
            </p>

            <Button
                size={"l-forever"}
                buttonType={"submit"}
                clazz={"button--green"}
            >
                Заказать и оплатить
            </Button>
        </Popup>
    );
};
export default OrderViewing;

import useLadyService from "@/services/LadyService";
import {LoadingButton} from "@/components/ui";

const DefaultButtons = ({ validateData, isGlobalLoading, formData }) => {
  const { t } = useLadyService();
  return (
    <div className="create-form__buttons justify-end">
      <LoadingButton
        onClick={validateData}
        buttonType={"submit"}
        size={"l-forever"}
        square={false}
        isLoading={isGlobalLoading}
        clazz="button--green min-w-160"
      >
        {!formData?.slug ? t("create") : t("continue")}
      </LoadingButton>
    </div>
  );
};

export default DefaultButtons
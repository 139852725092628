import { Icon } from "@/components/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import useLadyService from "@/services/LadyService";
import { Tag } from "../../../../ui";

const ModelIcons = ({ props }) => {
  const {
    verified_at,
    healthy_at,
    is_individual,
    is_new,
    tag_bdsm,
    porn_star,
  } = props;

  const { t } = useTranslation("translation");

  const { getDayMonthYear } = useLadyService();

  return (
    (healthy_at ||
      verified_at ||
      is_individual ||
      is_new ||
      tag_bdsm ||
      !!porn_star?.length) && (
      <div className="model__icons">
        {verified_at && (
          <span
            className={"model__icon"}
            title={`${t("verification")}. ${t("photosverified")}.  ${t(
              "lastcheck"
            )}: ${getDayMonthYear(verified_at)}`}
          >
            <Icon spritePath={"verify-fill"} size={"l"} />
            {t("verification")}
          </span>
        )}
        {healthy_at && (
          <span
            className={"model__icon"}
            title={`${t("healthy")}. ${t("modeltests")}. ${t(
              "lastcheck"
            )}: ${getDayMonthYear(healthy_at)}`}
          >
            <Icon spritePath={"health-fill"} size={"l"} />
            {t("healthy")}
          </span>
        )}

        {is_individual && <Tag title={t("indireg")}>{"INDI"}</Tag>}
        {is_new && <Tag title={t("newmodel")}>{"NEW"}</Tag>}
        {tag_bdsm && <Tag title={t("bdsmservice")}>{"BDSM"}</Tag>}
        {!!porn_star?.length && <Tag title={t("pornstar")}>{"STAR"}</Tag>}
      </div>
    )
  );
};

export default ModelIcons;

import useLadyService from "@/services/LadyService";
import { Button, Icon } from "@/components/ui";
import { Link } from "react-router-dom";
import AttentionBox from "../components/AttentionBox/AttentionBox";
import topLadyLight from "@/assets/img/status/top-light.svg";
import topLady from "@/assets/img/status/top.svg";
import eliteLady from "@/assets/img/status/elite.svg";
import GuideTags from "../components/tags/GuideTags";
import verifyPicture from "@/assets/img/examplePhotos/verExample.svg";
import healtyPicture from "@/assets/img/examplePhotos/verExamplePlus2.svg";
import indiAdEditImg from "@/assets/img/guide/agency/indiAdEdit.webp";
import searchAndFilterOptionImg from "@/assets/img/guide/agency/filterAng.webp";
import bulkManageImg from "@/assets/img/guide/agency/bulkManageImg.webp";
import sortImage from "@/assets/img/guide/agency/sortImage.webp";
import deactivateProfileImg from "@/assets/img/guide/agency/deactivate-profile.webp";
import GuideAside from "../components/aside/GuideAside";
import EliteStatusCards from "../../../eliteStatusCards/EliteStatusCards";
import howToEarnImg from "@/assets/img/guide/indi/how-to-earn.webp";
import "../guidePage.scss";
import React, { useEffect, useState } from "react";
import { DiscountToHealthPopup, } from "../../../popups";
import {Helmet} from "react-helmet";

const GuideAgency = ({ scrolledClass, show, setShow }) => {
  const { lang, theme, city, t, navigate, scrollToHash } = useLadyService();

  const [isOpenDiscount, setIsOpenDiscount] = useState(false);

  useEffect(() => {
    scrollToHash()
  }, []);

  const IconTemplate = (translateKey, icon) => (
    <Icon
      title={t(translateKey)}
      square
      clazz={"color-green"}
      spritePath={icon}
      size={"l"}
    />
  );

  return (
    <>
    <main className="guide-page">
      <Helmet>
        <meta
            name="description"
            content={t("guidedesc")}
        />
      </Helmet>
      <section className="guide__container">
        <h1 className={"mb-24 mb-16-mob"}>{t("guideforagency")}</h1>

        <Button
          clazz={`button--tetriary guide__button-heading${scrolledClass}`}
          size={"s"}
          onClick={() => setShow((prev) => !prev)}
        >
          <Icon size={"l"} spritePath={"instruction-cont"} />
          {t("i-contents")}
        </Button>

        <p>
          {t("i-greetings")}{" "}
          <Link to={"/"} className={"green"}>
            Lady4Love
          </Link>{" "}
          {t("i-greetingstext")}.
        </p>

        <div className="guide__body">
          <GuideAside
            props={{
              show,
              setShow,
              scrolledClass,
            }}
          />

          <div className="guide__content">
            
            {/* 1. ACCOUNT MANAGEMENT GUIDELINES */}

            <h2>{t("featuresagacc")}</h2>

            <h3 id={"generalset"}>{t("generalset")}</h3>
            <p>
              {t("generalinfo")}
            </p>

            <p>{t("seealso")}:{' '}<Link
                  to={`#mediacontent`}
                >           
                  {t("mediacontent")}.
                </Link></p>

            <h3 >{t("autofill")}</h3>
            <p>{t("settings")}</p> 
            <ul>
              <li>
                {t("tariffs")}{t('and')}{t('discounts')}
              </li>
              <li>
                {t("workhours")}
              </li>
              <li>
                {t("mainadress")}
              </li>
              <li>
                {t("phonandwa")}
              </li>
              <li>
                {t("restriction")}
              </li>
            </ul>

            <p>
              {t("seealso")}:{' '}
              <Link
                  to={`#uniquefeatures`} 
                >           
                  {t("moreabout")}.
                </Link>
            </p>

            <AttentionBox type={"advice"}>
              <p>{t("profilesettings")}.</p>
              <p>{t("seeother")}:</p>
              <ul>
                <li>
                <Link
                  to={`#crossnumber`}
                >           
                  {" "}
                  {t("checknum")}
                </Link>
                </li>
                <li>
                <Link
                  to={`#restriction`}
                >           
                  {" "}
                  {t("restriction")}
                </Link>
                </li>
              </ul>
            </AttentionBox>

            <h3 id='operatormanageand'>{t("operatormanageand")}</h3>
            <p>
              {`${t("inthesection")} `}
              <Link
                  to={`/lk/operators`}
                >           
                  {t("myoperator")}
              </Link>{` ${t("youcanadd")}.`}
            </p>
            <img
              className={"img-contain b-radius-24"}
              src={searchAndFilterOptionImg}
              alt=""
            />

            <h3 id="sexpartiesorg">{t("sexparties")}</h3>
            <AttentionBox type={'warning'}>
              <p>{t("whenorg")}{". "}<Link
                  to={`/lk/parties`}
                >           
                  {t("goto")}
                </Link></p>
            </AttentionBox>
              

            <h3 id='modelpreview'>{t("modelpreviews")}</h3>
            <AttentionBox type={'warning'}>
            <p>{t("tippyagencywatch")}{" "}<Link
                  to={`/lk/previews`}
                >           
                  {t("goto")}
                </Link></p>
            </AttentionBox>
              

            <h2>{t("i-maxeffective")}</h2>

            <h3 id="uniquefeatures">{t("uniquefeatures")}</h3>

            <p>
              {t("advancedfeatures")}.
            </p>
            <p>
              <b>{t("i-method")}</b>{` – ${t("i-methodtext")}`}.
            </p>
            <img
              className={"img-contain b-radius-24"}
              src={indiAdEditImg}
              alt=""
            />
            <p>
              <b>{t('g-sortandfilter')}</b>{' – '}{t("uniqueopp")}.
            </p>
            <img
              className={"img-contain b-radius-24"}
              src={sortImage}
              alt=""
            />
            
            <p>
              <b>{t("bulkmanage")}</b>{" "}{t("toactivate")}.
            </p>
            <img
              className={"img-contain b-radius-24"}
              src={bulkManageImg}
              alt=""
            />

            <p><b>{t('i-exportourside')}</b>{' – '}{t("i-filloutthead")}.</p>

            <p className={"guide__box d-block"}>
              *{t("i-importfromsites")}:
              {" "}
              <Link
                target={"_blank"}
                className={"green"}
                to={`/eurogirlsescort.com`}
              >           
              
                eurogirlsescort.com
              </Link>
              {`, `}
              <Link target={"_blank"} className={"green"} to={`//banging.cz`}>
                banging.cz
              </Link>
              {`, `}
              <Link target={"_blank"} className={"green"} to={`//escorts.cz`}>
                escorts.cz
              </Link>
              {`, `}
              <Link
                target={"_blank"}
                className={"green"}
                to={`/secretgirlprague.com`}
              >
                secretgirlprague.com
              </Link>
              {`, `}
              <Link
                target={"_blank"}
                className={"green"}
                to={`/dobryprivat.cz`}
              >
                dobryprivat.cz
              </Link>
            </p>
            

            <h3 id="linkingmodels"><b>{t("linkingmodels")}</b></h3>
            <p>{t("ifemploy")}{' '}
              <Link to={"#verify"}>{t("verified1")}</Link>
              {` ${t("and")} `}
              <Link to={"#healthControl"}>{t("healthy")}</Link>
              , {t('notneedcreate')}.
            </p>
            <AttentionBox type={"important"}>
              {t("allincomes")}.
            </AttentionBox>
            <p>{t("markany")} {' '}
              <Link to={"/lk/profiles"} className={"green d-inline"}>{t("indistatus")}</Link>
              {`, ${t("inthesection")} `}
              <Link to={"#statuses"} className={"green d-inline"}>{t("myadsall")}</Link>. {' '}
               {t('adswithstatus')}<b> INDI</b>,
              {t('displayedon')}, {t('notdisplayed')}.
            </p>

            <AttentionBox tag={"div"} type={"advice"}>
              <ul>
                <li>{t("i-completedads")}.</li>
                <li>{t("i-includedetails")}.</li>
                <li>{t("i-loadbeatyphoto")}.</li>
                <li>{t("ifvideo")}.</li>
                <li>{t("aigen")}.</li>
                <li>{t("i-fillmessengers")}.</li>
                <li>{t("i-pinninglocation")}.</li>
              </ul>
            </AttentionBox>

            <p className={"guide__box d-block"}>
              {t("i-contactmoderator")}{" "}
              <Link to={"/lk/support"}>
                {t("i-moderator")}
              </Link>
              .
            </p>

            <h3 id="mediaContent">
              {t("i-mediacontent")}: {t("i-contentmedia")}
            </h3>

            <p>{t("i-photosandvideos")}.</p>

            <AttentionBox tag={"div"} type={"advice"}>
              <ul>
                <li>
                  {t("i-uploadero")}{' '}
                  <Link to={"#eroContent"}>
                    {t("moreinfo")}
                  </Link>
                  .
                </li>
                <li>
                  {t("i-photoeditstart")}{" "}
                  <Link to={"#videochat"}>
                    {t("videochat")}
                  </Link>
                  {" "}
                  {t("i-photoeditend")}.
                </li>
                <li>
                  {t("paidservice")}{" "}
                  <Link to={`#modelpreviews`}>
                    {t("modelpreviews")}
                  </Link> 
                  {` ${t('makesensible')}`}.
                </li>

                <li>{t("i-storiesrelease")}</li>
                <li>{`${t("logoand")} `}
                  <Link to={`/lk/agency`}>
                    {t("profuser")}
                  </Link>.</li>
              </ul>
            </AttentionBox>

            <AttentionBox type={"important"}>
              {t("pesonalresp")}{" "}
              <Link to={"/rules"} target={"_blank"} className={"green"}>
                {t("portalules")}
              </Link>{" "}
              {t("notsanction")}.
              <br />
              <br />{" "}
              <span title={t("photosverified")} className={"model__icon"}>
                <Icon spritePath={"verify-fill"} size={"l"} />
                {t("verification")}
              </span>{" "}
              {t("and")}{" "}
              <span title={t("advantagehealth")} className={"model__icon"}>
                <Icon spritePath={"health-fill"} size={"l"} />
                {t("healthy")}
              </span>{" "}
              – {t("toget")}.
            </AttentionBox>

            <h3 id={"questionnairePosition"}>{t("i-positionpromo")}</h3>
            
            <p>
              {t("i-sortedprofiles")}.
            </p>
            <div className="guide__flexbox">
              <p>
                {t("i-sortedprofiles2")}{" "}
              <Link to={"/lk/profiles"}>{t("myadsall")}</Link>.
              <br/>
              <br/>
              {` ${t("i-yourposition")} TopLady.`}
              </p>
              <div className="guide__status">
                <img
                  src={theme === "light" ? topLadyLight : topLady}
                  alt="Top Lady"
                />
              </div>
            </div>

            <AttentionBox tag={"div"} type={"advice"}>
              <ul>
                <li>
                  {t("a-aktivate")}.
                </li>
                <li>
                  {t("i-lookatpositions")}{" "}
                  <Link
                    to={"/lk/profiles"}
                  >
                    {t("i-yourcards")}
                  </Link>
                  , {t("i-beingtop")}.
                </li>
                <li>
                  {t("i-promotion")}{" "}
                  <Link to={"/lk/statistic"}>{t("i-detailstat")}</Link>.
                </li>
              </ul>
            </AttentionBox>

            <h3 id="statusElite">{t("Status")} Elite Lady</h3>
            <div className={"guide__flexbox"}>
              <p>
                {t("i-elitetext")}.
                <br/>
                <br/>
                Elite Lady {t("i-elitedescription")}{" "}
                <Link
                  target={"_blank"}
                  to={`/${lang}/${city}/elitelady`}
                  className={"green"}
                >
                  https://lady4love.com/elitelady
                </Link>
                , {t("i-vipclients")}.
              </p>
              <Link
                target={"_blank"}
                to={`/${city}/elitelady`}
                className="guide__status"
              >
                <img src={eliteLady} alt="Elite Lady" />
              </Link>
            </div>

            <EliteStatusCards />

            <h3 id="statuses">
              {t("i-statusesobtain")}:
            </h3>

            <GuideTags />

            <h3 id="verify">
              {IconTemplate("verified1", "verify-fill")}
              {t("verification")}
            </h3>

            <p>
              {t("verification")}{' – '}{t("i-verifytext")}.
            </p>

            <div className="guide__box guide__box--verify">
              <div>
                <span className={"color-600"}>{t("procedure")}:</span>
                <p>{t("uploadselfi")}.</p>
                <b>{t("i-photoadmin")}</b>
                <p>{t("i-moderrequest")}.</p>
              </div>
  
              <div className="guide__box-img">
                <span className={"p2"}>{t("example")}</span>
                <img src={verifyPicture} alt="" />
              </div>
            </div>
            <p>
                  {t("i-altmethod")} 1.{' – '}<b>{t("i-moderatorcall")}</b> (
                  {t("i-payfunction")}).
                </p>
                <p>
                  {t("i-altmethod")} 2.{' – '}<b>{t("moderatorvisit")}</b>{" – "}
                  {`${t("fastmoderation")}, `} 
                  <Link
                    target={"_blank"}
                    to={`/lk/support`}
                  >
                    {t("i-phonesupport")}
                  </Link>
                  {` ${t("tolearnmore")}.`}
                </p>


              <AttentionBox type={"important"}>
                  {t('eachad')}.
              </AttentionBox>

            <h3 id="healthControl">
              {IconTemplate("healthy", "health-fill")}
              {t("healthy")}
            </h3>

            <p>
              {t("healthy")} – {t("i-healthytext")}.
            </p>

            <div className="guide__box guide__box--verify">
              <div>
                <span className={"color-600"}>{t("procedure")}:</span>
                <p>{t("i-verifyperson")}.</p>
                <b>{t("i-photoadmin")}</b>
                <p>{t("i-testresults")}.</p>
              </div>

              <div className="guide__box-img">
                <span className={"p2"}>{t("example")}</span>
                <img src={healtyPicture} alt="" />
              </div>
            </div>
            
            <AttentionBox type={"important"}>
                  {t('helthindividual')}.
            </AttentionBox>
            

            <h3 id="specials">{t("i-otherfeatures")}:</h3>
            <p>
              <b>{t("services")}</b> – {t("i-servicestext")}.
            </p>
            <p>
              <b>{t("i-welcoming")}</b> – {t("i-welcomingtext")}.
            </p>
            <p>
              <b>{t("i-tariffsettings")}</b> – {t("i-tariffsettingstext")}.
            </p>
            <p>
              <b>{t("i-workdiscount")}</b> – {t("i-workdiscounttext")}.
            </p>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2 id="howToEarn">{t("i-howearmoney")}</h2>

            <p>{t("i-freepost")}.</p>

            <p>
              {t("i-onfunction")}{" "}
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/${lang}/lk/videochats`);
                }}
                to={"/lk/videochats"}
                target={"_blank"}
              >
                «{t("videochat")}»
              </Link>
              {" ,"}
              <Link to={"/lk/reservations"} target={"_blank"}>
                «{t("onlineorder")}»
              </Link>{" "}{t("or")} <Link to={"/lk/previews"} target={"_blank"}>
                «{t("modelpreviews")}»
              </Link>
              {" - "}{t("i-readytoorder")}, {t("traveltoprev")}.
            </p>
            <img
              className={"img-contain b-radius-24"}
              src={howToEarnImg}
              alt=""
            />

            <h3 id="eroCoins">
              {IconTemplate("EroCoins", "erocoin")}
              EroCoins {t("and")} {t("i-payonportal")}
            </h3>

            <p>
              {t("i-cointext1")} <b>EroCoin</b>. {t("i-cointext2")}.
            </p>
            <p>{t("i-coinconvert")}.</p>

            <p>{t("i-coinsell")}.</p>

            <p>{t("i-coindeposit")}:</p>

            <ul>
              <li>{t("i-banktransit")} (IBAN).</li>
              <li>{t("i-bankcard")}.</li>
              <li>PayPal</li>
              <li>{t("i-crypto")}.</li>
            </ul>


            <h3 id="videochat">
              {IconTemplate("videochat", "video")}

              <b>{t("videochat")}</b>
            </h3>

            <p><b>{t("videochat")}</b> – {t("i-vidchattext")}.{' '}
            <Link to={"/lk/videochats"}>
                {t("goto")}
              </Link>.</p>

            <div className="guide__box _column">
              <span>{t("procedure")}:</span>
              <p>
                {t("clientstart")}.
                </p><p>
                {t("operatornotice")}{'. '}<b>{t("timeroaccept")} - 2 {t("s-minits")}</b>
                </p><p>
                {t('foreachmin')}
                <b>
                  {` ${t("i-youget")} EroCoin,`}
                </b>{" "}
                {t("i-allowsearn")}.
              </p>
            </div>

            <h3 id="onlineOrder">
              {IconTemplate("onlineorder", "pay-content")}

              {t("onlineorder")}
            </h3>

            <p>
              <b>{t("onlineorder")}</b> – {t("i-alloworder")}.
            </p>

            <div className="guide__box _column">
              <span>{t("procedure")}:</span>
              <p>
                {t("i-notification")} <b>{t("i-orderarrow")}</b>{" "}
                {t("i-ordergiarantie")}.
              </p>
            </div>

            <h3 id="modelpreviews">
              {IconTemplate("casting", "casting")}

              {t("modelpreviews")}
            </h3>
            <p>
              <b>{t("modelpreviews")}</b> - {t("clientpays")}.
            </p>
            <div className="guide__box _column">
              <span>{t("procedure")}:</span>
              <p>1.{' '} 
                {t("inthesection")}{' '}<Link to={"/lk/previews"} target={"_blank"}>{t("demos")}</Link>{' '}
                {t("pricepreview")}.
              </p>
              <p>
                2.{' '} 
                {t("inthesection")}{' '}<Link to={"/lk/profiles"} target={"_blank"}>{t("myadsall")}</Link>{' '}
                {t('allorseveral')}
              </p>
              <p>3.{' '} 
                {t('orderprev')}
              </p>
            </div>

            <h3 id="eroContent">
              {IconTemplate("erocontent", "pants")}

              {t("erocontent")}
            </h3>
            <p>
              <b>{t("erocontent")}</b> - {t("i-erodownload")}.
            </p>

            <div className="guide__box _column">
              <span>{t("procedure")}:</span>
              <p>
                {t("i-eromedia")}. <b>{t("i-profitacc")}.</b>
              </p>
            </div>

            <AttentionBox type={"important"}>
              {t("i-eroprovit")} <b>{t("i-only")}</b> {t("i-eroprovit2")}.
            </AttentionBox>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2>{t("i-rulesandinfo")}</h2>

            <h3 id="statistic">{t("i-detailstath3")}</h3>
            <p>
              {t("i-viewcount")} <b>{t("i-detailstattext")}.</b>
              <br />
              <br />
              {t("i-graph")}.{" "}
              <Link target={"_blank"} to={"/lk/statistic"}>
                {t("i-gotostat")}
              </Link>
              .
            </p>

            <h3 id="yourContacts">
              Email, WhatsApp {t("and")} Telegram {t("i-inyour")} {t("profile")}
            </h3>
            <p>
              WhatsApp {t("and")} Telegram – {t("i-socialtext")}.
            </p>
            <p>{t("i-hideemail")}.</p>
            <AttentionBox type={"important"}>
              {t("i-phonetext")}{" "}
              <Link to={"/lk/support"} target={"_blank"}>
                {t("i-phonesupport")}
              </Link>{" "}
              {t("i-phonechange")}.
            </AttentionBox>

            {/*//////////////////////////////////////////////////////////////////////////*/}

            <h2 id={"uniqueFeatures"}>{t("i-uniquefeatures")}</h2>

            <h3>{t("i-exportourside")}</h3>
            <p>{t("i-scratch")}.</p>

            <h3 className={"mb-16"}>{t("i-changecity")}</h3>
            <p>{t("i-changecitytext")}.</p>
            

            <div className="guide__box--friend-agency">
              <div>
                <h3>{t("i-method")}</h3>
                <p>{t("i-methodtext")}.</p>
              </div>

              <div>
                <h3>{t("linkprofile")}</h3>
                <p><b>{t("linkprofile")}</b>{' - '}{t("alreadyworks")}.</p>
              </div>

              <div>
                <h3>{t("autofill")}</h3>
                <p><b>{t("autofill")}</b>{' - '}{t("autoapp")}.</p>
              </div>

              <div>
                <h3>{t("operatormanage")}</h3>
                <p><b>{t("operatormanage")}</b>{' - '}{t("operatormanageand")}{' '}<Link to={"/lk/support"}>
                {t("myoperator")}.
              </Link></p>
              </div>

              <div>
                <h3>{t("partisorg")}</h3>
                <p><b>{t("partisorg")}</b>{' - '}{t("creatingevents")}.</p>
              </div>

              <div>
                <h3>{t("bulkedit")}</h3>
                <p><b>{t("bulkedit")}</b>{' - '}{t("savetime")}.</p>
              </div>

              <div>
                <h3>{t("i-closecounry")}</h3>
                <p>{t("i-closecheck")}.</p>
              </div>
            </div>

            <h3>{t("i-offprofile")}</h3>
            <p>{t("i-offprofiletext")}.</p>
            <img
              className={"img-contain b-radius-24"}
              src={deactivateProfileImg}
              alt=""
            />

            <AttentionBox type={"important"}>
              {t("i-offprofilepromo")}.
            </AttentionBox>

            <h3>{t("crossnumber")}</h3>
            <p>
              {t("i-checknumtext1")}{" "}
              <Link
                to={"//numbuster.com/"}
                target={"_blank"}
                className={"green"}
              >
                Numbuster.
              </Link>{" "}
              {t("i-checknumres")}.
            </p>

            <h3>{t("i-photograps")}</h3>
            <p>
              {t("i-photograpstext")}{" "}
              <Link className={"green"} to={"#"}>
                {t("i-photograpscat")}.
              </Link>
            </p>

            <div className={"guide__box--friend-agency"}>
              <h3>{t("i-gfconnect")}</h3>
              <p>{t("i-gfconnecttext")}.</p>
            </div>

            <h3>{t("i-reviews")}</h3>
            <p>
              {t("i-reviewstext")}.{" "}
              <Link to={"/lk/reviews"}>{t("i-gotoreviews")}</Link>.
            </p>

            <h3>{t("i-deleteprofile")}</h3>
            <p>
              {t("i-deleteprofiletext")}.{" "}
              <Link to={"/lk/indi"}>{t("i-deleteprofile")}</Link>.
            </p>

            <AttentionBox type={"important"}>
              {t("i-deletewarning")}.
            </AttentionBox>
          </div>
        </div>
      </section>
    </main>

  {isOpenDiscount &&
  <DiscountToHealthPopup
    open={isOpenDiscount}
    setOpen={setIsOpenDiscount}
  />
  }
  </>
  );
};

export default GuideAgency;
